/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

/* -------------------------- Internal Dependencies ------------------------- */
import {
  LayoutCenter,
  GridContainer,
  LogoContainer,
  LayoutCenterTop,
} from "../../Components/Layout";
import { HeadlineM } from "../../Components/Text";
import { Button } from "../../Components/UI";
import { InputLabel } from "../../Components/Forms";
import { PasswordBox } from "../../Components/Common";
import { loginUser } from "../../app/features/authSlice";

/* -------------------------- Internal SVG Dependencies ------------------------- */
import Logo from "../../Icons/Logo";
import Toast from "../../Components/Common/Messages/Toast";

export const LoginLayout = styled.section`
  border: 1px solid #e3e3e3;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
`;

const Login = () => {
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [verified, setVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [showActiveToast, setShowActiveToast] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const tokenFromState = useSelector((state) => state.auth.token);
  const isVerified = useSelector((state) => state.auth.verified);

  const [passwordError, setpasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [initialCheck, setInitalCheck] = useState(false);

  useEffect(() => {
    setToken(tokenFromState);
    setVerified(isVerified);
  }, [tokenFromState, token, isVerified, verified]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email && password) {
      try {
        const res = await dispatch(loginUser({ email, password }));

        if (res.payload.success) {
          const { data } = res.payload;
          console.log(
            location.state?.from?.pathname,
            location.state?.from?.search,
            location
          );

          if (data.onboarded) {
            if (data.user.profile_filled) {
              const previousLocation = location.state?.from || location;
              const { pathname, search } = previousLocation;
              const previousPathName = `${pathname}${search}`;
              if(window.location.pathname === previousPathName) {
                navigate("/");
                window.location.reload();
              }
              setTimeout(() => {
                navigate(previousPathName);
                window.location.reload();
              }, 500);
            } else {
              navigate("/userinfo");
            }
          } else {
            navigate("/");
            window.location.reload();
          }
        } else {
          if (res.payload.data.error === "This profile is not activated yet.") {
            setShowActiveToast(true);
          } else {
            setShowToast(true);
          }
        }
      } catch (error) {
        console.error("An error occurred during login:", error);
        setShowToast(true);
      }
    }
    setInitalCheck(true);
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/;
    if (!email.match(emailRegex) && initialCheck) {
      setEmailError(true);
    } else if (email.match(emailRegex) && initialCheck) {
      setEmailError(false);
    }
    if (password.length < 8 && initialCheck) {
      setpasswordError(true);
    } else if (password.length > 7 && initialCheck) {
      setpasswordError(false);
    }
  }, [email, password, initialCheck]);

  return (
    <LayoutCenter>
      <Toast
        showToast={showToast}
        message={"Wrong credentials"}
        setShowToast={setShowToast}
      />
      <Toast
        showToast={showActiveToast}
        message={"Your profile is not activated"}
        setShowToast={setShowActiveToast}
      />
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <LoginLayout>
        <HeadlineM mt="0px">Login</HeadlineM>
        <form onSubmit={handleSubmit}>
          <InputLabel
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email address"
            error={emailError}
            email
            name="email"
          />
          <PasswordBox
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            mt16
            type={showPassword ? "text" : "password"}
            setShowPassword={setShowPassword}
            showPassword={showPassword}
            error={passwordError}
            name="password"
          />

          <Button fwidth type="submit" text={"Login"} primary mt24 />
        </form>
      </LoginLayout>
      <GridContainer>
        <Button
          className="forgot-password-btn"
          text={"Forgot password?"}
          mt24="24px"
          fs="14px"
          textcolor="#042234"
          onClick={() => navigate("/forgotpassword")}
        />
        <Button
          text={"No account?"}
          mt24="24px"
          fs="14px"
          textcolor="#042234"
          // fwidth
          onClick={() => navigate("/register")}
        />
      </GridContainer>
    </LayoutCenter>
  );
};

export default Login;
