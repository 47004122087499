import styled from "styled-components";
import { SubheadingXS } from "../../Text";

const MenuButton = styled.button`
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
    padding: 10px 16px;
    background-color:  ${(props) => (props.isActive ? "var(--b-b50)" : "transparant")}; 
    border-radius: 10px;

    &:hover {
        background-color: var(--b-b50);
     }

    @media (max-width: 768px) {
        background-color: white;
        box-sizing: border-box;
        padding: 1.5rem 1rem;
        border: 1px solid #e3e3e3;
        box-shadow: 0px 1px 0px #e3e3e3;
        text-align: left;
        border-radius: 2px;
      }
`
const MenuItem = ({ Icon, label, active, onClick }) => {
    return (
        <MenuButton isActive={active} onClick={onClick}>
            {Icon || null}
            <SubheadingXS>{label}</SubheadingXS>
        </MenuButton>);
}

export default MenuItem;