import * as React from "react"

const ChatFileUpload = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill="#042234"
      d="M9.54 2.21a1.584 1.584 0 0 0-2.227 0l-4.5 4.5c-1.008 1.032-1.008 2.696 0 3.704a2.631 2.631 0 0 0 3.726 0l3.563-3.562c.21-.211.562-.211.773 0 .234.234.234.585 0 .796l-3.563 3.563a3.693 3.693 0 0 1-5.273 0C.562 9.758.562 7.39 2.04 5.914l4.5-4.476a2.646 2.646 0 0 1 3.774 0 2.646 2.646 0 0 1 0 3.773l-4.29 4.312a1.76 1.76 0 0 1-2.625-.14 1.76 1.76 0 0 1 .141-2.344l3.563-3.562c.21-.211.562-.211.773 0 .234.234.234.586 0 .796l-3.54 3.563a.617.617 0 0 0-.046.844c.234.304.656.328.938.047l4.312-4.313c.61-.586.61-1.594 0-2.203Z"
    />
  </svg>
)
export default ChatFileUpload
