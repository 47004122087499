import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import CustomCheckbox from "../../Components/Common/FormInputs/Checkbox";
import { ErrorMessage, SubheadingS } from "../../Components/Text";
import { InputLabel, RadioButton } from "../../Components/Forms";
import { Select } from "../../Components/Common/FormInputs/Select";
import { useSelector } from "react-redux";
import { transformToSelectData } from "../../Utils/form-functions";
import { handleOtherCheckboxChange } from "../../Components/Common/FormInputs/formFunctions";

const CreativeForm = () => {
  const countriesData = useSelector((state) => state.countries?.countries);
  const languagesData = useSelector((state) => state.languages?.languages);

  const {
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const handleChange = (name, checked, key, value) => {
    const currentValues = getValues(name) || {};
    const updatedValues = { ...currentValues };

    if (checked) {
      updatedValues[key] = value;
    } else {
      delete updatedValues[key];
    }

    setValue(name, updatedValues);

    if (Object.keys(updatedValues).length > 0) {
      clearErrors(name);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {/** Product included*/}
      <div id="influencer_product">
        <SubheadingS mb="16px">Is there a product included?</SubheadingS>
        <Controller
          name="influencer_product"
          control={control}
          defaultValue={0}
          render={({ field }) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                marginTop: 8,
              }}
            >
              <RadioButton
                label="Yes"
                value={1}
                checked={field.value === 1}
                onChange={() => field.onChange(1)}
              />
              <RadioButton
                label="No"
                value={0}
                checked={field.value === 0}
                onChange={() => field.onChange(0)}
              />
            </div>
          )}
        />
      </div>
      {/** Influencer occupations **/}
      <div id="influencer_occupations">
        <SubheadingS>The occupation of the creative</SubheadingS>
        <Controller
          name="influencer_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.0`}
              text="Floral designer"
              checked={field.value?.["0"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "0", "Floral designer")
              }
            />
          )}
        />
        <Controller
          name="influencer_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.1`}
              text="Florist / Flower shop"
              checked={field.value?.["1"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "1", "Florist / Flower shop")
              }
            />
          )}
        />
        <Controller
          name="influencer_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.2`}
              text="Photographer"
              checked={field.value?.["2"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "2", "Photographer")
              }
            />
          )}
        />
        <Controller
          name="influencer_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.3`}
              text="Videographer"
              checked={field.value?.["3"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "3", "Videographer")
              }
            />
          )}
        />
        <Controller
          name="influencer_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.4`}
              text="Blogger"
              checked={field.value?.["4"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "4", "Blogger")
              }
            />
          )}
        />
        <Controller
          name="influencer_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.5`}
              text="Influencer"
              checked={field.value?.["5"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "5", "Influencer")
              }
            />
          )}
        />
        <Controller
          name="influencer_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.6`}
              text="Marketeer"
              checked={field.value?.["6"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "6", "Marketeer")
              }
            />
          )}
        />
        <Controller
          name="influencer_occupations"
          control={control}
          render={({ field }) => {
            const checked =
              field.value?.["Other occupations"] !== null &&
              field.value?.["Other occupations"] !== undefined;
            const text = field.value?.["Other occupations"] || "";

            const handleInputChange = (e) => {
              setValue(field.name, {
                ...field.value,
                "Other occupations": e.target.value,
              });
            };
            return (
              <div>
                <CustomCheckbox
                  id="Other occupations"
                  text="Other occupations"
                  checked={checked}
                  onChange={(checked) =>
                    handleOtherCheckboxChange(
                      field.name,
                      field.value,
                      setValue,
                      "Other occupations",
                      checked
                    )
                  }
                />
                {checked && (
                  <InputLabel
                    placeholder="Please specify"
                    value={text}
                    onChange={handleInputChange}
                  />
                )}
              </div>
            );
          }}
        />
        {errors?.influencer_occupations && (
          <ErrorMessage message={errors?.influencer_occupations?.message} />
        )}
      </div>

      <div id="audience_occupations">
        {/** Audience occupations */}
        <SubheadingS>My audience(s) occupations</SubheadingS>

        <Controller
          name="audience_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.0`}
              text="Florists / Florist shops"
              checked={field.value?.["0"]}
              onChange={(checked) =>
                handleChange(
                  field.name,
                  checked,
                  "0",
                  "Florists / Florist shops"
                )
              }
            />
          )}
        />
        <Controller
          name="audience_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.1`}
              text="Wholesalers"
              checked={field.value?.["1"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "1", "Wholesalers")
              }
            />
          )}
        />
        <Controller
          name="audience_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.2`}
              text="Growers"
              checked={field.value?.["2"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "2", "Growers")
              }
            />
          )}
        />
        <Controller
          name="audience_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.3`}
              text="Breeders"
              checked={field.value?.["3"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "3", "Breeders")
              }
            />
          )}
        />
        <Controller
          name="audience_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.4`}
              text="Consumers buying flowers"
              checked={field.value?.["4"]}
              onChange={(checked) =>
                handleChange(
                  field.name,
                  checked,
                  "4",
                  "Consumers buying flowers"
                )
              }
            />
          )}
        />
        <Controller
          name="audience_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.5`}
              text="Consumers buying plants"
              checked={field.value?.["5"]}
              onChange={(checked) =>
                handleChange(
                  field.name,
                  checked,
                  "5",
                  "Consumers buying plants"
                )
              }
            />
          )}
        />
        <Controller
          name="audience_occupations"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.6`}
              text="I'm not sure"
              checked={field.value?.["6"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "6", "I'm not sure")
              }
            />
          )}
        />
        <Controller
          name="audience_occupations"
          control={control}
          render={({ field }) => {
            const checked =
              field.value?.["Others"] !== null &&
              field.value?.["Others"] !== undefined;

            const text = field.value?.["Others"] || "";

            const handleInputChange = (e) => {
              setValue(field.name, { ...field.value, Others: e.target.value });
            };

            return (
              <div>
                <CustomCheckbox
                  id="Others"
                  text="Others"
                  checked={checked}
                  onChange={(checked) =>
                    handleOtherCheckboxChange(
                      field.name,
                      field.value,
                      setValue,
                      "Others",
                      checked
                    )
                  }
                />
                {checked && (
                  <InputLabel
                    placeholder="Please specify"
                    value={text}
                    onChange={handleInputChange}
                  />
                )}
              </div>
            );
          }}
        />
      
        {errors?.audience_occupations && (
          <ErrorMessage message={errors?.audience_occupations?.message} />
        )}
      </div>

      <div id="audience_countries">
        <SubheadingS mb="16px">My audience countries </SubheadingS>
        <Controller
          name="audience_countries"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <Select
              multiple
              options={transformToSelectData(countriesData, "name", "id")}
              value={field.value}
              onChange={(o) => field.onChange(o)}
              placeholder="Select the country of your audience"
            />
          )}
        />
        {errors?.audience_countries && (
          <ErrorMessage message={errors?.audience_countries?.message} />
        )}
      </div>
      <div id="influencer_languages">
        <SubheadingS mb="16px">Creative languages</SubheadingS>
        <Controller
          name="influencer_languages"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <Select
              multiple
              options={transformToSelectData(languagesData, "name", "id")}
              value={field.value}
              onChange={(o) => field.onChange(o)}
              placeholder="Select the language of the reative"
            />
          )}
        />
        {errors?.influencer_languages && (
          <ErrorMessage message={errors?.influencer_languages?.message} />
        )}
      </div>
    </div>
  );
};

export default CreativeForm;
