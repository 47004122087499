// CustomCalendar.js
import React from 'react';
import Calendar from 'react-calendar';
import { isSameDay } from 'date-fns';
import 'react-calendar/dist/Calendar.css';
import './CustomCalendar.css';

function CustomCalendar({ selectedDate, onSelectDate }) {
  const handleDateChange = (newDate) => {
    onSelectDate(newDate);
  };

  const tileDisabled = ({ date }) => {
    const currentDate = new Date();
    return date < currentDate && !isSameDay(date, currentDate);
  };

  const tileClassName = ({ date }) => {
    const currentDate = new Date();
    return date < currentDate ? 'disabled-date' : '';
  };

  const locale = 'en-US';

  return (
    <div style={{zIndex:'99'}}>
      <Calendar
        onChange={handleDateChange}
        value={selectedDate}
        locale={locale}
        tileClassName={tileClassName} 
        tileDisabled={tileDisabled}
      />
    </div>
  );
}

export default CustomCalendar;
