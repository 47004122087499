import React, {useEffect, useState} from 'react'
import { CustomCheckbox, InputLabel } from '../../Forms';

function CheckboxWithInput({selectedValues, inputStyles, title, onChange, inputValue, question, isMandatory }) {
    const [checked, setChecked] = useState()
    const [text, setText] = useState('')

    useEffect(()=>{
        //setDefaultValues
        if(selectedValues){
            if (selectedValues[title]) {
                setText(selectedValues[title])
                setChecked(true);
            } else {
                setChecked(false);
            }
        }
  
    },[selectedValues])

    useEffect(() => {
        if(!checked) {
            setText(null)
            onChange(question, title, null, 'input', true)
        }else{
            onChange(question, title, text, 'input')
        }
    }, [checked])

    const onClickParentCheckBox = async ()=>{
        setChecked(!checked)
    }

    const onInputChange = (e) =>{
        setText(e.target.value,)
        onChange(question, title, e.target.value, 'input')
    }
 
    return (
        <div>
            <CustomCheckbox
                id={title}
                tabIndex={-1}
                text={title}
                checked={checked}
                onChange={onClickParentCheckBox}
                onClick={onClickParentCheckBox}
                grey={isMandatory}
            />
            {checked && <InputLabel 
                placeholder={inputValue}
                value={text}
                onChange={(e) => onInputChange(e)}
                inputStyles={inputStyles}
                />}
            
        </div>
    )
}
export default CheckboxWithInput;
