import * as React from "react"
const FlagBig = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#031B2A"
      d="M14.875 0c-.219 0-.406.063-.625.156-1.406.656-2.469.875-3.344.875-1.875 0-2.969-1-5.125-1-1.093 0-2.437.25-4.281 1V.75A.76.76 0 0 0 .75 0 .74.74 0 0 0 0 .75v14.5c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75V12c1.656-.719 3-.969 4.125-.969 2.313 0 3.906.969 6.219.969 1 0 2.125-.188 3.469-.719.437-.156.687-.5.687-.875V.97C16 .375 15.469 0 14.875 0ZM14.5 10c-.969.344-1.844.5-2.656.5-.969 0-1.813-.219-2.75-.438-1-.25-2.125-.53-3.469-.53-1.313 0-2.688.28-4.125.843V2.656l.563-.25c1.468-.594 2.687-.875 3.718-.875.875 0 1.532.219 2.282.438.78.25 1.687.562 2.843.562 1.094 0 2.25-.281 3.594-.844V10Z"
    />
  </svg>
)
export default FlagBig
