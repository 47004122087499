


import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import useCamapignOptionsModal from "../../../app/hooks/useCampaignOptionsModal";
import CampaignOptionsModal from "../../../app/modals/CampaignOptionsModal";
import apiClient from "../../../services/api";
import Details from "../../Common/Details";

const StyledContainer = styled.section`
  width: 100%;
  min-height: 100vh;
`

const DetailsFilter = ({campaignData}) => {
  // const location = useLocation();
  // const { campaignData } = location.state;
  const [currentComponent, setCurrentComponent] = useState('Brief');
  const user = useSelector((state) => state.profile.profile);
  const [campaignDataDetail, setCampaignDataDetail] = useState()
  
  const campaignOptionsModal = useCamapignOptionsModal();
  const switchToComponent = (component) => {
    setCurrentComponent(component);
  };
  const [pitchStatus, setPitchStatus] = useState(false)
  const [status, setStatus] = useState('')


  useEffect(()=>{
    apiClient.get(`campaign/${campaignData.id}`).then((response) => {
      setCampaignDataDetail(response.data.data)
    })
  },[pitchStatus, status])
    
  return (
    <StyledContainer>
      <Details campaignData={campaignData}/>
      <CampaignOptionsModal
        campaignData={campaignDataDetail}
        setPitchStatus={setPitchStatus}
        setStatus={setStatus}
      />
    </StyledContainer>
  );
};

export default DetailsFilter;
