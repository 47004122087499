import styled from "styled-components";
import { SubheadingS } from "../../../Components/Text";

const PitchOptionContainer = styled.button`
    width: 100%;
    display: flex;
    background: transparent;
    border: none;
    padding: 12px 0;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
    &:active {
        background-color: var(--g-s50);
    }

`
const IconContainer = styled.div`
    background: var(--g-s50);
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
`

const PitchOption = ({
    title,
    Icon,
    onClick,
    disable,
    textAlign
}) => {
    return (
        <PitchOptionContainer onClick={onClick} >
            {Icon && (
                <IconContainer>
                    {Icon}
                </IconContainer>
            )
            }
            <SubheadingS mt={"0px"} textAlign={textAlign}>{title}</SubheadingS>
        </PitchOptionContainer>);
}

export default PitchOption;