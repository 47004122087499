import React from 'react'

const Balloon = (props) => {
    return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8086 0.501953H1.80859C0.964844 0.501953 0.308594 1.18164 0.308594 2.00195V8.72852C0.308594 9.54883 0.964844 10.2051 1.80859 10.2051H4.05859V12.1738C4.05859 12.4082 4.31641 12.5254 4.50391 12.3848L7.43359 10.2051H10.8086C11.6289 10.2051 12.3086 9.52539 12.3086 8.72852V2.00195C12.3086 1.18164 11.6523 0.501953 10.8086 0.501953ZM11.207 8.75195C11.207 8.96289 11.0195 9.12695 10.832 9.12695H7.08203L5.20703 10.5332V9.12695H1.83203C1.62109 9.12695 1.45703 8.96289 1.45703 8.75195V2.00195C1.45703 1.81445 1.62109 1.62695 1.83203 1.62695H10.832C11.0195 1.62695 11.207 1.81445 11.207 2.00195V8.75195Z" fill="#031B2A"/>
    </svg>
    );
  }
  
  export default Balloon;