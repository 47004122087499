import styled from 'styled-components';

import { HeadlineM } from '../../../Text';
import CampaignInformation from './CampaignInformation';
import PitchInformation from './PitchInformation';

const Wrapper = styled.div`
  background-color: white;
  padding: 1rem;
  margin-bottom: 16px;
  margin-top: 16px;

  @media only screen and (max-width: 768px) {
    margin-top: 8px;
    margin-bottom: 16px;
    width: 84vw;
    max-width: var(--max-width);
}
`;

const SecondWrapper = styled.div`
background-color: white;
padding: 1rem; 
margin-top: 16px;

@media only screen and (max-width: 768px) {
  margin-bottom: 16px;

}
`

const BriefContainer = styled.div`
height: 100%;
width: 100%;
background: var(--g-s50);

.innerHtml{
  font-family: "Avenir LT Std" !important;

  a:link{ 
    color: var(--p500);
  }
  
  & * {
    font-family: "Avenir LT Std" !important;
  }
  
  &>*{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: "Avenir LT Std" !important;
  }
}

@media only screen and (max-width: 768px) {
  padding: 1.5rem;
  padding-bottom: 140px;

}

`;

const Brief = ({ campaignData }) => {
  return (
    <BriefContainer>
    <HeadlineM mt={"0px"} color={"#042234"}>Overview</HeadlineM>

      <Wrapper>
        <CampaignInformation
          marketing_goals={campaignData.marketing_goals}
          company_name={campaignData.company_name}
          influencer_product={campaignData.influencer_product}
          channels={campaignData.profile_channels}
        />
        <div style={{marginTop: '16px'}}>
        <PitchInformation
          budget_amount={campaignData.budget_amount}
          pitch_deadline={campaignData.pitch_deadline}
        />
        </div>
      </Wrapper>
      {/*TO DO: install html-react-parser and use parse */}
      <SecondWrapper>
       <div className="innerHtml" dangerouslySetInnerHTML={{ __html: campaignData.description }}></div>
      </SecondWrapper>
    </BriefContainer>
  )
}

export default Brief
