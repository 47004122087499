/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components';

export const FlexContainer = styled.div`
	display: flex;
	flex-direction: ${(p) => (p.flexColumn ? "column" : "row")};
	align-items: ${(p) => (p.alignItems ? p.alignItems : "flex-start")};
	justify-content: ${(p) => (p.justifyContent ? p.justifyContent : "flex-start")};
	margin-top: ${(p) => (p.mt ? p.mt : "0px")};
	gap: ${(p) => (p.gap8 ? "8px" : "0px")};
	
`;
