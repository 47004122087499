import styled from "styled-components";

import { Container } from "../../Layout";
import UserMenu from "./UserMenu";

const StyledNavbarContainer = styled.div`
    width: 100vw;
	position: fixed;
	zindex: 40;
	top: 0;
	left: 0;
	right: 0;
	margin-bottom: 140px;
	z-index: 40;
	box-shadow: 0px 1px 2px rgba(164, 175, 182, 1);
    background-color: #ffffff;
    box-sizing: border-box;

`;

const Navbar = () => {
    return (
        <StyledNavbarContainer>
            <Container>
                <UserMenu />
            </Container>
        </StyledNavbarContainer>

    );
}

export default Navbar;