import React from "react"
import IdealInfluencer from "../../Common/IdealInfluencer";

const IdealCreativeFilter = ({campaignData}) => {
  return (
    <div>
      <IdealInfluencer data={campaignData} />
    </div>
  )
};

export default IdealCreativeFilter;
