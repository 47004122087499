import React, { useEffect, useState } from "react";
import styled from "styled-components";

const AvatarBox = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;
    border-radius: 500px;
    border: ${(p) => (p.border? p.border : 
            p.size ? "1px solid var(--b-b100)" : "2px solid var(--b-b100)")};
    height:${(p) => (p.size ? p.size : "80px")};
    width:${(p) => (p.size ? p.size : "80px")};
	background-color: var(--b-b50);
`;


const StyledImage = styled.img`
	height: 100%;
	width: 100%;
    object-fit: cover;
`;
const AvatarStatic = ({ userImage, size, border}) => {
    const [image, setImage] = useState();

    useEffect(() => {
        if (userImage) {
            setImage(userImage)
        }

    }, [userImage])

    return (
        <div style={{ width: size }}>
            <AvatarBox size={size} border={border}>

                {image && (
                    <StyledImage src={image} alt="Avatar" />
                )}

            </AvatarBox>
        </div>
    );
}

export default AvatarStatic;