import React from 'react'
import styled from 'styled-components';

import { ParagraphS, SubheadingS, SubheadingXS, SubheadingXXS } from '../../Text';
import ParagraphXS from '../../Text/ParagraphXS';

const StyledButtonChannels = styled.div`
background-color: #EBEEEF;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Description = styled.div`
  .sub_heading_xxs {
    margin: 8px 0rem 8px 0rem;
    }

  .sub_heading_s {
    margin-bottom: 8px;
  }

  .innerHtml {
    font-family: "Avenir LT Std";
    font-weight: 400;

  a:link {
    color: var(--p500);
  }
`;
const Tags = styled.div`
  .sub_heading_xxs {
      background-color: var(--g-s50);
      margin: 1rem .5rem 1rem 0rem;
      padding: .5rem .7rem .5rem .7rem;
      width: auto;
      border-radius: 75px;
      display: inline-block;
      color: var(--b-b500);
    }

    a{
      color: black;
    }
`;
const Tag = styled.div`
  display: inline-block;

  .sub_heading_xs {
    background-color: var(--g-s50);
    padding: .5rem .7rem .5rem .7rem;
    width: auto;
    border-radius: 75px;
    display: inline-block;
    color: var(--b-b500);
    display: flex;
    flex-direction: column;
  }
  .paragraph_s{
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
  }
`;

const URLS = styled.div`
  .sub_heading_s {
    margin-top: 8px;
    margin-bottom: 8px;
  }    
`;
const OtherChannels = ({data}) => {
    const hasOtherUrls = data.channels.other.other_urls ? JSON.parse(data.channels.other.other_urls) : [];
    const hasOtherRequirements = data?.channels?.other?.other_requirements;
    const hasOtherNotes = data?.channels?.other?.other_notes;

    return (
        <div>
            <StyledButtonChannels>   
                <Description>
                    <SubheadingS mt={"0px"}>Top direction:</SubheadingS>
                    {hasOtherRequirements ? (
                        <Description>
                            <div className="innerHtml" dangerouslySetInnerHTML={{ __html: hasOtherRequirements }}></div>
                        </Description>
                    )
                        : (
                            <SubheadingXXS>No instructions</SubheadingXXS>
                        )}
                </Description>
                <URLS>
                    <SubheadingS mt={"0px"}>URLS to add:</SubheadingS>
                    {hasOtherUrls.length ? (
                      hasOtherUrls.map((item, index) => (
                            <Tag>
                                <SubheadingXS key={index}>
                                    <Tags><ParagraphS><a href={item.url.startsWith('http://') || item.url.startsWith('https://') ? item.url : `https://${item.url}`} target="_blank">{item.url} </a></ParagraphS>
                                    </Tags> <Tags><ParagraphXS>{item.title}</ParagraphXS></Tags>
                                </SubheadingXS>
                            </Tag>
                        ))
                    ) : (
                        <SubheadingXXS>No urls</SubheadingXXS>
                    )}
                </URLS>
                <Description>
                    <SubheadingS mt={"0px"}>Any other notes?</SubheadingS>
                    {hasOtherNotes ? (
                        <Description>
                            <div className="innerHtml" dangerouslySetInnerHTML={{ __html: hasOtherNotes }}></div>
                        </Description>
                    )
                        : (
                            <SubheadingXXS>No other notes</SubheadingXXS>
                        )}
                </Description>
            </StyledButtonChannels>
        </div>
    )
}

export default OtherChannels
