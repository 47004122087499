import React from "react";

const Calendar = (props) => {
  return (
    <svg 
      width={props.width || "25" }
      height={props.height || "25"} 
      viewBox="0 0 25 25" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="#EBEEEF"/>
      <path d="M10.8125 8H14.1875V7.0625C14.1875 6.75781 14.4219 6.5 14.75 6.5C15.0547 6.5 15.3125 6.75781 15.3125 7.0625V8H16.25C17.0703 8 17.75 8.67969 17.75 9.5V17C17.75 17.8438 17.0703 18.5 16.25 18.5H8.75C7.90625 18.5 7.25 17.8438 7.25 17V9.5C7.25 8.67969 7.90625 8 8.75 8H9.6875V7.0625C9.6875 6.75781 9.92188 6.5 10.25 6.5C10.5547 6.5 10.8125 6.75781 10.8125 7.0625V8ZM8.375 17C8.375 17.2109 8.53906 17.375 8.75 17.375H16.25C16.4375 17.375 16.625 17.2109 16.625 17V11H8.375V17Z" fill="#031B2A"/>
      <rect x="0.5" y="0.5" width="24" height="24" rx="12" stroke={props.stroke || "#D8DCDF"}/>
    </svg>

  );
}

export default Calendar;

