import React, { useEffect, useState } from 'react'
import styled from "styled-components";

import { ParagraphM, SubheadingS } from '../../Text'

export default function CustomSelect({ label, subheading, question, labelField, onChange, defaultValue, showLabel = true, customLabel }) {
	const [options] = useState(question?.options)
	const [defaultVal, setDefaultVal] = useState()

	useEffect(() => {
		if (defaultValue) {
			if (typeof options === 'object') {
				const entries = Object.entries(options);
				const index = entries.findIndex(([key, value]) => value === defaultValue);
				if (index !== -1) {
					// The value was found in the object, and index contains its position.
					const newItem = entries?.splice(index, 1)[0]; // Remove 
					setDefaultVal(newItem[0])					
				} else {
					setDefaultVal(defaultValue)
				}
			}
		}
	}, [options, defaultValue])


	return (
		<div>
			{showLabel && <SubheadingS>{label}</SubheadingS>}
			<ParagraphM>{subheading}</ParagraphM>
			{defaultValue && 
			<StyledList
				onChange={(e) => onChange(e, question)} defaultValue={defaultValue}
			>
				{!defaultValue && customLabel && (
					<StyledOption key="customLabel" value="customLabel" >
							{customLabel}
					</StyledOption>
				)}
				{options || question &&
					typeof options === 'object' || typeof question === 'object'
					? Object.entries(options || question).map(([key, value]) => (
						<StyledOption key={key} value={key}>
							{labelField ? value[labelField] : value}
						</StyledOption>
					))
					: options?.map((option, index) => (
						<StyledOption key={index} value={option}>
							{labelField ? option[labelField] : option}
						</StyledOption>
					))}
			</StyledList>} 
			{!defaultValue && 
			<StyledList
				onChange={(e) => onChange(e, question)} defaultValue={defaultValue}
			>
				{customLabel && (
					<StyledOption key="customLabel" value="customLabel" >
						{customLabel}
					</StyledOption>
				)}
				{options || question &&
					typeof options === 'object' || typeof question === 'object'
					? Object.entries(options || question).map(([key, value]) => (
						<StyledOption key={key} value={key}>
              {labelField ? value[labelField] : value}
						</StyledOption>
					))
					: options?.map((option, index) => (
						<StyledOption key={index} value={option}>
							{labelField ? option[labelField] : option}
						</StyledOption>
					))}
			</StyledList>} 
		</div>
	)
}

const StyledList = styled.select`
	text-align: center;
	width: 100%;
	padding: 12px 16px;
	display: inline-block;
	text-align: left;
	position: relative;
	border: 1px solid #697b86;
	border-radius: 4px;
	padding: 12px 16px;
	box-sizing: border-box;
	height: 48px;
	appearance: none;
	margin-top: ${(p) => (p.mt ? p.mt : "0px")};
	background-image: url("Dropdown.png");
	background-position: right 15.5px center;
	background-repeat: no-repeat;
	cursor: pointer;
`;

const StyledOption = styled.option`
	padding: 12px 16px;
	border-left: 1px solid #697b86;
	border-right: 1px solid #697b86;
	border-bottom: 1px solid #697b86;
	width: 50px;
	height: 50px;
	cursor: pointer;
	box-sizing: border-box;
	border-radius: 4px;
`;