import React from 'react'

import { ParagraphM, SubheadingS } from '../../../Text'
import { CustomCheckbox } from '../../../Forms';
import CheckboxListSubOptions from '../CheckboxListSubOptions';
import CheckboxWithInput from '../CheckboxWithInput';
import CheckboxWithMultiInput from '../CheckboxWithMultiInput';
import { isDictionary, isValueInDictionary } from '../../../../Utils/utility-functions';

export default function MultipleSelectCheckbox({ question, onChange, selectedValues, showLabel = true }) {

    return (
        <div>
            {showLabel && <SubheadingS>{question?.label}</SubheadingS>}
            {showLabel && <ParagraphM>{question?.sublabel}</ParagraphM>}

            {Object.entries(question.options).map(([key, value]) => {
                const optionType = Array.isArray(value)
                    ? value[0] === 'multi-input'
                        ? 'multi-input'
                        : 'array'
                    : value === 'input'
                        ? 'input'
                        : 'default';

                const checked =
                    Array.isArray(selectedValues?.[question.name]) ?
                        selectedValues?.[question.name]?.includes(value) ||
                        selectedValues?.[question.name] === value :
                        isDictionary(selectedValues?.[question.name]) ?
                            isValueInDictionary(selectedValues?.[question.name], value) :
                            selectedValues?.[question.name] === value


                if (optionType === 'array') {
                    return (
                        <CheckboxListSubOptions
                            key={key}
                            title={key}
                            value={value}
                            selectedOptions={selectedValues?.[question.name] || {}}
                            onChange={onChange}
                            question={question}
                        />
                    );
                } else {
                    return (
                        optionType === 'default' ? (
                            <CustomCheckbox
                                id={value}
                                key={key}
                                text={value}
                                selectedOptions={selectedValues?.[question.name] || {}}
                                checked={checked}
                                onChange={() => onChange(question, key, value, optionType)}
                                onClick={() => onChange(question, key, value, optionType)}
                            />

                        ) : optionType === 'input' ? (
                            <CheckboxWithInput
                                key={key}
                                title={key}
                                onChange={onChange}
                                onClick={onChange}
                                question={question}
                                isChecked={true}
                                selectedValues={selectedValues?.[question.name]}
                            />
                        ) : optionType === 'multi-input' ? (
                            <CheckboxWithMultiInput
                                key={key}
                                title={key}
                                onChange={onChange}
                                onClick={onChange}
                                question={question}
                                selectedValues={selectedValues?.[question.name]}
                            />
                        ) : null
                    );
                }
            })}
        </div>
    );
}

