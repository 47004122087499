import styled from "styled-components";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import { ParagraphS, SubheadingXXS } from "../Text";
import ParagraphXS from "../Text/ParagraphXS";
import apiClient from "../../services/api";
import useNotificationMenu from "./Navbar/hooks/useNotificationMenu";

const NotificationContainer = styled.button`
  border-radius: 0.25rem;
  display: flex;
  padding: 1rem;
  align-items: flex-start;
  gap: 8px;
  background: transparent;
  width: 100%;
  border: none;
  text-align: left;
  cursor: pointer;

  &:active {
    background-color: var(--g-s50);
  }
`;

const AvatarBox = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  border-radius: 500px;
  border: 1px solid var(--b-b100);
  height: 40px;
  width: 40px;
  background-color: var(--b-b50);
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const NotificationContent = styled.div`
  flex: 1;
`;
const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--b-b100);
  padding: 10px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
`;
const NotificationCard = ({ notification }) => {
  const navigate = useNavigate();
  const notificationMenu = useNotificationMenu();

  const onNotificationHandle = () => {
    if (notification) {
      if (notification.resource.type === "chatmessage") {
        if (
          notification.resource.campaign_id &&
          notification.resource.pitch_id
        ) {
          const { campaign_id, pitch_id } = notification.resource;
          navigate(`/chat/campaign/${campaign_id}/${pitch_id}`);
        } else if (notification.resource.marketplace_id) {
          const { marketplace_id, conversation_id } = notification.resource;
          navigate(`/chat/marketplace/${marketplace_id}?conversationId=${conversation_id}`);
        }
      }
    }

    notificationMenu.onClose();
  };
  return (
    <NotificationContainer onClick={onNotificationHandle}>
      <AvatarBox>
        <Avatar src={notification?.fromuser?.image} />
      </AvatarBox>
      <NotificationContent>
        <SubheadingXXS>{notification?.title}</SubheadingXXS>
        <ParagraphS color={"#727272"} mt={"4px"}>
          {notification?.content}
        </ParagraphS>
        <ParagraphXS color={"#A4AFB6"}>{notification?.created}</ParagraphXS>
      </NotificationContent>
      <ArrowContainer>
        <BsArrowRight size={18} />
      </ArrowContainer>
    </NotificationContainer>
  );
};

export default NotificationCard;
