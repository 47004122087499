/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components'

/* -------------------------- Internal Dependencies ------------------------- */
import { FlexContainer } from '../../Layout'
import HiddenCheckboxInput from '../CheckBox/HiddenCheckboxInput'
import CheckBoxIcon from '../CheckBox/CheckBoxIcon'
import { SubheadingXS } from '../../Text'
import ArrowUp from "../../../Icons/ArrowUp"
import Minus from "../../../Icons/Minus";

const IconContainer = styled.div`
	transform: ${(p) => (p.isDown ? "rotate(180deg)" : "")};
	cursor: pointer;
`;

const StyledCheckBoxOption = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const CollapsibleCheckbox = ({ handleCheckboxChange, isChecked, label }) => {

    return (
        <StyledCheckBoxOption onClick={handleCheckboxChange} >
            <FlexContainer gap8>
                <HiddenCheckboxInput
                    handleChange={handleCheckboxChange}
                    value={label}
                />
                <CheckBoxIcon checked={isChecked} Icon={<Minus fill="#FFF" />} />
                <SubheadingXS>{label}</SubheadingXS></FlexContainer>
            <IconContainer isDown={isChecked}>
                <ArrowUp />
            </IconContainer>
        </StyledCheckBoxOption>
    )
}

export default CollapsibleCheckbox
