import React from 'react';
import styled from 'styled-components';

import Brief from './Card/Campaigns/Brief';
import Channels from '../Common/Channels/Channels';

const Section = styled.div`
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 40px;
`
const Container = styled.div`
  width: 90%;
  max-width: var(--max-width);
  height: 100%;
`;

const FlexContainer = styled.div`
  @media (min-width: 720px){
    display: flex;
    justify-content: space-between;
  }
`;

const FlexItem = styled.div`

  @media (min-width: 720px){
    width: 49%;
  }
  
`;

const Details = ({ campaignData }) => {
  return (
    <Section>
    <Container>
      <FlexContainer>
        <FlexItem>
          <Brief campaignData={campaignData} />
        </FlexItem>
        {campaignData.channels.length === 0  
        ?
          <></>
        : 
        <FlexItem>
          <Channels campaignData={campaignData} />
        </FlexItem>
        }
      </FlexContainer>
    </Container>
    </Section>
  );
};

export default Details;
