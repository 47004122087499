
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import ProfilePageInfluencer from "./ProfilePageInfluencer";
import ProfilePageCompany from "./ProfilePageCompany";
import { fetchProfile } from "../../app/features/profileSlice";
import ClipLoader from "react-spinners/ClipLoader";

const LoadingContainer = styled.div`
	widht: 100vw; 
    height: 100vh;
    background-color: rgba(54, 78, 93, 1);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ProfilePage = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.profile.profile);

    const [variant, setVariant] = useState();

    useEffect(() => {
        dispatch(fetchProfile())
    }, [])

    useEffect(() => {
        if (user) {
            if (user.data.usertype === "influencer") {
                setVariant('INFLUENCER')
            } else {
                setVariant('COMPANY')
            }
        }

    }, [user])

    if (!user) {
        return (
            <LoadingContainer>
                <ClipLoader
                    color={'#FF4E5B'}
                    loading={true}
                    size={60}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </LoadingContainer>
        )
            ;
    }

    return variant === 'INFLUENCER' ? (

        <ProfilePageInfluencer user={user?.data} />
    ) : (
        <ProfilePageCompany user={user?.data} />
    );


}

export default ProfilePage;