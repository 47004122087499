import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import CustomCheckbox from "../../Components/Common/FormInputs/Checkbox";
import { ErrorMessage, ParagraphM, SubheadingS } from "../../Components/Text";
import { InputLabel } from "../../Components/Forms";
import { Switch } from "../../Components/Common/FormInputs/Switch";
import RichTextComponent from "../../Components/Advertisers/Marketplace/RichTextComponent";
import { format, parse } from "date-fns";
import CalendarModal from "../../app/modals/calenderModal";
import { handleOtherCheckboxChange } from "../../Components/Common/FormInputs/formFunctions";
import { FileDropZone } from "../../Components/Common";
//import FileDropZone from "./FileDropZone";

const GeneralInfoForm = () => {
  const {
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const [isPitchDeadlineCalenderOpen, setIsPitchDeadlineCalendarOpen] =
    useState(false);
  const [isDeliveryDeadlineCalenderOpen, setIsDeliveryDeadlineCalendarOpen] =
    useState(false);

  //Pitch deadline
  const onClosePitchDeadlineCalender = () => {
    setIsPitchDeadlineCalendarOpen(false);
  };

  const onOpenPitchDeadlineCalender = () => {
    setIsPitchDeadlineCalendarOpen(true);
  };

  const handlePitchDeadlineDateSelect = (date) => {
    const formattedDate = format(date, "dd-MM-yyyy");
    setValue("pitch_deadline", formattedDate);
    onClosePitchDeadlineCalender();
    clearErrors("pitch_deadline");
  };

  //Delivery deadline
  const onCloseDeliveryDeadlineCalender = () => {
    setIsDeliveryDeadlineCalendarOpen(false);
  };

  const onOpenDeliveryDeadlineCalender = () => {
    setIsDeliveryDeadlineCalendarOpen(true);
  };

  const handleDeliveryDeadlineDateSelect = (date) => {
    const formattedDate = format(date, "dd-MM-yyyy");
    setValue("delivery_deadline", formattedDate);
    onCloseDeliveryDeadlineCalender();
    clearErrors("delivery_deadline");
  };

  // Checkboxes change
  const handleChange = (name, checked, key, value) => {
    const currentValues = getValues(name) || {};
    const updatedValues = { ...currentValues };

    if (checked) {
      updatedValues[key] = value;
    } else {
      delete updatedValues[key];
    }
    setValue(name, updatedValues);

    if (Object.keys(updatedValues).length > 0) {
      clearErrors(name);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {/** Published **/}
      <div id="is_published">
        <SubheadingS mb="16px">Published</SubheadingS>
        <Controller
          name="is_published"
          defaultValue={0} 
          control={control}
          render={({ field }) => (
            <Switch
              checked={field.value === 1}
              onCheckedChange={(checked) => field.onChange(checked ? 1 : 0)}
            />
          )}
        />
        {errors?.is_published && (
          <ErrorMessage message={errors.is_published.message} />
        )}
      </div>

      {/** File Drop Zone **/}
      <div id="image">
        <SubheadingS>Project image</SubheadingS>
        <Controller
          name="image"
          control={control}
          render={({ field }) => (
            <FileDropZone image={field.value} setImage={field.onChange} />
          )}
        />
        {errors?.image && <ErrorMessage message={errors.image.message} />}
      </div>

      {/** Title **/}
      <div id="title">
        <SubheadingS>Title</SubheadingS>
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <InputLabel {...field} placeholder="Enter your project title" />
          )}
        />
        {errors?.title && <ErrorMessage message={errors.title.message} />}
      </div>

      {/** Rich Text Editor **/}
      <div id="description">
        <SubheadingS>Description</SubheadingS>
        <ParagraphM mt={"4px"} mb={"8px"} color={"#727272"}>
          What do you want from the creatives?
        </ParagraphM>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <RichTextComponent
              editorContent={field.value}
              setEditorContent={field.onChange}
              placeholder="Enter description"
            />
          )}
        />
        {errors?.description && (
          <ErrorMessage message={errors.description.message} />
        )}
      </div>

      {/** Marketing goals **/}
      <div id="marketing_goals">
        <SubheadingS>Marketing goals</SubheadingS>
        <Controller
          name="marketing_goals"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.0`}
              text="Brand awareness"
              checked={field.value?.["0"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "0", "Brand awareness")
              }
            />
          )}
        />
        <Controller
          name="marketing_goals"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.1`}
              text="Reach & Engagement"
              checked={field.value?.["1"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "1", "Reach & Engagement")
              }
            />
          )}
        />
        <Controller
          name="marketing_goals"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.2`}
              text="Findability"
              checked={field.value?.["2"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "2", "Findability")
              }
            />
          )}
        />
        <Controller
          name="marketing_goals"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.3`}
              text="Product promotion"
              checked={field.value?.["3"]}
              onChange={(checked) =>
                handleChange(field.name, checked, "3", "Product promotion")
              }
            />
          )}
        />
        <Controller
          name="marketing_goals"
          control={control}
          render={({ field }) => (
            <CustomCheckbox
              id={`${field.name}.4`}
              text="Photography/Videography"
              checked={field.value?.["4"]}
              onChange={(checked) =>
                handleChange(
                  field.name,
                  checked,
                  "4",
                  "Photography/Videography"
                )
              }
            />
          )}
        />
        <Controller
          name="marketing_goals"
          control={control}
          render={({ field }) => {
            const checked =
              field.value?.["Other"] !== null &&
              field.value?.["Other"] !== undefined;
            const text = field.value?.["Other"] || "";

            const handleInputChange = (e) => {
              handleChange(field.name, checked, "Other", e.target.value);
            };
            return (
              <div>
                <CustomCheckbox
                  id="Other"
                  text="Other"
                  checked={checked}
                  onChange={(checked) =>
                    handleOtherCheckboxChange(
                      field.name,
                      field.value,
                      setValue,
                      "Other",
                      checked
                    )
                  }
                />
                {checked && (
                  <InputLabel
                    placeholder="Please specify"
                    value={text}
                    onChange={handleInputChange}
                  />
                )}
              </div>
            );
          }}
        />
        {errors?.marketing_goals && (
          <ErrorMessage message={errors.marketing_goals.message} />
        )}
      </div>

      {/** Budget **/}
      <div id="budget_amount">
        <SubheadingS>Budget</SubheadingS>
        <Controller
          name="budget_amount"
          control={control}
          render={({ field }) => (
            <InputLabel {...field} placeholder="€500" type="number" />
          )}
        />
        {errors?.budget_amount && (
          <ErrorMessage message={errors.budget_amount.message} />
        )}
      </div>

      {/** Pitchdeadline **/}
      <div id="pitch_deadline">
        <SubheadingS>Pitch deadline</SubheadingS>
        <Controller
          name="pitch_deadline"
          control={control}
          render={({ field }) => {
            const selectedDate = field.value
              ? parse(field.value, "dd-MM-yyyy", new Date())
              : null;

            return (
              <>
                <InputLabel
                  {...field}
                  value={field.value || ""}
                  placeholder="Select pitch deadline"
                  readOnly
                  onClick={onOpenPitchDeadlineCalender}
                  isDateInput
                />
                {isPitchDeadlineCalenderOpen && (
                  <CalendarModal
                    selectedDate={selectedDate}
                    onSelectDate={handlePitchDeadlineDateSelect}
                    onClose={onClosePitchDeadlineCalender}
                  />
                )}
              </>
            );
          }}
        />
        {errors?.pitch_deadline && (
          <ErrorMessage message={errors.pitch_deadline.message} />
        )}
      </div>

      {/** Delivery Deadline **/}
      <div id="delivery_deadline">
        <SubheadingS>Delivery deadline</SubheadingS>
        <Controller
          name="delivery_deadline"
          control={control}
          render={({ field }) => {
            const selectedDate = field.value
              ? parse(field.value, "dd-MM-yyyy", new Date())
              : null;

            return (
              <>
                <InputLabel
                  {...field}
                  value={field.value || ""}
                  placeholder="Select delivery deadline"
                  readOnly
                  onClick={onOpenDeliveryDeadlineCalender}
                  isDateInput
                />
                {isDeliveryDeadlineCalenderOpen && (
                  <CalendarModal
                    selectedDate={selectedDate}
                    onSelectDate={handleDeliveryDeadlineDateSelect}
                    onClose={onCloseDeliveryDeadlineCalender}
                  />
                )}
              </>
            );
          }}
        />
        {errors?.delivery_deadline && (
          <ErrorMessage message={errors.delivery_deadline.message} />
        )}
      </div>
    </div>
  );
};

export default GeneralInfoForm;
