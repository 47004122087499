import styled from "styled-components";

const TagContainer = styled.div`
  background: #002336;
  padding: 12px 24px;
  z-index: 99999;
  border-radius: 2px 0 0 0;
  display: flex;
  align-items: center;
  ${(props) => props.className};
`;

const ProfileTag = ({ children, className }) => {
  return <TagContainer className={className}>{children}</TagContainer>;
};

export default ProfileTag;
