import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { CustomCheckbox, InputTrackerItem } from "../../Forms";
import { SubheadingS } from "../../Text";
import {
  findKeyByValue,
  findValueByKey,
} from "../../../Utils/utility-functions";

export default function MultiSelectDropdown({
  question,
  onChange,
  defaultValues,
  showLabel = true,
}) {
  console.log(defaultValues);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOptionsId, setSelectedOptionsId] = useState([]);
  // selectedOptions question?.options met de id in selectedOptionsId
  const [selectedOptions, setSelectedOptions] = useState([]);

  const optionsRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [defaultValues]);

  const toggleShowOptions = () => {
    setShowOptions(true);
  };

  //set defaultvalues
  useEffect(() => {
    let newSelectedOptions = [];
    let newSelectedOptionsId = [];

    if (Array.isArray(defaultValues) && defaultValues.length > 0) {
        if (typeof defaultValues[0] === 'object') {
            // Verwerken als het een array van objecten is
            newSelectedOptions = defaultValues.map(option => option.name);
            newSelectedOptionsId = defaultValues.map(option => option.id);
        } else if (typeof defaultValues[0] === 'string') {
            // Verwerken als het een array van strings is
            defaultValues.forEach((keyDefault) => {
                const valueDefault = findValueByKey(question.options, keyDefault);
                if (valueDefault) {
                    newSelectedOptions.push(valueDefault);
                    newSelectedOptionsId.push(keyDefault);
                }
            });
        }
    }

    setSelectedOptions(newSelectedOptions);
    setSelectedOptionsId(newSelectedOptionsId);
  }, [defaultValues]);

  const handleCheckboxChange = (key, name, question) => {
    let selectedOptionsIdVar = [...selectedOptionsId];

    if (selectedOptions.includes(name)) {
      const filteredSelectedOptions = selectedOptions.filter(
        (option) => option !== name
      );
      setSelectedOptions(filteredSelectedOptions);

      const filteredSelectedIds = selectedOptionsId.filter((id) => id !== key);
      setSelectedOptionsId(filteredSelectedIds);
      selectedOptionsIdVar = filteredSelectedIds;
    } else {
      setSelectedOptions((prevOptions) => [...prevOptions, name]);
      setSelectedOptionsId((prevOptions) => [...prevOptions, key]);
      selectedOptionsIdVar = [...selectedOptionsIdVar, key];
    }
    console.log(selectedOptions);
    onChange(question, selectedOptionsIdVar);
  };

  const deleteItem = (question, message) => {
    let selectedOptionsIdVar = selectedOptionsId;

    const key = findKeyByValue(question.options, message);
    const filteredSelectedIds = selectedOptionsId.filter((id) => id !== key);
    selectedOptionsIdVar = filteredSelectedIds;
    setSelectedOptionsId(filteredSelectedIds);

    const filteredOptions = selectedOptions.filter(
      (option) => option !== message
    );
    setSelectedOptions(filteredOptions);
    onChange(question.name, selectedOptionsIdVar);
  };

  const getPosition = () => {
    const rect = optionsRef.current.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    // If the top of the DropDownSelected is above the middle of the window, show below, else show above
    return rect.top > windowHeight / 2 ? "bottom" : "top";
  };

  return (
    <div>
      {showLabel && <SubheadingS>{question?.label}</SubheadingS>}
      <MultiSelectDropDown className="c-multi-select-dropdown" ref={optionsRef}>
        {showOptions && (
          <DropDownOptions
            position={getPosition()}
            data-testid="c-multi-select-dropdown__options"
          >
            {Object.entries(question?.options).map(([key, value], index) => {
              return (
                <DropDownOption key={key} className="c-multi-select-dropdown__option">
                  <CustomCheckbox
                    id={value}
                    tabIndex={-1}
                    text={value}
                    checked={selectedOptions.includes(value)}
                    onChange={(e) =>
                      handleCheckboxChange(key, value, question.name)
                    }
                    onClick={(e) =>
                      handleCheckboxChange(key, value, question.name)
                    }
                  />
                </DropDownOption>
              );
            })}
          </DropDownOptions>
        )}
        <DropdownSelected onClick={toggleShowOptions}>
          {selectedOptions?.length > 0 ? (
            selectedOptions.map((option) => {
              return (
                <InputTrackerItem
                  message={option}
                  question={question}
                  deleteItem={deleteItem}
                  key={option} 
                />
              );
            })
          ) : (
            <Placeholder>{question?.placeholder}</Placeholder>
          )}
        </DropdownSelected>
      </MultiSelectDropDown>
    </div>
  );
}

const DropDownOptions = styled.div`
  left: 0;
  ${({ position }) => (position === "bottom" ? "bottom: 100%;" : "top: 100%;")}
  width: 100%;
  border: solid 1px #eee;
  padding: 5px 0px;
  background-color: #fff;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  z-index: 1000;
`;
const MultiSelectDropDown = styled.div`
  position: relative;
  margin-top: 8px;
`;

const DropdownSelected = styled.div`
  border: solid 1px #eee;
  padding: 10px;
  align-items: center;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  min-height: 32px;
`;

const DropDownOption = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 12px;
  padding-left: 16px;

  &:hover {
    background-color: #eee;
  }
`;
const Placeholder = styled.div`
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: grey;
`;
