import React from "react";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { FaRegBell } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Popover, PopoverTrigger, PopoverContent, PopoverClose } from '@radix-ui/react-popover';

import './Notifications.css';

import { HeadlineS, SubheadingXS, SubheadingXXS } from "../../Text";
import useNotificationMenu from "./hooks/useNotificationMenu";
import NotificationCard from "../NotificationCard";

const StyledNotificationMenu = styled.div`
  position: absolute;
  width: 400px;
  // overflow: scroll;
  right: -20px;
  top: 5px;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  font-size: 0.875rem;
  // transform: translateY(${(props) => (props.showMenu ? "0" : "100%")});
  // opacity: ${(props) => (props.showMenu ? "1" : "0.2")};
  // pointer-events: ${(props) => (props.showMenu ? "all" : "none")};

  @media (max-width: 768px) {
    width: 90vw;
    max-width: 90vw;
    right: -16px;
  }
`;

const NotificationContainer = styled.div`
  min-height: 20vh;
  max-height: 600px;
  overflow: auto;
  z-index: 100;

`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #E0E0E0;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  flex-grow: 1;
`;

const RoundButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: grey;
  border-radius: 100px;
  cursor: pointer;
  background-color: #d8dcdf;
  z-index: 2;
  position: relative;

  &:hover {
        background-color: var(--g-s100);
    }
`
const NotificationCount = styled.div`
  position: absolute;
  bottom: -4px;
  right: -8px;
  height: 24px;
  width: 24px;
  background: var(--p500);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`

const NotificationMenu = () => {
  const notificationMenu = useNotificationMenu();
  const { notifications } = useSelector((state) => state.notifications);

  const popoverStyles = {
    backgroundColor: 'transparent',
    border: 'none', 
    cursor: 'pointer', 
    padding: 0,
    margin: 0,
  };

  const popoverCloseStyle = {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    background: 'var(--b-b100)',
    padding: 12,
    border: 'none',
    borderRadius: '100%',
    cursor: 'pointer'
  };
  
  return (
    <>
    <Popover showMenu={notificationMenu.isOpen}>
      <PopoverTrigger style={popoverStyles}>
        <RoundButton >
              <FaRegBell size={18} />
              {notifications && notifications.length > 0 &&
                <NotificationCount>
                <SubheadingXXS mt={"0px" } pt={"3px"} color="#fff">{notifications?.length}</SubheadingXXS>
              </NotificationCount>
              }
        </RoundButton>
      </PopoverTrigger>
      <PopoverContent className="PopoverContent">
        <StyledNotificationMenu showMenu={notificationMenu.isOpen}>
          <div className="flex flex-col cursor-pointer">
              <ModalHeader>
                <Title>
                  <HeadlineS mt={"0px"}>Notifications</HeadlineS>
                </Title>
              <PopoverClose style={popoverCloseStyle}>
                <IoMdClose size={16} />
              </PopoverClose>
              </ModalHeader>
              <NotificationContainer>
              {notifications &&
                notifications.length > 0 ? (
                notifications.map((notification) => (
                  <NotificationCard key={notification.id} notification={notification} />
                ))
              ) :
                (
                  <div style={{padding: '24px'}}>
                    <SubheadingXS>No notifications</SubheadingXS>
                  </div>
                )
              }
            </NotificationContainer>
          </div>
        </StyledNotificationMenu>
      </PopoverContent>
    </Popover>
    </>
  );
};

export default NotificationMenu;