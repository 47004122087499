import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";

export const registerInfluencer = createAsyncThunk(
  "user/registerInfluencer",
  async ({ firstname, lastname, name, email, password }) => {
    try {
      const response = await apiClient.post(`/register/influencer`, {
        firstname,
        lastname,
        name,
        email,
        password,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const registerCompany = createAsyncThunk(
  "user/registerCompany",
  async ({ firstname, lastname, name, email, password }) => {
    try {
      const response = await apiClient.post(`/register/company`, {
        firstname,
        lastname,
        name,
        email,
        password,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const loginUser = createAsyncThunk('auth/login', async (data) => {
  try {
    const response = await apiClient.post('login', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const logoutUser = createAsyncThunk('auth/logout', async (data) => {
  const response = await apiClient.post('logout');
  return response.data;
});

const initialState = {
  loggedIn: false,
  user: '',
  token: null,
  onboarded: false,
  verified: false,
  isLoading: false,
  isError: false,
  errors: '',
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setSuccessState = (state, action) => {
      state.isLoading = false;
      state.user = action.payload.data.user;
      state.loggedIn = true;
      state.token = action.payload.data.token;
      state.verified = action.payload.data.verified;
      state.onboarded = action.payload.data.onboarded;

      // set local states
      localStorage.setItem("token", JSON.stringify(action.payload?.data?.token));
      localStorage.setItem("verified", JSON.stringify(action.payload?.data?.verified));
      localStorage.setItem("onboarded", JSON.stringify(action.payload?.data?.onboarded));
      localStorage.setItem("email", action.payload?.data?.user?.email);
      localStorage.setItem("name", action.payload?.data?.user?.name);
      localStorage.setItem('usertype', action?.payload?.data?.usertype || action?.payload?.data?.user?.usertype);
    };
    //register influencer
    builder
      .addCase(registerInfluencer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerInfluencer.fulfilled, (state, action) => {

        if (action?.payload?.errors) {
          state.isLoading = false;
          state.isError = true;
          state.errors = action?.payload?.errors;
        } else {
          setSuccessState(state, action);
        }
      })
      .addCase(registerInfluencer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      //register company
      .addCase(registerCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerCompany.fulfilled, (state, action) => {
        if (action?.payload?.errors) {
          state.isLoading = false;
          state.isError = true;
          state.errors = action?.payload?.errors;
        } else {
          setSuccessState(state, action);
        }
      })
      .addCase(registerCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      //login
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        setSuccessState(state, action);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default authSlice.reducer;
