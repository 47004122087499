import React from 'react'

export const PitchFlow = ({ children, currentIndex, onNext, onPrevious, onFinish }) => {

  const goToNext = stepData => {
    const nextData = currentIndex + 1

    if (nextData < children.length) {
      onNext(stepData)
    } else {
      onFinish(stepData)
    }
  }

  const goToPrevious = () => {
    if (currentIndex !== 0) {
      onPrevious();
    } else {
      window.history.back();
    }

  }

  const currentChild = React.Children.toArray(children)[currentIndex];

  if (React.isValidElement(currentChild)) {
    return React.cloneElement(currentChild, { goToNext, goToPrevious })
  }

  return currentChild;

}
