import React, { useEffect, useState } from 'react';
import Instagram from '../../../Icons/Instagram';
import Facebook from '../../../Icons/Facebook';
import Pinterest from '../../../Icons/Pinterest';
import T from '../../../Icons/T';
import Globe from '../../../Icons/Globe';
import WifiSlash from '../../../Icons/WifiSlash';

const Channels = ({ channels }) => {
  const [socials, setSocials] = useState(null)
  const iconMapping = {
    instagram: <Instagram height={"100px"} />,
    facebook: <Facebook height={"100px"} />,
    pinterest: <Pinterest height={"100px"} />,
    thursd: <T height={"100px"} />,
    other: <Globe height={"100px"} />,
    offline: <WifiSlash height={"100px"} />,
  };

  useEffect(()=>{
    let filteredChannels;

    if(channels){
      filteredChannels = Array.isArray(channels)
      ? channels.filter(channel => iconMapping[channel]) 
      : Object.keys(channels).filter(channel => iconMapping[channel.toLowerCase()]);
      setSocials(filteredChannels)
    }
  
  },[channels])
  // Filter the channels based on the available iconMapping
  //const filteredChannels = channels.filter(channel => iconMapping[channel])
 
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {socials && socials.map(channel => (
        <div
          key={channel}
          style={{
            width: '30px',
            height: '30px',
            marginRight: '10px',
            borderRadius: '50%', 
            border: ' 1px solid #D8DCDF',
            backgroundColor: '#EBEEEF', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: ' -18px' 

          }}
        >
          {iconMapping[channel.toLowerCase()]}
        </div>
      ))}
    </div>
  );
};

export default Channels;
