/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components';

/* -------------------------- Internal Dependencies ------------------------- */
import { theme } from "../../Theme/theme";

const StyledRadioButtonLabel = styled.label`
	font-family: "Avenir LT Std";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;

	color: ${theme.gs700};

	${({ disabled }) =>
		disabled &&
		`
      color: gray !important; 
      cursor: not-allowed;
   `}

`;

const StyledRadioButtonContainer = styled.div`
	display:flex;
	alignItems: center;
	gap:16
`

const StyledRadioButton = styled.input.attrs({
	type: "radio"
})`
	-webkit-appearance: none;
	appearance: none;
	background: white;
	margin: 0;
	width: 16px;
	height: 16px;
	border: 1px solid ${theme.main};
	border-radius: 50%;
	position: relative;
	z-index: 3;
	margin-right: 6.5px;

	&:hover {
		content: "";

		/* border-radius: 26px; */
		color: #fff1f2;
		/* font-style: normal; */
		/* font-weight: 900; */
		border-width: 5px;
		/* line-height: 24px; */
		left: 0;
		top: 0;
		/* z-index: -1; */
		outline: 5px solid #fff1f2;
		/* ::after {
			content: "";
			position: absolute;
			display: inline-block;
			background-color: #fff1f2;
			border-radius: 50%;
			width: 30px;
			height: 30px;
		} */
	}

	&:checked {
		border-width: 5px;
		line-height: 24px;
		outline: none;
	}
`;


const RadioButton = ({label, value, checked, defaultChecked, onChange}) => {

  return (
    <StyledRadioButtonContainer>
        <StyledRadioButton id={`radio-${value}`} defaultChecked={defaultChecked} checked={checked} onChange={onChange} value={value}/>
        <StyledRadioButtonLabel for={`radio-${value}`}>{label}</StyledRadioButtonLabel>
    </StyledRadioButtonContainer>
  )
}

export default RadioButton