import React from 'react'

const SmallLeftArrow = (props) => {
    return (
    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.125 0.914062C5.24219 1.03125 5.3125 1.17188 5.3125 1.3125C5.3125 1.47656 5.24219 1.61719 5.125 1.71094L2.89844 3.9375H12.4375C12.7422 3.9375 13 4.19531 13 4.5C13 4.82812 12.7422 5.0625 12.4375 5.0625H2.89844L5.125 7.3125C5.35938 7.52344 5.35938 7.875 5.125 8.08594C4.91406 8.32031 4.5625 8.32031 4.35156 8.08594L1.16406 4.89844C0.929688 4.6875 0.929688 4.33594 1.16406 4.125L4.35156 0.9375C4.5625 0.703125 4.91406 0.703125 5.125 0.914062Z" fill="white"/>
    </svg>
    );
  }
  
  export default SmallLeftArrow;