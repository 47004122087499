/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledParagraphM = styled.p`
	margin-top: ${(p) => (p.mt ? p.mt : "8px")};
	color: ${(p) => (p.color ? p.color : (p.colorSecondary ? "var(--g-s400)" : "var(--gray2)"))};
	margin-left: ${(p) => (p.ml ? p.ml : "0px")};
	margin-right: ${(p) => (p.mr ? p.mr : "0px")};
	margin-bottom: ${(p) => (p.mb ? p.mb : "0px")};

	// color: ${(p) => p.color && p.color};
`;
const ParagraphM = ({ children, colorSecondary, mt, ml, mr, mb, onClick, color }) => {
	return (
		<StyledParagraphM
			color={color}
			onClick={onClick}
			ml={ml}
			mt={mt}
			mr={mr}
			mb={mb}

			colorSecondary={colorSecondary}
			className="paragraph_m"
		>
			{children}
		</StyledParagraphM>
	);
};

export default ParagraphM;
