import styled from "styled-components";
import { useSelector } from "react-redux";

import InfluencerCard from "./Cards/InfluencerCard";
import { Container } from "../Layout";

const BodyContainer = styled.div`
  flex: 1;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

  }
`
const GridContainer = styled.div`
  padding-top: 24px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media (min-width: 724px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 884px) {
    grid-template-columns: repeat(3, 1fr);
  }

`;

const InviteInfluencerBody = ({campaignData}) => {
    const influencers = useSelector((state) => state.influencers.influencers);

    return (
        <BodyContainer>
            <Container>
                <GridContainer>
                {influencers && Array.isArray(influencers) && influencers.map((influencer) => (
                    <InfluencerCard
                        key={influencer.id} 
                        influencer={influencer} 
                        campaignData={campaignData}
                    />
                ))}
                </GridContainer>
            </Container>
        </BodyContainer>
    );
}

export default InviteInfluencerBody;