import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { HeadingXL, ParagraphM, SubheadingS } from "../../Components/Text";
import { LayoutCenterTop } from "../../Components/Layout";
import CustomSelect from "../../Components/Common/Questions/CustomSelect";
import { Button } from "../../Components/UI";
import RightArrow from "../../Icons/RightArrow";
import { useDataSource } from "../../Utils/hooks/useDataSource";
import { fetchProfile, profileSetup } from "../../app/features/profileSlice";
import apiClient from "../../services/api";

const SetupPage = () => {
    const { resource: questions, } = useDataSource('/questions?type=profile')
    const setupAccountData = questions?.data?.[0]
    const setupAccountQuestions = setupAccountData?.questions
    const [selectedOptionsDict, setSelectedOptionsDict] = useState({});
    const userType = localStorage.getItem('usertype')
    const user = useSelector((state) => state.profile.profile);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchProfile())
    }, [])


    useEffect(() => {

        const defaultValues = {};
        const timezone = setupAccountQuestions?.[0];

        if (user?.data?.profile?.timezone) {

            defaultValues['timezone'] = user?.data?.profile?.timezone;
        } else if (Array.isArray(timezone?.options)) {
            defaultValues['timezone'] = timezone.options[0];
        }

        setSelectedOptionsDict(defaultValues)
    }, [setupAccountQuestions, user])

    const onChange = (e, option) => {
        let newSelectedOptionsDict = { ...selectedOptionsDict };
        const value = e.target.value

        if (Array.isArray(option.options)) {
            newSelectedOptionsDict[option.name] = option.options[value]
        } else {
            newSelectedOptionsDict[option.name] = value
        }
        setSelectedOptionsDict(newSelectedOptionsDict)
    }


    const navigateBasedOnUserType = (userType) => {
        userType === 'influencer' ?
            navigate('/setupprofilecreative') :
            navigate('/setupprofilecompany');
    }

    const onNext = () => {
        dispatch(profileSetup({ data: selectedOptionsDict }))
            .then((action) => {
                if (profileSetup.fulfilled.match(action)) {
                    if (userType === 'influencer' || userType === 'company') {
                        navigateBasedOnUserType(userType);
                    } else {
                        apiClient.get('/me').then((resp) => {
                            const userTypeFromApi = resp.data.usertype;
                            navigateBasedOnUserType(userTypeFromApi);
                        });
                    }
                }
            });
    }

    return (
        <LayoutCenterTop>
            <HeadingXL>{setupAccountData?.heading}</HeadingXL>
            <ParagraphM>
                Please fill in the information below so we can setup your account with the correct settings.
            </ParagraphM>
            {setupAccountQuestions &&
                <div>
                    <SubheadingS>{setupAccountQuestions?.[0].label}</SubheadingS>
                    <ParagraphM>{setupAccountQuestions?.[0].subheading}</ParagraphM>
                    {selectedOptionsDict &&
                        <CustomSelect
                            label={setupAccountQuestions?.[0].label}
                            subheading={setupAccountQuestions?.[0].sublabel}
                            question={setupAccountQuestions?.[0]}
                            showLabel={false}
                            onChange={onChange}
                            defaultValue={selectedOptionsDict['timezone']}
                        />}

                </div>
            }
            <Button
                primary
                fwidth
                text={"Next step"}
                Icon={<RightArrow />}
                onClick={onNext}
            />
        </LayoutCenterTop>
    );
};

export default SetupPage;
