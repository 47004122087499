import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";

export const fetchNotifications = createAsyncThunk('fetch/notifications', async () => {
  const response = await apiClient.get('/notifications');
  return response.data.data;
});

const initialState = {
  notifications: [],
  isLoading: false,
  errors: ''
};

const notifcationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {;
    //register influencer
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {

        if (action?.payload?.errors) {
          state.isLoading = false;
          state.isError = true;
          state.errors = action?.payload?.errors;
        } else {
            state.notifications = action.payload
        }
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
    
  },
});

export default notifcationsSlice.reducer;
