import { createContext, useState } from 'react';

// Create the context
export const FormContext = createContext({
    formData: {},
    createFormData: (newFormData)=>{}
});

// Create a context provider component
function FormProvider({ children }) {
    const [data, setData] = useState({});
 
 const createFormData = (newFormData) => {
    setData((prevFormData) => ({ ...prevFormData, ...newFormData }));
 };

 const value = {
    formData: data,
    createFormData: createFormData,
  };

    return (
        <FormContext.Provider value={value}>
            {children}
        </FormContext.Provider>
    );
}

export default FormProvider;