import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { LayoutCenterTop } from '../../Components/Layout'
import { Button } from '../../Components/UI'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { profileSetup } from '../../app/features/profileSlice'
import MultiInput from '../../Components/Common/MultiInput'
import { HeadlineXL, ParagraphM } from '../../Components/Text'
import ArrowButton from '../../Icons/ArrowButton'

const ArrowButtonWrapper = styled.div`
  cursor: pointer; 
`;
export default function AddThursdPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.profile.profile);
    const [inputList, setInputList] = useState([]);
    const [parentInput, setParentInput] = useState('')

    //set default values
    useEffect(() => {
        if (user) {
            setInputList(user.data.profile.channel["Offline"] || [])
        }
    }, [user])


    const handleBackButton = () => {
        window.history.back();
    };

 
    const onSubmit = async () => {
        const newChannels = { ...user.data.profile.channel}
        newChannels['Offline'] = inputList
        
        if(parentInput.length > 0 ) {
            newChannels['Offline'].push(parentInput)
        }

        dispatch(profileSetup({data: {channel: newChannels}})).then((resp)=>{
            navigate('/me')
        })

      }
    return (
        <LayoutCenterTop>
            <ArrowButtonWrapper onClick={handleBackButton}>
                <ArrowButton />
            </ArrowButtonWrapper>
            <div style={{ padding: '24px', }}>
                <HeadlineXL mt={"16px"}>Add your offline channel</HeadlineXL>
                <div style={{ marginTop: '16px' }}>
                    <ParagraphM color={'#4F4F4F'}>
                        Welcome! To add your offline channel, 
                        simply enter your user name in the designated input field. 
                    </ParagraphM>
                </div>
                <div style={{ marginTop: '16px' }}>
                {user &&
                    <MultiInput
                        title="Offline"
                        inputList={inputList}
                        setInputList={setInputList}
                        parentInput={parentInput}
                        setParentInput={setParentInput}
                    />}
                </div>
                <div style={{ marginTop: '32px' }}>
                    <Button
                        fwidth
                        primary
                        onClick={onSubmit}
                        text={"Submit"}
                        mt={"16px"}
                    />
                </div>
            </div>
        </LayoutCenterTop>
    )
}
