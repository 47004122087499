/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledSubheadingXS = styled.h2`
	margin-top: ${(p) => (p.mt ? p.mt : "0px")};
	margin-left: ${(p) => (p.ml ? p.ml : "0px")};
	margin-right: ${(p) => (p.mr ? p.mr : "0px")};

	margin-bottom: ${(p) => (p.mb ? p.mb : "0px")};
	color: ${(p) => (p.colorSecondary ? "var(--g-s400)" : "var(--gray2)")};
	color: ${(p) => p.color && p.color};
	font-weight: ${(p) => p.fontW? p.fontW : '700'};
`;

const SubheadingXS = ({ mt, ml, mr, mb, children, colorSecondary, color, fontW }) => {
	return (
		<StyledSubheadingXS
			mt={mt}
			ml={ml}
			mr={mr}
			mb={mb}
			fontW={fontW}
			colorSecondary={colorSecondary}
			color={color}
			className="sub_heading_xs"
		>
			{children}
		</StyledSubheadingXS>
	);
};

export default SubheadingXS;
