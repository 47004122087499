import { HeadlineL } from "../../Components/Text";
import styled from "styled-components";
import SubheadingXXXS from "../../Components/Text/SubheadingXXXS";
import Hamburger from "../../Icons/Hamburger";
import SmallLeftArrow from "../../Icons/SmallLeftArrow";
import { useNavigate } from "react-router-dom";

const Button = styled.button`
  margin-bottom: ${(props) => props.marginBottom || "0px"}; 
  z-index: 2;
  background-color: var(--p500);
  border: none;
  display:  flex;
  cursor: pointer;
  box-shadow: 0px 1px 0px #982e36;
  align-self: flex-start;
  justify-content: center;

  transition: transform 0.2s ease-in-out;
	&:hover {
    background: #982e36;
 }
`;

const ButtonBackground = styled.div`
  background-color: var(--p500);
  border-radius: 4px;
  display: inline-block;
  padding: ${(props) => props.padding || "6px 10px"}; 

  .sub_heading_xxs {
    color: white;
  }
  &:hover {
    background: #982e36;
 }
`;

const OptionButton = styled.button`
  z-index: 2;
  box-shadow: 0px 1px 0px #982e36;
  background-color: transparent;
  border: 2px solid white;
  display:  flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  align-self: flex-start;

  .sub_heading_xxxs {
    padding-left: 8px;
  }
`;

const CampaignDetailHeader = ({title, toggleOverlay, optionButton}) => {
  const navigate = useNavigate();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button onClick={() => {navigate('/meetupspot')}}>
        <ButtonBackground>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SmallLeftArrow />
            <div style={{ marginLeft: '8px' }}>
              <SubheadingXXXS mt={'0px'}>Back</SubheadingXXXS>
            </div>
          </div>
        </ButtonBackground>
      </Button>
      <HeadlineL mt={'8px'} mb={'8px'} color={'#F1F1F1'}> {title} </HeadlineL>
      {optionButton &&
        <OptionButton onClick={toggleOverlay}>
        <Hamburger />
        <SubheadingXXXS mt={'0px'}>Project options</SubheadingXXXS>
      </OptionButton>
      }
    </div>
  );
}

export default CampaignDetailHeader;
