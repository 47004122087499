import * as React from "react"

const ArrowDownSmall = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={8}
    fill="none"
    {...props}
  >
    <path
      fill="#FF4E5B"
      d="M12.906 1.914 7.562 6.941A.805.805 0 0 1 7 7.188a.955.955 0 0 1-.598-.211L1.06 1.914C.707 1.598.707 1.07 1.023.72 1.34.367 1.867.367 2.22.684L7 5.184l4.746-4.5c.352-.317.879-.317 1.195.035.317.351.317.879-.035 1.195Z"
    />
  </svg>
)
export default ArrowDownSmall