import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { AiOutlineCheck } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Modal from "../../app/modals/Modal";
import useCamapignOptionsModal from "../../app/hooks/useCampaignOptionsModal";
import CampaignOption from "../../Components/Common/Chat/PitchOption";
import apiClient from "../../services/api";

const Line = styled.div`
  border-bottom: 1px solid #E0E0E0;
`
const CampaignOptionsModal = (
  {
    campaignData, 
    setPitchStatus,
    setStatus
  }) => {

    const user = useSelector((state) => state.profile.profile);
    const campaignOptionsModal = useCamapignOptionsModal();
    const navigate = useNavigate();

    const acceptCampaign = () => {
      try {
        apiClient.get(`campaign/${campaignData.id}/accept`).then((response) => {
          setPitchStatus(true)
          campaignOptionsModal.onClose()
        });

      } catch (error) {
        console.error("Fout bij het accepteren van de campagne: ", error);
      }
    }
  
    const declineCampaign = () => {
      try {
        apiClient.get(`campaign/${campaignData.id}/decline`).then((response) => {
          campaignOptionsModal.onClose()
          setPitchStatus(false)
          setStatus("declined")
        });
      } catch (error) {
        console.error("Fout bij het accepteren van de campagne: ", error);
      }
    }

    const bodyContent = (
      <div>
        <CampaignOption
          title="... I accept this campaign."
          Icon={<AiOutlineCheck size={16} color={"#61B795"} />}
          onClick={acceptCampaign}
          
        />
        <Line />
          <CampaignOption
          title="... I decline this campaign."
          Icon={<IoMdClose size={16} color={"#FF4E5B"} />}
          onClick={declineCampaign}        
        />
      </div>
);

    return (
        <Modal
            title={`Hello ${user?.data?.user?.firstname},`}
            isOpen={campaignOptionsModal.isOpen}
            onClose={campaignOptionsModal.onClose}
            body={bodyContent}
        />);
}

export default CampaignOptionsModal;