import styled from "styled-components";

import IconRound from "../../IconRound";
import { ParagraphS } from "../../Text";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const InfoTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 200px;
`;

const InfoData = styled.div`
  
`;

const StyledLink = styled.a`
  color: #FFBFC4;
  transition: color 0.3s ease;

  &:hover {
    color: ${(p) => (p.theme.main)};
 }
`;
const ProfileInformationItem = ({ Icon, title, answer, link = false }) => {
    // Controleer of de link begint met "https://" of "http://"

    var formattedAnswer;
    if(answer && link){
        formattedAnswer = link && !answer.startsWith("http://") && !answer.startsWith("https://")
        ? `https://${answer}`
        : answer;
    }
  
    return (
        <Container>
            <InfoTitle>
                { title && <IconRound
                    iconMargin={title === 'Account created' && "0px 0px 3px 0px"}
                    Icon={Icon || null}
                />}
                <ParagraphS color={"white"}>{title}</ParagraphS>
            </InfoTitle>
            <InfoData>
                <ParagraphS color={"#FFBFC4"}>
                    {link ? (
                        <StyledLink href={formattedAnswer} target="_blank">
                            {formattedAnswer}
                        </StyledLink>
                    ) : (
                        <p>{answer}</p>
                    )}
                </ParagraphS>
            </InfoData>
        </Container>
    );
};

export default ProfileInformationItem;
