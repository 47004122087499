/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledSubheadingS = styled.h2`
	margin-top: ${(p) => (p.mt ? p.mt : "0px")};
	margin-left: ${(p) => (p.ml ? p.ml : "0px")};
	margin-bottom: ${(p) => (p.mb ? p.mb : "0px")};
	color: ${(p) => (p.colorSecondary ? "var(--g-s400)" : "var(--gray2)")};
	color: ${(p) => p.color && p.color};
	padding-top: ${(p) => p.pt && p.pt};
	`;
const SubheadingXXS = ({
	mt,
	ml,
	mb,
	children,
	colorSecondary,
	color,
	onClick,
	pt
}) => {
	return (
		<StyledSubheadingS
			mt={mt}
			ml={ml}
			mb={mb}
			pt={pt}
			color={color}
			colorSecondary={colorSecondary}
			className="sub_heading_xxs"
			onClick={onClick}
		>
			{children}
		</StyledSubheadingS>
	);
};

export default SubheadingXXS;
