import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NotificationPage from '../../Components/MegaphonePage';

export default function OnboardingThankYouPage() {
  const user = useSelector((state) => state.profile.profile);
  const usertype = user?.data?.usertype
  const navigate = useNavigate();  

  return (
    <NotificationPage
        onButtonClick={()=>navigate('/userinfo')}
        buttonText={'I’m ready to fill my profile'}
        text={usertype === 'company' ? 'Now it’s time to finish your advertiser profile. Let’s continue with your company details!' : "Now it’s time to finish your creative profile. Let’s continue with your details!"}
        title={'Thank you!'}
        />
  )
}
