import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";
import { Stepper } from "../../Components/Common/Stepper";
import ProjectForm from "./ProjectForm";
import { schema } from "./schema";
import CreativeForm from "./CreativeForm";
import GeneralInfoForm from "./GeneralInfoForm";
import ChannelRequirements from "./ChannelRequirements";
import { ErrorMessage } from "../../Components/Text";
import apiClient from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const stepFields = [
  [
    "is_published",
    "image",
    "title",
    "description",
    "marketing_goals",
    "budget_amount",
    "pitch_deadline",
    "delivery_deadline",
  ],
  ["channels"],
  [
    "product",
    "influencer_occupations",
    "audience_occupations",
    "influencer_languages",
    "audience_countries",
  ],
];

export default function Project({ initialData }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [globalError, setGlobalError] = useState(false);
  const { id } = useParams();

  /**
   * Ensure that when the user navigates back from step 3 to step 2,
   * any answered questions remain open.
   */
  const [accordionStates, setAccordionStates] = useState({
    instagram: false,
    facebook: false,
    pinterest: false,
    thursd: false,
    other: false,
  });

  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: initialData || {
      channels: {
        facebook: {
          facebook_tag_hashtag: ["#mythursd"]
        },
        instagram: {
          instagram_tag_hashtag: ["#mythursd"]
        },
        pinterest: {
          pinterest_tag_hashtag: ["#mythursd"]
        },
      },
    },
  });

  const objectToArray = (fieldName, data) => {
    let results = {};

    const processEntry = (prefix, obj) => {
      for (const [key, value] of Object.entries(obj)) {
        if (value === undefined || value === "") continue;

        let formKey = `${prefix}[${key}]`;
        if (typeof value === "object" && value !== null) {
          processEntry(formKey, value);
        } else {
          results[formKey] = value;
        }
      }
    };

    processEntry(fieldName, data);

    return results;
  };

  const objectToFormData = (fieldName, data, formData) => {
    let results = objectToArray(fieldName, data);
    for (const [key, value] of Object.entries(results)) {
      if (value !== undefined || value !== "") {
        formData.append(key, value);
      }
    }

    return formData;
  };

  const onSubmit = (data, e) => {
    e.preventDefault(); // Prevent default form submission

    setLoading(true);

    let formData = new FormData();
    if (typeof data.image === "object") {
      formData.set("image", data.image);
    }
    const newProjectData = {
      ...data,
      budget_currency: "EUR",
      status: "active",
    };

    console.log(newProjectData)

    for (const key in newProjectData) {
      if (newProjectData.hasOwnProperty(key) && key !== "image") {
        // console.log(key, newProjectData[key])
        if (typeof newProjectData[key] === "object") {
          //key = influence_occupations
          formData = objectToFormData(key, newProjectData[key], formData);
        } else {
          formData.append(key, newProjectData[key]);
        }
      }
    }

    if (initialData) {
      apiClient
        .post(`campaign/${id}/edit`, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          navigate("/meetupspot?status=mine&tab=My+projects");
          setLoading(false);
        });
    } else {
      apiClient
        .post("campaign/create", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          navigate("/meetupspot?status=mine&tab=My+projects");
          setLoading(false);
        });
    }
  };

  const handleStep = async (isFinish) => {
    const fieldsToValidate = stepFields[currentIndex];
    const isValid = await methods.trigger(fieldsToValidate);

    console.log(methods.formState.errors)
    console.log(methods.getValues('channels'))

    if (!isValid) {
      setGlobalError(true);
      const firstErrorField = fieldsToValidate.find(
        (field) => methods.formState.errors[field]
      );

      //Scroll to first error
      const errorElement = document.getElementById(firstErrorField);

      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }

      return;
    }
    methods.clearErrors();
    setGlobalError(false);

    if (isFinish) {
      methods.handleSubmit(onSubmit)();
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const onNext = () => {
    handleStep(false);
  };

  const onPrevious = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const onFinish = () => {
    handleStep(true);
  };

  useEffect(() => {
    const fieldsToValidate = stepFields[currentIndex];
    const errors = fieldsToValidate.some(
      (field) => methods.formState.errors[field]
    );

    setGlobalError(errors);
  }, [methods.formState, currentIndex]);

  const toggleAccordion = (accordion) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [accordion]: !prevState[accordion],
    }));
  };

  if (loading) {
    return (
      <LoadingContainer>
        <ClipLoader
          color={"#FF4E5B"}
          loading={true}
          size={60}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </LoadingContainer>
    );
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <Stepper
          currentIndex={currentIndex}
          onNext={onNext}
          onPrevious={onPrevious}
          onFinish={onFinish}
        >
          <ProjectForm
            goToNext={onNext}
            goToPrevious={onPrevious}
            index={currentIndex + 1}
            title="General info"
            subTitle={"Provide the general information about your project."}
          >
            <GeneralInfoForm />
            {globalError && (
              <ErrorMessage message={"Fill in all mandatory fields"} />
            )}
          </ProjectForm>
          <ProjectForm
            goToNext={onNext}
            goToPrevious={onPrevious}
            index={currentIndex + 1}
            title="Channel Requirements"
            subTitle="Specify the channel requirements for your project. Please select at least one option."
          >
            <ChannelRequirements
              accordionStates={accordionStates}
              toggleAccordion={toggleAccordion}
            />
            {globalError && (
              <ErrorMessage message={"At least one field must be mandatory."} />
            )}
          </ProjectForm>

          <ProjectForm
            goToNext={onNext}
            goToPrevious={onPrevious}
            index={currentIndex + 1}
            title="Ideal Creative"
            subTitle="Specify your ideal creative."
          >
            <CreativeForm />
            {globalError && (
              <ErrorMessage message={"Fill in all mandatory fields"} />
            )}
          </ProjectForm>
        </Stepper>
      </form>
    </FormProvider>
  );
}
