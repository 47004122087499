import styled from 'styled-components';

import HeadlineL from '../Text/HeadlineL';
import SubheadingXXXS from '../Text/SubheadingXXXS';
import LeftArrow from '../../Icons/LeftArrow';

const HeaderWrapper = styled.section`
margin-top: 68px;
height: 100%;

`
const Container = styled.div`
  position: relative;
  background-color: var(--b-b400);
  text-align: ${props => props.buttonVisible ? "left" : "center"};
  height: 100%;
  color: var(--g-s50);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 20px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 80%;
    background-color: #364E5D; 
    z-index: 1;
  }
  background-color: ${props => props.buttonVisible ? "var(--b-b400)" : "var(--b-b400)"};
  background-image:  ${props => props.backgroundImage ? `url(${props.backgroundImage})` : "var(--b-b400)"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  
  .headline_l {
    position: relative;  
    z-index: 2; 
    color: var(--g-s50); 
    padding: ${props => props.buttonVisible ? "0" : "2rem 0"};
    
  }

  @media only screen and (min-width: 1024px){
    background-image: none;
  }
`;

const LayoutContainer = styled.div`
max-width: var(--max-width);
padding: 0 40px; 
width: 100%;

@media (max-width: 1200px) {
  padding: 0 16px; 
  
}
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  
  .left-arrow {
    padding-right: 8px;
    align-items: center;
  }
`;

const HeadlineContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 16px 0;

`

const Button = styled.button`
  position: relative;
  background-color: var(--p500);
  color: var(--g-s50); 
  padding: 6px 10px 6px 10px;
  border: none;
  visibility:${props => props.buttonVisible ? "visible" : "hidden"};
  box-shadow: 0px 1px 0px #982e36;
  display: inline-block;
  z-index: 2; 
  cursor: pointer;
  
  transition: transform 0.2s ease-in-out;

	&:hover {
    background: #982e36;
 }

  .sub_heading_xxxs {
    align-items: center;
    font-weight: 400;
  }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: right;
    position: relative;
    right: 0;
    bottom: 107px;
    z-index: 10;
    cursor: none;
    pointer: none;

    .image-container {
      height: 206px;
      width: 253px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      cursor: auto;
      border-radius: 4px 4px 0px 0px;
  }

    @media only screen and (max-width: 1024px) {
      display: none;
    }
`;

const Image = styled.image`
    position: absolute;
    cursor: none;
    pointer: none;
`;


const Header = ({
  text,
  buttonVisible,
  backgroundImage,
  onBack
}) => {
  const handleBack = () => {
    if (onBack) {
      onBack(); // Call the provided onBack function
    } else {
      window.history.back(); // Fallback to window.history.back() if onBack is not provided
    }
  };
  return (
    <HeaderWrapper>
    <Container backgroundImage={backgroundImage} buttonVisible={buttonVisible}>
      <LayoutContainer>
        {buttonVisible ?
          <Button buttonVisible={buttonVisible} onClick={handleBack}>
            <Wrapper>
              <LeftArrow fill={"#ffffff"} />
              <SubheadingXXXS mt={"0px"}>Back</SubheadingXXXS>
            </Wrapper>
          </Button>
          : null}
        <HeadlineContainer>
          <HeadlineL mt={"8px"}>{text}</HeadlineL>
        </HeadlineContainer>
        {backgroundImage &&
          <ImageContainer>
            <Image>
              <div className='image-container'
                style={{ backgroundImage: `url(${backgroundImage})` }}
              />
            </Image>
          </ImageContainer>}
      </LayoutContainer>
    </Container>
    </HeaderWrapper>
  );
};

export default Header;