import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { CustomCheckbox, InputLabel } from '../../Forms';

const StyledButton = styled.button`
    border: ${(p) => (p.primary ? "transparent" : "1px solid #e3e3e3")};    background: ${(props) => props.primary ? props.theme.main : props.theme.white};
    color: ${(props) => props.primary ? props.theme.white : props.theme.main};
    font-size: 12px;
    font-weight: ${(props) => props.primary ? 600 : 700};
    lineheight: 150%;
    padding: 8px 24px;
    box-shadow: ${(p) => p.disabled ? "0px 1px 0px #E3E3E3" : p.primary ? "0px 1px 0px #982e36" : "0px 1px 0px #E3E3E3"};    
    border-radius: 2px;
    cursor: pointer;
    margin-top: 8px;
`

function CheckboxWithMultiInput({ selectedValues, onChange, title, inputValue, question }) {
    const [checked, setChecked] = useState();
    const [inputList, setInputList] = useState([]);
    const [parentInput, setParentInput] = useState('')

    useEffect(() => {

        if (title && selectedValues) {
            //set default values listst
            setInputList(selectedValues[title] || [])

            //set default values for checked
            if (selectedValues[title]) {
                setChecked(selectedValues[title].length > 0);
            } else {
                setChecked(false);
            }
        }
    }, [selectedValues])

    useEffect(() => {
        const updatedSelectedValues = { ...selectedValues };
        updatedSelectedValues[title] = inputList;
        onChange(question, title, updatedSelectedValues, 'multi-input');
    }, [inputList]);


    const onClickParentCheckBox = () => {


        if (checked) {
            setInputList([])
        }
        setChecked(!checked);

    };

    const handleAddInput = () => {
        if (parentInput.trim() !== '') {
            setInputList([...inputList, parentInput]);
            setParentInput('');
        }
    };

    const removeInput = (index) => {
        const updatedInputList = inputList.filter((_, i) => i !== index);
        setInputList(updatedInputList);
    };


    const handleParentInputChange = (e) => {
        const inputValue = e.target.value;
        setParentInput(inputValue);
    };

    const handleChildInputChange = (e, index) => {

        //maak een kopie van de lijst
        const updatedInputList = [...inputList];

        //werk de waarde van de juiste input bij
        updatedInputList[index] = e.target.value;

        //update de lijst
        setInputList(updatedInputList);
    };

    return (
        <div>
            <CustomCheckbox
                id={title}
                tabIndex={-1}
                text={title}
                checked={checked}
                onChange={onClickParentCheckBox}
                onClick={onClickParentCheckBox}
            />
            {checked && (
                <div>
                    <InputLabel
                        placeholder={inputValue}
                        onChange={handleParentInputChange}
                        value={parentInput}
                    />

                    <StyledButton primary onClick={handleAddInput}>
                        Add channel
                    </StyledButton>

                    {inputList.map((item, index) => (
                        <div key={index} style={{ marginTop: '16px', marginBottom: '16px' }}>
                            <InputLabel
                                value={item}
                                onChange={(e) => handleChildInputChange(e, index)}
                            />
                            <StyledButton onClick={() => removeInput(index)}>
                                Remove channel
                            </StyledButton>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default CheckboxWithMultiInput;
