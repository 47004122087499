/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

/* -------------------------- Internal Dependencies ------------------------- */
import { HeadingXL, SubheadingS, ParagraphM } from "../../Components/Text";
import { LayoutCenterTop } from "../../Components/Layout";
import { Button, RoundButtonLink } from "../../Components/UI";
import { FieldErrorMessage } from "../../Components/Forms";
import { FileDropZone, PhoneBox } from "../../Components/Common";

/* -------------------------- Internal SVG Dependencies ------------------------- */
import Logo from "../../Icons/Logo";
import { profileSetup } from "../../app/features/profileSlice";
import { useDataSource } from "../../Utils/hooks/useDataSource";
import NationalitySelect from "./NationalitySelect";
//import FileDropZone from "./FileDropZone";
import Close from "../../Icons/Close";

const UserInfo = () => {
  // Redux hooks for dispatching actions and accessing state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.profile.profile);

  // Custom hook to fetch nationality data
  const { resource: countries } = useDataSource("nationalities");

  // Component state variables
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [nationalityError, setNationalityError] = useState(false);
  const [nationality, setNationality] = useState(null);
  const [image, setImage] = useState();

  useEffect(() => {
    if (user) {
      const { data } = user;

      if (data?.avatar) {
        setImage(data.avatar);
      }

      if (data?.profile?.phonenumber || data?.phonenumber) {
        setPhoneNumber(data?.profile?.phonenumber || data?.phonenumber);
      }
    }
  }, [user]);

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const handleNationalityChange = (selectedId) => {
    //const selectedId = e.target.value;
    const selectedObject = countries.find((item) => item.id === selectedId);
    setNationality(selectedObject);
  };

  // Create form data to submit to the server
  const createFormData = () => {
    const formData = new FormData();

    // Only append the image if it's a file (not a URL)
    if (image instanceof File || image instanceof Blob) {
      formData.append("image", image);
    }
    formData.append("phonenumber", "+" + phoneNumber);
    if (nationality?.id) {
      formData.append("nationality", nationality.id);
    }
    return formData;
  };

  // Navigate to the appropriate setup page based on the user type
  const navigateBasedOnUserType = () => {
    const userType = localStorage.getItem("usertype");
    if (userType === "influencer") {
      navigate("/setupprofilecreative");
    } else {
      navigate("/setupprofilecompany");
    }
  };

  // Handle form submission
  const handleFormSubmit = () => {
    setNationalityError(false);
    setPhoneError(false);

    // Validate nationality selection
    if (!nationality) {
      setNationalityError(true);
      return;
    }

    // Validate phone number length
    if (phoneNumber.length < 8) {
      setPhoneError(true);
      return;
    }

    // Store selected country in local storage
    localStorage.setItem("country", nationality.name);

    const formData = createFormData();

    dispatch(profileSetup({ data: formData })).then((action) => {
      if (profileSetup.fulfilled.match(action)) {
        navigateBasedOnUserType();
      }
    });
  };

  // Extract the user's first name for personalized greeting
  const userFirstName =
    user?.data?.firstname || user?.data?.user?.firstname || "";

  // Determine the button text based on whether the user has completed onboarding
  const buttonText =
    user?.data?.onboarded === true ? "Update Profile" : "Create Account";

  return (
    <LayoutCenterTop>
      {/* Header with logo and close button */}
      <Container>
        <Logo height={35} />
        <RoundButtonLink
          height="40px"
          width="40px"
          fill="#FFF"
          onClick={() => navigate("/me")}
          Icon={<Close fill="#364E5D" />}
        />
      </Container>
      {/* Greeting the user */}
      {userFirstName && <HeadingXL>Hi {userFirstName}</HeadingXL>}
      {/* Instructions for the user */}
      <ParagraphM>
        Please fill out all the information below so we can set up your account
        on myThursd.
      </ParagraphM>
      {/* Section to upload a personal picture */}
      <SubheadingS>Upload a personal picture.</SubheadingS>
      <ParagraphM colorSecondary>
        Please add a personal picture. myThursd is all about personal contact
        between you and advertisers.
      </ParagraphM>
      <FileDropZone setImage={setImage} image={image} isAvatar />
      {/* Nationality selection */}
      <SubheadingS>Nationality</SubheadingS>
      {countries && (
        <NationalitySelect
          label="Nationality"
          data={countries}
          onChange={handleNationalityChange}
          defaultValue={user?.data?.nationality || null}
        />
      )}
      {nationalityError && (
        <ErrorContainer>
          <FieldErrorMessage text="This field is mandatory. Please select a nationality." />
        </ErrorContainer>
      )}
      {/* Phone number input */}
      <SubheadingS>Phone number </SubheadingS>
      <ParagraphM colorSecondary>
        We need your phone number. We will only call you for urgent matters
        concerning projects by advertisers you are working on.
      </ParagraphM>
      <PhoneBox>
        <PhoneInput
          autoFormat
          value={phoneNumber}
          onChange={handlePhoneChange}
          country="nl"
          inputStyle={{
            border: "none",
            transform: "translateY(-6px)",
            backgroundColor: "transparent",
            color: "#727272",
            marginLeft: "-8px",
            "&:placeholder": {
              color: "#727272",
            },
          }}
          placeholder={"(0)6-12345678"}
          dropdownStyle={{ border: "none" }}
          buttonStyle={{
            border: "none",
            transform: "translateY(-6px)",
            background: "none",
          }}
        />
      </PhoneBox>
      {phoneError && (
        <ErrorContainer>
          <FieldErrorMessage text="This field is mandatory. Please type in a valid phone number." />
        </ErrorContainer>
      )}
      {/* Submit button */}
      <Button
        fwidth
        primary="true"
        text={buttonText}
        onClick={handleFormSubmit}
      />
    </LayoutCenterTop>
  );
};

export default UserInfo;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ErrorContainer = styled.div`
  margin-top: 8px;
`;
