import { Outlet, Navigate, useLocation } from 'react-router-dom'

const PrivateRoutes = () => { 
	const localStorageToken = localStorage.getItem('token')
  const location = useLocation();

    return(
      localStorageToken? <Outlet/> : <Navigate to="/login" state={{ from: location }} />
    )
}

export default PrivateRoutes