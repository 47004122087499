import { ParagraphM, SubheadingS } from "../../../Components/Text";
import RichTextEditor from "./RichtTextEditor";

const RichTextComponent = ({
  title,
  details,
  editorContent,
  setEditorContent,
}) => {
  return (
    <>
      {title && <SubheadingS>{title}</SubheadingS>}
      {details && (
        <ParagraphM mt={"4px"} mb={"8px"} color={"#727272"}>
          {details}
        </ParagraphM>
      )}
      <div style={{ paddingBottom: 40 }}>
        <RichTextEditor
          editorContent={editorContent}
          setEditorContent={setEditorContent}
        />
      </div>
    </>
  );
};

export default RichTextComponent;
