import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { FaSliders } from "react-icons/fa6";

// import Card from "../../Components/Card/Card";
import MarketplaceCard from "../Advertisers/Marketplace/Card";
import Card from "../Advertisers/Card/Card";
import useEventOptionsModal from "../../app/hooks/useEventOptionsModal";
import Loading from "../Loading";
import useFilterMenu from "../../app/hooks/useFilterMenu";
import EventOptionsModal from "../../app/modals/EventOptionsModal";
import NoData from "../Common/NoData";
import { Container } from "../Layout";
import { Button } from "../UI";
import ManageProjectModal from "../../app/modals/ManageProjectModal";

const StyledContainer = styled.div`
  background: var(--g-s50);
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  padding: 24px 0;
  padding-top: 24px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media (min-width: 724px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 884px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media (min-width: 780px) {
    justify-content: flex-start;
  }
`;

const FlexCenterContainer = styled.div`
  display: flex;
  width: calc(100vw - 92px);
  max-width: var(--max-width);
  justify-content: center;
`;

const FilterMenuButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  display: block;
  padding: 16px;
  z-index: 99;
  @media (min-width: 1200px) {
    display: none;
  }
`;


const HomeBody = ({ filterBody, selectedFilterValue, page, setPage, isLoading, refreshData }) => {
  const [isEventOptionsModalOpen, setIsEventOptionsModalOpen] = useState(false);
  const [selectedMarketplaceData, setSelectedMarketplaceData] = useState(null);
  const [clickedCardData, setClickedCardData] = useState(null);
  const eventOptionmodal = useEventOptionsModal();
  const existingCampaigns = useSelector((state) => state.companyCampaigns.companyCampaigns);
  const filterMenu = useFilterMenu();
  const user = useSelector((state) => state.profile.profile);
  /*
    const loadMoreCampaigns = () => {
      const nextPage = page + 1;
      const updatedFilterBody = { ...filterBody, page: nextPage };
      setPage(nextPage);
  
      apiClient
        .post("/campaign/search", updatedFilterBody)
        .then((searchResponse) => {
          if (searchResponse.data.data.data.length === 0) {
            console.log('no more campaigns');
          } else {
            dispatch(updateCompanyCampaigns([...existingCampaigns, ...searchResponse.data.data.data]));
          }
        });
    };
  
    useScroll({
      shouldLoadMore: true,
      loadMore: () => loadMoreCampaigns(),
    });*/

  const handleOptionsClick = (marketplaceData) => {
    setSelectedMarketplaceData(marketplaceData);
    setIsEventOptionsModalOpen(true);
    eventOptionmodal.onOpen(marketplaceData);
  };

  const handleCardClick = (data) => {
    setClickedCardData(data);
  };

  /*const openEventOptionsModal = (marketplaceData) => {
    eventOptionmodal.onOpen(marketplaceData);
  };*/

  return (
    <StyledContainer>
      <Container>
        <Flex>
          <ContentContainer>
            {isLoading ? (
              <FlexCenterContainer>
                <Loading height={'40vw'} />
              </FlexCenterContainer>
            ) : existingCampaigns && existingCampaigns.length > 0 ? (
              existingCampaigns.map((data) => (
                data.model === 'marketplace' ? (
                  <div style={{ display: 'flex' }} onClick={() => handleCardClick(data)} key={data.id}>
                    <MarketplaceCard
                      key={data.id}
                      marketplaceData={data}
                      openEventOptionsModal={() => { }}
                      onOptionsClick={handleOptionsClick}
                      canceled={selectedFilterValue === "Cancelled"}
                    />
                  </div>
                ) : (
                  <Card
                    key={data.id}
                    data={data}
                    activeFilter={selectedFilterValue === "My projects"}
                    finishedFilter={selectedFilterValue === "Finished"}
                    canceledFilter={user?.data?.user?.company_id === data.company_id &&
                      selectedFilterValue === "Canceled"
                    }
                    refreshCampaigns={refreshData}
                    handleCardClick={()=>handleCardClick(data)}
                  />
                )
              ))
            ) : (
              <FlexCenterContainer>
                <NoData
                  text={
                    selectedFilterValue === "Projects by creatives"
                      ? "There are no active projects by creatives ideas"
                      : selectedFilterValue === "All"
                        ? "There are no active projects by advertisers/projects by creatives ideas"
                        : selectedFilterValue === "Projects by advertisers"
                          ? "There are no projects by advertisers"
                          : selectedFilterValue === "Pitched"
                            ? "There are no pitched projects by creatives"
                            : selectedFilterValue === "Finished"
                              ? "There are no finished projects by advertisers"
                              : selectedFilterValue === "Cancelled"
                                ? "You don't have projects"
                                : "You don't have projects"
                  }
                />
              </FlexCenterContainer>
            )}
            {selectedFilterValue && selectedFilterValue === "Projects by advertisers" && (
              <FilterMenuButtonWrapper>
                <Button
                  primary
                  padding={"6px 10px"}
                  text="Filter"
                  textSize={"12px"}
                  width={"150px"}
                  mt={"0px"}
                  onClick={filterMenu.isOpen ? () => filterMenu.onClose() : () => filterMenu.onOpen()}
                  Icon={<FaSliders />}
                />
              </FilterMenuButtonWrapper>
            )}

          </ContentContainer>
        </Flex>
      </Container>
      {isEventOptionsModalOpen && (
        <EventOptionsModal
          onClick={eventOptionmodal.onOpen}
          refreshData={refreshData}
          clickedCardData={clickedCardData}
        />
      )}
      <ManageProjectModal title="Manage project" projectId={clickedCardData?.id} />

    </StyledContainer>
  );

};

export default HomeBody;

