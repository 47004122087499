/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components';

/* -------------------------- Internal Dependencies ------------------------- */
import DummyImage from '../../../../Images/dummy-image.png'
import AvatarStatic from '../../../AvatarStatic';
import { ParagraphM } from '../../../Text';
import ProfileTag from '../../ProfileTag';

const StatusCampaignContainer = styled.div`
    position: relative;
    width: 100%;

    .image-container {
        width: 100%;
        height: 100%;
        height: 229px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        border-radius: 4px 4px 0px 0px;
    }
`

const Container = styled.div`
    position: absolute;
    bottom: 0;
    height: 30%;
    width: 100%;
    overflow: hidden;

`
const LinearGradientContainer = styled.div`
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(3,27,42));
    height: 100%;
    width: 100%;
    z-index: 1;
`

const CardImage = styled.div`
  position: relative;
  overflow: hidden;
  aspect-ratio: 1;
  height: 229px;
  width: 100%;
  overflow: hidden;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

const InfoContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  // left: 24px;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  gap: 16px;
`

const StatusCampaign = (props) => {
  return (
    <StatusCampaignContainer>
      <CardImage>
        <StyledImage
          className='card-image'
          alt="listing"
          src={props.image || DummyImage}
        />

        <InfoContainer>
          <div style={{ marginLeft: 24, marginBottom: 20 }}>
            <AvatarStatic userImage={props.profileImage || DummyImage} border={'2px solid #FF4E5B'} />
          </div>
          <div style={{position: 'absolute', right: '0px'}}>
            <ProfileTag>
              <ParagraphM color="#fff" mt="0px">{props.profileName}</ParagraphM>
            </ProfileTag>
          </div>
        </InfoContainer>ƒ
      </CardImage>

      <Container>
        <LinearGradientContainer>
        </LinearGradientContainer>
      </Container>

    </StatusCampaignContainer>
  )
}

export default StatusCampaign;
