import React from 'react'

const Profile = (props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="16"
        viewBox="0 0 15 16"
        className='profile'
        {...props}
      >
        <path
          fill={props.fill}
          d="M9 9.5H6C2.9375 9.5 0.5 11.9688 0.5 15C0.5 15.5625 0.9375 16 1.5 16H13.5C14.0312 16 14.5 15.5625 14.5 15C14.5 11.9688 12.0312 9.5 9 9.5ZM2 14.5C2.25 12.5312 3.9375 11 6 11H9C11.0312 11 12.7188 12.5312 12.9688 14.5H2ZM7.5 8C9.6875 8 11.5 6.21875 11.5 4C11.5 1.8125 9.6875 0 7.5 0C5.28125 0 3.5 1.8125 3.5 4C3.5 6.21875 5.28125 8 7.5 8ZM7.5 1.5C8.875 1.5 10 2.625 10 4C10 5.40625 8.875 6.5 7.5 6.5C6.09375 6.5 5 5.40625 5 4C5 2.625 6.09375 1.5 7.5 1.5Z"
        ></path>
      </svg>
    );
  }
  
  export default Profile;