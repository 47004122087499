import { ParagraphM, SubheadingS } from "../../Components/Text";
import Modal from "../modals/Modal";
import useDeleteSocialModal from "../hooks/useSocialsModal copy";

const DeleteSocialModal = ({
    onDelete
}) => {
    const deleteSocialModal = useDeleteSocialModal()

    const bodyContent = (

        <div style={{padding: '0 16px', marginBottom: '16px'}}>
            <SubheadingS>Are you sure you want to delete your social media channel?</SubheadingS>
            <ParagraphM color="#727272">
                This action is irreversible and will permanently delete
                your social media channel.
                Please consider this decision carefully.
                If you're certain about deleting your social media channel,
                click the "Delete" button below. If not, you can click "Cancel" to go back.
            </ParagraphM>
        </div>
    );

    return (
        <Modal
            title={`Delete your channel`}
            isOpen={deleteSocialModal.isOpen}
            onClose={deleteSocialModal.onClose}
            body={bodyContent}
            actionLabel='Cancel'
            onSubmit={deleteSocialModal.onClose}
            secondaryAction={onDelete}
            secondaryActionLabel='Delete'
        />);
}

export default DeleteSocialModal;