import React from 'react'

const CalendarIconFilled = (props) => {
    return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 1C3 0.46875 3.4375 0 4 0C4.53125 0 5 0.46875 5 1V2H9V1C9 0.46875 9.4375 0 10 0C10.5312 0 11 0.46875 11 1V2H12.5C13.3125 2 14 2.6875 14 3.5V5H0V3.5C0 2.6875 0.65625 2 1.5 2H3V1ZM14 14.5C14 15.3438 13.3125 16 12.5 16H1.5C0.65625 16 0 15.3438 0 14.5V6H14V14.5Z" fill="#F1F1F1"/>
    </svg>
    );
  }
  
  export default CalendarIconFilled;