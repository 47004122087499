import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Modal from "../../app/modals/Modal";
import useAddChannelsModal from "../../app/hooks/useAddChannelsModal";
import { ParagraphM, SubheadingS } from "../../Components/Text";

const Container = styled.div`
    margin: 0 24px 24px;
`
const AddChannelModal = () => {
    const addChannelsModal = useAddChannelsModal()
    const navigate = useNavigate();

    const navigateToChannels = ()=>{
        addChannelsModal.onClose();
        navigate('/me')
    }

    const bodyContent = (
        <Container>
            <SubheadingS>You need to add your (social media) channels first</SubheadingS>
            <ParagraphM color="#727272">
                You cannot pitch on projects because you 
                haven't added any (social media) channels.
            </ParagraphM>
            <ParagraphM  color="#727272" mt="16px">
            Please note that you can see the number of followers only if you 
            have a business account on Instagram.</ParagraphM>
        </Container>
    )

    return (
        <Modal
            title={"Add your channels"}
            isOpen={addChannelsModal.isOpen}
            onClose={addChannelsModal.onClose}
            body={bodyContent}
            actionLabel="Add your channels"
            onSubmit={navigateToChannels}
        />);
}

export default AddChannelModal;