import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import useInitialProjectData from './useInitialProjectData';
import Project from './Project'; // Zorg ervoor dat het pad klopt
import { format, parse } from 'date-fns';

const removeDateSuffix = (dateStr) => {
  return dateStr.replace(/(\d+)(th|st|nd|rd)/, '$1');
};

const convertDate = (dateStr) => {
  if (!dateStr) return null;
  const cleanedDateStr = removeDateSuffix(dateStr);
  const parsedDate = parse(cleanedDateStr, 'MMMM d yyyy', new Date());
  if (isNaN(parsedDate)) return null; // Check of de datum geldig is
  return format(parsedDate, 'dd-MM-yyyy');
};

const ProjectDetails = () => {
  const { initialProjectData, loading, error } = useInitialProjectData();

  if (loading) {
    return (
      <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
        <ClipLoader color={"#FF4E5B"} loading={true} size={60} aria-label="Loading Spinner" data-testid="loader" />
      </div>
    );
  }

  if (error) {
    return <p>Something went wrong: {error.message}</p>;
  }

  const formattedData = initialProjectData  ? {
    is_published: initialProjectData?.is_published,
    title: initialProjectData?.title,
    description: initialProjectData?.description,
    marketing_goals: initialProjectData?.marketing_goals,
    budget_amount: initialProjectData?.budget_amount,
    delivery_deadline: convertDate(initialProjectData?.delivery_deadline),
    pitch_deadline: convertDate(initialProjectData?.pitch_deadline),
    channels: initialProjectData?.channels,
    influencer_product: initialProjectData?.influencer_product === false ? 0 : 1,
    influencer_occupations: initialProjectData?.influencer_occupations,
    audience_occupations: initialProjectData?.influencer_occupations,
    audience_countries: initialProjectData?.audience_countries,
    influencer_languages: initialProjectData?.influencer_languages, 
    image: initialProjectData?.image
  } : null;


  return <Project initialData={formattedData} />;
};

export default ProjectDetails;
