import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { FilterButton } from '../UI'

const Section = styled.div`
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
`
const Wrapper = styled.div`
    width: 90%;
    height: 100%;
    max-width: var(--max-width);
    overflow: auto;

    `
const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
  max-width: 290px;
 
`
const FilterDetailsPageBar = ({ setFilterBarState, filterBarState }) => {
    const [activeButton, setActiveButton] = useState(0);
    const [isDesktop768, setIsDesktop768] = useState(false);
    const [isDesktop1024, setIsDesktop1024] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop768(window.innerWidth <= 768);
            setIsDesktop1024(window.innerWidth >= 1024);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    useEffect(() => {
        switch (activeButton) {
            case 0:
                isDesktop768 ? setFilterBarState('brief') : setFilterBarState('details')
                break;
            case 1:
                isDesktop768 || isDesktop1024 ? setFilterBarState('channelRequirements') : setFilterBarState('details')
                break;
            case 2:
                setFilterBarState('idealInfluencers')
                break;
            case 3:
                setFilterBarState('details')
                break;
        }

    }, [activeButton, isDesktop768])


    const handleClick = (index) => {
        setActiveButton(index);
    };

    return (
        <Section>
            <Wrapper>
                <StyledButtonContainer>
                    {isDesktop768 && <FilterButton text={"Brief"}
                        buttonStyle={activeButton === 0 ? "primary" : "secondary"}
                        onClick={() => handleClick(0)} />}
                    {isDesktop768 &&
                        <FilterButton text={"Channel requirements"}
                            buttonStyle={activeButton === 1 || filterBarState === '' ? "primary" : "secondary"}
                            onClick={() => handleClick(1)} />}
                    <FilterButton text={"Ideal creatives"}
                        buttonStyle={activeButton === 2 ? "primary" : "secondary"}
                        onClick={() => handleClick(2)} />
                    {!isDesktop768 &&
                        <FilterButton text={"Details"}
                            buttonStyle={activeButton === 3 || filterBarState === 'details' ? "primary" : "secondary"}
                            onClick={() => handleClick(3)} />}
                </StyledButtonContainer>
            </Wrapper>
        </Section>
    )
}

export default FilterDetailsPageBar
