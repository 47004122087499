/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledButton = styled.button`
	border: ${(p) => (p.primary ? "transparent" : "1px solid #e3e3e3")};
	background: ${(p) => (p.primary ? p.theme.main : p.theme.white)};
	color: ${(p) =>
		p.primary ? p.theme.white : p.textcolor ? p.textcolor : p.theme.main};
	font-size: 16px;
	font-weight: 700;
	line-height: 150%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	box-shadow: ${(p) =>
		p.primary ? " 0px 1px 0px #982e36" : "0px 1px 0px #E3E3E3"};
	border-radius: 1px;
	margin-top: ${(p) => (p.mt ? p.mt : "24px")};
	width: ${(p) => p.fwidth && "100%"};
	font-family: "Avenir LT Std";
	margin-top: ${(p) => p.mt && p.mt};
	cursor: pointer;
	color: ${(p) => p.color && p.color};
	width: ${(p) => (p.fwidth ? "100%" : p.width || "auto")}; /* Voeg hier de width prop toe */
	padding: 10px 14px;

	&:hover {
        background: #982e36;
     }

`;

const FrontIconButton = ({
	Icon,
	text,
	primary,
	mt24,
	textcolor,
	fwidth,
	width,
	onClick,
	mt,
	children,
}) => {
	return (
		<StyledButton
			primary={primary}
			mt={mt}
			textcolor={textcolor}
			fwidth={fwidth}
			width={width}
			onClick={onClick}
			mt24={mt24}
		>
			{children}
			<span>{Icon || null}</span>
			<span>{text}</span>
		</StyledButton>
	);
};

export default FrontIconButton;
