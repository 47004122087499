/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledButton = styled.button`
  padding: 10px 14px;
  background-color: ${(props) =>
    props.disabledWithColor
      ? "#D8DCDF"
      : props.disabled
      ? "#C0C0C0" // Grijs als de knop is uitgeschakeld
      : props.primary
      ? props.theme.main
      : props.background || props.theme.white};
  color: ${(props) =>
    props.disabled
      ? props.theme.white // Aangepaste tekstkleur wanneer disabled, pas dit aan naar behoefte
      : props.primary
      ? props.theme.white
      : props.textcolor || props.theme.main};
  font-weight: 700;
  border: ${(p) => (p.primary ? "transparent" : "1px solid #e3e3e3")};
  border-radius: ${(p) => (p.roundBorder ? "78px" : "2px")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  gap: ${(props) => (props.gap ? props.gap : "0")};
  font-family: "Avenir LT Std";
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(p) => p.mb && p.mb};
  margin-top: ${(p) => (p.mt ? p.mt : "24px")};
  padding: ${(p) =>
    p.padding ? p.padding : p.primary ? "16px 24px" : "12px 10px"};
  width: ${(p) => (p.width ? p.width : p.fwidth ? "100%" : null)};
  // background: ${(props) => (props.disabled ? "#C0C0C0	" : "#982e36")};

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "none" : props.primary ? "#982e36" : "#f0f0f0"};
  }
`;

const Button = ({
  Icon,
  primary,
  secondary,
  mt24,
  textcolor,
  fwidth,
  onClick,
  width,
  mt,
  padding,
  type,
  text,
  roundBorder,
  textSize,
  iconFirst,
  mb,
  disabled,
  background,
  disabledWithColor,
  cursor,
  light,
  hover,
  gap,
  className,
  spanStyle
}) => {
  const buttonClassName = `button ${primary ? "primary" : "secondary"} ${
    className || ""
  }`;

  return (
    <StyledButton
      primary={primary}
      secondary={secondary}
      mt={mt}
      textcolor={textcolor}
      fwidth={fwidth}
      width={width}
      onClick={onClick}
      hover={hover}
      mt24={mt24}
      className={buttonClassName}
      type={type}
      padding={padding}
      cursor={cursor}
      roundBorder={roundBorder}
      disabledWithColor={disabledWithColor}
      textSize={textSize}
      background={background}
      disabled={disabled}
      mb={mb}
      light={light}
      gap={gap}
    >
      {iconFirst ? (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {Icon || null}
          <span>{text}</span>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span style={spanStyle}>{text}</span>
          {Icon || null}
        </div>
      )}
    </StyledButton>
  );
};

export default Button;
