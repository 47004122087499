/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components';
import React from "react";
import { useNavigate } from 'react-router-dom';
import { LiaMicrophoneAltSolid } from "react-icons/lia";
import { AiOutlineCheck } from "react-icons/ai";
import { useDispatch } from 'react-redux';

/* -------------------------- Internal Dependencies ------------------------- */
import Channels from '../Channels/Channels';
import influencerProfile from '../../../Images/influencer-profile.png';
import SummaryInfluencerCard from './SummaryInfluencerCard';
import { updateInfluencer } from '../../../app/features/InfluencersSlice';
import ParagraphXS from '../../Text/ParagraphXS';
import apiClient from '../../../services/api';

const Container = styled.div`
  border-radius: 4px;
  background: #fff;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02);

  @media (max-width: 724px) {
    max-width: 360px;
  }

}`;

const StyledWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
}`;

const Row = styled.div`
  display: flex;
  gap: ${(props) => props.gap || "0"}; 
  margin-top: 8px;
  margin-bottom: 8px;
`;


const StyledButton = styled.button`
border: ${(p) => (p.invited ? "transparent" : "1px solid #e3e3e3")};
  background: ${(p) => p.invited ? p.theme.main : p.theme.white};
	color: ${(p) => (p.invited ? '#fff' : '#042234')};
	font-size: 16px;
	font-weight: ${(p) => p.light ? 500 : 700};
	line-height: 150%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	padding: 12px 10px;
	box-shadow: ${(p) => p.invited ? "0px 1px 0px #E3E3E3" : "0px 1px 0px #E3E3E3"};
	border-radius:  2px;
	margin-top: 24px;
	width: 100%;
	font-family: "Avenir LT Std";
	margin-top: ${(p) => p.mt && p.mt};
	cursor: ${(p) => (p.invited ? "not-allowed" : "pointer")};

    &:active {
        opacity: 0.5;
     }
`

const TopContainer = styled.div`
  display: flex;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: 16px; 
`;

const ImageContainer = styled.div`
  width: 88px;        
  height: 88px;        
  border-radius: 50%;  
  cursor: pointer;

`;

const Name = styled.p`
  font-size: 22px;
  font-family: Avenir LT Std;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.44px;
`;

const DetailsContainer = styled.div`
     flex: 1;
     height: 100%;
`;

const StyledImage = styled.img`
  display: flex;
	align-items: center;
	overflow: hidden;
  border-radius: 50%;
  height: 80px;
  width: 80px;
	background-color: var(--b-b50);
  border: 1px solid var(--b-b100);
`;


const InfluencerCard = ({ influencer, campaignData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleInviteToPitch = () => {

    apiClient.post(`campaign/${campaignData.id}/invite`, {user: influencer.id }).then((resp)=>{
      const modifiedObject = {...influencer}
      modifiedObject.invited = true;
      dispatch(updateInfluencer(modifiedObject))
    })
  }

  const handleCardPress = () => {
    navigate(`/creative/${influencer.id}`)
  }

  return (
    <Container>
      <StyledWrapper>
        <Row gap="5px">
          <TopContainer>
            <ImageContainer  onClick={handleCardPress}>
              <StyledImage src={influencer.image || influencerProfile} alt="Creatives image" />
            </ImageContainer>
            <TextContainer>
              <Name>{influencer?.name}</Name>
              {influencer.channels &&
                <>
                  <ParagraphXS>Channels I propose to post on:</ParagraphXS>
                  <div style={{ marginLeft: 20, marginTop: 5 }}>
                    <Channels channels={influencer?.pitch_channels} />
                  </div>
                </>
              }
            </TextContainer>
          </TopContainer>
        </Row>
        <DetailsContainer>
          <SummaryInfluencerCard influencer={influencer} />
        </DetailsContainer>
          <StyledButton
            onClick={handleInviteToPitch}
            textcolor={"#042234"}
            textSize="14px"
            width='100%'
            invited={influencer.invited}
          >
            {influencer.invited ? <AiOutlineCheck /> : <LiaMicrophoneAltSolid />}
            {influencer.invited ? "Invited" : "Invite to pitch"}
          </StyledButton>
      </StyledWrapper>
    </Container>
  );
};

export default InfluencerCard;
