import React from "react";

function ArrowSmall() {
  return (
  <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.9688 10.7812C10.8125 10.6562 10.75 10.4688 10.75 10.25C10.75 10.0625 10.8125 9.875 10.9688 9.75L13.9375 6.75H1.25C0.8125 6.75 0.5 6.4375 0.5 6C0.5 5.59375 0.8125 5.25 1.25 5.25H13.9375L10.9688 2.28125C10.6562 2 10.6562 1.53125 10.9688 1.25C11.25 0.9375 11.7188 0.9375 12.0312 1.25L16.2812 5.5C16.5625 5.78125 16.5625 6.25 16.2812 6.53125L12.0312 10.7812C11.7188 11.0938 11.25 11.0938 10.9688 10.7812Z" fill="white"/>
  </svg>
  );
}

export default ArrowSmall;
