/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components';

/* -------------------------- Internal Dependencies ------------------------- */
import CheckBox from '../../../../Icons/CheckBox';
import Rocket from '../../../../Icons/Rocket';
import { FlexContainer } from '../../../Layout';
import { ParagraphS, SubheadingS } from '../../../Text';
import CampaignBadges from './CampaignBadges';

const StyledIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const StyledFlexContainer = styled(FlexContainer)`      
    justify-content: space-between;
    padding-top: 20px;
    .paragraph_s {
        display: flex;
        padding-left: 8px;
        align-self: center;
    }
`

const StyledFlexContainerCampaignName = styled(FlexContainer)`      
    justify-content: space-between;
    align-items: self-end;
    margin-top: 20px;
`

const CampaignInformation = ({ marketing_goals, company_name, influencer_product, channels, data}) => {
  
  return (
      <div>
        <StyledFlexContainerCampaignName>
          <SubheadingS fontWeight={"500"} mt={"0px"}>
              By {company_name}
              {/* <span style={{ fontWeight: 700 }}>&nbsp;{company_name}</span> */}
          </SubheadingS>
         {channels&& <CampaignBadges channels={channels}/> }
        </StyledFlexContainerCampaignName>
        <StyledIconContainer>
          <StyledFlexContainer>
            <CheckBox height={"24px"} fill="#A4AFB6" width={"24px"}/>
            <ParagraphS mt={"0px"}>
              {influencer_product ? "Product included" : "Product not included"}
            </ParagraphS>
          </StyledFlexContainer>
          <StyledFlexContainer>
            <Rocket height={"24px"} fill="#A4AFB6" width={"24px"}/>
            {marketing_goals && <ParagraphS mt={"0px"}>{Object.keys(marketing_goals).length > 0 ? marketing_goals[Object.keys(marketing_goals)[0]] : ''}</ParagraphS>}
          </StyledFlexContainer>
        </StyledIconContainer>
    </div>
  )
}

export default CampaignInformation
