import React, { useState } from 'react'
import styled from 'styled-components'

import { ParagraphM, SubheadingXS } from '../../Text'
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";

const Section = styled.div`
    margin-top: 16px;
    cursor: pointer;
    
  @media (max-width: 770px) {
    width: 90vw;
  }
`

const AccordionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    padding: 20px 16px;
    
    &:hover {
        .accordion-btn{
            background-color: var(--g-s100);
        }
    }
    
`
const TitleContainer = styled.div`
    display: flex;
    gap: 4px;
`

const ButtonContainer = styled.div`
    margin-left: 16px;
`

const Button = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px #D8DCDF;
    background: #D8DCDF;
    border-radius: 50%;
    cursor: pointer;
`
const OpenButton = styled(Button)`
   
    transform: ${(p) => (p.arrowButtonClick ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform .15s ease-in, background-color .2s;
`
const AccordionContent = styled.div`
    width: 100%;
    overflow: hidden;
    transition: 0.5s ease-in-out;
    max-height: ${props => props.arrowButtonClick ? "1000px" : "0"};
`

const Channel = ({ Icon, title, text, children, open }) => {
    const [arrowButtonClick, setArrowButtonClick] = useState(open);

    return (
        <Section>
            <AccordionHeader onClick={() => setArrowButtonClick(!arrowButtonClick)}>
                <div>
                    <TitleContainer>
                        {Icon || null}
                        <SubheadingXS>{title}</SubheadingXS>
                    </TitleContainer>
                    {text && <ParagraphM>{text}</ParagraphM>}
                </div>
                <ButtonContainer>
                    <OpenButton 
                        className='accordion-btn'
                        onClick={() => setArrowButtonClick(!arrowButtonClick)} 
                        arrowButtonClick={arrowButtonClick}>
                        {arrowButtonClick? <FiMinus /> :
                        <FiPlus />}
                    </OpenButton>
                </ButtonContainer>
            </AccordionHeader>
            <AccordionContent arrowButtonClick={arrowButtonClick}>
                {children}
            </AccordionContent>
        </Section>
    )
}

export default Channel
