/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

/* -------------------------- Internal Dependencies ------------------------- */
import { HeadingXL, ParagraphM } from "./Text";
import { LayoutCenter } from "./Layout";
import { Button } from "./UI";

/* -------------------------- Internal SVG Dependencies ------------------------- */
import RightArrow from "../Icons/RightArrow";
/* -------------------------- Images ------------------------- */
import Megaphone from "../Images/Megaphone.png"


const StyledImage = styled.img`
	display: inline-block;
	width: 153px;
	height: 130px;
`;

const MegaphonePage = ({ onButtonClick, title, text}) => {
	return (
		<LayoutCenter>
			<StyledImage src={Megaphone} alt="welcome" />
			<HeadingXL>{title}</HeadingXL>
			<ParagraphM>
				<div dangerouslySetInnerHTML={{__html: text}}/>
			</ParagraphM>
			<Button
				fwidth
				onClick={onButtonClick}
				text={"Continue"}
				primary
				Icon={<RightArrow />}
			/>
		</LayoutCenter>
	);
};

export default MegaphonePage;
