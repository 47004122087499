import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../../services/api";

const useInitialProjectData = () => {
  const { id } = useParams();
  const [initialProjectData, setInitialProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (id === "new") {
      setLoading(false);
      return;
    }

    const fetchProjectData = async () => {
      try {
        const response = await apiClient.get(`campaign/${id}`);
        setInitialProjectData(response.data.data);
      } catch (error) {
        console.error("Fout bij het ophalen van de campaign ", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [id]);

  return { initialProjectData, loading, error };
};

export default useInitialProjectData;
