import Channel from './Channel'
import { HeadlineM, ParagraphS } from '../../Text'
import FaceBook from './FaceBook'
import OtherChannels from './OtherChannels'
import BlogPost from './BlogPost'
import Instagram from './Instagram'
import Offline from './Offline'
import Pinterest from './Pinterest'
import styled from 'styled-components'

const StyledChannels = styled.div`
    padding-top: 24px;
    padding-bottom: 180px;
    padding: 0px 16px;
    width: 100%;

    @media only screen and (max-width: 768px) {
        padding: 24px;
    }
`
const Channels = ({ campaignData }) => {

    const renderChannelComponent = (channel, data, index) => {
        switch (channel) {
            case 'instagram':
                return <Instagram data={data} />;
            case 'facebook':
                return <FaceBook data={data} />;
            // Voeg hier andere kanalen toe
            case 'pinterest':
                return <Pinterest data={data} />;
            case 'thursd':
                return <BlogPost data={data} />;
            case 'other':
                return <OtherChannels data={data} />;
            case 'offline':
                return <Offline data={data} />;
            default:
                return <ParagraphS>No channel requirements</ParagraphS>;
        }
    };

    return (
        <StyledChannels>
            <HeadlineM mt={"0px"} color={"#042234"}>Channel requirements</HeadlineM>
            { campaignData && campaignData?.channels && Object.entries(campaignData.channels).reverse().map(([channel], index) => (
                <Channel 
                    key={channel} 
                    title={channel.charAt(0).toUpperCase() + channel.slice(1)}
                    open={index === 0}>
                    {renderChannelComponent(channel, campaignData, index)}
                </Channel>
            ))}

        </StyledChannels>
    )
}

export default Channels
