import React from "react";

import { LayoutCenterTop } from "../../Components/Layout";
import { HeadingXL, SubheadingS } from "../../Components/Text";
import { Button } from "../../Components/UI";
import RightArrow from "../../Icons/RightArrow";

export default function ProjectForm({
  goToNext,
  goToPrevious,
  index,
  title,
  subTitle,
  children
}) {
  return (
    <LayoutCenterTop>
      {title && <HeadingXL mt="0px">{title}</HeadingXL>}
      {subTitle && <SubheadingS>{subTitle}</SubheadingS>}
      <div>
        {children}
      </div>
      <div>
        {index === 3 ? (
          <Button
            mt24
            fwidth
            mt="24px"
            padding={"16px"}
            text={"Submit"}
            primary
            Icon={<RightArrow />}
            onClick={() => goToNext()}
          />
        ) : (
          <Button
            mt24
            fwidth
            mt="24px"
            padding={"16px"}
            text={"Next"}
            primary
            Icon={<RightArrow />}
            onClick={() => goToNext()}
            type="submit"

          />
        )}
      </div>

      {index && index > 1 && (
        <Button
          fwidth
          mt="24px"
          text={"Back to previous question"}
          textcolor={(p) => p.theme.main}
          fs14
          onClick={goToPrevious}
          padding={"10px"}
        />
      )}
    </LayoutCenterTop>
  );
}
