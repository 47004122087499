import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ParagraphM, HeadlineXL } from '../../Components/Text'
import MultiInput from '../../Components/Common/MultiInput'
import { LayoutCenterTop } from '../../Components/Layout'
import { Button } from '../../Components/UI'
import ArrowButton from '../../Icons/ArrowButton'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { profileSetup } from '../../app/features/profileSlice'

const ArrowButtonWrapper = styled.div`
  cursor: pointer; /* Voeg een cursor toe om aan te geven dat het klikbaar is */
`;
export default function AddWebsitePage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.profile.profile);
    const [inputList, setInputList] = useState([]);
    const [parentInput, setParentInput] = useState('')

    //set default values
    useEffect(() => {
        if (user) {
            setInputList(user.data.profile.channel["Websites"] || [])
        }
    }, [user])


    const handleBackButton = () => {
        window.history.back();
    };

    const onSubmit = async () => {
        const newChannels = { ...user.data.profile.channel}
        newChannels['Websites'] = inputList

        if(parentInput.length > 0 ) {
            newChannels['Websites'].push(parentInput)
        }
        
        dispatch(profileSetup({data: {channel: newChannels}})).then((resp)=>{
            navigate('/me')
        })

      }
    return (
        <LayoutCenterTop>
            <ArrowButtonWrapper onClick={handleBackButton}>
                <ArrowButton />
            </ArrowButtonWrapper>
            <div style={{ padding: '24px', }}>
                <HeadlineXL mt={"16px"}>Add your website</HeadlineXL>
                <div style={{ marginTop: '16px' }}>
                    <ParagraphM color={'#4F4F4F'}>
                        Welcome! To add your website, simply enter the website URL in the designated input field.
                        Make sure to include the complete URL, including 'http://' or 'https://'. For example,
                        'https://www.thursd.com'. Once you've entered the URL, click the 'Submit' button to save your changes.
                    </ParagraphM>
                </div>
                <div style={{ marginTop: '16px' }}>
                    {user &&
                        <MultiInput
                            title="Websites"
                            inputList={inputList}
                            setInputList={setInputList}
                            parentInput={parentInput}
                            setParentInput={setParentInput}
                        />}
                </div>
                <div style={{ marginTop: '32px' }}>
                    <Button
                        fwidth
                        primary
                        onClick={onSubmit}
                        text={"Submit"}
                        mt={"16px"}
                    />
                </div>
            </div>
        </LayoutCenterTop>
    )
}
