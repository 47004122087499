import React from 'react'

const T = (props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="13"
        viewBox="0 0 13 13"
        className='thursd'
        {...props}
      >
        <path
          fill={props.fill}
          d="M0.815938 0.768L0.575938 4.784H1.23194C2.39994 2.272 2.83194 1.424 3.90394 1.424H4.43194V10.32C4.43194 11.152 4.15994 11.216 3.11994 11.328V12H8.87994V11.328C7.83994 11.216 7.56794 11.152 7.56794 10.32V1.424H8.09594C9.16794 1.424 9.59994 2.272 10.7679 4.784H11.4239L11.1839 0.768H0.815938Z"
        ></path>
      </svg>
    );
  }
  
  export default T;