/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components'

const StyledSubheadingM= styled.h2`
	margin-top: ${(p) => (p.mt ? p.mt : "24px")};
	margin-left: ${(p) => (p.ml ? p.ml : "0px")};
	margin-bottom: ${(p) => (p.mb ? p.mb : "0px")};
	color: ${(p) => (p.color ? p.color : "var(--g-s700)")};
	font-weight: ${(p) => (p.fontWeight && p.fontWeight)};
`
const SubheadingM = ({mt, ml, mb, children, fontWeight, color}) => {
  return (
    <StyledSubheadingM mt={mt} ml={ml} mb={mb} color={color} fontWeight={fontWeight} className="sub_heading_m">
		{children}
	</StyledSubheadingM>
  )
}

export default SubheadingM;
