import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";

export const onboardingUser = createAsyncThunk(
	"user/onboardingUser",
  async ( data ) => {
    const response = await apiClient.post(`/onboarding`, data);
    return response.data;
  }
);

const initialState = {
	onboarding: {}
};

export const onboardingSlice = createSlice({
	name: "onboarding",
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder
			.addCase(onboardingUser.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(onboardingUser.fulfilled, (state, action) => {
				state.onboarding = action.payload
			})
			.addCase(onboardingUser.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
			});
	},
});
export const {

} = onboardingSlice.actions;
export default onboardingSlice.reducer;
