import React from 'react';
import styled from 'styled-components';
import { AiOutlineInstagram } from "react-icons/ai";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaPinterest } from "react-icons/fa";
import { BiWifiOff } from "react-icons/bi";
import { PiGlobe } from "react-icons/pi";

import IconRound from '../../../IconRound';
import { ParagraphS } from '../../../Text';
import T from '../../../../Icons/T';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Channel = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
`
const ChannelNames = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const CampaignBadge = ({ channels }) => {
    const icons = {
        Facebook: <BiLogoFacebookCircle size={24} color="#031B2A" />,
        Instagram: <AiOutlineInstagram size={24} color="#031B2A" />,
        Pinterest: <FaPinterest size={24} color="#031B2A" />,
        Websites: <PiGlobe size={24} color={"#031B2A"} />,
        Offline: <BiWifiOff size={24} color="#031B2A" />,
        Other: <BiWifiOff size={24} color="#031B2A" />,
        Thursd: <T height={"100px"} />,

    };

    const badges = Object.entries(channels).map(([channelName, items]) => {
        const displayName = channelName === 'Other' ? `Other: ${items}` : channelName;

        if (icons[channelName]) {
            return (
                <Channel key={channelName}>
                    <IconRound
                        key={channelName}
                        height={"32px"}
                        width={"32px"}
                        fill={"#FFF"}
                        Icon={icons[channelName]}
                    />

                    <ChannelNames>
                    <ParagraphS color="#364E5D">{displayName}</ParagraphS>

                    {/* {items?.map((item, index) => (
                        <ParagraphS color="#364E5D" key={index}>{item}</ParagraphS>
                    ))} */}
                    </ChannelNames>
                </Channel>
            );
        }
        return null;
    });

    return <Container>{badges}</Container>;
};

export default CampaignBadge;
