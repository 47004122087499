import styled from "styled-components";
import { ParagraphS, SubheadingXS } from "../../Text";
import { Button } from "../../UI";
import usePitchStatus from "../../../app/hooks/usePitchStatus";

const CardContainer = styled.div`
  max-width: 65vw;
  padding: 1rem;
  background-color: white;
  width: 348px;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;
const ContainerWithBorder = styled.div`
  height: 100%;
  border-bottom: 1px solid #e0e0e0;
  padding: 4px 0;
  margin-top: 16px;
`;

const DottedStripe = styled.div`
  width: 100%;
  border-bottom: 1px dashed #f1f1f1;
`;

const FooterCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
`;

const ReceiptItem = ({ title, amount }) => (
  <FlexSpaceBetween>
    <ParagraphS fontWeight="200" color="#959595">
      {title}
    </ParagraphS>
    <ParagraphS fontW="200" color="#959595">
      {amount}
    </ParagraphS>
  </FlexSpaceBetween>
);

const ReceiptItemBold = ({ title, amount, subheading }) => (
  <div>
    <FlexSpaceBetween>
      <div style={{ maxWidth: "20ch" }}>
        <SubheadingXS>{title}</SubheadingXS>
      </div>
      <SubheadingXS>{amount}</SubheadingXS>
    </FlexSpaceBetween>
    {subheading && (
      <div style={{ marginBottom: "16px", width: "30ch", maxWidth: "40vw" }}>
        <ParagraphS fontW="200" color="#959595">
          {subheading}
        </ParagraphS>
      </div>
    )}
  </div>
);

const ReceiptItemText = ({ title, text }) => (
  <div style={{ marginBottom: "16px" }}>
    <SubheadingXS fontWeight="200">{title}</SubheadingXS>
    <ParagraphS fontW="200" color="#959595">
      {text}
    </ParagraphS>
  </div>
);

const Receipt = ({ bid, accept, acceptBid }) => {
  const { pitchStatus } = usePitchStatus();
  const showAcceptButton =
    pitchStatus !== "accepted" && pitchStatus !== "closed";

  return (
    <>
      <CardContainer>
        <ReceiptItem
          title="Creative gets"
          amount={bid?.pricing?.influencer_get}
        />
        <ReceiptItem title="myThursd" amount={bid?.pricing?.thursd_get} />
        <DottedStripe />
        <ContainerWithBorder>
          <ReceiptItemBold title="Bid total" amount={bid?.pricing?.bid_total} />
        </ContainerWithBorder>

        {bid && bid.note && (
          <ContainerWithBorder>
            <ReceiptItemText title="Justification of bid:" text={bid.note} />
          </ContainerWithBorder>
        )}
        {bid && bid.changes && (
          <ContainerWithBorder>
            <ReceiptItemText
              title="Channels:"
              text={Object.values(bid.changes).map((value, index, array) => (
                <span key={value}>
                  {value?.charAt(0).toUpperCase() + value?.slice(1)}
                  {index < array.length - 1 ? ", " : ""}
                </span>
              ))}
            />
          </ContainerWithBorder>
        )}

        {bid && bid.pricing.thursd_blog && (
          <ContainerWithBorder>
            <ReceiptItemBold
              title="Thursd.com Blog"
              amount={bid.pricing.thursd_blog}
              subheading="Fixed price"
            />
          </ContainerWithBorder>
        )}

        {bid.pricing.shipping !== "€ 0.00" && bid && bid.pricing.shipping && (
          <ContainerWithBorder>
            <ReceiptItemBold
              title="Shipping & Handling by Thursd"
              amount={bid.pricing.shipping}
              subheading="Shipping & Handling costs per creative."
            />
          </ContainerWithBorder>
        )}

        <div style={{ marginTop: "16px" }}>
          <ReceiptItemBold
            title="Price for this project"
            amount={bid?.pricing?.total}
          />
        </div>
        {accept && (
          <FooterCard>
            <Button
              primary
              mt={"0px"}
              text="Accept pitch"
              width="100%"
              onClick={() => acceptBid(bid.id)}
              disabled={!showAcceptButton}
            />
            <Button
              mt={"0px"}
              text="Accept and Close"
              width="100%"
              onClick={() => acceptBid(bid.id)}
              disabled={!showAcceptButton}
            />
          </FooterCard>
        )}
      </CardContainer>
    </>
  );
};

export default Receipt;
