import React from 'react';
import styled from 'styled-components';

import Flag from '../../Icons/Flag';
import Bag from '../../Icons/Bag';
import People from '../../Icons/People';
import { HeadlineM } from '../Text';
import { Container } from '../Layout';
import ProfileInformationItemSmall from "../Common/ProfilePage/ProfileInformationItemSmall";

const Section = styled.div`
  min-height: 60vh;
  background: var(--g-s50);
  width: 100%;
`;

const StyledContainer = styled.div`
  margin-top: 1.25rem;

  .headline_m {
    margin-bottom: 1rem;
  }
`;


const SubContainer = styled.div`
    width: 50%;

    @media (max-width: 780px) {
        width: 100%;
    }
   
`
const SmallItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top:16px;
`
const IdealInfluencer = ({ data }) => {
  if (!data || !data.influencer_occupations || !data.audience_occupations || !data.influencer_languages_names || !data.audience_countries_names) {
    return null;
  }
  return (
    <Section>
      <Container>
        <StyledContainer>

          <SubContainer>
            <HeadlineM mt={"0px"} color={"#042234"}>Ideal creative</HeadlineM>
            <SmallItemsContainer>

              <ProfileInformationItemSmall
                Icon={<Bag />}
                title={"Occupation"}
                answers={data.influencer_occupations}
              />
              <ProfileInformationItemSmall
                Icon={<People />}
                title={"Audience occupations"}
                answers={data.audience_occupations}
              />
              <ProfileInformationItemSmall
                Icon={<Flag />}
                title={"Audience(s) in"}
                answers={data.audience_countries_names} />
            </SmallItemsContainer>
          </SubContainer>
        </StyledContainer>
      </Container>
    </Section>
  )
}

export default IdealInfluencer;
