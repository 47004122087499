import React from 'react';
import styled from 'styled-components';
import { InputLabel } from '../Forms';

const StyledButton = styled.button`
    border: ${(p) => (p.primary ? "transparent" : "1px solid #e3e3e3")};    background: ${(props) => props.primary ? props.theme.main : props.theme.white};
    color: ${(props) => props.primary ? props.theme.white : props.theme.main};
    font-size: 12px;
    font-weight: ${(props) => props.primary ? 600 : 700};
    lineheight: 150%;
    padding: 8px 24px;
    box-shadow: ${(p) => p.disabled ? "0px 1px 0px #E3E3E3" : p.primary ? "0px 1px 0px #982e36" : "0px 1px 0px #E3E3E3"};    
    border-radius: 2px;
    cursor: pointer;
    margin-top: 8px;
`

function MultiInput({ placeholder, inputList, setInputList, parentInput, setParentInput }) {

    const handleAddInput = () => {
        if (parentInput.trim() !== '') {
            setInputList([...inputList, parentInput]);
            setParentInput('');
        }
    };

    const removeInput = (index) => {
        const updatedInputList = inputList.filter((_, i) => i !== index);
        setInputList(updatedInputList);
    };


    const handleParentInputChange = (e) => {
        const inputValue = e.target.value;
        setParentInput(inputValue);
    };

    const handleChildInputChange = (e, index) => {

        //maak een kopie van de lijst
        const updatedInputList = [...inputList];

        //werk de waarde van de juiste input bij
        updatedInputList[index] = e.target.value;

        //update de lijst
        setInputList(updatedInputList);
    };

    return (
        <div>
            <div>
                <InputLabel
                    placeholder={placeholder}
                    onChange={handleParentInputChange}
                    value={parentInput}
                />

                <StyledButton primary onClick={handleAddInput}>
                    Add channel
                </StyledButton>

                {inputList.map((item, index) => (
                    <div key={index} style={{ marginTop: '16px', marginBottom: '16px' }}>
                        <InputLabel
                            value={item}
                            onChange={(e) => handleChildInputChange(e, index)}
                        />
                        <StyledButton onClick={() => removeInput(index)}>
                            Remove channel
                        </StyledButton>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MultiInput;
