/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components'

const StyledSubheadingS= styled.h2`
	margin-top: ${(p) => (p.mt ? p.mt : "24px")};
	margin-left: ${(p) => (p.ml ? p.ml : "0px")};
	margin-bottom: ${(p) => (p.mb ? p.mb : "0px")};
	margin-right: ${(p) => (p.mr ? p.mr : "0px")};
	color: ${(p) => (p.color ? p.color : "var(--g-s700)")};
	font-weight: ${(p) => (p.fontWeight && p.fontWeight)};
	text-align: ${(p) => (p.textAlign && p.textAlign)};

`
const SubheadingS = ({mt, ml, textAlign, mb, children, fontWeight, color}) => {
  return (
    <StyledSubheadingS mt={mt} ml={ml} mb={mb} color={color} fontWeight={fontWeight} textAlign={textAlign} className="sub_heading_s">
		{children}
	</StyledSubheadingS>
  )
}

export default SubheadingS;
