import { useState, useEffect } from 'react'
import apiClient from '../../services/api';

export const useDataSource = resourceUrl => {
    const [resource, setResource] = useState(null)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const result = await apiClient.get(resourceUrl);
                setResource(result.data);
                setLoading(false); 
            } catch (error) {
                console.error('Error fetching data:', error, resourceUrl);
                setLoading(false); 
            }
        })();
    }, [resourceUrl])


    return { resource, loading };
}
