import React from "react";

function ArrowUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="6"
      fill="none"
      viewBox="0 0 11 6"
    >
      <path
        fill="#031B2A"
        d="M.602 4.602L5.078.312c.14-.117.281-.187.422-.187.14 0 .258.047.375.14l4.477 4.29c.234.234.234.586.023.797-.21.234-.563.234-.797.023L5.5 1.485 1.375 5.421a.56.56 0 01-.797-.024c-.21-.234-.21-.585.024-.796z"
      ></path>
    </svg>
  );
}

export default ArrowUp;
