import React from 'react'
import styled from 'styled-components';

import SubheadingL from '../../Text/SubheadingL';
import { ParagraphM, SubheadingS } from '../../Text';

const InputWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.textarea`
  height: ${(props) => props.height || "50px"}; 
  background-color: white;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  padding: ${(props) => props.padding || "12px 16px"};
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #727272;
  width: 100%;
`;

const EuroSignWrapper = styled.div`
  background-color: #FF4E5B;
  position: absolute;
  top: 0;
  left: 0;
  height: 93%; 
  width: 36px; 
`;

const EuroSign = styled.div`
  position: absolute;
  left: 12px;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
`;

export default function EuroInput({
    label,
    sublabel,
    setInput,
    input
}) {
    return (
        <>
            <SubheadingS color={"#2F2F2F"}>{label}</SubheadingS>
            <ParagraphM colorSecondary>{sublabel}</ParagraphM>
            <InputWrapper>

                <EuroSignWrapper>
                    <EuroSign>
                        <SubheadingL mt={"0px"} color="white">€</SubheadingL>
                    </EuroSign>
                </EuroSignWrapper>
                <StyledInput
                    padding="12px 16px 12px 50px"
                    type='number'
                    placeholder='Please state the full price e.g. 250'
                    onChange={(e) => {
                        const inputValue = e.target.value;
                        const numericInput = inputValue.replace(/[^0-9]/g, '');
                        setInput(numericInput);
                    }}
                    value={input}
                />
            </InputWrapper>
        </>
    )
}
