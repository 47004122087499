import styled from "styled-components";
import { ParagraphS } from "../../../Components/Text";

const PdfWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.1);
`;

const FileIcon = styled.div`
  height: 2.8rem; 
  width: 2.4rem; 

  background: linear-gradient(-135deg, transparent 0.70708rem, rgb(226 232 240) 0);
  position: relative;

  &:after {
     content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1rem; /* 2.5rem / 2.5 */
    height: 1rem; 

    background: linear-gradient(to left bottom, transparent 50%, rgb(100 116 139) 0) no-repeat 100% 0;
    box-shadow: -0.16rem 0.16rem 0.16rem -0.08rem rgba(0 0 0 / 50);
  }
`;

const FileName = styled.p`
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 160px; 
  white-space: nowrap;
  font-family: 'Avenir LT Std';
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0%;
`

const PdfLink = styled.a`
  cursor: pointer;
  transition: text-decoration 0.3s;
  text-decoration: none;
  color: var(--p500);

  
  &:hover {
    text-decoration: underline;
  }
`;

const PdfFile = ({fileUrl}) => {

    const parts = fileUrl ? fileUrl.split("/") : [];
    const lastPart = parts[parts?.length - 1];

    return (
        <PdfWrapper >
            <PdfLink
                href={fileUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
                <div style={{ display: 'flex', gap: '8px' }}>
                    <FileIcon />
                    <div style={{ display: 'flex', flexDirection: 'column', width: '180px' }}>
                        <FileName>{lastPart}</FileName>
                        <ParagraphS>PDF</ParagraphS>
                    </div>
                </div>
            </PdfLink>
        </PdfWrapper>);
}

export default PdfFile;