import React from 'react'

const People = (props) => {
    return (
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="#EBEEEF"/>
<path d="M12.4766 14C13.8359 14 14.9141 12.9219 14.9141 11.5625C14.9141 10.2266 13.8125 9.125 12.4766 9.125C11.1406 9.125 10.0625 10.2266 10.0625 11.5625C10.0391 12.9219 11.1406 14 12.4766 14ZM12.4766 10.25C13.2031 10.25 13.7891 10.8594 13.7891 11.5625C13.7891 12.2891 13.2031 12.875 12.4766 12.875C11.7734 12.875 11.1641 12.2891 11.1641 11.5625C11.1641 10.8594 11.7734 10.25 12.4766 10.25ZM17 10.25C18.0312 10.25 18.875 9.42969 18.875 8.375C18.875 7.34375 18.0312 6.5 17 6.5C15.9453 6.5 15.125 7.34375 15.125 8.375C15.125 9.42969 15.9453 10.25 17 10.25ZM13.6484 14.75H11.3281C9.47656 14.75 8 16.1562 8 17.8906C8 18.2422 8.28125 18.5 8.65625 18.5H16.3203C16.6953 18.5 17 18.2422 17 17.8906C17 16.1562 15.5 14.75 13.6484 14.75ZM9.17188 17.375C9.40625 16.5312 10.2734 15.875 11.3047 15.875H13.6484C14.6797 15.875 15.5469 16.5312 15.8047 17.375H9.17188ZM17.9141 11H16.4844C16.1797 11 15.8984 11.0938 15.6406 11.2109C15.6406 11.3281 15.6641 11.4453 15.6641 11.5625C15.6641 12.3594 15.3828 13.0859 14.8906 13.625H19.5781C19.8125 13.625 20 13.4375 20 13.2031C20 11.9844 19.0625 11 17.9141 11ZM9.28906 11.5625C9.28906 11.4453 9.3125 11.3281 9.33594 11.2109C9.07812 11.0703 8.79688 11 8.49219 11H7.0625C5.91406 11 5 11.9844 5 13.2031C5 13.4375 5.16406 13.625 5.39844 13.625H10.0859C9.59375 13.0859 9.28906 12.3594 9.28906 11.5625ZM8 10.25C9.03125 10.25 9.875 9.42969 9.875 8.375C9.875 7.34375 9.03125 6.5 8 6.5C6.94531 6.5 6.125 7.34375 6.125 8.375C6.125 9.42969 6.94531 10.25 8 10.25Z" fill="#031B2A"/>
<rect x="0.5" y="0.5" width="24" height="24" rx="12" stroke="#D8DCDF"/>
</svg>

    );
  }
  

  export default People;