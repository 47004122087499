
import Chatscreen from "./Chatscreen";

const ChatPitch = () => {
    return (
        <Chatscreen
            actionLabel="Back to pitch"
            headerTitle="Secret Garden: Buttery Yellow Tullip variation"
        />
        );
}

export default ChatPitch;