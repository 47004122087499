import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { BsPencil } from "react-icons/bs";

import Modal from "../../app/modals/Modal";
import PitchOption from "../../Components/Common/Chat/PitchOption";
import apiClient from "../../services/api";
import useManageProjectModal from "../hooks/useManageProjectModal";

const Line = styled.div`
  border-bottom: 1px solid #e0e0e0;
`;

const ManageProjectModal = ({ projectId, title }) => {
  const manageProjectModal = useManageProjectModal();
  const navigate = useNavigate();

  const deleteProject = () => {
    try {
      apiClient
        .post(`campaign/${projectId}/delete`)
        .then((response) => {
          manageProjectModal.onClose();
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error deleting item: ", error);
        });
    } catch (error) {
      console.error("Error deleting item: ", error);
    }
  };

  const editProject = () => {
    manageProjectModal.onClose();
    navigate(`/project/${projectId}`);
  };

  const bodyContent = (
    <div>
      <PitchOption
        title="Edit this project"
        onClick={editProject}
        Icon={<BsPencil size={16} color={"#FFAC09"} />}
      />
      <Line />
      <PitchOption
        title="Remove this project"
        onClick={deleteProject}
        Icon={<IoMdClose size={16} color={"#FF4E5B"} />}
      />
    </div>
  );

  return (
    <Modal
      title={title}
      isOpen={manageProjectModal.isOpen}
      onClose={manageProjectModal.onClose}
      body={bodyContent}
      //overview
    />
  );
};

export default ManageProjectModal;
