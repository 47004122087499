import React from "react";

const SearchIcon = () => {
	return (
		<svg
			width="15"
			height="15"
			viewBox="0 0 15 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.875 6.4375C11.875 7.69531 11.4648 8.87109 10.7812 9.80078L14.2266 13.2734C14.582 13.6016 14.582 14.1758 14.2266 14.5039C13.8984 14.8594 13.3242 14.8594 12.9961 14.5039L9.52344 11.0312C8.59375 11.7422 7.41797 12.125 6.1875 12.125C3.04297 12.125 0.5 9.58203 0.5 6.4375C0.5 3.32031 3.04297 0.75 6.1875 0.75C9.30469 0.75 11.875 3.32031 11.875 6.4375ZM6.1875 10.375C8.34766 10.375 10.125 8.625 10.125 6.4375C10.125 4.27734 8.34766 2.5 6.1875 2.5C4 2.5 2.25 4.27734 2.25 6.4375C2.25 8.625 4 10.375 6.1875 10.375Z"
				fill="#727272"
			/>
		</svg>
	);
};

export default SearchIcon;
