import React from 'react'
import styled from 'styled-components';
import { CustomCheckbox } from '../../Forms';
import { ParagraphM, SubheadingXS } from '../../../Components/Text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 0px #E3E3E3;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid var(--g-s100);
  padding:  20px;
  margin: 24px 0;
  gap: 8px;
}`

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    
`

export default function ProposeChangesCheckbox({
    label,
    subheading,
    mandatory,
    checked,
    handleCheckboxChange,
    Icon,
    secondary
}) {

    return (
        <Wrapper>
            <CheckboxContainer>
                <CustomCheckbox
                    id={label}
                    checked={checked}
                    onChange={handleCheckboxChange}
                    onClick={handleCheckboxChange}
                    {...(mandatory ? { grey: true } : {})}
                    mt={"0"}
                   text={label}
                />
                {<div style={{marginLeft: '8px'}}>{Icon}</div> || null}
            </CheckboxContainer>
            {subheading && <ParagraphM ml={"35px"} mt="0px" color={"#727272"}>{subheading}</ParagraphM>}
        </Wrapper>
    )
}