import styled from "styled-components";

export const PhoneBox = styled.div`
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	box-sizing: border-box;
	padding: 12px 16px;
	height: 48px;
	position: relative;
	z-index: 3;
	margin-top: 8px;
`;
