import React, {useEffect} from 'react'
import apiClient from '../../services/api';

export default function FacebookConnect() {
    useEffect(() => {
        // Haal de volledige URL op na het decoderen
        const decodedURL = new URL(window.location.href);
    
        // Parse de URL om queryparameters te verkrijgen
        const urlParams = new URLSearchParams(decodedURL.search);
        // Haal de waarde van 'state' en 'code' op uit de queryparameters
        const state = urlParams.get('state');
        const code = urlParams.get('code');

        apiClient.get(`/social/connect/facebook?code=${code}&state=${state}`)
      }, []);
    

  return (
    <div>
      Facebook
    </div>
  )
}
