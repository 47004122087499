import React from "react";

const Eye = () => {
	return (
		<svg
			width="17"
			height="13"
			viewBox="0 0 17 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5 6.25C5 4.33594 6.55859 2.75 8.5 2.75C10.4141 2.75 12 4.33594 12 6.25C12 8.19141 10.4141 9.75 8.5 9.75C6.55859 9.75 5 8.19141 5 6.25ZM8.5 8.4375C9.70312 8.4375 10.6875 7.48047 10.6875 6.25C10.6875 5.04688 9.70312 4.0625 8.5 4.0625C8.47266 4.0625 8.44531 4.0625 8.41797 4.0625C8.47266 4.22656 8.5 4.36328 8.5 4.5C8.5 5.48438 7.70703 6.25 6.75 6.25C6.58594 6.25 6.44922 6.25 6.3125 6.19531C6.3125 6.22266 6.3125 6.25 6.3125 6.25C6.3125 7.48047 7.26953 8.4375 8.5 8.4375ZM3.22266 2.33984C4.50781 1.13672 6.28516 0.125 8.5 0.125C10.6875 0.125 12.4648 1.13672 13.75 2.33984C15.0352 3.51562 15.8828 4.9375 16.293 5.92188C16.375 6.14062 16.375 6.38672 16.293 6.60547C15.8828 7.5625 15.0352 8.98438 13.75 10.1875C12.4648 11.3906 10.6875 12.375 8.5 12.375C6.28516 12.375 4.50781 11.3906 3.22266 10.1875C1.9375 8.98438 1.08984 7.5625 0.679688 6.60547C0.597656 6.38672 0.597656 6.14062 0.679688 5.92188C1.08984 4.9375 1.9375 3.51562 3.22266 2.33984ZM8.5 1.4375C6.69531 1.4375 5.24609 2.25781 4.125 3.29688C3.05859 4.28125 2.34766 5.42969 1.96484 6.25C2.34766 7.07031 3.05859 8.24609 4.125 9.23047C5.24609 10.2695 6.69531 11.0625 8.5 11.0625C10.2773 11.0625 11.7266 10.2695 12.8477 9.23047C13.9141 8.24609 14.625 7.07031 15.0078 6.25C14.625 5.42969 13.9141 4.28125 12.8477 3.29688C11.7266 2.25781 10.2773 1.4375 8.5 1.4375Z"
				fill="#042234"
			/>
		</svg>
	);
};

export default Eye;
