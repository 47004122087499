import React from "react";
import styled from "styled-components";

import { SubheadingS } from "../Text";
import SearchIcon from "../../Icons/SearchIcon";
import { IoClose } from "react-icons/io5";

const StyledInputContainer = styled.div`
	width: 100%;
	height: 100%;
	background: var(--g-s50);
	display: contents;
	justify-content: center;

	@media only screen and (min-width: 1024px) {
		display: flex;
  }
`;

const LayoutContainer = styled.div`
	padding: 16px;
    background: var(--g-s50);
	max-width: var(--max-width);


	@media only screen and (min-width: 1024px) {
    	padding: 16px;
		width: 100%;
  }
`;
const StyledContent = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;

const StyledInput = styled.input`
	height: 48px;
	width: 100%;
	background-color: white;
	box-sizing: border-box;
	display: inline-block;
	border-radius: 4px;
	border: 1px solid #e3e3e3;
	padding: 14px 16px;
	font-family: "Avenir LT Std";
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0em;
	text-align: left;
	color: #2f2f2f;

	&::placeholder {
		transform: translatey(2px);
  }
`;
const IconBox = styled.div`
	display: inline-block;
	position: absolute;
	right: 16px;
	top: 46px;
`;

const CloseButton = styled.div`
  background: transparent;
  border: none; 
  cursor: pointer;

  &:hover {
    color: #f0f0f0;
  }
`

function SearchBar({ onChange, handleSubmit, onClear, value, subheading, placeholder, margin }) {
	return (
		<StyledInputContainer margin={margin}>
			<LayoutContainer>
				<StyledContent>
					{subheading && <SubheadingS mb={"8px"} mt={"0px"}>{subheading}</SubheadingS>}
					<form style={{ width: '100%' }} onSubmit={handleSubmit}>

						<StyledInput
							type="text"
							placeholder={placeholder ? placeholder : "Search"}
							onChange={onChange}
							value={value}
						/>
						{value.length > 0 ?
							<IconBox>
								<CloseButton onClick={onClear}>
									<IoClose color={'#727272'} />
								</CloseButton>
							</IconBox>
							:
							<IconBox>
								<SearchIcon />
							</IconBox>
						}
					</form>
				</StyledContent>
			</LayoutContainer>
		</StyledInputContainer>
	);
}

export default SearchBar;
