export const theme = {
	main: "#FF4E5B",
	white: "#fff",
	headings_black: "#1C1C1C",
	headings_gray: "#727272",
	brilliant_blue: "#042234",
	gs50: '#F1F1F1',
	gs500: "#4f4f4f",
	gs700: "#2f2f2f",
	bb200: "#A4AFB6",
	bb50: "#EBEEEF",
	bb100: '#D8DCDF',
	bb400: "#364E5D",
	bb500: "#042234",
	bb600: "#031B2A",
	p700: "#982e36",
	p100: "#ffe3e5",
	noborder: "none",
	grayborder: "#e3e3e",
};
