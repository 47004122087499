/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";
import React from "react";

const StyledBio = styled.textarea`
	width: 100%;
	min-height: 122px;
	border: 1px solid #e3e3e3;
	border-color: ${(p) =>
	p.validationError ? "rgba(255, 113, 124, 1)" : "#e3e3e3"};
	border-radius: 4px;
	margin-top: 8px;
	padding: 12px 16px;
	font-family: "Avenir LT Std";
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	box-sizing: border-box;
	color: #2f2f2f;
`;
const Bio = React.forwardRef(
	({ validationError, onChange, question, value}, bio) => {
		return (
			<StyledBio
				tabIndex={2}
				ref={bio}
				onChange={(e) => onChange(e,question)}
				validationError={validationError}
				placeholder={"Tell the world who you are, and what you can do"}
				value={value}
			></StyledBio>
		);
	}
);

export default Bio;
