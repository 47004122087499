import {useEffect, useLayoutEffect, useState} from "react";

export const useChatScroll = ({
    chatRef,
    bottomRef,
    shouldLoadMore,
    loadMore,
    loading,
    count,
  }) => {
    const [hasInitialized, setHasInitialized] = useState(false);
  
    /*
    useEffect(() => {
      const topDiv = chatRef?.current;
  
      const handleScroll = () => {
        const scrollTop = topDiv?.scrollTop;

        if (scrollTop === 0 && shouldLoadMore) {
          loadMore()
        }
      };
  
      topDiv?.addEventListener("scroll", handleScroll);
  
      return () => {
        topDiv?.removeEventListener("scroll", handleScroll);
      }
    }, [shouldLoadMore, loadMore, chatRef]);*/
  
    useEffect(() => {
      const topDiv = chatRef.current;

      const shouldAutoScroll = () => {
        if (!hasInitialized && topDiv) {
          setHasInitialized(true);
          return true;
        }     
  
        if (!topDiv ) {
          return false;
        }
  
       const distanceFromBottom = topDiv.scrollHeight - topDiv.scrollTop - topDiv.clientHeight;
       return  distanceFromBottom < 900;
  
      }
  
      if (shouldAutoScroll()) {
        setTimeout(() => {
          chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }, 500);       
      }

    }, [bottomRef, chatRef, count, hasInitialized, loading]);
  }