import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import InviteInfluencerBody from "../../Components/Creatives/InviteInfluencerBody";
import { fetchInfluencers } from "../../app/features/InfluencersSlice";
import { useScroll } from "../../Utils/hooks/useScroll";
import apiClient from "../../services/api";
import { HeadlineM } from "../../Components/Text";
import SearchBar from "../../Components/Common/Searchbar";
import Navbar from "../../Components/Common/Navbar/NavigationBar";
import PageHeader from "../../Components/Common/PageHeader";
import { Container } from "../../Components/Layout";

const PageContainer = styled.div`
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background: var(--b-b100);
    padding-bottom: 200px;
`

const InviteInfluencer = () => {
    const influencerMeta = useSelector((state) => state.influencers.meta);
    const [isLoading, setIsLoading] = useState(false);
    const [campaignData, setCampaignData] = useState(null);
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const searchTimer = useRef(null);
    const [page, setPage] = useState(1);
    const [filterBody, setFilterBody] = useState({
        name: ''
    });
    const routeParams = useParams();

    useEffect(()=>{
        if(routeParams?.id){
            const { id } = routeParams;

            apiClient.get(`campaign/${id}`).then((resp)=>{
                const { data } = resp.data;

                setCampaignData(data);
            })
        }
    },[routeParams])

    const fetchData = async (query) => {
        try {
            await dispatch(fetchInfluencers(query));
        } catch (error) {
            console.error("Error:", error);
        } 
    };

    const loadMoreInfluencers = () => {
        const nextPage = page + 1;
        const updatedFilterBody = { ...filterBody, page: nextPage };
        setPage(nextPage);
    
        if(influencerMeta.to !== null){
            fetchData(updatedFilterBody)
        }
      };


    useScroll({
        shouldLoadMore: true,
        loadMore: () => loadMoreInfluencers(),
      });

    useEffect(() => {
        const searchTerm = searchText.toLowerCase();
        clearTimeout(searchTimer.current);

        searchTimer.current = setTimeout(() => {

            if (searchTerm.length > 3) {
                setFilterBody({ name: searchTerm, campaign_id: campaignData.id});
            } else {
                setFilterBody({ name: '' , campaign_id: campaignData.id});
            }
        }, 500);

    }, [searchText]);


    useEffect(() => {
        setIsLoading(true)
        
        setPage(1)

        fetchData(filterBody).then(()=>{
            setIsLoading(false)
        })

    }, [filterBody]);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    if(!campaignData){
        return null;
    }

    return (
        <PageContainer>
            <div>
                <Navbar />
                <PageHeader>
                    <Container>
                        <div style={{ padding: '8px 0' }}>
                            <HeadlineM mt={'0px'} color={'#ffffff'}>
                                Creatives & Channels
                            </HeadlineM>
                        </div>
                    </Container>
                </PageHeader>

                <SearchBar
                    subheading={"Search creatives"}
                    onChange={handleSearch}
                    value={searchText}
                />
            </div>

            <InviteInfluencerBody campaignData={campaignData}/>

        </PageContainer>);
}

export default InviteInfluencer;