// formUtils.js
import { isEmptyObject, objectHasEmptyValue } from "../../Utils/utility-functions";

export const handleCustomSelectChange = (e, options, formCtx, clearError) => {
  const selectedVal = e.target.value;
  const value = { [options.name]: selectedVal };
  formCtx.createFormData(value);
  clearError(options.name);
};

export const handleMultiSelectDropdownChange = (questionName, selected, formCtx, clearError) => {
  const value = { [questionName]: selected };
  clearError(questionName);
  formCtx.createFormData(value);

};

export const handleInputChange = (e, question, formCtx, clearError) => {
  const event = e.target.value;
  const value = { [question.name]: event };
  if (value) {
    clearError(question.name);
    formCtx.createFormData(value);
  }
};

export const handleSubmit = (data, formCtx, setErrors) => {
  let updatedErrors = {};

  data.forEach((questionChunk) => {
    questionChunk.questions.forEach((question) => {
      if (
        question.mandatory &&
        (!formCtx.formData[question.name] ||
          isEmptyObject(formCtx.formData[question.name]) ||
          objectHasEmptyValue(formCtx.formData[question.name]) ||
          formCtx.formData[question.name] === "")
      ) {
        if (objectHasEmptyValue(formCtx.formData[question.name])) {
          updatedErrors = {
            ...updatedErrors,
            [question.name]: `The selected option for ${question.label} should have a value.`,
          };
        } else {
          updatedErrors = {
            ...updatedErrors,
            [question.name]: `${question.label} is required.`,
          };
        }
        return updatedErrors
      }
    });
  });

  if (Object.keys(updatedErrors).length === 0) {
    return false;
  } else {
    return updatedErrors;
  }
};
