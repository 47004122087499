/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledParagraphS = styled.p`
	margin-top: ${(p) => (p.mt ? p.mt : "8px")};
	color: ${(p) => p.color && p.color};
	// color: ${(p) => (p.colorSecondary ? p.theme.main : "var(--g-s500)")};
`;
const ParagraphS = ({ children, mt, onClick, color, colorSecondary, ...props }) => {
	return (
		<StyledParagraphS 
			mt={mt} 
			onClick={onClick} 
			className={props.className ? "paragraph_s " + props.className : "paragraph_s"}
			color={color}
			colorSecondary={colorSecondary}
		>
			{children}
		</StyledParagraphS>
	);
};

export default ParagraphS;
