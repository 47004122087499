import { SubheadingS, ParagraphM } from "../../Components/Text";
import useDeletePitchModal from "../hooks/useDeletePitch";
import Modal from "./Modal";



const DeletePitchModal = ({
    onDelete
}) => {

    const deletePitch = useDeletePitchModal();
    const bodyContent = (

        <div style={{padding: '0 16px', marginBottom: '36px'}}>
            <SubheadingS>Are you sure you want to close your pitch?</SubheadingS>
            <ParagraphM color="#727272">
                This action is irreversible and will permanently delete
                your pitch.
                Please consider this decision carefully.
                If you're certain about deleting your pitch,
                click the "Close this pitch" button below. If not, you can click "Cancel" to go back.
            </ParagraphM>
        </div>
    );

    return (
        <Modal
            title={`Close your pitch`}
            isOpen={deletePitch.isOpen}
            onClose={deletePitch.onClose}
            body={bodyContent}
            actionLabel='Cancel'
            onSubmit={deletePitch.onClose}
            secondaryAction={onDelete}
            secondaryActionLabel='Close this pitch'
            paddingBottom={120}
        />);
}

export default DeletePitchModal;