import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LiaMicrophoneAltSolid } from "react-icons/lia";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import apiClient from "../../services/api";
import NavigationBar from '../../Components/Common/Navbar/NavigationBar';
import Header from '../../Components/Common/Header';
import FilterDetailsPageBar from '../../Components/Filtering/FilterDetailsPageBar';
import IdealInfluencer from '../../Components/Common/IdealInfluencer';
import Brief from '../../Components/Common/Card/Campaigns/Brief'
import Details from '../../Components/Common/Details';
import Channels from '../../Components/Common/Channels/Channels';
import HoorayPopUp from '../../Components/Common/HoorayPopUp';
import { useSelector } from 'react-redux';
import AddChannelModal from '../../app/modals/AddChannelsModal';
import useAddChannelsModal from '../../app/hooks/useAddChannelsModal';
import { Button } from '../../Components/UI';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const PageWrapper = styled.div`
  background-color: var(--g-s50);
  min-height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
`;

const Bottom = styled.div`
  width: 100%;
  background-color: #364e5d;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  padding: 16px;
  box-sizing: border-box;
`;
const ButtonWrapper = styled.div`
  width: 100vw;
  max-width: var(--max-width);
`;

const DetailsCampaignPage = () => {
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState(null);
  const [filterBarState, setFilterBarState] = useState();
  const [showPopup, setShowPopup] = useState(false);

  const user = useSelector((state) => state.profile.profile);
  const addChannelsModal = useAddChannelsModal();

  const { id } = useParams();

  useEffect(() => {
    const baseURL = `campaign/${id}`;
    apiClient.get(baseURL).then((response) => {
      setCampaignData(response.data.data);
    });
  }, []);

  const handleCloseButtonClick = () => {
    setShowPopup(false);
  };

  if (!user) {
    return null;
  }

  const navigateToChat = () => {
    apiClient
      .post(`/campaign/${campaignData.id}/pitch/search`)
      .then((response) => {
        const pitchData = response.data.data[0];

        navigate(`/chat/campaign/${campaignData.id}/${pitchData.id}`);
      });
  };

  const pitchHandler = () => {
    if (campaignData.is_pitched) {
      navigateToChat();
    } else {
      if (user.data.hasChannels) {
        navigate(`/${campaignData.id}/new-pitch`);
      } else {
        addChannelsModal.onOpen();
      }
    }
  };

  return (
    <div>
      {showPopup && (
        <HoorayPopUp
          isVisible={showPopup}
          handleButtonClick={handleCloseButtonClick}
          campaignId={id}
          setCampaignData={setCampaignData}
          campaignData={campaignData}
        />
      )}
      {campaignData && (
        <div>
          <Container>
            <NavigationBar />
            <Header
              text={campaignData.title}
              backgroundImage={campaignData.image}
              buttonVisible
              // onBack={() => navigate(`/?searchText=${searchText || ''}`)}
              onBack={() => navigate("/")}
            />
          </Container>
          <Bottom>
            {user.data.usertype !== "company" &&
              <ButtonWrapper>
                <Button
                  iconFirst
                  Icon={<LiaMicrophoneAltSolid color="#fff" size={20} />}
                  primary
                  text={
                    campaignData.is_pitched
                      ? "Continue this pitch"
                      : "Pitch on this project"
                  }
                  textSize={"14px"}
                  width={"100%"}
                  padding={"10px"}
                  mt={"0px"}
                  onClick={pitchHandler}
                  disabled={
                    user.data.usertype === "influencer"
                      ? !campaignData.active
                        ? true
                        : false
                      : true
                  }
                />
              </ButtonWrapper>
              } 
          </Bottom>
          <FilterDetailsPageBar
            setFilterBarState={setFilterBarState}
            filterBarState={filterBarState}
          />
          <PageWrapper>
            {filterBarState && filterBarState === "idealInfluencers" && (
              <IdealInfluencer data={campaignData} />
            )}
            {filterBarState && filterBarState === "details" && (
              <Details campaignData={campaignData} />
            )}
            {filterBarState && filterBarState === "brief" && (
              <Brief campaignData={campaignData} />
            )}
            {filterBarState && filterBarState === "channelRequirements" && (
              <Channels campaignData={campaignData} />
            )}
          </PageWrapper>
        </div>
      )}
      <AddChannelModal />
    </div>
  );
};

export default DetailsCampaignPage;
