import React, { useEffect, useState } from "react";

import { PitchFlow } from "./PitchFlow";
import PitchPage from "../../../Pages/PitchPage/PitchPage";
import { useNavigate, useParams } from "react-router-dom";
import apiClient from "../../../services/api";

const pages = [
  {
    title: "Welcome to pitching",
    subtitle:
      "myThursd is the ultimate floriculture platform for businesses and creatives to connect, collaborate, and conquer the market together! Unlock opportunities as you effortlessly connect with diverse enterprises, exchange services, and join forces for powerful promotional projects.",
    paragraph: null,
  },
  {
    title: "Your pitch",
    subtitle:
      "You know your skills. And you know best what works for the goals you want to achieve. Describe your approach below.",
    paragraph: `Some advertisers/brands or creatives have already mapped out their project from start to finish, but most are open to new ideas! Don’t limit yourself and propose new things in your pitch if possible.
                    This field below is meant as a summary. It’s the first thing the advertiser sees. Make sure you create an attractive and compelling summary. You can add your detailed approach in a later step.`,
  },
  {
    title: "Channels I will post on",
    subtitle: `Please select the social media channels you'd like to include. If there are grey checkboxes,
            these channels are mandatory.`,
    paragraph: null,
  },
  {
    title: "Delivery",
    subtitle: "Information about the delivery deadline.",
    paragraph: null,
  },
  {
    title: "Pitching",
    subtitle:
      "When you pitch, you make an offer on the project. The advertiser views your pitch and your profile to determine whether your bid is appropriate. Both you and the advertiser will meet up in the chat privately to discuss further.",
    paragraph: null,
  },
];

export default function Pitch() {
  const params = useParams();
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState(null);
  const [pitchData, setPitchData] = useState({});
  const [showMandatoryError, setShowMandatoryError] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { campaignId } = params;

  useEffect(()=>{
    if(campaignId){
        apiClient.get(`campaign/${campaignId}`).then((resp) => {
      const newCampaignData = resp.data.data;
      setCampaignData(newCampaignData);
    });
    }
  },[campaignId])

  const handleStep = (stepData, isFinish) => {
    const newPitchData = {
      ...pitchData,
      ...stepData,
    };

    setPitchData(newPitchData);

    if (isFinish) {
      try {
        apiClient.post(`campaign/${campaignData.id}/pitch`, {
          approach: newPitchData[1],
          channels: newPitchData[2],
          note: newPitchData[3].note,
          accept_deadline: newPitchData[3].accept_deadline,
          bid: newPitchData[4].bid,
          bid_justify: newPitchData[4].bid_justify,
          is_pitched: true,
        });
      } catch (error) {
        console.error("Unexpected error:", error);
        console.error("Unexpected error:", error.message);
      } finally {
        navigate(`/hoorayscreen`, {
          state: { campaignDataId: campaignData.id, campaignData },
        });
      }
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const onNext = (stepData) => {
    handleStep(stepData, false);
  };

  const onPrevious = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const onFinish = (stepData) => {
    handleStep(stepData, true);
  };

  if(!campaignData) return null;


  return (
    <PitchFlow
      currentIndex={currentIndex}
      onNext={onNext}
      onPrevious={onPrevious}
      onFinish={onFinish}
    >
      {pages.map((page, index) => (
        <PitchPage
          key={index}
          page={page}
          totalPages={pages.length}
          currentIndex={index}
          showMandatoryError={showMandatoryError}
          campaignData={campaignData}
          pitchData={pitchData}
        />
      ))}
    </PitchFlow>
  );
}
