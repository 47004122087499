import React, { useEffect, useState } from "react";
import InfluencerCampaigns from "../../Components/Creatives/CampaignPage/InfluencerCampaigns"; 
import CompanyCampaigns from "../Home/Home"; 
import ErrorPage from "../Error/ErrorPage";
import { useSelector } from "react-redux";

const CampaignPage = ({}) => {
  const user = useSelector((state) => state.profile.profile);

  if (user) {
    if (user.data.usertype === "company") {
      return <CompanyCampaigns />;
    } else if (user.data.usertype === "influencer") {
      return <InfluencerCampaigns />;
      
    } else {
      return <ErrorPage/>; 
    }
  } 
};

export default CampaignPage;
