// Components/Common/CalendarModal.js

import React from "react";
import styled from "styled-components";
import CustomCalendar from "../../Components/Advertisers/Marketplace/Calendar";
import { Button } from "../../Components/UI";

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(105, 123, 134, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const CalendarModalWrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  width: 100%;

  @media (max-width: 768px) {
    margin: 20px;
  }
`;

const CalendarModalContent = styled.div`
  text-align: center;
`;

const CalendarModal = ({ selectedDate, onSelectDate, onClose }) => (
  <Background onClick={onClose}>
    <CalendarModalWrapper onClick={(e) => e.stopPropagation()}>
      <CalendarModalContent>
        <CustomCalendar
          selectedDate={selectedDate}
          onSelectDate={onSelectDate}
        />
        <Button textcolor={"042234"} onClick={onClose} text={"Confirm date"} />
      </CalendarModalContent>
    </CalendarModalWrapper>
  </Background>
);

export default CalendarModal;
