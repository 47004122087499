import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";

import SubheadingXXXS from "../../../Components/Text/SubheadingXXXS";
import PdfFile from "./pdfFile";
import Receipt from "./Receipt";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  gap: 8px;
  text-align: left;
`;

const AvatarWrapper = styled.div`
  transition: box-shadow 0.3s;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Timestamp = styled.span`
  font-size: 0.75rem;
  color: #555;
`;

const ImageLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  overflow: hidden;
  max-width: 150px;
  height: 100px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const Message = styled.p`
  color: var(--bb-500-secondary, #042234);
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 8px 0px;
`;

const MessageContainer = styled.div`
  max-width: 286px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const ChatItemReceiver = ({
  id,
  content,
  member,
  timestamp,
  fileUrl,
  currentMember,
  message,
  acceptBid,
  acceptAndClose,
}) => {
  const [isBid, setIsBid] = useState(false);

  const onMemberClick = () => {
    if (message?.user.id === currentMember.id) {
      return;
    }

    // Moet het nog doorlikken naar de user?
  };

  const fileType = message.file?.url?.split(".").pop();

  const isPDF = fileType === "pdf" && fileUrl;
  const isImage = !isPDF && fileUrl;
  //const dateObject = new Date(message.created);
  //const formattedTime = `${dateObject.getHours()}:${String(dateObject.getMinutes()).padStart(2, '0')}`;

  useEffect(() => {
    if (message && message?.bid !== null) {
      setIsBid(true);
    }
  }, [message]);

  return (
    <Wrapper>
      <AvatarWrapper onClick={onMemberClick}>
        <img src={message?.user?.image} alt={"name"} />
      </AvatarWrapper>
      <div>
        {isImage && (
          <ImageLink href={fileUrl} target="_blank" rel="noopener noreferrer">
            <Image src={fileUrl} alt={content} />
          </ImageLink>
        )}
        {isPDF && <PdfFile fileUrl={fileUrl} />}
        <MessageContainer>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <SubheadingXXXS mt={"0px"} color={"#000"} onClick={onMemberClick}>
              {member?.name}
            </SubheadingXXXS>
            <SubheadingXXXS mt={"0px"} color={"#555"} onClick={onMemberClick}>
              •
            </SubheadingXXXS>
            <Timestamp>{message.created}</Timestamp>
          </div>
          <Message>{message.content}</Message>
        </MessageContainer>
        {isBid && (
          <Receipt
            bid={message?.bid}
            accept
            acceptBid={() => acceptBid(message?.bid?.id)}
            acceptAndClose={() => acceptAndClose(message?.bid?.id)}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default ChatItemReceiver;
