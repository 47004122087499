
import React, { useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Biography from "../../Components/Common/ProfilePage/Biography";
import ProfileInformationItem from "../../Components/Common/ProfilePage/ProfileInformationItem";
import { HeadlineL, HeadlineM, ParagraphM, ParagraphS, SubheadingXS } from "../../Components/Text";
import NavigationBar from "../../Components/Common/Navbar/NavigationBar";
import ProfileInformationItemSmall from "../../Components/Common/ProfilePage/ProfileInformationItemSmall";
import Flag from "../../Icons/Flag";
import Suitcase from "../../Icons/Suitcase";
import Audience from "../../Icons/Audience";
import Avatar from "../../Components/Avatar";
import { EndIconButton } from "../../Components/UI";
import PenIcon from "../../Icons/PenIcon";
import { findNamesByKeys } from "./utils";
import FrontIconButton from "../../Components/UI/Buttons/FrontIconButton";
import SocialsModal from "../../app/modals/SocialsModal";
import apiClient from "../../services/api";
import ChannelSocials from "../../Components/Common/ProfilePage/ChannelSocials";
import Channel from "../../Components/Common/ProfilePage/Channel";
import useSocialsModal from "../../app/hooks/useSocialsModal";
import { profileSetup, setProfileData } from "../../app/features/profileSlice";
import DeleteSocialModal from "../../app/modals/DeleteSocialModal";
import useDeleteSocialModal from "../../app/hooks/useSocialsModal copy";
import CalenderIcon from "../../Icons/CalenderIcon";

const Container = styled.section`
    min-height: 100vh;
    
    background-color: rgba(54, 78, 93, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 100px 0;

    @media (max-width: 780px) {
        align-items: flex-start;
        background-color: var(--g-s50);
        padding: 0;
    }
    `;

const NavigationContainer = styled.div`

`

const ProfileContainer = styled.div`
    heigth: 640px;
    width: 980px;
    border-radius: 8px;
    
    @media (max-width: 780px) {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background-color: rgba(54, 78, 93, 1);
        border-radius: 0px;
        margin-top: 68px; // headerheight
    }
`
const EditContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 26px 24px;
    box-sizing: border-box;
    max-width: 1000px;
    height: 73px;
    background-color: var(--g-s50);
    border-radius: 8px;
    align-items: center;
    margin-bottom: 24px;
   

    @media (max-width: 780px) {
        flex-direction: column;
        gap: 16px;
        max-width: 100%;
        width: 100%;
        height: 120px;
        justify-content: center;
        padding: 16px 24px;
        margin-bottom: 0;
        border-radius: 0px;
        background-color: white;
        position: fixed;
        bottom: 0px;
         z-index: 4;
    }

`
const ProfileHeaderInformation = styled.div`
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: rgba(54, 78, 93, 1);
    padding-bottom: 40px;

    @media (max-width: 780px) {
        padding-bottom: 24px;
    }
`

const AboutSection = styled.div`
    width: 100%;
    min-height: 100%;
    background-color: var(--g-s50);


    @media (max-width: 780px) {
        border-radius: 0px;
    }

`

const TextContainer = styled.div`
    padding: 32px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 780px) {
        flex-direction: column;
    }
`

const SubContainer = styled.div`
    width: 50%;

    @media (max-width: 780px) {
        width: 100%;
    }
   
`
const SecondSubContainer = styled(SubContainer)`

@media (max-width: 780px) {
   margin-top: 24px;
}
`
const SubTextBlock = styled.div`
    max-width: 50ch;

    @media (max-width: 780px) {
        max-width: 100%;
    }
`

const SmallItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top:16px;
`


const ButtonContainer = styled.div`
    display: flex;
    gap: 8px;

     @media (max-width: 780px) {
        width: 90vw;
        padding:  0 20px;
    }
`
const ChannelsContainer = styled.div`
    background: var(--b-b500-secondary);
    padding: 24px 24px 60px 24px;
  
    @media (max-width: 780px) {
        padding: 24px 24px 180px 24px;

    }
`
const ProfilePageInfluencer = ({ user }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const socialModal = useSocialsModal();
    const deleteSocialModal = useDeleteSocialModal();
    const [userLanguages, setUserLanguages] = useState()
    const [audienceCountries, setAudienceCountries] = useState()
    const languagesData = useSelector((state) => state.languages?.languages);
    const countriesData = useSelector((state) => state.countries?.countries);
    const [channels, setChannels] = useState({})
    const [countryNames, setCountryNames] = useState([]); 

    const [socialToDelete, setSocialToDelete] = useState(null)

    useMemo(() => {
        if (user) {
            setUserLanguages(findNamesByKeys(user?.profile?.my_languages, languagesData))
            setAudienceCountries(findNamesByKeys(user?.profile?.audience_country, countriesData))
        }

    }, [languagesData, user])

    const logout = () => {
        localStorage.removeItem('token');
        setTimeout(() => {
            navigate('/');
            window.location.reload();
        }, 500);
    };

    const handleRemoveChannelClick = (channel, platform, index) => {

        if(index !== null){
            setSocialToDelete({
                index: index,
                platform: platform,
                channel: channel
            })

        }else{
            setSocialToDelete(null)

            const newChannels = { ...user.profile.channel };

            if (Array.isArray(user.profile.channel[platform])) {
                const filteredChannels = user.profile.channel[platform].filter(ch => ch !== channel);
                newChannels[platform] = filteredChannels;
    
            } else {
                delete newChannels[platform]
            }
            setChannels(newChannels)
        }

        deleteSocialModal.onOpen();
    };

    const onDelete = () => {
        dispatch(profileSetup({ data: { channel: channels } }));
        deleteSocialModal.onClose();
    }

    const onDeleteSocial = ( channel, platform) => {
        apiClient.get(`social/${platform}/delete/${channel.id}`).then((resp)=>{
            dispatch(setProfileData(resp.data))
        })
    }

    const deleteChannel = ()=>{
        if(socialToDelete === null){
            onDelete()
        }else{
            onDeleteSocial(socialToDelete.channel, socialToDelete.platform)
        }

        deleteSocialModal.onClose()
    }

    useEffect(() => {
        const audienceCountries = user?.profile?.audience_country || [];
        const countryNames = audienceCountries
          .filter(item => typeof item === 'object') 
          .map(item => item.name);
        setCountryNames(countryNames);
    }, []);
    
    return (
        <>
            <Container>
                <NavigationContainer>
                    <NavigationBar />
                </NavigationContainer>
                <ProfileContainer>
                    <EditContainer>
                        <ParagraphS>You are viewing your own profile</ParagraphS>
                        <ButtonContainer>
                            <EndIconButton
                                primary={true}
                                textcolor={true}
                                color={"var(--bb500)"}
                                text={"Edit profile"}
                                mt={"0px"}
                                onClick={() => navigate('/userinfo')}
                            >
                                <PenIcon />
                            </EndIconButton>
                            <EndIconButton
                                primary={true}
                                textcolor={true}
                                color={"var(--bb500)"}
                                text={"Log out"}
                                mt={"0px"}
                                onClick={logout}
                            >
                            </EndIconButton>
                        </ButtonContainer>
                    </EditContainer>
                    <ProfileHeaderInformation>
                        <div style={{ marginBottom: '16px', display: 'flex', gap: 16 }}>
                            <Avatar userImage={user?.avatar} />
                            <div style={{ flexWrap: 'nowrap', maxWidth: '50ch' }}>
                                <HeadlineL color={"white"}>{user?.firstname} {user?.lastname}</HeadlineL>

                            </div>
                        </div>
                        <ProfileInformationItem
                            Icon={<CalenderIcon />}
                            title={"Account created"}
                            answer={user?.registered}
                        />
                    </ProfileHeaderInformation>
                    <AboutSection>
                        <TextContainer>
                            <SubContainer>
                                <HeadlineM mt={'0px'}>About</HeadlineM>
                                <SubTextBlock>
                                    <SubheadingXS mt={'16px'}>Starting Reason</SubheadingXS>
                                    <ParagraphM>{user?.profile?.reason_start}</ParagraphM>
                                </SubTextBlock>

                                {user && <Biography text={user?.profile?.biography} />}
                                <SubTextBlock>
                                    <SubheadingXS mt={'16px'}>Company name</SubheadingXS>
                                    {user && <ParagraphM>{user?.profile?.company_name}</ParagraphM>}
                                </SubTextBlock>


                            </SubContainer>

                            <SecondSubContainer>
                                <HeadlineM mt={'0px'}>Occupation & Audience</HeadlineM>
                                <SmallItemsContainer>
                                    <ProfileInformationItemSmall
                                        Icon={<Suitcase />}
                                        title={"Occupation"}
                                        answers={user?.profile?.occupation}
                                    />
                                    <ProfileInformationItemSmall
                                        Icon={<Audience />}
                                        title={"Audience occupations"}
                                        answers={user?.profile?.audience}
                                    />
                                    <ProfileInformationItemSmall
                                        Icon={<Flag />}
                                        title={"Audience(s) in"}
                                        answers={countryNames.length > 0 ? countryNames : audienceCountries }
                                    />
                                </SmallItemsContainer>
                            </SecondSubContainer>

                        </TextContainer>
                    </AboutSection>
                    <ChannelsContainer>
                        <HeadlineM color="#fff">Channels</HeadlineM>
                        <div style={{ marginBottom: '24px' }}>
                            <FrontIconButton
                                onClick={socialModal.onOpen}
                                primary text="+ Add a channel"
                                width="auto"
                            />
                        </div>
                        {user && user.connected_socials && Object.keys(user.connected_socials).map((platform) => (
                            <div key={platform}>
                                {Array.isArray(user.connected_socials[platform]) && user.connected_socials[platform].length > 0 ? (
                                    user.connected_socials[platform].map((channel, index) => (
                                        <div style={{ marginTop: '16px' }}>
                                            <ChannelSocials
                                                key={index}
                                                user={channel.data}
                                                platform={platform}
                                                channel={user.connected_socials}
                                                removeSocialChannel={() => handleRemoveChannelClick(channel, platform, index)}
                                            />
                                        </div>
                                    ))) : null
                                }
                            </div>
                        ))}
                        <div>
                            {user && user.profile && user.profile.channel && Object.keys(user.profile.channel).map((platform) => (
                                <div key={platform}>
                                    {Array.isArray(user.profile.channel[platform]) && user.profile.channel[platform].length > 0  ? (
                                        user.profile.channel[platform].map((channel, index) => (
                                            <div style={{ marginTop: '16px' }}>
                                                <Channel
                                                    key={index}
                                                    channel={channel}
                                                    channels={user.profile.channel}
                                                    platform={platform}
                                                    removeChannel={() => handleRemoveChannelClick(channel, platform, null)}
                                                />
                                            </div>
                                        ))) : (
                                        <div style={{ marginTop: '16px' }}>
                                            <Channel
                                                channel={user.profile.channel[platform]}
                                                channels={user.profile.channel}
                                                platform={platform}
                                                removeChannel={() => handleRemoveChannelClick(user.profile.channel[platform], platform, null)}
                                            />
                                        </div>
                                    )
                                    }
                                </div>
                            ))}
                        </div>
                    </ChannelsContainer>
                </ProfileContainer>
                <SocialsModal />
                <DeleteSocialModal onDelete={deleteChannel} />
            </Container> 

        </>
    );
}

export default ProfilePageInfluencer;