/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ErrorMessage,
  HeadlineXL,
  ParagraphM,
  SubheadingS,
} from "../../../Components/Text";
import { LayoutCenterTop } from "../../../Components/Layout";
import CloseButton from "../../../Icons/CloseButton";
import { InputLabel } from "../../../Components/Forms";
import RichTextComponent from "./RichTextComponent";
import Visbility from "./Visibility";
import CalendarIconFilled from "../../../Icons/CalendarIconFilled";
import CustomCalendar from "./Calendar";
import { Button } from "../../../Components/UI";
import apiClient from "../../../services/api";
// dubbelchecken
import Loading from "../../Loading";
import { formatDate } from "../../../Utils/utility-functions";
import { FileDropZone } from "../../Common";

const HR = styled.div`
  border: 1px solid var(--g-s100);
  width: 100%;
  margin-top: 20px;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: right;
  padding: 16px;
`;

const InputContainer = styled.div`
  padding-top: 8px;
  position: relative;

  @media (max-width: 768px) {
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  cursor: ${(props) => (props.disabledWithColor ? "not-allowed" : "pointer")};
`;

const Icon = styled.div`
  position: absolute;
  left: 12px;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
`;

const StyledInput = styled.div`
  height: 50px;
  background-color: white;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  padding: ${(props) => props.padding || "12px 16px"};
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #727272;
  width: 100%;
`;

const IconWrapper = styled.div`
  background-color: ${(props) =>
    props.selectedVisibilityOption === "Default; Two weeks from now"
      ? "#D8DCDF"
      : "#FF4E5B"};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 36px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(105, 123, 134, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const CalendarModal = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  width: 100%;

  @media (max-width: 768px) {
    margin: 20px;
  }
`;

const CalendarModalContent = styled.div`
  text-align: center;
`;

const ContentContainer = styled.div`
  margin: 0 24px;

  @media (max-width: 768px) {
    margin: 0;
  }
`;
const IdeaOrEventScreen = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [postData, setPostData] = useState();
  const [requesting, setRequesting] = useState("");
  const [returning, setReturning] = useState("");
  const [details, setDetails] = useState("");
  const [selectedOption, setSelectedOption] = useState(
    "Default; Two weeks from now"
  );
  const [defaultUntil, setDefaultUntil] = useState(null);
  const [selectedVisibilityOption, setSelectedVisibilityOption] =
    useState(null);
  const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState({
    title: false,
    subtitle: false,
    requesting: false,
    details: false,
    returning: false,
  });

  useEffect(() => {
    handleOptionClick("Default; Two weeks from now");
    const today = new Date();
    const newDate = new Date(today);
    newDate.setDate(today.getDate() + 14);
    setDefaultUntil(newDate);

    setSelectedDate(newDate);
  }, []);

  const toggleCalendarModal = () => {
    if (selectedOption === "Default; Two weeks from now") {
      setIsCalendarOpen(false);
      setSelectedDate(defaultUntil);
    } else if (selectedOption === null) {
      setIsCalendarOpen(false);
    } else {
      setIsCalendarOpen(!isCalendarOpen);
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSelectedVisibilityOption(option);
    if (option === "Default; Two weeks from now") {
      setSelectedDate(defaultUntil);
    }
  };

  const postIdea = () => {
    setIsLoading(true);

    if (!title || !subtitle || !requesting || !returning || !details) {
      setErrorMessage((prevState) => ({
        ...prevState,
        title: title === "" ? true : false,
        subtitle: subtitle === "" ? true : false,
        requesting: requesting === "" ? true : false,
        details: details === "" ? true : false,
        returning: returning === "" ? true : false,
      }));

      setIsLoading(false);
      return;
    }

    const formattedDate = selectedDate ? formatDate(selectedDate) : null;
    const formatteDefaultUntil = defaultUntil ? formatDate(defaultUntil) : null;
    const untilDate =
      selectedOption === "Default; Two weeks from now"
        ? formatteDefaultUntil
        : formattedDate;
    let formData = new FormData();
    if (image) {
      formData.set("image", image);
    }

    formData.append("title", title);
    formData.append("subtitle", subtitle);
    formData.append("requesting", requesting);
    formData.append("details", details);
    formData.append("until", untilDate);
    formData.append("returning", returning);
    apiClient
      .post("marketplace/create", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setPostData(response);
        navigate("/meetupspot");
        setIsLoading(false);
      });
  };

  return (
    <LayoutCenterTop>
      {isLoading ? (
        <Loading height={"100%"} />
      ) : (
        <>
          <TopContainer>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/meetupspot")}
            >
              <CloseButton />
            </div>
          </TopContainer>
          <HeadlineXL mt={"8px"}>
            Create a project, event, or idea, and submit it to the meetup
          </HeadlineXL>
          <ParagraphM mr={"24px"} color={"#4F4F4F"}>
            You as a creative can submit your project, event, or any other idea
            freely for advertisers/brands or other creatives to see. What cool
            stuff do you seek collaboration on?
          </ParagraphM>
          <HR />
          <ContentContainer>
            <SubheadingS mt={"16px"}>Upload a file to this project</SubheadingS>
            <FileDropZone setImage={setImage} image={image}/>
            <SubheadingS>Title</SubheadingS>
            <ParagraphM mt={"4px"} color={"#727272"}>
              Short title that summarizes your idea or event
            </ParagraphM>
            <InputLabel
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {errorMessage?.title && (
              <ErrorMessage message="Fill in the title" />
            )}
            <SubheadingS>Sub-title</SubheadingS>
            <ParagraphM mt={"4px"} color={"#727272"}>
              Optional sub-title for additional information
            </ParagraphM>
            <InputLabel
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
            />
            {errorMessage?.subtitle && (
              <ErrorMessage message="Fill in the subtitle" />
            )}
            <RichTextComponent
              title={"What do you want from the brands/advertisers?"}
              details={
                "E.g. I would like to receive products to use at the event I’m working for."
              }
              editorContent={requesting}
              setEditorContent={setRequesting}
            />
            {errorMessage?.requesting && (
              <ErrorMessage message="Fill in what you want from the brands/advertisers" />
            )}
            <RichTextComponent
              editorContent={returning}
              setEditorContent={setReturning}
              title={
                "What will you give in exchange to the brands/advertisers?"
              }
              details={
                "E.g. professional photographs, exposure on my instagram channel with 24k followers."
              }
            />
            {errorMessage?.returning && (
              <ErrorMessage message="Fill in what you give in exchange" />
            )}
            <RichTextComponent
              editorContent={details}
              setEditorContent={setDetails}
              title={"Details"}
              details={
                "Write a detailed description of the idea you have or the event that is coming up. Brands need to be triggered to start a conversation with you over this idea or event!"
              }
            />
            {errorMessage?.details && (
              <ErrorMessage message="Fill in the details" />
            )}
            <Visbility
              defaultOption="Default; Two weeks from now"
              onOptionClick={handleOptionClick}
              selectedOption={selectedOption}
              title={"Visible until?"}
              details={
                "By default the idea will be visible for two weeks in the marketplace. You can change that date below."
              }
            />

            <SubheadingS mt={"8px"}>The idea is visible until..</SubheadingS>
            <InputContainer>
              <div onClick={toggleCalendarModal}>
                <InputWrapper>
                  <IconWrapper
                    selectedVisibilityOption={selectedVisibilityOption}
                  >
                    <Icon>
                      <CalendarIconFilled />
                    </Icon>
                  </IconWrapper>
                  <StyledInput>
                    <ParagraphM mt={"0px"} ml={"32px"} color={"black"}>
                      {selectedDate?.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                    </ParagraphM>
                  </StyledInput>
                </InputWrapper>
              </div>
            </InputContainer>

            {isCalendarOpen && (
              <Background>
                <CalendarModal>
                  <CalendarModalContent>
                    <CustomCalendar onSelectDate={handleDateSelect} />
                    <Button
                      textcolor={"042234"}
                      onClick={toggleCalendarModal}
                      text={"Confirm date"}
                    />
                  </CalendarModalContent>
                </CalendarModal>
              </Background>
            )}
          </ContentContainer>
          {Object.values(errorMessage).includes(true) && (
            <ErrorMessage message={"Fill in all fields before updating."} />
          )}

          <Button
            text={"Save idea/event"}
            fwidth
            primary
            onClick={postIdea}
            mt={"8px"}
            mb={"24px"}
          />
        </>
      )}
    </LayoutCenterTop>
  );
};
export default IdeaOrEventScreen;
