import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ParagraphM, HeadlineXL } from '../../Components/Text'
import MultiInput from '../../Components/Common/MultiInput'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../Components/UI'
import { LayoutCenterTop } from '../../Components/Layout'
import ArrowButton from '../../Icons/ArrowButton'
import { profileSetup } from '../../app/features/profileSlice'

const ArrowButtonWrapper = styled.div`
  cursor: pointer; /* Voeg een cursor toe om aan te geven dat het klikbaar is */
`;
export default function AddInstagramPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.profile.profile);
    const [inputList, setInputList] = useState([]);
    const [parentInput, setParentInput] = useState('');

    //set default values
    useEffect(() => {
        if (user) {
            setInputList(user.data.profile.channel["Instagram"] || [])
        }
    }, [user])


    const handleBackButton = () => {
        window.history.back();
    };

    const onSubmit = async () => {
        const newChannels = { ...user.data.profile.channel}
        newChannels['Instagram'] = inputList

        if(parentInput.length > 0 ) {
            newChannels['Instagram'].push(parentInput)
        }
        
        dispatch(profileSetup({data: {channel: newChannels}})).then((resp)=>{
            navigate('/me')
        })

      }
    return (
        <LayoutCenterTop>
            <ArrowButtonWrapper onClick={handleBackButton}>
                <ArrowButton />
            </ArrowButtonWrapper>
            <div style={{ padding: '24px', }}>
                <HeadlineXL mt={"16px"}>Add your Instagram</HeadlineXL>
                <div style={{ marginTop: '16px' }}>
                    <ParagraphM color={'#4F4F4F'}>
                    `Welcome! To add your Instagram, simply enter your Instagram username. You don't need to include the full link to your Instagram profile. 
                    Just enter your Instagram name, for example, if your Instagram handle is @example_user, simply type example_user.`
                    </ParagraphM>
                </div>
                <div style={{ marginTop: '16px' }}>
                    {user &&
                        <MultiInput
                            title="Websites"
                            inputList={inputList}
                            setInputList={setInputList}
                            parentInput={parentInput}
                            setParentInput={setParentInput}
                        />}
                </div>
                <div style={{ marginTop: '32px' }}>
                    <Button
                        fwidth
                        primary
                        onClick={onSubmit}
                        text={"Submit"}
                        mt={"16px"}
                    />
                </div>
            </div>
        </LayoutCenterTop>
    )
}
