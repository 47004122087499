
import React, { useState } from "react";
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { FaSliders } from "react-icons/fa6";

import { Container } from "../../Layout";
import useFilterMenu from "../../../app/hooks/useFilterMenu";
import { Button } from "../../UI";

const ButtonWrapper = styled.section`
  background: white;
  overflow-x: auto;
`

const ButtonContainer = styled.div`
display: flex;

`;

const FilterButtonsContainer = styled.div`
margin-left: 16px;
display: flex;
gap: 8px; 
justify-content: flex-start;
white-space: nowrap;
flex: 1;
padding: 16px 0;
`

const StyledButton = styled.button`
  padding: 10px 14px;
  background-color: ${props => props.active ? 'var(--p500)' : '#ffffff'};
  color: ${props => props.active ? 'white' : '#042234'};
  font-weight: 700;
  border-radius: 50px;
  border: ${props => props.active ? '2px solid var(--b-b500)' : '2px solid var(--g-s100)'};
  cursor: pointer;
  font-family: "Avenir LT Std";
  font-size: 14px;
`;


const FilterMenuButtonWrapper = styled.div`
  display: flex;
  padding: 20px;

  @media (max-width: 1200px) {
    display: none;
  }
`
const HomeFilter = ({
  setPage,
  isLoading,
  setIsLoading
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filterMenu = useFilterMenu();
  const [selectedFilterValue, setSelectedFilterValue] = useState(searchParams.get("tab") || "All");
  const user = useSelector((state) => state.profile.profile);

  if (!user) {
    return null
  }

  const switchToComponent = (filterValue) => {
    setSelectedFilterValue(filterValue);

    switch (filterValue) {
      case 'Projects by creatives':
        searchParams.set('status', 'active')
        searchParams.set('model', 'marketplace')
        searchParams.set('tab', 'Projects by creatives')
        setSearchParams(searchParams)
        break;

      case 'Projects by advertisers':
        searchParams.set('status', 'active')
        searchParams.set('model', 'campaign')
        searchParams.set('tab', 'Projects by advertisers')
        setSearchParams(searchParams)
        break;

      case 'Pitched':
        searchParams.delete('model');
        searchParams.set('status', 'pitched')
        searchParams.set('tab', 'Pitched')
        setSearchParams(searchParams)
        break;

      case 'Finished':
        searchParams.delete('model');
        searchParams.set('status', 'finished')
        searchParams.set('tab', 'Finished')
        setSearchParams(searchParams)
        break;

      case 'Cancelled':
        searchParams.delete('model');
        searchParams.set('status', 'cancelled')
        searchParams.set('tab', 'Cancelled')
        setSearchParams(searchParams)
        break;

      case 'My projects':
        searchParams.delete('model');
        searchParams.set('status', 'mine')
        searchParams.set('tab', 'My projects')
        setSearchParams(searchParams)
        break;

      default:
        searchParams.delete('model');
        searchParams.delete('status');
        searchParams.delete('tab')
        setSearchParams(searchParams);
        break;
    }
  };

  return (
    <ButtonWrapper>
      <Container>
        <ButtonContainer>
          <FilterButtonsContainer>
            <StyledButton
              active={selectedFilterValue === 'All'}
              onClick={() => switchToComponent('All')}
              disabled={isLoading}
            >
              All
            </StyledButton>
            {/* {user && user.data.usertype !== 'company' && */}
              <StyledButton
                active={selectedFilterValue === 'Projects by advertisers'}
                onClick={() => switchToComponent('Projects by advertisers')}
                disabled={isLoading}
              >
                Projects by advertisers
              </StyledButton>
              {/* } */}
            <StyledButton
              active={selectedFilterValue === 'Projects by creatives'}
              onClick={() => switchToComponent('Projects by creatives')}
              disabled={isLoading}
            >
              Projects by creatives
            </StyledButton>
            <StyledButton
              active={selectedFilterValue === 'Pitched'}
              onClick={() => switchToComponent('Pitched')}
              disabled={isLoading}
            >
              Pitched
            </StyledButton>
            <StyledButton
              active={selectedFilterValue === 'Finished'}
              onClick={() => switchToComponent('Finished')}
              disabled={isLoading}
            >
              Finished
            </StyledButton>
            {/* <StyledButton
              active={selectedFilterValue === 'Cancelled'}
              onClick={() => switchToComponent('Cancelled')}
              disabled={isLoading}
            >
              Cancelled
            </StyledButton> */}
            <StyledButton
              active={selectedFilterValue === 'My projects'}
              onClick={() => switchToComponent('My projects')}
              disabled={isLoading}
            >
              My projects
            </StyledButton>
          </FilterButtonsContainer>
          {selectedFilterValue &&
            selectedFilterValue === "Projects by advertisers" &&
            <FilterMenuButtonWrapper>
              <Button
                primary
                padding={"6px 10px"}
                text="Filter"
                textSize={"12px"}
                width={"150px"}
                mt={"0px"}
                onClick={filterMenu.isOpen ?
                  () => filterMenu.onClose() :
                  () => filterMenu.onOpen()
                }
                Icon={<FaSliders />}
              />
            </FilterMenuButtonWrapper>}
        </ButtonContainer>
      </Container>
    </ButtonWrapper>

  );
};

export default HomeFilter;
