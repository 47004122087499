import React, { createContext, useContext, useRef, useEffect, useMemo } from 'react';
import Pusher from "pusher-js";

const PusherContext = createContext();

const PusherProvider = ({ children }) => {
  const pusherRef = useRef(null);

  useEffect(() => {
    pusherRef.current = new Pusher('c9ad1c824158385fb9ab', {
      cluster: 'eu',
    });

    return () => {
      pusherRef.current.disconnect(); 
    };
  }, []);

  return (
    <PusherContext.Provider value={pusherRef}>
      {children}
    </PusherContext.Provider>
  );
};

const usePusher = () => {
  const pusherRef = useContext(PusherContext);

  return pusherRef.current;
};

export { PusherProvider, usePusher };