import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from '../../services/api';

const initialState = {
  companyCampaigns: {
    data: [],
  },
};

export const fetchCampaigns = createAsyncThunk('campaigns', async ({ page }) => {
  const response = await apiClient.get('campaigns', {
    params: {
      page    
    },
  });
  return response.data.data;
});

const companyCampaignsSlice = createSlice({
  name: 'companyCampaigns',
  initialState,
  reducers: {
    updateCompanyCampaigns: (state, action) => {
      state.companyCampaigns = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCampaigns.fulfilled, (state, action) => {
      state.companyCampaigns = action.payload;
    });
  },
});

export const { updateCompanyCampaigns } = companyCampaignsSlice.actions;

export default companyCampaignsSlice.reducer;
