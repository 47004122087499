import React from 'react'

const Pinterest = (props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        className='pinterest'
        {...props}
      >
        <path
          fill={props.fill}
          d="M15.75 8C15.75 12.2812 12.2812 15.75 8 15.75C7.1875 15.75 6.40625 15.6562 5.6875 15.4062C6 14.9062 6.46875 14.0625 6.65625 13.375C6.75 13.0312 7.125 11.5312 7.125 11.5312C7.375 12.0312 8.125 12.4375 8.90625 12.4375C11.25 12.4375 12.9375 10.2812 12.9375 7.625C12.9375 5.0625 10.8438 3.125 8.15625 3.125C4.8125 3.125 3.03125 5.375 3.03125 7.8125C3.03125 8.96875 3.625 10.375 4.59375 10.8438C4.75 10.9062 4.84375 10.875 4.875 10.7188C4.875 10.625 5.03125 10.0938 5.09375 9.84375C5.09375 9.78125 5.09375 9.6875 5.03125 9.625C4.71875 9.25 4.46875 8.53125 4.46875 7.84375C4.46875 6.15625 5.75 4.5 7.96875 4.5C9.84375 4.5 11.1875 5.78125 11.1875 7.65625C11.1875 9.75 10.125 11.1875 8.75 11.1875C8 11.1875 7.4375 10.5625 7.59375 9.8125C7.8125 8.875 8.25 7.875 8.25 7.21875C8.25 6.625 7.9375 6.125 7.28125 6.125C6.5 6.125 5.875 6.9375 5.875 8C5.875 8.6875 6.09375 9.15625 6.09375 9.15625C6.09375 9.15625 5.34375 12.4062 5.1875 13C5.03125 13.6875 5.09375 14.625 5.15625 15.2188C2.28125 14.0938 0.25 11.3125 0.25 8C0.25 3.71875 3.71875 0.25 8 0.25C12.2812 0.25 15.75 3.71875 15.75 8Z"
        ></path>
      </svg>
    );
  }
  
  export default Pinterest;