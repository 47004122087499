import React from 'react'
import styled from 'styled-components'
import { PiFlag } from "react-icons/pi";
import { PiSuitcase } from "react-icons/pi";

import ProfileInformationItem from '../../Common/ProfilePage/ProfileInformationItem';
import Audience from '../../../Icons/Audience';

const SmallItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top:16px;
    flex: 1;
`
export default function SummaryInfluencerCard({ influencer }) {
   
    return (
        <SmallItemsContainer>
            <ProfileInformationItem
                Icon={<PiSuitcase />}
                title={"Occupation"}
                answers={influencer?.occupation}
            />
            <ProfileInformationItem
                Icon={<Audience />}
                title={"Audience occupations"}
                answers={influencer?.audience_occupation}
            />
            <ProfileInformationItem
                Icon={<PiFlag />}
                title={"Audience(s) in"}
                    answers={influencer?.audience_country} />
        </SmallItemsContainer>
    )
}
