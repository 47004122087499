import React from 'react'

export const Stepper = ({children, currentIndex, onNext, onPrevious, onFinish}) => {
	
    const goToNext = () => {
        const nextData = currentIndex + 1
    
        if(nextData < children.length){
            onNext()
        }else{
            onFinish()
        }
    }

    const goToPrevious = () => {
        onPrevious(); 
    }

	const currentChild = React.Children.toArray(children)[currentIndex];

    if(React.isValidElement(currentChild)){
        return React.cloneElement(currentChild, {goToNext, goToPrevious})
    }

    return currentChild;

}
