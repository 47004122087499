/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";
import React from "react";
/* -------------------------- Internal Dependencies ------------------------- */
import { SubheadingS, ParagraphM } from "../Text";
import { theme } from "../../Theme/theme";
import CalendarIconFilled from "../../Icons/CalendarIconFilled";
const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(p) => (p.fwidth ? p.fwidth : "100%")};
  position: relative;
`;

const StyledInput = styled.input`
  height: 48px;
  background-color: white;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  border-color: ${(p) =>
    p.validationError ? "rgba(255, 113, 124, 1)" : "#e3e3e3"};
  padding: 12px 16px;
  padding-left: ${(p) => (p.isDateInput ? "46px" : "16px")};
  margin-left: ${(p) => (p.ml8 ? "8px" : "0px")};
  margin-top: 8px;
  margin-right: 4px;
  width: 100%;
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2f2f2f;

  &:focus {
    border: 2px solid ${theme.main};
  }

  ::placeholder {
    font-family: "Avenir LT Std";
    font-size: 14px;
    color: #a3a3a3;
  }
`;

const IconWrapper = styled.div`
  background-color:#FF4E5B;
  position: absolute;
  top: 8px;
  left: 0;
  height: 48px;
  width: 36px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const Icon = styled.div`
  position: absolute;
  left: 12px;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
`;
const InputLabel = React.forwardRef((props, ref) => {
  const {
    onChange,
    label,
    subheading,
    placeholder,
    value,
    fwidth,
    ml8,
    mt16,
    error,
    email,
    notRegister,
    validationError,
    onBlur,
    errorMessage,
    defaultValue,
    inputStyles,
    name,
	isDateInput,
    ...rest
  } = props;

  return (
    <StyledInputContainer mt16={mt16}>
      {label && <SubheadingS>{label}</SubheadingS>}
      {subheading && <ParagraphM colorSecondary>{subheading}</ParagraphM>}
      <StyledInput
        style={inputStyles}
        fwidth={fwidth}
        ml8={ml8}
        value={value}
        notRegister={notRegister}
        placeholder={placeholder}
        validationError={validationError}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        email={email}
        ref={ref}
        name={name}
		isDateInput={isDateInput}
        {...rest}
      />
      {isDateInput && <IconWrapper mt16={mt16}>
        <Icon>
          <CalendarIconFilled />
        </Icon>
      </IconWrapper>}
      {error && errorMessage && (
        <span
          style={{
            display: "block",
            color: "#982336",
            fontFamily: "Avenir LT Std",
            fontSize: "10px",
            lineHeight: "14px",
            marginLeft: "2px",
          }}
        >
          {errorMessage}
        </span>
      )}
    </StyledInputContainer>
  );
});

export default InputLabel;
