/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from "react";
import styled from "styled-components";
import PasswordStrengthBar from "react-password-strength-bar";

/* -------------------------- Internal Dependencies ------------------------- */
import { SubheadingS } from "../../Text";
import { FlexContainer } from "../../Layout";

/* -------------------------- Internal SVG Dependencies ------------------------- */
import Eye from "../../../Icons/Eye";

const StyledPasswordContainer = styled.div`
	margin-top: ${(p) => (p.mt16 ? "16px" : "0px")};
`;
const StyledPasswordInput = styled.input.attrs({})`
	height: 48px;
	background-color: white;
	box-sizing: border-box;
	display: inline-block;
	border-radius: 4px;
	border: 1px solid #e3e3e3;
	padding: 12px 16px;
	margin-left: ${(p) => (p.ml8 ? "8px" : "0px")};
	margin-right: 4px;
	width: 100%;
	font-family: "Avenir LT Std";
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: #2f2f2f;

	&:hover {
		color: #727272;
	}
`;

const StyledIconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 46px;
	height: 48px;
	padding: 10px 14px;
	border: 1px solid #e3e3e3;
	filter: drop-shadow(0px 1px 0px #e3e3e3);
	border-radius: 4px;
	box-sizing: border-box;
	cursor: pointer;
`;
export const StyledPasswordStrengthBarContainer = styled.div`
	margin-top: 16px;
`;

const PasswordBox = ({
	onChange,
	password,
	placeholder,
	label,
	mt16,
	type,
	passwordstrengthbar,
	error,
	name,
	showConfirmedPassword,
	setShowConfirmedPassword,
}) => {
	const [showPassword, setShowPassword] = useState(false);
	return (
		<StyledPasswordContainer mt16={mt16}>
			<SubheadingS>{label}</SubheadingS>
			<FlexContainer alignItems="center" mt="8px">
				<StyledPasswordInput
					value={password}
					onChange={onChange}
					// minLength={8}
					placeholder={placeholder}
					type={showPassword ? "text" : "password"}
					name={name}
				/>
				<StyledIconContainer onClick={() => setShowPassword(!showPassword)}>
					<Eye />
				</StyledIconContainer>
			</FlexContainer>
			{error && (
				<span
					style={{
						display: "block",
						color: "#982336",
						fontFamily: "Avenir LT Std",
						fontSize: "10px",
						lineHeight: "14px",
						marginLeft: "2px",
					}}
				>
					Password must be at least 8 characters long
				</span>
			)}
			{passwordstrengthbar && (
				<StyledPasswordStrengthBarContainer>
					<PasswordStrengthBar
						shortScoreWord={
							<span
								style={{
									fontSize: "12px",
									fontWeight: 700,
									lineHeight: "18px",
									fontFamily: "Avenir LT Std",
									color: "#727272",
								}}
							>
								password strength
							</span>
						}
						scoreWords={["Very weak", "weak", "okay", "good", "strong"]}
						password={password}
					/>
				</StyledPasswordStrengthBarContainer>
			)}
		</StyledPasswordContainer>
	);
};

export default PasswordBox;
