import React, {useEffect, useState} from 'react'
import { CustomCheckbox, InputLabel } from '../../Forms';

function CheckboxWithInput({ title, onChange, placeholder }) {
    const [checked, setChecked] = useState()
    const [text, setText] = useState('')


    const onClickParentCheckBox = ()=>{
        setChecked(!checked)
    }

    const onInputChange = (e) =>{
        setText(e.target.value,)
        onChange(title, e.target.value, 'input')
    }
    
    useEffect(() => {
        if(checked){
            onChange(title, null, 'input')
        } else {
            setText('')
            onChange(title, null, 'input', true)
        }
    }, [checked])

    return (
        <div>
            <CustomCheckbox
                id={title}
                tabIndex={-1}
                text={title}
                checked={checked}
                onChange={onClickParentCheckBox}
                onClick={onClickParentCheckBox}
            />
         {checked && <InputLabel 
                placeholder={placeholder}
                value={text}
                onChange={(e) => onInputChange(e)}
                />}
            
        </div>
    )
}
export default CheckboxWithInput;
