import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { ParagraphS, SubheadingXXS } from "../../../Components/Text";
import ParagraphXS from "../../../Components/Text/ParagraphXS";
import { truncateText } from "../../../Utils/utility-functions";

const Container = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  background: white;
  border: none;
  cursor: pointer;
  border-top: 1px solid var(--b-b50);
  text-align: left;
  
  &:active {
    background-color: var(--g-s50);
}
`;

const ImageContainer = styled.div`
  border-radius: 100%;
  background: var(--g-s50); 
  height: 60px;
  width: 60px;
  margin-right: 16px; 
  overflow: hidden;
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
`
const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0;
`;

const UnreadBalloon = styled.div`
  width: 24px;
  height: 24px;
  background: var(--p500);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`
const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ChatCard = ({ chatMessage }) => {
  const navigate = useNavigate();

  const handleChatCardClick = () => {
    const { resource, id } = chatMessage.conversation;
    const marketplaceId = resource.id;
    navigate(`/chat/marketplace/${marketplaceId}?conversationId=${id}`);
  }


  return (
    <Container onClick={handleChatCardClick}>
      <ImageContainer>
        <Avatar src={chatMessage?.conversation?.user?.image} />
      </ImageContainer>
      <TextContainer>
        <Flex>
          <SubheadingXXS mt={"0px"} color={"042234"}>{chatMessage?.conversation?.user?.name}</SubheadingXXS>
          <ParagraphXS mt={"0px"} fontWeight={'bold'} color={chatMessage?.read ? "#FF4E5B" : "#A4AFB6"}>
            {chatMessage?.created}
          </ParagraphXS>
        </Flex>
        <Flex>
          {chatMessage?.content &&
            <ParagraphS mt={"8px"} color={chatMessage?.read ? "#A4AFB6" : "#042234"}>
              {truncateText(chatMessage?.content, 40)}
            </ParagraphS>}
          {chatMessage &&
            chatMessage?.message_count &&
            chatMessage?.message_count > 0 ?
            (
              <UnreadBalloon>
                <ParagraphXS
                  mt={"4px"}
                  fontWeight={'bold'}
                  color={'white'}>
                  {chatMessage?.message_count}
                </ParagraphXS>
              </UnreadBalloon>
            ) : null
          }
        </Flex>
      </TextContainer>
    </Container>
  );
};

export default ChatCard;
