import React from 'react'
import styled from 'styled-components'
import { HeadingXL, HeadlineL, ParagraphM } from '../../Components/Text'
import { Button } from '../../Components/UI'
import Error from '../../Icons/Error'

const Section = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ErrorWrapper = styled.div`
    width: 90%;
    max-width: 1200px;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #EBEEEF;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        gap: 40px;
      }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 50ch;
    margin-left: 86px;

    @media (max-width: 768px) {
        order:2;
        margin-left: 0px;
      }
`
const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
      
    @media (max-width: 768px) {
        order:1;
    }
`


const ErrorPage = () => {

    return (
        <Section>
            <ErrorWrapper>
                <Content>
                    <HeadingXL>Oops...</HeadingXL>
                    <HeadlineL mt={"16px"}>Page Not Found</HeadlineL>
                    <ParagraphM>Sorry, the content you are looking for doesn't exist. Either it was removed, or you mistyped the link</ParagraphM>
                    <Button primary text={"Go back"} mt={"80px"}  onClick={() => window.location.href = '/'}/>
                </Content>
                <ImageContainer>
                    <Error />
                </ImageContainer>
            </ErrorWrapper>
        </Section>
     
    )
}

export default ErrorPage
