import { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { AiOutlineMenu } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { FaRegBell } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import MenuItem from "./MenuItem";
import { HeadlineM, SubheadingXXS } from "../../Text";
import Rocket from "../../../Icons/Rocket";
import Profile from '../../../Icons/Profile';
import LogoThursdInfluence from '../../../Images/LogoMyThursdSvg.svg';
import NotificationMenu from "./NotificationMenu";
import useNotificationMenu from "./hooks/useNotificationMenu";
import { usePusher } from "../../../app/features/PusherContext";
import { fetchNotifications } from "../../../app/features/notificationsSlice";

const UserMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  position: relative;
  box-sizing: border-box;
`;

const Logo = styled.div`
width: 124px;
height: 24px;
cursor: pointer;
`;

const Image = styled.img`
width: 100%;
`

const RoundButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: grey;
  border-radius: 100px;
  cursor: pointer;
  background-color: #d8dcdf;
  z-index: 2;
  position: relative;

  &:hover {
        background-color: var(--g-s100);
    }

`

const MenuButton = styled(RoundButton)`
  z-index: 2000;
  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopMenu = styled.div`

  @media (min-width: 768px) {
    display: flex;
    gap: 16px;    
    
  }
`;

const DesktopItems = styled.div`
  display: none;
  gap: 10px;
  @media (min-width: 768px) {
    display: flex;
  }
`

const MenuContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 60vw; 
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  background-color: #042234;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  top: 0;
  right: ${(props) => (props.open ? "0" : "-100%")};
  font-size: 0.875rem;
  opacity: ${(props) => (props.open ? "1" : "0")};  
  transition: opacity 0.3s ease, right 0.3s ease, width 0.3s ease;

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;
`

const NotificationCount = styled.div`
  position: absolute;
  bottom: -4px;
  right: -8px;
  height: 24px;
  width: 24px;
  background: var(--p500);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`

const UserMenu = () => {
  const location = useLocation()
  const dispatch = useDispatch();
  const pusher = usePusher();
  const notificationMenu = useNotificationMenu();

  const user = useSelector((state) => state.profile.profile);
  const {notifications} = useSelector((state) => state.notifications);
  const pathname = location.pathname; 

  const [isOpen, setIsOpen] = useState(false);

  const profileActive = pathname.endsWith('/me')

  const toggleOpen = useCallback(() => {
    setIsOpen((value) => !value);
  }, []);

  const handleNotificationClick = () =>{

    if(notificationMenu.isOpen){
      notificationMenu.onClose();
    }else{
      dispatch(fetchNotifications());
      notificationMenu.onOpen();
    }
  }
  const handleMenuItemClick = (label) => {

    switch (label) {
      case "Meetup Spot":
        window.location.href = "/meetupspot";
        break;
      case "My profile":
        window.location.href = "/me";
        break;
      default:
        break;
    }
  };

  useEffect(() => {

    dispatch(fetchNotifications());

    if (pusher && user) {
        const channel = pusher.subscribe(`notification_${user?.data?.id}`);

        channel.bind('notification', (data) => {
          dispatch(fetchNotifications());
        });

        return () => {
            pusher.unsubscribe(`notification_${user?.data?.id}`);
            channel.unbind("notification");
        };
    } else {

        //const intervalId = setInterval(dispatch(fetchNotifications()), 10000000);

        return () => {
            //clearInterval(intervalId);
        };
    }
}, [pusher, user]);

  return (
    <div>
      <UserMenuWrapper>
        <Logo onClick={() => handleMenuItemClick("Meetup Spot")}>
          <Image src={LogoThursdInfluence} alt="Logo Thursd Influence" />
        </Logo>

        {/* Desktop Menu */}
        <DesktopMenu>

        <DesktopItems>
          <MenuItem
            onClick={() => handleMenuItemClick("Meetup Spot")}
            Icon={<Rocket />}
            label="Meetup Spot"
            active={!profileActive}
          />
          <MenuItem
            onClick={() => handleMenuItemClick("My profile")}
            Icon={<Profile />}
            label="My profile"
            active={profileActive}
          />
          </DesktopItems>
        

        </DesktopMenu>

        <div style={{ display: 'flex', gap: '16px' }}>
          
          {/* Mobile Menu */}
          <MenuButton onClick={toggleOpen}>
            {isOpen ? <IoMdClose size={20} /> : <AiOutlineMenu size={20} />}
          </MenuButton>
          <NotificationMenu />

        </div>
        
        <MenuContainer open={isOpen}>
          <HeadlineM mt="20px" color="white">Menu</HeadlineM>
          <MobileMenuItems>
            <MenuItem
              onClick={() => handleMenuItemClick("Meetup Spot")}
              Icon={<Rocket />}
              label="Meetup Spot"
            />
            <MenuItem
              onClick={() => handleMenuItemClick("My profile")}
              Icon={<Profile />}
              label="My profile"
            />
          </MobileMenuItems>
        </MenuContainer>

      </UserMenuWrapper>

    </div>
  );
};

export default UserMenu;
