import React from "react";
import styled from "styled-components";

import { HeadlineS } from "../Text";
import Close from "../../Icons/Close";
import Button from "../UI/Buttons/Button";

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.openFilterMenu ? "block" : "none")};
  z-index: 200; /* Zorg ervoor dat de overlay bovenop het menu staat */
`;

const StyledFilterMenu = styled.div`
  background: #ffffff;
  height: 100%;
  width: 414px;
  position: fixed;
  top: 0;
  right: ${(p) => (p.openFilterMenu ? "0" : "-100%")};
  transition: right 0.3s ease-in-out;
  z-index: 300;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledHeader = styled.div`
  height: 60px;
  background: var(--b-b400);
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 16px;
`;

const StyledCloseButtonContainer = styled.div`
  height: 25px;
  width: 25px;
  background: var(--b-b50);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`;

const StyledButtonContainer = styled.div`
  width: 100%;
  padding: 0px 16px 16px 16px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
`;

const FilterMenu = ({
  openFilterMenu,
  onClose,
  handleFilterMenu,
  children,
}) => {
  return (
    <>
      <StyledOverlay openFilterMenu={openFilterMenu} onClick={onClose} />
      <StyledFilterMenu openFilterMenu={openFilterMenu}>
        <div>
          <StyledHeader>
            <StyledCloseButtonContainer onClick={onClose}>
              <Close />
            </StyledCloseButtonContainer>
            <div>
              <HeadlineS mt={"0px"} color={"#F1f1f1"}>
                Filter projects by advertisers
              </HeadlineS>
            </div>
          </StyledHeader>
          {children}
        </div>
        <StyledButtonContainer>
          <Button
            primary
            type={"submit"}
            text={"Show projects by advertisers"}
            fwidth
            onClick={handleFilterMenu}
          />
        </StyledButtonContainer>
      </StyledFilterMenu>
    </>
  );
};

export default FilterMenu;
