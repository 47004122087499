import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BsPersonPlus } from "react-icons/bs";

import PitchedCard from '../../Creatives/Filter/PitchedCard';
import { HeadlineM } from '../../../Components/Text';
import usePitchesFilter from "../../../app/hooks/usePitchesFilter";
import apiClient from "../../../services/api";
import { Container } from "../../../Components/Layout";
import NoData from "../../Common/NoData";
import ButtonSmall from "../../UI/Buttons/ButtonSmall";

const StyledContainer = styled.div`
  background: var(--g-s50);
`;

const ButtonContainer = styled.div`
  background: var(--g-s50);
  padding: 16px 20px;
  display: flex;
  gap: 8px;
  overflow-x: auto;
  white-space: nowrap;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: ${props => props.active ? 'var(--p500)' : '#ffffff'};
  color: ${props => props.active ? 'white' : 'black'};
  border-radius: 50px;
  border: ${props => props.active ? '2px solid var(--b-b500)' : '2px solid var(--g-s100)'};
  cursor: pointer;
  font-family: "Avenir LT Std";
  font-size: 14px;
`;

const ContentContainer = styled.div`
  background: var(--b-b300);
  padding: 20px;
  min-height: 100vh;

`;

const CardContainer = styled.div`
  flex-wrap: wrap;
  gap: 24px;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    gap: 16px;
  }
`;


const NoCampaignWrapper = styled.div`
    display: flex;
`;

const TitleContainer = styled.div`
  padding-left: 20px; 
  padding-bottom: 10px;
  padding-top: 24px;
  display: flex;
  align-items: center;
  gap: 16px;

`

const InfluencerFilter = ({ campaignData }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [campaigns, setCampaigns] = useState([]);
  const containerRef = useRef(null);
  const loadMoreButtonRef = useRef(null);
  const [currentComponent, setCurrentComponent] = useState('Pitches');
  const [hasMoreCampaigns, setHasMoreCampaigns] = useState(true);
  const { filterBody, setFilterBody } = usePitchesFilter();
  const [isLoading, setIsLoading] = useState(false)
  const [pitches, setPitches] = useState([]);
  const [updatedCampaignData, setUpdatedCampaignData] = useState(campaignData)
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const switchToComponent = (component) => {
    if (component === 'Pitches') {
      setFilterBody({})
    } else {
      setFilterBody({ status: [component.toLowerCase()] })
    }

    setCurrentComponent(component);
  };

  useEffect(() => {
    setIsLoading(true)

    // fetch de pitches 
    apiClient
      .post(`campaign/${updatedCampaignData.id}/pitch/search`, filterBody)
      .then((searchResponse) => {
        setPitches(searchResponse.data.data)

        //fetch de campaign zodat je de juiste aantallen van de statusen kan weergeven
        apiClient.get(`/campaign/${updatedCampaignData.id}`).then((resp)=>{
          setUpdatedCampaignData(resp.data.data)
          setIsLoading(false)
        })
      
      });

  }, [filterBody]);


  return (
    <StyledContainer>
      <Container>
        <TitleContainer >
          <HeadlineM mt="0px">Pitches on my project</HeadlineM>     
          <ButtonSmall 
            text="Invite to pitch"
            Icon={<BsPersonPlus height={18} width={18}/>}
            onClick={() => {navigate(`/${campaignData.id}/invitecreative`)}}
            />
        </TitleContainer>
        <ButtonContainer>
          <StyledButton
            active={currentComponent === 'Pitches'}
            onClick={() => switchToComponent('Pitches')}
          >
            Pitches ({updatedCampaignData.influencers.pitches.length})
          </StyledButton>
          <StyledButton
            active={currentComponent === 'Active'}
            onClick={() => switchToComponent('Active')}
          >
            Active ({updatedCampaignData.influencers.active.length})
          </StyledButton>
          <StyledButton
            active={currentComponent === 'Finished'}
            onClick={() => switchToComponent('Finished')}
          >
            Finished ({updatedCampaignData.influencers.finished.length})
          </StyledButton>
          <StyledButton
            active={currentComponent === 'Declined'}
            onClick={() => switchToComponent('Declined')}
          >
            Declined ({updatedCampaignData.influencers.declined.length})
          </StyledButton>
        </ButtonContainer>
      </Container>
      <ContentContainer id="content-container" ref={containerRef}>
        <Container>
          <CardContainer>
          {pitches.length > 0 ? (
            pitches.map((pitch, index) => (
              <PitchedCard key={index} pitchData={pitch} campaignData={campaignData} />
            ))
          ) : (
            <NoCampaignWrapper>              
              <NoData text="No pitches found"/>
            </NoCampaignWrapper>
          )}
          </CardContainer>
        </Container>
      </ContentContainer>
    </StyledContainer>
  );
};

export default InfluencerFilter;
