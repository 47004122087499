import React from "react";

const Megaphone = (props) => {
  return (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M14.7656 1.16406C15.7422 0.148438 17.5 0.851562 17.5 2.25781V17.5312C17.5 18.9375 15.7422 19.6406 14.7656 18.625L13.8672 17.6484C12.1484 15.8516 9.92188 14.7188 7.5 14.4062V18.3125C7.5 19.5234 6.48438 20.5 5.3125 20.5H4.6875C3.47656 20.5 2.5 19.5234 2.5 18.3125V14.25C1.09375 14.25 0 13.1562 0 11.75V8C0 6.63281 1.09375 5.5 2.5 5.5H5.58594L6.71875 5.46094C9.45312 5.30469 11.9922 4.13281 13.8672 2.14062L14.7656 1.16406ZM16.25 2.25781C16.25 1.94531 15.8984 1.82812 15.7031 2.02344L14.7656 3C13.125 4.71875 11.0156 5.92969 8.75 6.4375V13.3516C11.0156 13.8594 13.125 15.0312 14.7656 16.7891L15.7031 17.7656C15.8984 17.9609 16.25 17.8047 16.25 17.5312V2.25781ZM7.5 13.1562V6.63281C7.26562 6.67188 7.03125 6.67188 6.79688 6.71094L5.625 6.75H2.5C1.79688 6.75 1.25 7.33594 1.25 8V11.75C1.25 12.4531 1.79688 13 2.5 13H5.625L6.79688 13.0781C7.03125 13.0781 7.26562 13.1172 7.5 13.1562ZM3.75 14.25V18.3125C3.75 18.8594 4.14062 19.25 4.6875 19.25H5.3125C5.82031 19.25 6.25 18.8594 6.25 18.3125V14.2891L5.58594 14.25H3.75ZM19.375 8C19.6875 8 20 8.3125 20 8.625V11.125C20 11.4766 19.6875 11.75 19.375 11.75C19.0234 11.75 18.75 11.4766 18.75 11.125V8.625C18.75 8.3125 19.0234 8 19.375 8Z" fill="#A4AFB6"/>
  </svg>
  );
}

export default Megaphone;
