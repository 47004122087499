import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LayoutCenterTop } from "../../Components/Layout";
import { PasswordBox } from "../../Components/Common";
import { HeadingXL, ParagraphM } from "../../Components/Text";
import { Button, RoundButtonLink } from "../../Components/UI";
import apiClient from "../../services/api";
import LeftArrow from "../../Icons/LeftArrow";

const CreatePassword = () => {
    const [ password, setPassword] = useState("");
    const [ confirmedPassword, setConfirmedPassword] = useState("");
    const [ passwordError, setPasswordError] = useState(false);
    const [ showButton, setShowButton ] = useState(false);

    const navigate = useNavigate();
    const locationData = useLocation();

    useEffect(() => {
        if (password === confirmedPassword && password && confirmedPassword && password.length >= 6) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }, [password, confirmedPassword])

    const createNewPassword = () => {
        apiClient.post('password/reset', {
            token: locationData.state.token,
            password: password,
            confirmed_password: confirmedPassword
        }).then((resp) => {
            navigate('/passwordchanged')
        })
    }

    return (
        <LayoutCenterTop>
            <RoundButtonLink
				Icon={<LeftArrow height={"16px"}
                onClick={() => navigate("/forgotpassword")}
                />}
		    />
            <HeadingXL>Create password</HeadingXL>
            <ParagraphM>
               Create your new password to login with
			</ParagraphM>
                <PasswordBox
                    error={passwordError}
                    label="Create a password"
                    password={password}
                    placeholder="At least 8 characters"
                    onChange={(e) => setPassword(e.target.value)}
                    passwordstrengthbar
                />
                <PasswordBox
                    error={passwordError}
                    label="Repeat your password"
                    password={confirmedPassword}
                    onChange={(e) => setConfirmedPassword(e.target.value)}
                />
            {showButton && (
                <Button
                    fwidth mt24 primary
                    text="Submit"
                    onClick={createNewPassword}
                />
            )}
        </LayoutCenterTop>
    )
}

export default CreatePassword;
