import styled from "styled-components";

const RoundContainer = styled.div`
  width: ${(p) => p.width ? p.width : "40px"};
  height:  ${(p) => p.height ? p.height : "40px"};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--BB100, #D8DCDF);
  background: ${(p) => p.bg ? p.bg : "#EBEEEF"};
  border-radius: 50%;
`;

const IconStyle = styled.div`
  margin: ${(p) => p.margin || "0"}; 
  height: auto;
  display: flex;
  flex-direction: column;
`;

const IconRound = ({
    Icon,
    bg,
    width,
    height,
    iconMargin, // Voeg de margin prop toe voor het icoontje
}) => {
    return (
        <RoundContainer
            bg={bg}
            height={height}
            width={width}
        >
            <IconStyle margin={iconMargin}>{Icon || null}</IconStyle>
        </RoundContainer> 
    );
};

export default IconRound;
