import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "../../Forms/multiselect.css";
import { ErrorMessage, HeadlineM, SubheadingS } from "../../../Components/Text";
import { FormContext } from "../../../Pages/ProfileSetup/FormProvider";
import { InputLabel, Bio } from "../../Forms";
import CustomSelect from "../../Common/Questions/CustomSelect";
import MultipleSelectCheckbox from "../../Common/Questions/MultiSelectCheckbox/MultipleSelectCheckbox";
import MultiSelectDropdown from "../../Common/Questions/MultiSelectDropdown";

import useMultiCheckboxHandler from "../../../app/hooks/useMultiCheckboxHandler";
import {
  handleCustomSelectChange,
  handleMultiSelectDropdownChange,
  handleInputChange,
  handleSubmit,
} from "../../../Pages/ProfileSetup/formUtils";
import { updateFormData } from "../../../Utils/utility-functions";
import { profileSetup } from "../../../app/features/profileSlice";
import { Button } from "../../../Components/UI";
import { FileDropZone } from "../../Common";

export default function CompanyUserForm({
  data,
  answers,
  initialLogo,
  customLabel,
}) {
  // Use the FormContext to access form data and methods
  const formCtx = useContext(FormContext);

  // Custom hook to handle multiple checkbox selections
  const { handleMultiCheckboxChange, selectedOptionsDict } =
    useMultiCheckboxHandler(formCtx);

  // Component state for image and errors
  const [image, setImage] = useState(initialLogo);
  const [errors, setErrors] = useState({});

  // Redux hooks for dispatching actions and navigation
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Update image state when initialLogo prop changes
  useEffect(() => {
    if (initialLogo) {
      setImage(initialLogo);
    }
  }, [initialLogo]);

  // Update form data when answers prop changes
  useEffect(() => {
    formCtx.createFormData(answers);
  }, [answers]);


  // Function to clear a specific field's error
  const clearError = (fieldName) => {
    if (errors[fieldName]) {
      const updatedErrors = { ...errors };
      delete updatedErrors[fieldName];
      setErrors(updatedErrors);
    }
  };

  // Handlers for form input changes
  const handleCustomSelectChangeLocal = (e, options) => {
    handleCustomSelectChange(e, options, formCtx, clearError);
  };

  const handleMultiSelectDropdownChangeLocal = (questionName, selected) => {
    handleMultiSelectDropdownChange(
      questionName,
      selected,
      formCtx,
      clearError
    );
  };

  const handleInputChangeLocal = (e, question) => {
    handleInputChange(e, question, formCtx, clearError);
  };

  // Function to create FormData for image upload
  const updateImage = (image) => {
    let formData = new FormData();

    // Only append the image if it's a file (not a URL)
    if (image instanceof File || image instanceof Blob) {
      formData.set("logo", image);
    }

    return formData;
  };

  const onSubmit = () => {
    // Validate form data
    const validationErrors = handleSubmit(data, formCtx, setErrors);

    let completeData = formCtx.formData;

    if (validationErrors) {
      setErrors(validationErrors);

      // Scroll to the first error field
      const errorElement = document.getElementById(
        Object.keys(validationErrors)[0]
      );

      if (errorElement) {
        errorElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }

      return;
    }

    if (image) {
      const formDataWithImage = updateImage(image);
      completeData = updateFormData(formCtx.formData, formDataWithImage);
    }

    dispatch(profileSetup({ data: completeData })).then((action) => {
      if (profileSetup.fulfilled.match(action)) {
        if (action.payload.success) {
          if (action.payload.data.profile_filled) {
            navigate("/");
          }
        }
      }
    });
  };

  const renderInputByFieldType = (fieldtype, question) => {
    switch (fieldtype) {
      case "input":
        return (
          <InputLabel
            subheading={question.sublabel}
            placeholder={question.placeholder}
            onChange={(e) => handleInputChangeLocal(e, question)}
            value={formCtx.formData[question?.name]}
          />
        );
      case "select":
        return (
          <CustomSelect
            question={question}
            showLabel={false}
            onChange={handleCustomSelectChangeLocal}
            defaultValue={formCtx.formData[question?.name]}
            customLabel={customLabel}
          />
        );
      case "checkbox":
        return (
          <MultipleSelectCheckbox
            question={question}
            showLabel={false}
            onChange={handleMultiCheckboxChange}
            selectedOptionsDict={selectedOptionsDict}
          />
        );
      case "textarea":
        return (
          <Bio
            onChange={handleInputChangeLocal}
            question={question}
            value={formCtx.formData[question?.name]}
          />
        );
      case "checkbox_select":
        return (
          <MultiSelectDropdown
            question={question}
            onChange={handleMultiSelectDropdownChangeLocal}
            showLabel={false}
          />
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      {formCtx.formData &&
        data &&
        data.map((questionChunk, index) => (
          <div key={index}>
            {index === 1 && (
              <React.Fragment>
                <SubheadingS>Upload company logo</SubheadingS>
                <FileDropZone setImage={setImage} image={image} isAvatar />
              </React.Fragment>
            )}
            <HeadlineM>{questionChunk.heading}</HeadlineM>
            {questionChunk.questions.map((question, questionIndex) => (
              <div key={questionIndex} id={question.name}>
                <SubheadingS>{question.label}</SubheadingS>
                {renderInputByFieldType(question.fieldtype, question)}
                <ErrorMessage message={errors?.[question?.name]} />
              </div>
            ))}
          </div>
        ))}
      <Button
        onClick={onSubmit}
        fwidth={"100%"}
        text={"Submit"}
        mt={"24px"}
        primary
      />
      {Object.keys(errors)?.length > 0 && (
        <ErrorMessage message={"fill in all mandatory fields"} />
      )}
    </React.Fragment>
  );
}
