import styled from 'styled-components';
import React, { useState, useEffect } from "react";
import {useNavigate, useParams} from 'react-router-dom';
import { FaRegEnvelope } from 'react-icons/fa'; // Let op de juiste import

import InfluencerFilter from '../../Components/Creatives/Filter/InfluencerFilter';
import { HeadlineM, ParagraphS } from '../../Components/Text';
import Trash from '../../Icons/Trash';
import Close from '../../Icons/Close';
import Navbar from '../../Components/Common/Navbar/NavigationBar';
import DetailsFilter from '../../Components/Common/Filter/DetailsFilter';
import { Container } from '../../Components/Layout';
import PageHeader from '../../Components/Common/PageHeader';
import CampaignDetailHeader from './CampaignDetailHeader';
import { openEmail, openEmailTeam } from '../../Utils/utility-functions';
import apiClient from '../../services/api';
import IdealCreativeFilter from '../../Components/Creatives/Filter/IdealCreativeFilter';
import { Button } from '../../Components/UI';

const StyledContainer = styled.div`
  min-height: 100vh;
  background: ${props =>
    props.currentComponent === "Influencers" ? "pink" : "#F1F1F1"};
`;

const ButtonWrapper = styled.div`
  background: white;
  // width: 100vw;
`

const ButtonContainer = styled.div`
  padding: 16px 20px;
  display: flex;
  gap: 8px;
  overflow-x: auto;
  white-space: nowrap;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: ${props => props.active ? 'var(--p500)' : '#ffffff'};
  color: ${props => props.active ? 'white' : 'black'};
  border-radius: 50px;
  border: ${props => props.active ? '2px solid var(--b-b500)' : '2px solid var(--g-s100)'};
  cursor: pointer;
  font-family: "Avenir LT Std";
  font-size: 14px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(105, 123, 134, 0.8);
  display: ${props => props.visible ? 'block' : 'none'};
  z-index: 999; /* Zorg ervoor dat het bovenop andere elementen staat */
`;

const Wrapper = styled.div`
background-color: white;
width: 300px;
height: 300px;
display: flex;
flex-direction: column;
gap: 8px;
justify-content: center; 
align-items: center; 
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);

.button-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
}
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  background-color: var(--p500);
  color: ${props => props.active ? 'white' : 'black'};
  border: none;
  cursor: pointer;
  font-family: "Avenir LT Std";
  font-size: 14px;
  display: flex;
  justify-content: center;
  gap: 6px;

  .paragraph_s{
    color: white;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;

  &:focus {
    outline: none;
  }
`;

const ContactBtnWrapper = styled.div`
     display: flex;
    gap: 1rem;
    padding-top: 8px;
    width: 100%;

    @media (max-width: 1224px) {
        margin-right: 16px;
      }
    @media (max-width: 768px){
        flex-direction: column;
        gap: 0.5rem;

    }
`

const HeaderContainer = styled.div`
  display: flex; 
  width: 100%;
  justify-content: space-between;
  align-items: center;
`


const CampaignDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState();
  const [currentComponent, setCurrentComponent] = useState('Influencers');
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isInfluencerFilterDisabled, setIsInfluencerFilterDisabled] = useState(false); // Added state

  //const { campaignData } = location.state;

  // op de een of andere manier neemt hij de scroll over van devorige pagine
  // voor nu is het zo
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (params.id) {
      apiClient.get(`campaign/${params.id}`).then((resp) => {
        const campaignData = resp.data.data;
        setCampaignData(campaignData);
      });
    }
  }, [params])

  useEffect(() => {
    if (campaignData) {
      if (campaignData.influencers.pitches.length === 0) {
        setIsInfluencerFilterDisabled(true);
        setCurrentComponent('Details');
      } else {
        setIsInfluencerFilterDisabled(false);
      }
    }

  }, [campaignData])


  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

  const switchToComponent = (component) => {
    setCurrentComponent(component);
  };

  if (!campaignData) {
    return null
  }


  return (
    <StyledContainer currentComponent={currentComponent}>
      <Navbar />
      <PageHeader backgroundImage={campaignData.image}>
        <Container>
          <HeaderContainer>
            {currentComponent === 'Influencers' && (
              <CampaignDetailHeader
                title={`${campaignData.company_name}: ${campaignData.title}`}
                toggleOverlay={toggleOverlay}
                optionButton />
            )}
            {currentComponent === 'Details' && (
              <CampaignDetailHeader
                title={`${campaignData.company_name}: ${campaignData.title}`} />
            )}
            {currentComponent === 'Ideal Creatives' && (
              <CampaignDetailHeader
                title={`${campaignData.company_name}: ${campaignData.title}`} />
            )}
            <ContactBtnWrapper>
              <Button
                primary
                padding={"10px 10px"}
                text="Talk to a team member"
                textSize={"12px"}
                onClick={openEmailTeam}
                mt={"0px"}
              />
              <Button
                primary
                padding={"10px 32px"}
                text="Create a project"
                textSize={"12px"}
                mt={"0px"}
                onClick={()=> navigate('/project/new')}
              />
            </ContactBtnWrapper>
          </HeaderContainer>
        </Container>
      </PageHeader>

      <ButtonWrapper>
        <Container>
          <ButtonContainer>
            <StyledButton
              active={currentComponent === 'Influencers'}
              onClick={() => switchToComponent('Influencers')}
              disabled={isInfluencerFilterDisabled} // Disable the button based on the state
            >
              {`Pitches on my project (${campaignData?.influencers?.pitches?.length})`}
            </StyledButton>
            <StyledButton
              active={currentComponent === 'Ideal Creatives'}
              onClick={() => switchToComponent('Ideal Creatives')}
            >
              Ideal Creatives
            </StyledButton>
            <StyledButton
              active={currentComponent === 'Details'}
              onClick={() => switchToComponent('Details')}
            >
              Details
            </StyledButton>
          </ButtonContainer>
        </Container>
      </ButtonWrapper>

      {currentComponent === 'Influencers' &&
        <>
          <InfluencerFilter campaignData={campaignData} />

        </>
      }
      {currentComponent === 'Ideal Creatives' &&
        <>
          <IdealCreativeFilter campaignData={campaignData} />
        </>
      }
      {currentComponent === 'Details' &&
        <>
          <DetailsFilter campaignData={campaignData} />
        </>
      }

      {/* Modal */}
      <Overlay visible={isOverlayVisible}  >
        <Wrapper>
          <CloseButton onClick={toggleOverlay}>
            <Close />
          </CloseButton>
          <div style={{ marginBottom: '10px', marginLeft: '24px' }}>
            <HeadlineM>Project by advertisers options</HeadlineM>
          </div>
          <ModalButton onClick={openEmail}>
            <FaRegEnvelope style={{ fontSize: '16px', color: 'white' }} />
            <ParagraphS mt={'0px'}>Edit project by advertisers</ParagraphS>
          </ModalButton>
          <ModalButton onClick={openEmail}>
            <Trash color={'#ffffff'} />
            <ParagraphS mt={'0px'}>Cancel project by advertisers</ParagraphS>
          </ModalButton>
        </Wrapper>
      </Overlay>
    </StyledContainer>
  );
};

export default CampaignDetail;
