import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";

const LoadingContainer = styled.div`
  height: ${(props) => (props.height ? props.height : "100vh")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = ({ loading, height }) => {
  return (
    <LoadingContainer height={height}>
      <ClipLoader
        color={'#FF4E5B'}
        loading={loading}
        size={60}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </LoadingContainer>
  );
};

export default Loading;
