import React from 'react';
import styled from 'styled-components';

import { SubheadingXS } from '../../Text';
import ArrowUp from '../../../Icons/ArrowUp';

const StyledContainer = styled.div`
  border-bottom: 1px solid #F1F1F1;
  max-width: 848px;
  padding: 24px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${props => (props.clicked ? '1000px' : '0')};
  overflow: hidden;
  transition: 0.3s ease-in-out;
`;

const StyledArrowContainer = styled.div`
  transform: ${props => (props.clicked ? 'rotate(0deg)' : 'rotate(180deg)')};
  width: 25px;
  height: 25px;
  background: var(--b-b100);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  border-radius: 100%;
`;

const MultiAccordion = ({ children, title, clicked, onAccordionClick }) => {
  return (
    <StyledContainer>
      <StyledHeader onClick={onAccordionClick}>
        <SubheadingXS>{title}</SubheadingXS>
        <StyledArrowContainer clicked={clicked}>
          <ArrowUp />
        </StyledArrowContainer>
      </StyledHeader>
      <StyledContent clicked={clicked}>{children}</StyledContent>
    </StyledContainer>
  );
};

export default MultiAccordion;
