/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components'

const StyledSubheadingXXXS= styled.h2`
	margin-top: ${(p) => (p.mt ? p.mt : "24px")};
	margin-left: ${(p) => (p.ml ? p.ml : "0px")};
	margin-bottom: ${(p) => (p.mb ? p.mb : "0px")};
	color: ${(p) => (p.color ? p.color : "white")};
`
const SubheadingXXXS = ({mt, ml, mb, color, children}) => {
  return (
    <StyledSubheadingXXXS mt={mt} ml={ml} mb={mb} color={color} className="sub_heading_xxxs">
		{children}
	</StyledSubheadingXXXS>
  )
}

export default SubheadingXXXS;