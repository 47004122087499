/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from "react";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

/* -------------------------- Internal Dependencies ------------------------- */
import { Button } from '../../UI';
import ParagraphXS from '../../Text/ParagraphXS';
import Balloon from '../../../Icons/Balloon';
import Summary from '../../Advertisers/Summary';
import Channels from '../../Creatives/Channels/Channels';
import influencerProfile from '../../../Images/influencer-profile.png'
import { SubheadingXXS } from '../../Text';

const Container = styled.div`
  border-radius: 4px;
  background: #fff;
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02);

  @media  (max-device-width: 768px) {
    margin-bottom: 10px;
  }  

}`;

const StyledWrapper = styled.div`
  padding: 24px;
}`;

const Row = styled.div`
  display: flex;
  gap: ${(props) => props.gap || "0"}; 
  margin-top: 8px;
  margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

const TopContainer = styled.div`
  display: flex;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: 16px; 
`;

const ImageContainer = styled.div`
  width: 88px;        
  height: 88px;        
  border-radius: 50%;  
`;

const Name = styled.p`
  font-size: 22px;
  font-family: Avenir LT Std;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.44px;
`;

const DetailsContainer = styled.div`
  height: 100%;
`;

const StyledImage = styled.img`
  display: flex;
	align-items: center;
	overflow: hidden;
  border-radius: 50%;
  height: 80px;
  width: 80px;
	background-color: var(--b-b50);
  border: 1px solid var(--b-b100);
`;

const NotificationCount = styled.div`
  position: absolute;
  bottom: 28%;
  left: 90px;
  height: 20px;
  width: 20px;
  background: var(--p500);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`

const PitchedCard = ({ pitchData, campaignData }) => {
  const navigate = useNavigate();

  const navigateToInfluencerCard = () => {
   navigate(`/pitch/${campaignData.id}/${pitchData.id}`);
  };

  const navigateToChat = () => {
    const {conversation_id} = pitchData;
    navigate(`/chat/campaign/${campaignData.id}/${pitchData.id}?conversationId=${conversation_id}`)
  }

  return (
    <Container>
      <StyledWrapper>
        <Row gap="5px">
          <TopContainer>
            <ImageContainer>
              <StyledImage src={pitchData.image || influencerProfile} alt="Influencer image" />
            </ImageContainer>
            <TextContainer>
              <Name>{pitchData?.firstname} {pitchData?.lastname}</Name>
              <ParagraphXS>Channels I propose to post on:</ParagraphXS>
              <div style={{ marginLeft: 20, marginTop: 5 }}>
                 <Channels channels={pitchData.pitch_channels} />
              </div>
            </TextContainer>
          </TopContainer>
        </Row>
        <DetailsContainer >
          <Summary data={pitchData} smallCard />
          <ButtonContainer>
            <div style={{ position: 'relative' }}>
              <Button
                hover={true}
                secondary
                text={"Chat"}
                textcolor={"#042234"}
                Icon={<Balloon />}
                onClick={navigateToChat}
                textSize="14px"
                width='135px'
                disabled={pitchData.status === 'closed' || pitchData.status === 'declined'}
              />
              {pitchData && pitchData?.message_count > 0 &&
                <NotificationCount>
                  <SubheadingXXS mt={"0px"} pt={"3px"} color="#fff">
                    {pitchData?.message_count}
                  </SubheadingXXS>
                </NotificationCount>
              }
            </div>

            <Button
              secondary
              text={"View pitch"}
              onClick={navigateToInfluencerCard}
              textcolor={"#042234"}
              textSize="14px"
              width='135px'
            />
          </ButtonContainer>
        </DetailsContainer>
      </StyledWrapper>
    </Container>
  );
};

export default PitchedCard;
