/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';

/* -------------------------- Internal Dependencies ------------------------- */
import { theme } from '../../Theme/theme';
import { ErrorMessage, ParagraphS } from '../../Components/Text';
import RoundButtonLink from '../UI/Buttons/RoundButtonLink';
import DropzoneIcon from '../../Icons/DropzoneIcon';
import CloseIcon from '../../Icons/Close';

/* ----------------------------- Styled Components -------------------------- */

const StyledDropZoneWrapper = styled.div`
  margin-top: 10px;
  cursor: pointer;
`;

const StyledContainer = styled.div`
  height: 152px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px dashed var(--g-s200);
  background-color: var(--b-b50);
  color: var(--b-b400);
  font-family: 'Avenir LT Std';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  cursor: pointer;

  span {
    color: ${({ color }) => color};
    text-decoration: underline;
  }
`;

const DroppedImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  aspect-ratio: ${({ isAvatar }) => (isAvatar ? '1/1' : '16/9')};
  border-radius: ${({ isAvatar }) => (isAvatar ? '50%' : '0')};
  height: ${({ isAvatar }) => (isAvatar ? '120px' : '268px')};
`;

const DropZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-items: center;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledListBox = styled.ul`
  list-style-type: disc;
  color: #a4afb6;
  font-family: 'Avenir LT Std';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  margin-left: -12px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ isAvatar }) => (isAvatar ? '50%' : '0')};
`;

/* -------------------------------------------------------------------------- */
/*                             FileDropZone Component                         */
/* -------------------------------------------------------------------------- */

const FileDropZoneWithOutCrop = ({
  image, // Initial image prop
  setImage,
  fileUpload = false,
  isAvatar = false,
  maxFileSize = 5 * 1024 * 1024, // Default to 5MB
}) => {
  const [file, setFile] = useState(null);
  const [pageNumber] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  // Handle file drop
  const handleDrop = (acceptedFiles, fileRejections) => {
    setErrorMessage(null);

    if (fileRejections.length > 0) {
      // Handle errors
      fileRejections.forEach((fileRejection) => {
        fileRejection.errors.forEach((error) => {
          if (error.code === 'file-too-large') {
            setErrorMessage('File size exceeds the limit of 5MB.');
          } else if (error.code === 'file-invalid-type') {
            setErrorMessage('Invalid file type.');
          }
        });
      });
      return;
    }

    const acceptedFile = acceptedFiles[0];

    if (fileUpload) {
      // Accept both images and PDFs
      if (
        acceptedFile.type === 'application/pdf' ||
        acceptedFile.type.startsWith('image/')
      ) {
        setFile(acceptedFile);
        setImage(acceptedFile);
      } else {
        setErrorMessage('Only PDF and image files are allowed.');
      }
    } else {
      // Only accept images
      if (acceptedFile.type.startsWith('image/')) {
        setFile(acceptedFile);
        setImage(acceptedFile);
      } else {
        setErrorMessage('Only image files are allowed.');
      }
    }
  };

  // Handle file delete
  const handleDelete = () => {
    setFile(null);
    setImage(null);
  };

  // PDF Document load success
  const onDocumentSuccess = ({ numPages }) => {
    // Handle PDF document success if needed
  };

  // Determine what content to display
  let content;
  if (file || image) {
    if (file && file.type === 'application/pdf') {
      // Display the PDF document
      content = (
        <DroppedImageContainer isAvatar={isAvatar}>
          <Document file={file} onLoadSuccess={onDocumentSuccess}>
            <div
              style={{
                width: '200px',
                height: '100px',
                position: 'relative',
              }}
            >
              <Page pageNumber={pageNumber} width={200} height={100} />
            </div>
          </Document>
        </DroppedImageContainer>
      );
    } else if (file && file.type.startsWith('image/')) {
      // Display the uploaded image
      const imageUrl = URL.createObjectURL(file);
      content = (
        <DroppedImageContainer isAvatar={isAvatar}>
          <StyledImage src={imageUrl} alt="Uploaded" isAvatar={isAvatar} />
        </DroppedImageContainer>
      );
    } else if (image && !file) {
      // Display the initial image
      const imageUrl =
        typeof image === 'string' ? image : URL.createObjectURL(image);
      content = (
        <DroppedImageContainer isAvatar={isAvatar}>
          <StyledImage src={imageUrl} alt="Initial" isAvatar={isAvatar} />
        </DroppedImageContainer>
      );
    }
  } else {
    // Display the drop zone instructions
    content = (
      <DropZoneContainer>
        <DropzoneIcon />
        <p>
          Drag & drop file here or <span>browse</span>
        </p>
      </DropZoneContainer>
    );
  }

  return (
    <StyledDropZoneWrapper>
      <Dropzone
        onDrop={handleDrop}
        accept={fileUpload ? 'application/pdf,image/*' : 'image/*'}
        maxFiles={1}
        maxSize={maxFileSize}
      >
        {({ getRootProps, getInputProps }) => (
          <StyledContainer
            {...getRootProps()}
            file={file}
            initialImage={image}
            color={theme.main}
            isAvatar={isAvatar}
          >
            <input
              {...getInputProps()}
              type={'file'}
              accept={fileUpload ? 'application/pdf,image/*' : 'image/*'}
            />
            {content}
          </StyledContainer>
        )}
      </Dropzone>
      {(file || image) && (
        <FileContainer>
          <ParagraphS mt={'0px'}>
            {file ? file.name : 'Image Selected'}
          </ParagraphS>
          <div onClick={handleDelete}>
            <RoundButtonLink
              height={'20px'}
              width={'20px'}
              Icon={<CloseIcon height={'8px'} />}
            />
          </div>
        </FileContainer>
      )}
      <StyledListBox>
        <li>
          {fileUpload
            ? 'png, gif, jpeg, jpg, webp, svg, pdf'
            : 'png, gif, jpeg, jpg, webp, svg'}
        </li>
        <li>Size limit: 5MB.</li>
        <li>We recommend a ratio of {isAvatar ? '1:1' : '16:9'}</li>
      </StyledListBox>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </StyledDropZoneWrapper>
  );
};

export default FileDropZoneWithOutCrop;
