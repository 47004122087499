import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

import { useDataSource } from "../../Utils/hooks/useDataSource";
import { ParagraphS } from "../Text";
import FilterMenu from "./FilterMenu";
import useFilterMenu from "../../app/hooks/useFilterMenu";
import { CustomCheckbox } from "../Forms";
import { queryStringToArray } from "../../Utils/utility-functions";
import MultiAccordion from "../Common/Accordion/MultiAccordion";

const ClearButton = styled.button`
  position: absolute;
  bottom: 80px;
  right: 16px;
  border: none;
  background: none;
  color: ${(props) => props.theme.main};
  font-family: "Avenir LT Std";
  font-size: 14px;
  font-weight: 900;
  cursor: pointer;
  z-index: 999;

  &:hover {
    color: #982e36;
  }

`;

const CampaignFilterMenu = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const filterMenuRef = useRef(null);

    const filterMenu = useFilterMenu();
    const { resource: searchOptions } = useDataSource('/campaign/search-options');
    const filterData = searchOptions?.data || {};
    const [checkedItems, setCheckedItems] = useState({});

    const [filterBody, setFilterBody] = useState({});

    useEffect(() => {
        const marketingGoals = searchParams.get("mg")
        const influencerOcc = searchParams.get("io")
        const advertiserOcc = searchParams.get("ao")

        const updatedItems = {}

        if (marketingGoals) {
            const marketingGoalsArr = queryStringToArray(marketingGoals)
            updatedItems['marketing_goals'] = marketingGoalsArr;

        }

        if (influencerOcc) {
            const influencerOccArr = queryStringToArray(influencerOcc)
            updatedItems['influencer_occupations'] = influencerOccArr;
        }


        if (advertiserOcc) {
            const audOccArr = queryStringToArray(advertiserOcc)
            updatedItems['audience_occupations'] = audOccArr
        }

        setCheckedItems(updatedItems)

    }, [])

    const [openAccordion, setOpenAccordion] = useState(null);

    const handleAccordionClick = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };


    const handleCheckboxChange = (category, item) => {
        // Maak een kopie van de huidige staat van checkedItems
        const updatedItems = { ...checkedItems };

        // Controleer of de huidige categorie al in checkedItems zit, anders maak een lege array
        if (!updatedItems[category]) {
            updatedItems[category] = [];
        }

        // Controleer of het item al in de array zit
        const itemIndex = updatedItems[category].indexOf(item);

        if (itemIndex !== -1) {
            // Als het item al in de array zit, verwijder het
            updatedItems[category].splice(itemIndex, 1);

            if (updatedItems[category].length === 0) {
                // Verwijder de categorie volledig uit de dictionary
                delete updatedItems[category];
            }
        } else {
            // Voeg het item toe aan de array
            updatedItems[category].push(item);
        }
        // Update de staat met de nieuwe checkedItems
        setCheckedItems(updatedItems);
    };

    const handleFilterMenu = () => {
        // Maak een kopie van de huidige staat van filterBody
        const updatedFilterBody = { ...filterBody, ...checkedItems };

        // Loop door de keys van filterBody om lege categorieën te verwijderen
        for (const category in updatedFilterBody) {
            if (Array.isArray(updatedFilterBody[category]) && updatedFilterBody[category].length === 0) {
                delete updatedFilterBody[category];
            }
        }

        // Werk filterBody bij met de bijgewerkte versie zonder lege categorieën
        setFilterBody(updatedFilterBody);

        if (updatedFilterBody?.audience_occupations) {
            const queryStringAudienceOcc = updatedFilterBody?.audience_occupations.map(category => `${encodeURIComponent(category)}`).join('&');
            searchParams.set('ao', queryStringAudienceOcc)
        } else {
            searchParams.delete('ao')
        }

        if (updatedFilterBody?.marketing_goals) {
            const queryStringMarketingGoals = updatedFilterBody?.marketing_goals.map(category => `${encodeURIComponent(category)}`).join('&');
            searchParams.set('mg', queryStringMarketingGoals)

        } else {
            searchParams.delete('mg')
        }

        if (updatedFilterBody?.influencer_occupations) {
            const queryStringInfluencerOcc = updatedFilterBody?.influencer_occupations.map(category => `${encodeURIComponent(category)}`).join('&');
            searchParams.set('io', queryStringInfluencerOcc)

        } else {
            searchParams.delete('io')
        }

        setSearchParams(searchParams)

        //Sluit het filterMenu
        setTimeout(() => {
            filterMenu.onClose();
        }, 300);
    };

    const clearFilters = () => {
        searchParams.delete('ao');
        searchParams.delete('mg');
        searchParams.delete('io');
        setSearchParams(searchParams)

        setFilterBody({})
        setCheckedItems({})
    }

    return (
        <div ref={filterMenuRef}>
            <FilterMenu
                openFilterMenu={filterMenu.isOpen}
                onClose={() => filterMenu.onClose()}
                handleFilterMenu={handleFilterMenu}
            >

                <MultiAccordion
                    title={"Need help with your choice?"}
                    clicked={openAccordion === 0}
                    onAccordionClick={() => handleAccordionClick(0)}
                >
                    <ParagraphS color={'#727272'}>
                        We are here to help you, start a chat with us in the bottom-right corner.
                    </ParagraphS>
                    <ParagraphS color={"#BFBFBF"}>
                        Available on workdays between 9 am and 5 pm (Amsterdam GMT+2).
                    </ParagraphS>
                </MultiAccordion>

                {searchOptions && (
                    <div>
                        <MultiAccordion
                            title={"Marketing goal"}
                            clicked={openAccordion === 1}
                            onAccordionClick={() => handleAccordionClick(1)}
                        >
                            {Object.entries(filterData.marketing_goals).map(([key, value]) => (
                                filterData.marketing_goals[key] !== 'input' && (
                                    <CustomCheckbox
                                        id={value}
                                        key={key}
                                        value={value}
                                        text={filterData.marketing_goals[key]}
                                        checked={checkedItems['marketing_goals']?.includes(value)}
                                        onChange={() => handleCheckboxChange('marketing_goals', value)}
                                        onClick={() => handleCheckboxChange('marketing_goals', value)}
                                    />
                                )
                            ))}
                        </MultiAccordion>
                        <MultiAccordion
                            title={"Creative occupations"}
                            clicked={openAccordion === 2}
                            onAccordionClick={() => handleAccordionClick(2)}
                        >
                            {Object.entries(filterData.influencer_occupations).map(([key, value]) => (
                                key !== 'Floriculture' && (
                                    filterData.influencer_occupations[key] !== 'input' && (
                                        <CustomCheckbox
                                            id={value}
                                            key={key}
                                            value={value}
                                            text={value}
                                            checked={checkedItems['influencer_occupations']?.includes(value)}
                                            onChange={() => handleCheckboxChange('influencer_occupations', value)}
                                            onClick={() => handleCheckboxChange('influencer_occupations', value)}

                                        />
                                    )
                                )
                            ))}

                            {/* Render sub-checkboxes for "Floriculture" */}
                            {filterData.influencer_occupations && filterData.influencer_occupations["Floriculture"] && (
                                <div>
                                    {filterData.influencer_occupations['Floriculture'].map((subItem) => (
                                        <CustomCheckbox
                                            id={subItem}
                                            key={subItem}
                                            text={subItem}
                                            checked={checkedItems['influencer_occupations']?.includes(subItem)}
                                            onChange={() => handleCheckboxChange('influencer_occupations', subItem)}
                                            onClick={() => handleCheckboxChange('influencer_occupations', subItem)}
                                        />
                                    ))}
                                </div>
                            )}
                        </MultiAccordion>
                        <MultiAccordion
                            title={"Audience occupations"}
                            clicked={openAccordion === 3}
                            onAccordionClick={() => handleAccordionClick(3)}
                        >
                            {Object.entries(filterData.audience_occupations).map(([key, value]) => (
                                filterData.audience_occupations[key] !== 'input' && (
                                    <CustomCheckbox
                                        id={value}
                                        key={key}
                                        value={value}
                                        text={filterData.audience_occupations[key]}
                                        checked={checkedItems['audience_occupations']?.includes(value)}
                                        onChange={() => handleCheckboxChange('audience_occupations', value)}
                                        onClick={() => handleCheckboxChange('audience_occupations', value)}
                                    />
                                )
                            ))}
                        </MultiAccordion>

                    </div>
                )}


                {checkedItems && Object.values(checkedItems).length > 0 &&
                    < ClearButton onClick={clearFilters}>
                        Clear filters
                    </ClearButton>}
            </FilterMenu>
        </div >
    );
}

export default CampaignFilterMenu;
