/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledButtonLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px #D8DCDF;
  background:  ${(p)=>p.bg? p.bg : "#D8DCDF"};
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  width: ${(p)=>p.width? p.width : "40px"};
  height:  ${(p)=>p.height? p.height : "40px"};
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex;
  width: 100%
  height: 100%
`

const RoundButtonLink = ({
	Icon,
  bg,
  width,
  height,
	onClick,
}) => {
	return (
		<StyledButtonLink
      bg={bg}
      height={height} 
      width={width}
      onClick={onClick}
      className='round-button'
		>
			<IconWrapper onClick={() => console.log(':)))')}>{Icon || null}</IconWrapper>
		</StyledButtonLink>
	);
};

export default RoundButtonLink;
