import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { HeadlineL } from "../../../Components/Text";
import SubheadingXXXS from "../../../Components/Text/SubheadingXXXS";
import SmallLeftArrow from "../../../Icons/SmallLeftArrow";

const Button = styled.button`
  margin-bottom: ${(props) => props.marginBottom || "0px"}; 
  z-index: 2;
  background-color: var(--p500);
  border: none;
  display:  flex;
  cursor: pointer;
  box-shadow: 0px 1px 0px #982e36;
  align-self: flex-start;
  justify-content: center;
  padding: ${(props) => props.padding || "6px 10px"}; 
  transition: transform 0.2s ease-in-out;

  &:hover {
    background-color: #982e36;
  }
`;

const ButtonBackground = styled.div`
  border-radius: 4px;
  display: inline-block;

  .sub_heading_xxs {
    color: white;
  }
`;

const Container = styled.div`
  position: relative;
  background-color: var(--b-b400);
  text-align: center;
  height: 100%;
  color: var(--g-s50);
  display: flex;
  justify-content: center;
  align-items: center;

  .headline_l {
    position: relative;  
    z-index: 2; 
    color: var(--g-s50); 
  }

  @media only screen and (min-width: 1024px){
    background-image: none;
  }

  @media (max-width: 1200px) {
    background-color: transparent;
  }
`;

const LayoutContainer = styled.div`
  max-width: var(--max-width);
  width: 100%;

  @media (max-width: 1200px) {
    padding: 0 16px; 
  }
`

const ImageContainer = styled.div`
    display: flex;
    justify-content: right;
    position: relative;
    right: 0;
    bottom: 107px;
    z-index: 10;
    cursor: none;
    pointer: none;

    .image-container {
      height: 162px;
      width: 253px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      cursor: auto;
      // border-radius: 4px 4px 0px 0px;
      margin-top: 20px;
  }

    @media only screen and (max-width: 1024px) {
      display: none;
    }
`;

const Image = styled.image`
    position: absolute;
    cursor: none;
    pointer: none;
`;

const HeadlineContainer = styled.div`
  height: 100%;
  width: 70vw; 
  padding: 16px 0;

  @media only screen and (max-width: 1024px) {
    width: 80vw;
  }
`

const MarketplaceHeader = ({ buttonTitle, headerTitle, headerImage}) => {
  const navigate = useNavigate();
  
  return (
    <Container >
      <LayoutContainer>
        <Button onClick={() => { navigate('/meetupspot') }} >
          <ButtonBackground>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <SmallLeftArrow />
            <div style={{ marginLeft: '8px' }}>
                <SubheadingXXXS mt={'0px'}>{buttonTitle}</SubheadingXXXS>
              </div>
            </div>
         </ButtonBackground>
        </Button>
        <HeadlineContainer>
          {headerTitle && headerTitle.length > 60 ? <HeadlineL mt={"8px"} >{headerTitle.substring(0, 60)}...</HeadlineL> :
          <HeadlineL mt={"8px"} >{headerTitle}</HeadlineL>}
        </HeadlineContainer>
          <ImageContainer>
            <Image>
              <div className='image-container'
                style={{ backgroundImage: `url(${headerImage})` }}
              />
            </Image>
          </ImageContainer>
      </LayoutContainer>
    </Container>
  );
}

export default MarketplaceHeader;
