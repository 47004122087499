import styled from 'styled-components';

import WateringCan from '../../Icons/WateringCan';
import SubheadingL from '../Text/SubheadingL';
import { Button } from '../UI';

const Card = styled.div`
  width: 80vw;
  max-width: 382px;
  max-height: 352px;
  margin: 1rem;
  background-color: var(--b-b100);
  border: 1.5px solid var(--b-b200);
  box-shadow: 1px 1px var(--b-b200);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  .img {
    width 115px;
  }
`

const Wrapper = styled.div`
  margin: 1rem;

  .sub_heading_l {
    text-align: center;
  }
`
const NoData = ({
    text,
    actionLabel,
    onAction,
    secondActionLabel,
    SecondOnAction
}) => {

    return (
        <div>
            <Card>
                <ImageContainer>
                    <WateringCan />
                </ImageContainer>
                <Wrapper>
                    <SubheadingL mt={"0px"}>{text}</SubheadingL>
                    {onAction && actionLabel &&
                        <Button fwidth primary text={actionLabel} onClick={onAction} />
                    }
                    {SecondOnAction
                        && secondActionLabel &&
                        <Button mt={"8px"} fwidth primary text={secondActionLabel} onClick={SecondOnAction
                        } />
                    }
                </Wrapper>
            </Card>
        </div>
    )
}

export default NoData