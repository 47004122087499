
const useMultiCheckboxHandler = (formCtx, clearErrorsCallback) => {

  const handleMultiCheckboxChange = (question, key, value, optionType, deleteFromForm = false) => {
    let newSelectedOptionsDict = { ...formCtx.formData };

    if (!newSelectedOptionsDict[question.name]) {
      newSelectedOptionsDict[question.name] = {};
    }

    if (optionType === 'input') {
      // if (!value) {
      //   delete newSelectedOptionsDict[question?.name][key]
      // } else {
        newSelectedOptionsDict[question?.name][key] = value
      // }
    } else if (optionType === 'multi-input') {

      if (value[key].length === 0) {
        delete newSelectedOptionsDict[question?.name][key]
      } else {
        newSelectedOptionsDict[question?.name] = value;
      }

    } else if (optionType === 'default') {
      /** 
       * Je hebt een chckboxlist met subcheckboxes, daar zitten default checkboxes in
       * Het grote verschil tussen een lijst die subcheckboxes en defaultboxes bevat en een
       * lijst die alleen defaultboxes bevat, is dat de lijst met alleen default boxes een array is.
       * De andere is een dictionary. We moeten dus eerst kijken uit wat voor lijst de default checkbox komt
       * om het correct op te slaan. 
       */
      if (Array.isArray(newSelectedOptionsDict[question.name])) {
        // De defaultcheckbox komt uit een lijst met alleen default checkboxes
        updateDefaultCheckboxInList(newSelectedOptionsDict, question, key, value)
        //updateSubCheckboxData(newSelectedOptionsDict, question.name, key, value);
      } else {
        //'De defaultcheckbox komt uit een lijst met default checkboxes en subcheckboxes
        updateDefaultCheckboxInDictionary(newSelectedOptionsDict, question, key, value)
      }

    } else {
      updateSubCheckboxData(newSelectedOptionsDict, question, key, value)
    }

    if (deleteFromForm) {
      delete newSelectedOptionsDict[question?.name][key]
    }

    formCtx.createFormData(newSelectedOptionsDict)

    clearErrorsCallback(question.name);
  };

  /**
   * Deze functie voegt aan een lijst een waarde toe van de checkbox die je hebt aangeklikt.
   *
   * @param {object} newSelectedOptionsDict - De dictionary die alle waarden van het gehele formulier bevat.
   * @param {object} question - Het vraagobject waarop een antwoord moet worden gegeven. Het bevat een 'name'-variabele
   * die wordt gebruikt om de sleutel in 'newSelectedOptionsDict' te bepalen. Met deze sleutel moet het worden opgestuurd
   * naar de database
   * @param {any} value - De waarde die aan de lijst moet worden toegevoegd of uit de lijst moet worden verwijderd.
   * Deze waarde is de waarde die de gebruiker invult op de vraag.
   */
  const updateDefaultCheckboxInList = (newSelectedOptionsDict, question, key, value) => {

    // Controleer of de lijst bestaat, zo niet, maak een lege lijst aan.
    if (!newSelectedOptionsDict[question.name]) {
      newSelectedOptionsDict[question.name] = [];
    }
    if (!newSelectedOptionsDict?.[question.name]?.includes(value)) {
      // Voeg de waarde toe aan de lijst als deze nog niet aanwezig is.
      newSelectedOptionsDict[question.name].push(value);
    } else {
      // Verwijder de waarde uit de lijst als deze al aanwezig is.
      const questionName = question.name;
      const valueToRemove = value;

      newSelectedOptionsDict[questionName] = newSelectedOptionsDict[questionName].filter(item => item !== valueToRemove);
    }
  }

  /**
   *  * Deze functie voegt het value van de checkbox toe aan een dictionary. Het gaat hier om een checkbox in de 
   * bovenste laag, dus geen subcheckbox
   *
   * @param {object} newSelectedOptionsDict - De dictionary die alle waarden van het gehele formulier bevat.
   * @param {object} question - Het vraagobject waarop een antwoord moet worden gegeven. Het bevat een 'name'-variabele
   * die wordt gebruikt om de sleutel in 'newSelectedOptionsDict' te bepalen. Met deze sleutel moet het worden opgestuurd
   * naar de database
   *  @param {any} key - Dit is de key van het antwoord dat wordt aangeklikt
   * @param {any} value - De waarde die aan de lijst moet worden toegevoegd of uit de lijst moet worden verwijderd.
   * Deze waarde is de waarde die de gebruiker invult op de vraag.
   */
  const updateDefaultCheckboxInDictionary = (newSelectedOptionsDict, question, key, value) => {
    const checkboxDictionary = newSelectedOptionsDict[question.name]

    /*TODO: heel soms is de key fout, maar ik weet niet aan wat dat ligt*/

    if (checkboxDictionary[key] === value) {
      delete checkboxDictionary[key]
    } else {
      checkboxDictionary[key] = value;
    }

  }

  /**
   *  * Deze functie voegt het value van de checkbox toe aan een dictionary. Het gaat hier om een subCheckbox
   *
   * @param {object} newSelectedOptionsDict - De dictionary die alle waarden van het gehele formulier bevat.
   * @param {object} question - Het vraagobject waarop een antwoord moet worden gegeven. Het bevat een 'name'-variabele
   * die wordt gebruikt om de sleutel in 'newSelectedOptionsDict' te bepalen. Met deze sleutel moet het worden opgestuurd
   * naar de database
   *  @param {any} key - Dit is de key van het antwoord dat wordt aangeklikt
   * @param {any} value - De waarde die aan de lijst moet worden toegevoegd of uit de lijst moet worden verwijderd.
   * Deze waarde is de waarde die de gebruiker invult op de vraag.
   */
  const updateSubCheckboxData = (newSelectedOptionsDict, question, key, value) => {
    if (!newSelectedOptionsDict[question.name][key]) {
      newSelectedOptionsDict[question.name][key] = [];
    }
    
    const checkboxDictionary = newSelectedOptionsDict[question.name]

    if (!checkboxDictionary[key]?.includes(value)) {
      checkboxDictionary[key].push(value);
    } else {
      checkboxDictionary[key] = checkboxDictionary[key].filter(option => option !== value);

      if (checkboxDictionary[key].length === 0) {
        delete checkboxDictionary[key];
      }
    }
  };

  return { handleMultiCheckboxChange };
};



export default useMultiCheckboxHandler;
