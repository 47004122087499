export const findNamesByKeys = (userData, data) => {
    const dataArray = Array.isArray(userData)
        ? userData
        : [userData];

    const names = dataArray.map(key => {
        const foundItem = data.find(item => item.id === key);
        return foundItem ? foundItem.name : 'Not Found';
    });
    return names;
};

export function formatToMonthYear(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short' };
    return date.toLocaleDateString('en-US', options);
  }
  