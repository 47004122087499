import React from "react";

const Audience = ()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      fill="none"
      viewBox="0 0 15 10"
    >
      <path
        fill="#031B2A"
        d="M7.477 8a2.42 2.42 0 002.437-2.438 2.45 2.45 0 00-2.437-2.437c-1.336 0-2.415 1.102-2.415 2.438A2.401 2.401 0 007.478 8zm0-3.75c.726 0 1.312.61 1.312 1.313a1.31 1.31 0 01-1.312 1.312 1.326 1.326 0 01-1.313-1.313c0-.703.61-1.312 1.313-1.312zm4.523 0c1.031 0 1.875-.82 1.875-1.875A1.88 1.88 0 0012 .5a1.866 1.866 0 00-1.875 1.875c0 1.055.82 1.875 1.875 1.875zm-3.352 4.5h-2.32C4.477 8.75 3 10.156 3 11.89c0 .352.281.61.656.61h7.664c.375 0 .68-.258.68-.61 0-1.734-1.5-3.14-3.352-3.14zm-4.476 2.625c.234-.844 1.101-1.5 2.133-1.5h2.343c1.032 0 1.899.656 2.157 1.5H4.172zM12.914 5h-1.43c-.304 0-.586.094-.843.21 0 .118.023.235.023.353 0 .796-.281 1.523-.773 2.062h4.687A.42.42 0 0015 7.203C15 5.984 14.062 5 12.914 5zm-8.625.563c0-.118.024-.235.047-.352C4.078 5.07 3.796 5 3.492 5h-1.43C.915 5 0 5.984 0 7.203c0 .234.164.422.398.422h4.688a3.054 3.054 0 01-.797-2.063zM3 4.25c1.031 0 1.875-.82 1.875-1.875A1.88 1.88 0 003 .5a1.866 1.866 0 00-1.875 1.875c0 1.055.82 1.875 1.875 1.875z"
      ></path>
    </svg>
  );
}

export default Audience;
