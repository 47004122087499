

// Transform the data that you want to pass to the Select
export const transformToSelectData = (data, labelField, valueField) => {
  if (!data) return;

  return data.map((item) => ({
    label: item[labelField],
    value: item[valueField],
  }));
};