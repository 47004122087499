import React from 'react'

const CloseButton = (props) => {
    return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#D8DCDF"/>
      <path d="M24.75 23.7188C25.0312 24.0312 25.0312 24.5 24.75 24.7812C24.4375 25.0938 23.9688 25.0938 23.6875 24.7812L20 21.0625L16.2812 24.7812C15.9688 25.0938 15.5 25.0938 15.2188 24.7812C14.9062 24.5 14.9062 24.0312 15.2188 23.7188L18.9375 20L15.2188 16.2812C14.9062 15.9688 14.9062 15.5 15.2188 15.2188C15.5 14.9062 15.9688 14.9062 16.25 15.2188L20 18.9688L23.7188 15.25C24 14.9375 24.4688 14.9375 24.75 15.25C25.0625 15.5312 25.0625 16 24.75 16.3125L21.0312 20L24.75 23.7188Z" fill="#364E5D"/>
    </svg>
    );
  }
  
  export default CloseButton;