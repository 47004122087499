import { configureStore } from "@reduxjs/toolkit";
import onboardingSlice from "./features/onboardingSlice";
import authSlice from "./features/authSlice";
import profileSlice from "./features/profileSlice";
import languagesSlice from "./features/languagesSlice";
import countriesSlice from "./features/countriesSlice";
import companyCampaignSlice from "./features/companyCampaignSlice";
import marketplaceSlice from "./features/marketplaceSlice";
import notificationsSlice from "./features/notificationsSlice";
import InfluencersSlice from "./features/InfluencersSlice";

export const store = configureStore({
	reducer: {
		auth: authSlice,
		onboarding: onboardingSlice,
		profile: profileSlice,
		languages: languagesSlice,
		countries: countriesSlice,
		companyCampaigns: companyCampaignSlice,
		marketplace: marketplaceSlice,
		notifications: notificationsSlice,
		influencers: InfluencersSlice
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }),
});
