import React from "react";

const WifiSlash = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="17"
      viewBox="0 0 22 17"
      {...props}
    >
      <path
        fill={props.fill}
        d="M4.71875 9.96875C4.40625 10.25 4.40625 10.75 4.6875 11.0312C4.96875 11.3438 5.46875 11.3438 5.75 11.0625C6.40625 10.4062 7.21875 9.90625 8.0625 9.5625L6.75 8.53125C6 8.90625 5.3125 9.40625 4.71875 9.96875ZM1.21875 5.71875C0.90625 6 0.90625 6.46875 1.1875 6.78125C1.46875 7.09375 1.9375 7.09375 2.25 6.8125C2.59375 6.46875 2.96875 6.1875 3.375 5.90625L2.15625 4.9375C1.8125 5.1875 1.5 5.4375 1.21875 5.71875ZM10.9688 12.5C10.0312 12.5 9.25 13.3125 9.25 14.25C9.25 15.2188 10.0312 16 10.9688 16C11.9375 16 12.75 15.2188 12.75 14.25C12.75 13.3125 11.9375 12.5 10.9688 12.5ZM12.3438 9.125C13.8125 9.375 15.1562 10.0312 16.2188 11.0625C16.375 11.1875 16.5312 11.25 16.7188 11.25C16.9062 11.25 17.0938 11.1875 17.25 11.0312C17.5312 10.75 17.5312 10.25 17.2188 9.96875C15.5938 8.375 13.375 7.5 11 7.5C10.7812 7.5 10.5625 7.53125 10.3438 7.5625L6.28125 4.375C7.75 3.8125 9.34375 3.5 11 3.5C14.3438 3.5 17.5312 4.71875 19.7188 6.8125C19.875 6.9375 20.0625 7 20.2188 7C20.4062 7 20.5938 6.9375 20.75 6.78125C21.0312 6.46875 21.0312 6 20.7188 5.71875C18.2812 3.375 14.7188 2 10.9688 2C8.84375 2 6.75 2.46875 4.90625 3.3125L2.1875 1.1875C1.875 0.90625 1.40625 0.96875 1.15625 1.3125C0.875 1.625 0.9375 2.09375 1.28125 2.34375L19.7812 16.8438C19.9062 16.9688 20.0625 17 20.2188 17C20.4375 17 20.6562 16.9062 20.7812 16.7188C21.0625 16.4062 21 15.9375 20.6562 15.6875L12.3438 9.125Z"
      ></path>
    </svg>
  );
}

export default WifiSlash;