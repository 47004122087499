import styled from "styled-components";
import backgroundImageDefault from '../../Images/dummy-data.png'

const HeaderContainer = styled.div`
  position: relative;
  background-color: var(--b-b400);
  background-image: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : "var(--b-b400)"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 24px 0 ;
  margin-top: 68px;

  @media only screen and (min-width: 1024px) {
    background-image: none;
  }
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8; /* Adjust opacity as needed */
  background-color: #364E5D;
  z-index: 1;
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 2;
`;

const PageHeader = ({ children, backgroundImage }) => {

  return (
    <HeaderContainer
      backgroundImage={backgroundImage ?
        backgroundImage :
        backgroundImageDefault}>
      <BackgroundOverlay />
      <ContentContainer>
        {children}
      </ContentContainer>
    </HeaderContainer>
  );
}

export default PageHeader;
