import React from "react";

const DropzoneIcon = () => {
	return (
		<svg
			width="50"
			height="50"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.1875 23.75L24.4375 18.25C24.5938 18.0938 24.7812 18.0312 25 18.0312C25.1875 18.0312 25.375 18.0938 25.5312 18.25L30.7812 23.75C31.0625 24.0625 31.0625 24.5312 30.75 24.8125C30.4688 25.0938 29.9688 25.0938 29.6875 24.7812L25.75 20.625V31.25C25.75 31.6875 25.4062 32 25 32C24.625 32 24.25 31.6875 24.25 31.25V20.625L20.2812 24.7812C20 25.0938 19.5 25.0938 19.2188 24.8125C18.9062 24.5312 18.9062 24.0312 19.1875 23.75Z"
				fill="#FF717C"
			/>
			<rect x="0.5" y="0.5" width="49" height="49" rx="0.5" stroke="#FF4E5B" />
		</svg>
	);
};

export default DropzoneIcon;
