import styled from "styled-components";
import { useForm } from 'react-hook-form';

import { HeadlineL, ParagraphM, SubheadingS } from "../Text";
import wateringCan from "../../Images/watering-can.png";
import laptop from "../../Images/laptop.png";
import { RoundButtonLink } from "../UI";
import Close from "../../Icons/Close";
import SubheadingM from "../Text/SubheadingM";
import apiClient from "../../services/api";
import Button from '../UI/Buttons/Button';
import FieldErrorMessage from "../Forms/FieldErrorMessage";

const BackrgroundColor = styled.div`
  min-height: 100vh;
  min-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: ${props => props.visible ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999;
  background-color: rgba(105, 123, 134, 0.8);
`;


const Container = styled.div`
  border: 1px solid;
  margin: 2rem;
  background-color: white;
  position: relative;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
`;

const Content = styled.div`
  margin: 1.5rem;
  position: relative;

  hr {
    border-top: 1px solid var(--g-s50);
    margin-top: 16px;
  }
`;


const Top = styled.div`
  display: flex;
  justify-content: space-between;

  .sub-heading_s {
    margin-bottom: 16px;
  }
`;

const Images = styled.div`
  display: flex;
`;

const Block = styled.div`
  background-color: var(--b-b50);
  
  .timeline {
    margin: 20px auto;
    padding: 20px;
  }
  
  .outer {
    border-left: 2px solid var(--b-b200);
  }
  
  .card {
    position: relative;
    margin: 0 0 20px 20px;
    color: gray;
    border-radius: 8px;
  }
  
  .info {
    display: flex;
    flex-direction: column;
  }

  h3{
    color: var(--b-b300);
    position: relative;
    font-family: 'Avenir LT Std';
    font-size: 18px;
    line-height: 150%;
    font-weight: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  
  .title1::before {
    content: "1";
    position: absolute;
    width: 28px;
    height: 28px;
    background: var(--b-b300);
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    display: flex;
  }

  .title2::before {
    content: "2";
    position: absolute;
    width: 28px;
    height: 28px;
    background: var(--b-b300);
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    display: flex;
  }

  .title3::before {
    content: "3";
    position: absolute;
    width: 28px;
    height: 28px;
    background: var(--b-b300);
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    display: flex;
  }

  .title4::before {
    content: "4";
    position: absolute;
    width: 28px;
    height: 28px;
    background: var(--b-b300);
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    display: flex;
  }

  .title5::before {
    content: "5";
    position: absolute;
    width: 28px;
    height: 28px;
    background: var(--b-b300);
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    display: flex;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.textarea`
  width: 100%;
  min-height: 244px;
  border: 1px solid #e3e3e3;
  border-color: ${(p) => p.validationError ? "rgba(255, 113, 124, 1)" : "#e3e3e3"};
  border-radius: 4px;
  margin-top: 24px;
  padding: 12px 16px;
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  box-sizing: border-box;
  color: #2f2f2f;
`;


const StyledButtonContainer = styled.div`
    margin-top: 40px;
`
const HoorayPopUp = ({ handleButtonClick, isVisible, campaignId, setCampaignData, campaignData }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    apiClient.post(`/api/campaign/${campaignId}/pitch`, JSON.stringify(data)).then(() => {
      setCampaignData({ ...campaignData, ...{ is_pitched: true } })
    })
  };

  return (
    <BackrgroundColor visible={isVisible} >
      <Container >
        <div style={{ height: "auto", maxWidth: '800px' }}>
          {
            !campaignData?.is_pitched &&

            <Content>
              <Top>
                <Images>
                  <img src={wateringCan}></img>
                  <img src={laptop}></img>
                </Images>
                <RoundButtonLink
                  height={"40px"}
                  width={"40px"}
                  fill={"#FFF"}
                  onClick={handleButtonClick}
                  Icon={<Close fill={"#364E5D"}/>}
                />
              </Top>
              <HeadlineL mt={"16px"}>Motivation</HeadlineL>
              <SubheadingS mt={"16px"}>Thank you for your interest in pitching yourself as a creative for this project by advertisers.</SubheadingS>
              <ParagraphM>Tell us why you are the perfect candidate and have the skills and personality to promote the project by advertisers effectively, tailored to its needs and goals. Your passion for the topic and creativity in promoting the project by advertisers will be key. </ParagraphM>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  id="motivation"
                  name="motivation"
                  placeholder="Tell us why you're the perfect candidate..."
                  {...register("motivation", { required: true })}
                  validationError={errors.motivation}
                />
                {errors.motivation && (<FieldErrorMessage />)}
                <StyledButtonContainer>
                  <Button primary type={"submit"} text={"Send motivation"} fwidth />
                </StyledButtonContainer>
              </form>
            </Content>}
          {campaignData?.is_pitched &&
            <Content>
              <Top>
                <Images>
                  <img src={wateringCan}></img>
                  <img src={laptop}></img>
                </Images>
                <RoundButtonLink
                  height={"40px"}
                  width={"40px"}
                  fill={"#FFF"}
                  Icon={<Close fill={"#364E5D"}
                    onClick={handleButtonClick}
                  />
                  }
                />
              </Top>
              <HeadlineL>Hooray!</HeadlineL>
              <HeadlineL mt={"14px"}>Your Pitch is live!</HeadlineL>
              <SubheadingS>You just pitched on the project by advertisers {campaignData.title}</SubheadingS>
              <hr />
              <SubheadingM color={"#262626"}>What happens next?</SubheadingM>
              <Block>
                <div class="timeline">
                <div class="outer">
                <div class="card">
                  <div class="info">
                    <h3 class="title1">Pitch review</h3>
                    <ParagraphM>The advertiser/brand or creative will review your pitch. No adjustments? Then your pitch will be accepted, and you are good to go. Adjustments? In the chat, you meet up privately and you can start negotiating.</ParagraphM>
                  </div>
                </div>
                <div class="card">
                  <div class="info">
                    <h3 class="title2">Physical products</h3>
                    <ParagraphM>Are there physical products involved? myThursd will reach out to both you and the advertiser to get the products to your wholesaler or your address.</ParagraphM>
                  </div>
                </div>
                <div class="card">
                  <div class="info">
                    <h3 class="title3">Create content</h3>
                    <ParagraphM>It’s your turn! Start working and creating what is agreed upon. When finished, myThursd will review it. When approved by myThursd, the advertiser/brand or creative gets notice.</ParagraphM>
                  </div>
                </div>
                <div class="card">
                  <div class="info">
                    <h3 class="title4">Post / publish project by advertisers</h3>
                    <ParagraphM>Post or deliver the content that is approved at the date/time to the channels you agreed upon.</ParagraphM>
                  </div>
                </div>
                <div class="card">
                  <div class="info">
                    <h3 class="title5">Get paid</h3>
                    <ParagraphM>You will get paid for the project by myThursd within 30 days of delivery.</ParagraphM>
                  </div>
                </div>
              </div>
                </div>
              </Block>
              <StyledButtonContainer>
                <Button text={"Click here to pitch on more projects"} primary fwidth onClick={() => window.location.href = '/meetupspot'} />
              </StyledButtonContainer>
            </Content>}
        </div>
      </Container>
    </BackrgroundColor>
  );
};

export default HoorayPopUp;
