import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";

export const fetchLanguages = createAsyncThunk('fetch/languages', async () => {
  const response = await apiClient.get('/languages');
  return response.data;
});

const initialState = {
  languages: [],
  isLoading: false,
  errors: ''
};

const languagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {;
    //register influencer
    builder
      .addCase(fetchLanguages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLanguages.fulfilled, (state, action) => {

        if (action?.payload?.errors) {
          state.isLoading = false;
          state.isError = true;
          state.errors = action?.payload?.errors;
        } else {
            state.languages = action.payload
        }
      })
      .addCase(fetchLanguages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
    
  },
});

export default languagesSlice.reducer;
