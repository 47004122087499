import React from 'react'

const Minus = (props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="2"
        viewBox="0 0 9 2"
        {...props}
      >
        <path
          fill={props.fill}
          d="M8.563.75a.463.463 0 01-.47.469H.907c-.273 0-.469-.196-.469-.45 0-.273.196-.488.47-.488h7.187c.254 0 .469.215.469.469z"
        ></path>
      </svg>
    );
  }
  
  export default Minus;