import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Navbar from '../../Components/Common/Navbar/NavigationBar';
import HomeFilter from '../../Components/Common/Filter/HomeFilter';
import SearchBar from '../../Components/Common/Searchbar';
import HomeBody from '../../Components/HomePage/HomeBody';
import HomePageHeader from '../../Components/HomePage/HomePageHeader';
import useFilterBody from '../../app/hooks/useFiltering';
import useHomeFilterButtons from '../../app/hooks/useHomeFilterButtons';
import apiClient from '../../services/api';
import { updateCompanyCampaigns } from '../../app/features/companyCampaignSlice';
import CampaignFilterMenu from '../../Components/Filtering/CampaignFilterMenu';
import { queryStringToArray } from '../../Utils/utility-functions';

const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(searchParams.get("s") || "");
  const companyCampaigns = useSelector(state => state.companyCampaigns.companyCampaigns);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false)
  const { filterBody, setFilterBody } = useFilterBody();
  const { selectedFilterValue, setSelectedFilterValue } = useHomeFilterButtons();
  const tabValue = searchParams.get("tab") || "";
  
  useEffect(() => {
    const s = searchParams.get("s");
    const status = searchParams.get("status");
    const model = searchParams.get("model");
    const marketingGoals = searchParams.get("mg")
    const influencerOcc = searchParams.get("io")
    const advertiserOcc = searchParams.get("ao")

    let searchTerms = {};
    /* filter queries from searchbar*/
    if (s) {
      searchTerms = { ...searchTerms, query: s };
    }

     /* filter queries from filter tabs*/
    if(model){
      searchTerms = { ...searchTerms, model: model };
    }else{
      delete searchTerms.model;
    }

    if(status){
      searchTerms = { ...searchTerms, status: [status] };
    }else{
      delete searchTerms.status;
    }


    /* filter queries from filter menu*/
    if(marketingGoals){
      const marketingGoalsArr =  queryStringToArray(marketingGoals)
      searchTerms = { ...searchTerms, marketing_goals: marketingGoalsArr };
    }else{
      delete searchTerms.marketing_goals;
    }
    if(influencerOcc){
      const influencerOccArr =  queryStringToArray(influencerOcc)
      searchTerms = { ...searchTerms, influencer_occupations: influencerOccArr };
    }else{
      delete searchTerms.influencer_occupations;
    }
    if(advertiserOcc){
      const audOccArr =  queryStringToArray(advertiserOcc)
      searchTerms = { ...searchTerms, audience_occupations: audOccArr };
    }else{
      delete searchTerms.audience_occupations;
    }

    apiClient
      .post("/campaign/search", searchTerms)
      .then((searchResponse) => {
        dispatch(updateCompanyCampaigns(searchResponse.data.data.data))
        setIsLoading(false)
      });
  }, [searchParams]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);

    if (e.target.value === '') {
      setFilterBody({ ...filterBody, ...{ query: '' } });
      searchParams.delete('s');
      setSearchParams(searchParams);
    }
  }

  const refreshData = () => {
    apiClient.post("/campaign/search", filterBody)
      .then((searchResponse) => {
        dispatch(updateCompanyCampaigns(searchResponse.data.data.data))
        setIsLoading(false)
      });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
      searchParams.set('s', searchText)
      setSearchParams(searchParams)
      setFilterBody({ ...filterBody, ...{ query: searchText } });
  }

  const clearSearchBar = ()=>{
    setSearchText('')
    searchParams.delete('s');
    setSearchParams(searchParams);
  }

  return (
    <>
      <Navbar />
      <HomePageHeader />
      <SearchBar
        subheading={"Search projects"}
        onChange={handleSearch}
        value={searchText}
        handleSubmit={handleSubmit}
        onClear={clearSearchBar}
        placeholder={"Search for creatives..."}
      />
      <HomeFilter
        data={companyCampaigns}
        searchText={searchText}
        filterBody={filterBody}
        setFilterBody={setFilterBody}
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        //page={page}
        //setPage={setPage}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <CampaignFilterMenu />
      <HomeBody
        filterBody={filterBody}
        page={page}
        setPage={setPage}
        selectedFilterValue={tabValue}
        isLoading={isLoading}
        refreshData={refreshData}
      />
    </>
  )
};
export default Home;