/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components';

export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
	margin-top: ${(p) => (p.mt16 ? "16px" : p.mt24 ? "24px" : "0px")};

	a{
		text-decoration: none;
		width: 100%;
	}
`;
