import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "../../../Theme/theme";

// Styled Components for the Switch
const SwitchRoot = styled.div`
  display: inline-flex;
  height: 24px;
  width: 44px;
  align-items: center;
  border-radius: 9999px;
  border: 1px solid #e3e3e3;
  transition: background-color 0.2s;
  cursor: pointer;
  background-color: ${(props) =>
    props.checked ? theme.main : theme.gs50};
`;

const SwitchThumb = styled.div`
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 9999px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: transform 0.2s, background-color 0.2s;
  transform: ${(props) => (props.checked ? "translateX(20px)" : "translateX(0px)")};
  background-color: ${(props) =>
    props.checked ? theme.gs50 : theme.main};
`;

const Switch = React.forwardRef(({ checked, onCheckedChange }, ref) => {
  const handleToggle = () => {
    onCheckedChange(!checked);
  };

  return (
    <SwitchRoot checked={checked} onClick={handleToggle} ref={ref}>
      <SwitchThumb checked={checked} />
    </SwitchRoot>
  );
});
Switch.displayName = "Switch";

export { Switch };
