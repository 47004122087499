import React, { useEffect, useState } from 'react'

import { LayoutCenterTop } from '../../Components/Layout'
import { HeadingXL, ParagraphM, SubheadingS } from '../../Components/Text'
import { Button } from '../../Components/UI'
import RightArrow from '../../Icons/RightArrow'
import ErrorMessage from '../../Components/Text/ErrorMessage'
import MultiCheckbox from '../../Components/Common/Onboarding/MultiCheckbox'

export default function SurveyPage1({ goToNext, goToPrevious, question, data, totalQuestions, index, showMandatoryError, errorMessage }) {
  const [selectedOptions, setSelectedOptions] = useState({});

  useEffect(()=>{
    if(data?.[question.name]){
      setSelectedOptions(data?.[question.name])
    }
  },[])

  const handleCheckboxChange = (key, value, optionType, deleteFromForm = false) => {
    let answers = { ...selectedOptions };
    if (optionType === 'input') {
      if (deleteFromForm) {
        delete answers[key]
      } else {
        if(question.multiple_answer){
          answers[key] = value
        }else{
          answers = {[key]: value}
        }
        
      }
    }else{
      if (answers[key] === value) {
        delete answers[key]
      } else {

        if(question.multiple_answer){
          answers[key] = value
        }else{
          answers = {[key]: value}
        }

      }
    }
    setSelectedOptions(answers)
  };


  return (
    <LayoutCenterTop>
      <HeadingXL mt="0px">{`Onboarding survey ${index}/${totalQuestions}`}</HeadingXL>
      {index === 1 && 
        (<ParagraphM>{`We would like to ask you ${totalQuestions} questions to get to know you, and how we can help you.`}</ParagraphM>)
      }
      <SubheadingS>{`${index}. ${question.label}`}</SubheadingS>
      {/* Link Questions */}
      {question?.options && question.fieldtype === 'link' &&
        question.options.map((option) => {
          return (
            <Button
              mt24
              fwidth
              mt="6px"
              padding={'16px'}
              text={option}
              textcolor="#042234"
              gap={'5px'}
              Icon={<RightArrow iconColor="#042234" />}
              onClick={() => goToNext({ [question.name]: option })}
            />
          );
        })}
      {/* Checkbox Questions */}
      {question?.options && question.fieldtype === 'checkbox' && (
        <div>

          <MultiCheckbox
            question={question}
            showLabel={false}
            onChange={handleCheckboxChange}
            answers={data}
            selectedValues={selectedOptions}
            />

          {showMandatoryError && <ErrorMessage message={errorMessage} />}
          {question.order === 3 ? 
              <Button
              mt24
              fwidth
              mt="24px"
              padding={'16px'}
              text={'Continue Onboarding'}
              primary
              Icon={<RightArrow />}
              onClick={() => goToNext({ [question.name]: selectedOptions })}
            />
            :
            <Button
              mt24
              fwidth
              mt="24px"
              padding={'16px'}
              text={'Next question'}
              primary
              Icon={<RightArrow />}
              onClick={() => goToNext({ [question.name]: selectedOptions })}
          />
        }
        </div>
      )}

      {index &&
        index > 1 &&
        <Button
          fwidth
          mt="24px"
          text={"Back to previous question"}
          textcolor={(p) => p.theme.main}
          fs14
          onClick={goToPrevious}
          padding={'10px'}
        />
      }
    </LayoutCenterTop>
  )
}
