import styled from "styled-components";
import { ParagraphM, SubheadingS, SubheadingXS } from "../../../Components/Text";

const Option = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
`;

const Bullet = styled.span`
  width: 15px;
  height: 15px;
  cursor: pointer;
  border: 1px solid ${(props) => (props.selected ? "#FF4E5B" : "#FF4E5B")};
  border-radius: 50%;
  background: ${(props) =>
    props.selected
      ? "radial-gradient(circle, white 35%, transparent 38%) #FF4E5B"
      : "transparent"};
  background-size: 100% 100%;
  background-position: center;
  margin-right: 10px;
`;



const Visibility = ({ title, details, selectedOption, onOptionClick, defaultOption }) => {

  const handleOptionClick = (option) => {
    if (selectedOption === option) {
      return;
    } else {
      onOptionClick(option);
    }
  };
  

  return (
    <>
      <SubheadingS>{title}</SubheadingS>
      <ParagraphM mt={"4px"} mb={"8px"} color={"#727272"}>
        {details}
      </ParagraphM>
      <div style={{ marginTop: '16px' }}>
        <Option
          onClick={() => handleOptionClick(defaultOption)}
          selected={selectedOption === defaultOption}
        >
          <Bullet selected={selectedOption === defaultOption} />
          <SubheadingXS>{defaultOption}</SubheadingXS>
        </Option>
        <Option
          onClick={() => handleOptionClick("Different date")}
          selected={selectedOption === "Different date"}
        >
          <Bullet selected={selectedOption === "Different date"} />
          <SubheadingXS>Different date </SubheadingXS>
        </Option>
      </div>
    </>
  );
};

export default Visibility;
