import React from "react";

const FieldErrorMessage = ({ text }) => {
	return (
		<span
			style={{
				display: "block",
				color: "#982336",
				fontFamily: "Avenir LT Std",
				fontSize: "10px",
				lineHeight: "14px",
				marginLeft: "2px",
			}}
		>
			{text ? text : "Field can’t be empty"}
		</span>
	);
};

export default FieldErrorMessage;
