import React from 'react'
import styled from 'styled-components';
import { CustomCheckbox, InputLabel } from '../../../Components/Forms';
import { ParagraphM } from '../../../Components/Text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 0px #E3E3E3;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid var(--g-s100);
  padding: 20px;
  margin: 24px 0;
  gap: 8px;
}`

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;    
`


export default function ProposeChangesCheckboxInput({
    label,
    subheading,
    mandatory,
    checked,
    handleCheckboxChange,
    Icon,
    input,
    setInput
}) {

    return (
        <Wrapper>
            <CheckboxContainer>
                <CustomCheckbox
                    checked={checked}
                    onChange={handleCheckboxChange}
                    onClick={handleCheckboxChange}
                    {...(mandatory ? { grey: true } : {})}
                    id={label}
                    text={label}
                    mt={"0"}

                />
                {<div style={{marginLeft: '8px'}}>{Icon}</div> || null}
            </CheckboxContainer>
            {checked &&
                <div style={{ marginLeft: '35px',  marginRight: '35px', width: '90%' }}>
                    <InputLabel
                        onChange={(e) => setInput(e.target.value)}
                        value={input}
                      
                    />
                </div>
            }
            {subheading && <ParagraphM ml={"35px"} mt="0px" color={"#727272"}>{subheading}</ParagraphM>}
        </Wrapper>
    )
}