import React from 'react';
import styled from 'styled-components';
import { AiOutlineInstagram } from "react-icons/ai";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { FaPinterest } from "react-icons/fa6";// Importeer de gewenste iconen
import { IoMdClose } from "react-icons/io";

import { ParagraphS, SubheadingXXS } from '../../../Components/Text';
import IconRound from '../../IconRound';

const ChannelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 1px;
  border: 1px solid var(--GS100, #E3E3E3);
  box-shadow: 0px 1px 0px 0px #E3E3E3;
  background: #FFF;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 42px;
  height: 88px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ImageContainer = styled.div`
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;

const RemoveButton = styled.button`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px 14px;
  background: var(--bb-500-secondary, #042234);
  color: #fff;
  text-align: left;
  cursor: pointer;
  
  &:active {
    opacity: 0.5;
 }
`
const SocialLink = styled.a`
  cursor: pointer;
  color: #000;

  &:hover {
      color: ${(p) => (p.theme.main)};
    }
`

export default function ChannelSocials({ user, platform, removeSocialChannel }) {
  const platformIcons = {
    facebook: <BiLogoFacebookCircle size={24} color="#031B2A" />,
    instagram: <AiOutlineInstagram size={24} color="#031B2A" />,
    pinterest: <FaPinterest size={24} color="#031B2A" />,
  };

  function formatStringForFacbook(inputString) {
  // Converteer de string naar kleine letters
  const lowercaseString = inputString.toLowerCase();

  // Vervang spaties door punten
  const formattedString = lowercaseString.replace(/ /g, '.');

  return formattedString;
}
  return (
    <ChannelContainer>
      <Content>
        <LeftContainer>
          <IconRound Icon={platformIcons[platform]} />
          <div>
            {/*platform === "instagram" && <ParagraphS color="#262626">{user.username}</ParagraphS>*/}

            {platform === "facebook" && user &&
              <SocialLink className="paragraph_s" href={`https://facebook.com/${formatStringForFacbook(user?.name)}`} target="_blank" >
                {user?.name}
              </SocialLink>
            
            }
            {platform === "pinterest" &&
              <>
                {user &&
                  <SocialLink className="paragraph_s" href={`https://nl.pinterest.com/${user?.nickname}`} target="_blank" >
                    {user?.account_type === 'BUSINESS' ? user?.business_name : user?.name}
                  </SocialLink>
                }
                <ParagraphS color="#959595">following count: {user.following_count}</ParagraphS>
              </>
            }
          </div>
        </LeftContainer>
        {platform !== "instagram" &&
          <ImageContainer>
            <img src={user.avatar} alt={`${user.name}'s avatar`} />
          </ImageContainer>}
      </Content>
      {removeSocialChannel && (
        <RemoveButton onClick={removeSocialChannel}>
          <SubheadingXXS>Remove this channel</SubheadingXXS>
          <IoMdClose size={14} />
        </RemoveButton>
      )}
    </ChannelContainer>
  );
}
