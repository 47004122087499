import React from 'react'

const Facebook = (props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || "16"} 
        height={props.height || "16"} 
        viewBox="0 0 16 16"
        className='facebook'
        {...props}
      >
        <path
          fill={props.fill}
          d="M15.75 8C15.75 3.71875 12.2812 0.25 8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 11.875 3.0625 15.0938 6.78125 15.6562V10.25H4.8125V8H6.78125V6.3125C6.78125 4.375 7.9375 3.28125 9.6875 3.28125C10.5625 3.28125 11.4375 3.4375 11.4375 3.4375V5.34375H10.4688C9.5 5.34375 9.1875 5.9375 9.1875 6.5625V8H11.3438L11 10.25H9.1875V15.6562C12.9062 15.0938 15.75 11.875 15.75 8Z"
        ></path>
      </svg>
    );
  }
  
  export default Facebook;