import React from "react";

const CalenderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="20"
      fill="none"
      viewBox="0 0 15 14"
    >
      <path
        fill="#031B2A"
        d="M4.75 2h4.5V.75A.74.74 0 0110 0a.76.76 0 01.75.75V2H12c1.094 0 2 .906 2 2v10c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2V4c0-1.094.875-2 2-2h1.25V.75A.74.74 0 014 0a.76.76 0 01.75.75V2zM1.5 14c0 .281.219.5.5.5h10c.25 0 .5-.219.5-.5V6h-11v8z"
      ></path>
    </svg>
  );
}

export default CalenderIcon;
