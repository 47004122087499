/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components';

/* -------------------------- Internal Dependencies ------------------------- */
import { ParagraphM, SubheadingS }  from '../../Text';
import { FlexContainer } from '../../Layout';

/* -------------------------- Internal SVG Dependencies ------------------------- */
import InfoCircle from '../../../Icons/InfoCircle'

const StyledWarningMessageWrapper = styled.div`
  border-bottom: 1px solid #F1F1F1;
  padding-bottom: 24px;
`
const StyledWarningMessage = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    background: #EBEEEF;
    border: 1px solid #697B86;
    border-radius: 1px;
`

const WarningMessage = ({warningTitle, warningMessage}) => {
  return (
    <StyledWarningMessageWrapper>
    <StyledWarningMessage>
        <FlexContainer alignItems="center" gap8>
          <InfoCircle />
          <SubheadingS mt="0px">{warningTitle}</SubheadingS>
        </FlexContainer>
        <ParagraphM noMargin>{warningMessage}</ParagraphM>
    </StyledWarningMessage>
    </StyledWarningMessageWrapper>
  )
}

export default WarningMessage