import React from "react";

function ArrowButton() {
  return (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#D8DCDF"/>
    <path d="M21.4375 26.5312L15.7188 20.5625C15.5625 20.375 15.5 20.1875 15.5 20C15.5 19.8438 15.5625 19.6562 15.6875 19.5L21.4062 13.5312C21.6875 13.2188 22.1875 13.2188 22.4688 13.5C22.7812 13.7812 22.7812 14.25 22.5 14.5625L17.2812 20L22.5312 25.5C22.8125 25.7812 22.8125 26.2812 22.5 26.5625C22.2188 26.8438 21.7188 26.8438 21.4375 26.5312Z" fill="#364E5D"/>
  </svg>

  );
}

export default ArrowButton;
