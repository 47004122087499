import React, { useState } from "react";
import styled from "styled-components";
import { ParagraphM, SubheadingXS, SubheadingXXS } from "../../Text";
import ArrowDownSmall from "../../../Icons/ArrowDownSmall";

const ArrowContainer = styled.div`
  transform: ${(p) => (p.isShortened ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.15s ease-in-out;
`;


const Biography = ({ text }) => {
  const [readMore, setReadMore] = useState(false);

  return (
    text && (
      <div>
        <SubheadingXS mt={"16px"}>Biography</SubheadingXS>
        {text && text.length > 200 ? (
          <>
            {readMore ? (
              <ParagraphM>{text}</ParagraphM>
            ) : (
              
                <ParagraphM>{text.substring(0, 200)}...</ParagraphM>
            )}
            <div
              onClick={() => setReadMore(!readMore)}
              style={{ marginTop: "4px", cursor: "pointer" }}
            >
              <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                <SubheadingXXS color={"#FF4E5B"}>
                  {readMore ? "Hide biography" : "Show full biography"}
                </SubheadingXXS>
                <ArrowContainer isShortened={readMore}>
                  <ArrowDownSmall />
                </ArrowContainer>
              </div>
            </div>
          </>
        ) : (
          <ParagraphM>{text}</ParagraphM>
        )}
      </div>
    )
  );
};

export default Biography;
