import React from 'react'

import { ParagraphM, SubheadingS } from '../../Text';
import { CustomCheckbox } from '../../Forms';
import CheckboxWithInput from '../Onboarding/CheckboxWithInput';
import { isValueInDictionary } from '../../../Utils/utility-functions';

export default function MultiCheckbox({ question, onChange, selectedValues, showLabel = true }) {
    return (
        <div>

            <React.Fragment>
                {showLabel && <SubheadingS>{question?.label}</SubheadingS>}
                {showLabel && <ParagraphM>{question?.sublabel}</ParagraphM>}

                {Object.entries(question.options).map(([key, value], index) => {
                    const optionType = value === 'input' ? 'input' : 'default';

                    if (optionType === 'default') {
                        return (
                            <CustomCheckbox
                                id={`${index}_${value}`}
                                key={key}
                                checked={isValueInDictionary(selectedValues, value) ? true : false}
                                onChange={() => onChange(key, value, optionType)}
                                onClick={() => onChange(key, value, optionType)}
                                text={value}
                            />
                    
                        );
                    } else {
                        return (
                            <CheckboxWithInput
                                title={key}
                                onChange={onChange}
                                onClick={onChange}
                            />
                        );
                    }
                })}
            </React.Fragment>

        </div>
    );
}
