import styled from "styled-components";
import { useSelector } from "react-redux";
import { AiOutlineInstagram } from "react-icons/ai";
import { BiLogoFacebookCircle, BiWifiOff } from "react-icons/bi";
import { FaPinterest } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { PiGlobe } from "react-icons/pi";

import Modal from "../../app/modals/Modal";
import PitchOption from "../../Components/Common/Chat/PitchOption";
import useSocialsModal from "../../app/hooks/useSocialsModal";
import apiClient from "../../services/api";
import T from "../../Icons/T";

const Line = styled.div`
  border-bottom: 1px solid #E0E0E0;
`
const SocialsModal = () => {
    const socialModal = useSocialsModal()
    const user = useSelector((state) => state.profile.profile);
    const navigate = useNavigate();

    const navigateTo = (link) => {
        socialModal.onClose()
        navigate(link)
    }

    const handleSocials = (social) => {

        apiClient.get(`social/${social}/init`).then((resp) => {
            const code = resp.data.data.code;

            window.location.href = `${process.env.REACT_APP_SOCIAL_BASE_URL}/social/${social}/connect/${code}`
        })
    };

    const bodyContent = (

        <div>

            <>
                <PitchOption
                    title="Add your Facebook account "
                    Icon={<BiLogoFacebookCircle size={18} color={"#031B2A"} />}
                    onClick={() => handleSocials('facebook')}
                />
                <Line />
            </>
            <PitchOption
                title="Add your Instagram account"
                Icon={<AiOutlineInstagram size={18} color={"#031B2A"} />}
                onClick={() => navigateTo('/addinstagram')}
            />

            <Line />
            <PitchOption
                title="Add your Pinterest account"
                Icon={<FaPinterest size={18} color={"#031B2A"} />}
                onClick={() => handleSocials('pinterest')}
            />

            <Line />
            <PitchOption
                title="Add your website"
                Icon={<PiGlobe size={18} color={"#031B2A"} />}
                onClick={() => navigateTo('/addwebsite')}
            />
            <Line />
            <PitchOption
                title="Add offline channels"
                Icon={<BiWifiOff size={18} color={"#031B2A"} />}
                onClick={() => navigateTo('/addoffline')}
            />
            <Line />
            <PitchOption
                title="Add your Thursd account"
                Icon={<T height="15px" width="15px"/>}
                onClick={() => navigateTo('/addthursd')}
            />
        </div>
    );

    return (
        <Modal
            title={`Hello ${user?.data?.firstname},`}
            isOpen={socialModal.isOpen}
            onClose={socialModal.onClose}
            body={bodyContent}
        />);
}

export default SocialsModal;