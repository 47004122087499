/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { FaSliders } from "react-icons/fa6";
import { useNavigate,  useSearchParams } from "react-router-dom";

/* -------------------------- Internal Dependencies ------------------------- */
import Card from "../../Common/Card/Campaigns/Card";
import SearchBar from "../../Common/Searchbar";
import Navbar from "../../Common/Navbar/NavigationBar";
import HomeFilter from "../../Common/Filter/HomeFilter";
import { updateCompanyCampaigns } from "../../../app/features/companyCampaignSlice";
import { Container } from "../../Layout";
import MarketplaceCard from '../../Advertisers/Marketplace/Card'
import useEventOptionsModal from "../../../app/hooks/useEventOptionsModal";
import Loading from "../../Loading";
import CampaignHeaderInfluencer from "./CampaignPageHeaderInfluencer";
import { Button } from "../../UI";
import useFilterMenu from "../../../app/hooks/useFilterMenu";
import CampaignFilterMenu from "../../Filtering/CampaignFilterMenu";
import useFilterBody from "../../../app/hooks/useFiltering";
import useHomeFilterButtons from "../../../app/hooks/useHomeFilterButtons";
import apiClient from "../../../services/api";
import EventOptionsModal from "../../../app/modals/EventOptionsModal";
import NoData from "../../Common/NoData";
import { queryStringToArray } from "../../../Utils/utility-functions";

const Section = styled.div`
  background: var(--g-s50);
  min-height: 100vh;
`;

const StyledGrid = styled.div`
	padding-top: 24px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media (min-width: 780px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (min-width: 1004px) {
    grid-template-columns: repeat(3, 1fr);
  }
	`;

const FilterMenuButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  display: block;
  padding: 16px;
  z-index: 99;
  @media (min-width: 1200px) {
    display: none;
  }
`
const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media (min-width: 780px) {
	justify-content: flex-start;
  }
`

const ContainerCenter = styled.div`
  display: flex;
	padding-top: 8px;
  width: 100%;
  justify-content: center;

`
const InfluencerCampaigns = () => {
	const [isEventOptionsModalOpen, setIsEventOptionsModalOpen] = useState(false);
	const [selectedMarketplaceData, setSelectedMarketplaceData] = useState(null);
	const [clickedCardData, setClickedCardData] = useState(null);
	const [showNoData, setShowNoData] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [searchText, setSearchText] = useState("");
	const [page, setPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false)

	const navigate = useNavigate();
	const eventOptionmodal = useEventOptionsModal();
	const dispatch = useDispatch();
	const filterMenu = useFilterMenu();

	const companyCampaigns = useSelector(state => state.companyCampaigns.companyCampaigns);
	const searchTimer = useRef(null);

	const { filterBody, setFilterBody } = useFilterBody();
	const { selectedFilterValue, setSelectedFilterValue } = useHomeFilterButtons();

	const tabValue = searchParams.get("tab") || '';

	useEffect(() => {
		setIsLoading(true)

		const s = searchParams.get("s");
		const status = searchParams.get("status");
		const model = searchParams.get("model");
		const marketingGoals = searchParams.get("mg")
		const influencerOcc = searchParams.get("io")
		const advertiserOcc = searchParams.get("ao")

		let searchTerms = {};
		/* filter queries from searchbar*/
		if (s) {
			searchTerms = { ...searchTerms, query: s };
		}

		/* filter queries from filter tabs*/
		if (model) {
			searchTerms = { ...searchTerms, model: model };
		} else {
			delete searchTerms.model;
		}

		if (status) {
			searchTerms = { ...searchTerms, status: [status] };
		} else {
			delete searchTerms.status;
		}

		/* filter queries from filter menu*/
		if (marketingGoals) {
			const marketingGoalsArr = queryStringToArray(marketingGoals)
			searchTerms = { ...searchTerms, marketing_goals: marketingGoalsArr };
		} else {
			delete searchTerms.marketing_goals;
		}
		if (influencerOcc) {
			const influencerOccArr = queryStringToArray(influencerOcc)
			searchTerms = { ...searchTerms, influencer_occupations: influencerOccArr };
		} else {
			delete searchTerms.influencer_occupations;
		}
		if (advertiserOcc) {
			const audOccArr = queryStringToArray(advertiserOcc)
			searchTerms = { ...searchTerms, audience_occupations: audOccArr };
		} else {
			delete searchTerms.audience_occupations;
		}

		apiClient
			.post("/campaign/search", searchTerms)
			.then((searchResponse) => {
				dispatch(updateCompanyCampaigns(searchResponse.data.data.data))
				setIsLoading(false)
			});
	}, [searchParams]);


	const handleSearch = (e) => {
		setSearchText(e.target.value);

		if (e.target.value === '') {
			setFilterBody({ ...filterBody, ...{ query: '' } });
			searchParams.delete('s');
			setSearchParams(searchParams);
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault();


		searchParams.set('s', searchText)
		setSearchParams(searchParams)
		setFilterBody({ ...filterBody, ...{ query: searchText } });

	}

	// Refresh data 
	const refreshData = () => {
		apiClient.post("/campaign/search", filterBody)
			.then((searchResponse) => {
				dispatch(updateCompanyCampaigns(searchResponse.data.data.data))
				setIsLoading(false)
			});
	}

	const MemoizedCard = React.memo(Card);

	const handleOptionsClick = (marketplaceData) => {
		setSelectedMarketplaceData(marketplaceData);
		setIsEventOptionsModalOpen(true);
		eventOptionmodal.onOpen();
	};

	const handleCardClick = (marketplaceData) => {
		setClickedCardData(marketplaceData);
	};

	const clearSearchBar = () => {
		setSearchText('')
		searchParams.delete('s');
		setSearchParams(searchParams);
	}


	return (
		<Section>
			<Navbar />
			<CampaignHeaderInfluencer />
			<SearchBar
				subheading={"Search projects"}
				onChange={handleSearch}
				handleSubmit={handleSubmit}
				value={searchText}
				placeholder={'Search for projects...'}
				onClear={clearSearchBar}
			/>
			<HomeFilter
				data={companyCampaigns}
				searchText={searchText}
				selectedFilterValue={selectedFilterValue}
				setSelectedFilterValue={setSelectedFilterValue}
				//page={page}
				//setPage={setPage}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
			/>
			<CampaignFilterMenu />

			<Container>
				{isLoading ? (
					<Loading height={'40vh'} />
				) : companyCampaigns && companyCampaigns?.length > 0 ? (
					<Flex>
						<StyledGrid>
							{companyCampaigns.map((data) =>
								data.model === 'marketplace' ? (
									<div style={{ display: 'flex' }} onClick={() => handleCardClick(data)} key={data.id}>
										<MarketplaceCard
											key={data.id}
											marketplaceData={data}
											openEventOptionsModal={() => { }}
											onOptionsClick={handleOptionsClick}
											canceled={selectedFilterValue === "Cancelled"}
										/>
									</div>
								) : (
									(() => {
										const currentDate = new Date();
										const pitchDeadline = new Date(data.pitch_deadline);

										return (
											<MemoizedCard
												data={data}
												key={data.id}
												searchText={searchText}
												removePitchButton={
													selectedFilterValue === 'Cancelled' ||
													selectedFilterValue === 'Finished' ||
													currentDate > pitchDeadline
												}
											/>
										);
									})()
								)
							)}
						</StyledGrid>
					</Flex>
				) : (
					<ContainerCenter>
						{ isLoading ? null : (
							<div>
								{tabValue === 'Projects by creatives' ? (
							<NoData
								text="There are no active projects by creatives"
								actionLabel="Create a project"
								onAction={() => { navigate('/projectsbycreatives/idea') }}
							/>
						) : tabValue === 'Projects by advertisers' ? (
							<NoData text="There are no projects by advertisers" />
						) : tabValue === 'All' ? (
							<NoData
								text="There are no active projects"
								actionLabel="Create a project"
								onAction={() => { navigate('/projectsbycreatives/idea') }}
							/>
						) : tabValue === 'My projects' ? (
							<NoData
								text="You don't have any projects"
								actionLabel="Create a project"
								onAction={() => { navigate('/projectsbycreatives/idea') }}
							/>
						) : (
							<NoData text={`There are no ${tabValue.toLowerCase()} projects`} />
						)
						}
							</div>
						)}
					</ContainerCenter>
				)}
				{tabValue &&
					tabValue === "Projects by advertisers" &&
					<FilterMenuButtonWrapper>
						<Button
							primary
							padding={"6px 10px"}
							text="Filter"
							textSize={"12px"}
							width={"150px"}
							mt={"0px"}
							onClick={filterMenu.isOpen ?
								() => filterMenu.onClose() :
								() => filterMenu.onOpen()}
							Icon={<FaSliders />}
						/>
					</FilterMenuButtonWrapper>}
				{isEventOptionsModalOpen && (
					<EventOptionsModal
						onClick={eventOptionmodal.onOpen}
						clickedCardData={clickedCardData}
						refreshData={refreshData}
					/>
				)}
			</Container>
		</Section>
	);
};

export default InfluencerCampaigns;
