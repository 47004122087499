/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";
import React from "react";

/* -------------------------- Internal Dependencies ------------------------- */
import { useNavigate } from "react-router-dom";
import { HeadlineL, ParagraphM, ParagraphS, SubheadingS } from "../../../Components/Text";
import StatusCampaign from "../../Common/Card/Campaigns/StatusCampaign";
import PitchInformation from "../../Common/Card/Campaigns/PitchInformation";
import { Button } from "../../../Components/UI";
import Trash from "../../../Icons/Trash";
import Microphone from "../../../Icons/Microphone";
import Megaphone from "../../../Icons/Megaphone";
import apiClient from "../../../services/api";
import { useSelector } from "react-redux";
import { truncateText } from "../../../Utils/utility-functions";
import CampaignInformation from "../../Common/Card/Campaigns/CampaignInformation";
import useManageProjectModal from "../../../app/hooks/useManageProjectModal";
import ManageProjectModal from "../../../app/modals/ManageProjectModal";

const StyledWrapper = styled.div`
border-radius: 4px;
background: white;
width: 100%;
min-width:var(--min-width-card);
max-width: var(--max-width-card);
max-height: var(--max-height-card);
display: flex;
flex-direction: column;
cursor: pointer;
margin-bottom: 1rem;
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02);
transition: transform 0.2s;

@media (min-wdith: 768px){
    margin-bottom: 0rem;
}

&:hover{
    .card-image{
        transform: scale(1.1);
    }
 }

}`;

const TopContainer = styled.div`
  margin: 0 1rem;
`;

const BottomContainer = styled.div`
  margin: 1rem;
`;

const Wrapper = styled.div`
  flex-direction: row;
  display: flex;

  align-items: center;
  margin-left: ${(props) => props.marginL || "0"};
  margin-bottom: ${(props) => props.marginB || "0"};
  .margin-top {
    margin-top: 3px;
  }
`;

const Margin = styled.div`
  margin-left: 3px;
`;
const Title = styled.h2`
  margin-top: 16px;
`;

const CampaignStatus = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  background: var(--b-b100);
  z-index: 2;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Card = ({
  data,
  conceptFilter,
  activeFilter,
  finishedFilter,
  canceledFilter,
  refreshCampaigns,
  handleCardClick,
}) => {
  const navigate = useNavigate();
  const campaignId = data.id;
  const user = useSelector((state) => state.profile.profile);
  const myCompany = data && user.data.user.company_id === data.company_id;
  const manageProjectModal = useManageProjectModal();

  const acceptCampaign = () => {
    try {
      apiClient.get(`campaign/${campaignId}/accept`).then((response) => {
        refreshCampaigns();
      });
    } catch (error) {
      console.error("Fout bij het accepteren van de campagne: ", error);
    }
  };

  const declineCampaign = () => {
    try {
      apiClient.get(`campaign/${campaignId}/decline`).then((response) => {
        refreshCampaigns();
      });
    } catch (error) {
      console.error("Fout bij het accepteren van de campagne: ", error);
    }
  };

  const deleteCampaign = () => {
    try {
      apiClient.delete(`campaign/${campaignId}/delete`).then((response) => {
        refreshCampaigns();
      });
    } catch (error) {
      console.error("Fout bij het accepteren van de campagne: ", error);
    }
  };

  const manageMyCampaign = () => {
    manageProjectModal.onOpen();
    if (handleCardClick) {
      handleCardClick();
    }
  };

  const navigateToDetails = () => {
    if (user && data) {
      if (user.data.user.company_id === data.company_id) {
        navigate(`/projectdetail/${campaignId}`);
      } else {
        navigate(`/detailpage/${data.id}`);
      }
    } else {
      throw Error("No user or data available");
    }
  };

  return (
    <StyledWrapper>
      <div onClick={navigateToDetails} style={{ flex: 1 }}>
        <div style={{ position: "relative" }}>
          <StatusCampaign
            image={data.image}
            profileImage={data.company_image}
            profileName={"Advertiser"}
          />
          {myCompany && !data?.is_published &&(
            <CampaignStatus>
              <SubheadingS fontWeight={"500"} mt="0px">
                Draft
              </SubheadingS>
            </CampaignStatus>
          )}
        </div>
        <TopContainer>
          <Title className="card_title headline_l">{data.title}</Title>
          <CampaignInformation
            marketing_goals={data.marketing_goals}
            company_name={data.company_name}
            influencer_product={data.influencer_product}
            channels={data?.profile_channels}
          />
        </TopContainer>

        <BottomContainer>
          {activeFilter ? (
            <Wrapper marginB="10px">
              <Microphone fill="#A4AFB6" />
              <Margin>
                <ParagraphS>
                  {data.participating_influencer_count} pitches received
                </ParagraphS>
              </Margin>
              <Wrapper marginL={"17px"}>
                <Megaphone />
                <Margin>
                  <ParagraphS>
                    {data.active_influecer_count} active creatives
                  </ParagraphS>
                </Margin>
              </Wrapper>
            </Wrapper>
          ) : null}
          <PitchInformation
            pitch_deadline={data.pitch_deadline}
            budget_amount={data.budget_amount}
          />
        </BottomContainer>
      </div>
      {conceptFilter ? (
        <>
          <Button
            fwidth
            mt={"16px"}
            padding={"16px 24px"}
            Icon={<Trash color={"black"} />}
            text={"Discard this concept"}
            onClick={declineCampaign}
            textcolor={"#042234"}
            textSize="14px"
            iconFirst
          />
          <Button
            primary
            mt={"0px"}
            fwidth
            text={"Publish this concept"}
            onClick={acceptCampaign}
            textcolor={"#042234"}
            textSize="14px"
          />
        </>
      ) : activeFilter ? (
        <Button
          primary
          fwidth
          mt={"16px"}
          text={"Manage my project"}
          textcolor={"#042234"}
          onClick={manageMyCampaign}
          textSize="14px"
        />
      ) : finishedFilter ? (
        <Button
          mt={"0px"}
          fwidth
          text={"View finished campaign"}
          onClick={() => navigate(`/projectdetail/${campaignId}`)}
          textcolor={"#FF4E5B"}
          textSize="14px"
        />
      ) : canceledFilter ? (
        <>
          <Button
            mt={"0px"}
            fwidth
            text={"View canceled campaign"}
            onClick={() => navigate(`/projectdetail/${campaignId}`)}
            textcolor={"#FF4E5B"}
            textSize="14px"
          />
          {
            <Button
              mt={"0px"}
              fwidth
              Icon={<Trash color={"black"} />}
              text={"Remove forever"}
              onClick={deleteCampaign}
              textcolor={"var(--b-b500)"}
              textSize="14px"
              iconFirst
            />
          }
        </>
      ) : myCompany ? (
        <Button
          primary
          fwidth
          mt={"16px"}
          text={"Manage my campaign"}
          textcolor={"#042234"}
          onClick={manageMyCampaign}
          textSize="14px"
        />
      ) : null}
    </StyledWrapper>
  );
};
export default Card;
