/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";
import React from "react";
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { useNavigate } from "react-router-dom";

/* -------------------------- Internal Dependencies ------------------------- */
import CampaignInformation from "./CampaignInformation";
import PitchInformation from "./PitchInformation";
import StatusCampaign from "./StatusCampaign";
import { Button } from "../../../UI";
import apiClient from "../../../../services/api";

const StyledWrapper = styled.div`
 border-radius: 4px;
 background: #fff;
 width: 100%;
 min-width:var(--min-width-card);
 max-width: var(--max-width-card);
 max-height: var(--max-height-card);
 display: flex;
 flex-direction: column;
 cursor: pointer;
 margin-bottom: 1rem;
 box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02);
 transition: transform 0.2s;

 @media (min-wdith: 768px){
     margin-bottom: 0rem;
 }

 &:hover{
    .card-image{
        transform: scale(1.1);
    }
 }
 }`;

const TopContainer = styled.div`
  margin: 0 1rem;
`;

const BottomContainer = styled.div`
  margin: 1rem;
`;
const Title = styled.h2`
  margin-top: 16px;
`;

const Buttons = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: #FFF1F2;
    padding: 16px 24px;
`


const Card = ({ data, removePitchButton, searchText }) => {
  const navigate = useNavigate();
  const campaignDataId = data.id;
  const campaignData = data;

  const navigateToChat = () => {
    apiClient
      .post(`/campaign/${campaignData.id}/pitch/search`)
      .then((response) => {
        const pitchData = response.data.data[0];
        navigate(`/chat/campaign/${campaignData.id}/${pitchData.id}`);
      });
  };

  return (
    <StyledWrapper key={data.id}>
      <div
        onClick={() => navigate(`/detailpage/${campaignDataId}`)}
        style={{ flex: "1" }}
      >
        <StatusCampaign
          image={data.image}
          profileImage={data.company_image}
          profileName={"Advertiser"}
        />
        <TopContainer>
          <Title className="card_title headline_l">{data.title}</Title>
          <CampaignInformation
            marketing_goals={data.marketing_goals}
            company_name={data.company_name}
            influencer_product={data.influencer_product}
            channels={data.channels}
          />
        </TopContainer>
      </div>
      <div>
        <BottomContainer>
          <PitchInformation
            budget_amount={data.budget_amount}
            pitch_deadline={data.pitch_deadline}
          />
        </BottomContainer>
        {!removePitchButton && (
          <>
            {data.is_pitched && data.active ? (
              <div style={{ background: "#FFF1F2", padding: '16px 24px' }}>
                <Button
                  fwidth
                  mt={"0"}
                  text={"Continue this pitch"}
                  onClick={navigateToChat}
                  padding={"10px"}
                  />
              </div>
            ) : !data.active ? (
              <Button fwidth primary mt={"0"} text={"Finished"} disabled />
            ) : (
              <Buttons>
                <Button
                iconFirst
                width={"50%"}
                primary
                text={"Pitch on project"}
                textSize={"14px"}
                padding={"10px"}
                mt="0px"
                onClick={() => navigate(`/${campaignData.id}/new-pitch`)}
              />
              <Button
                iconFirst
                Icon={<InfoCircledIcon color={"#042234"} style={{marginBottom: "1px"}} />}
                onClick={() => navigate(`/detailpage/${campaignDataId}`)}
                width={"50%"}
                textcolor={"#042234"}
                textSize={"14px"}
                text={"See details"}
                padding={"10px"}
                mt="0px"
              />
              </Buttons>
            )}
          </>
        )}
      </div>
    </StyledWrapper>
  );
};
export default Card;
