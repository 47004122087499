/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledHeadlineL = styled.h1`
	margin-top: ${(p) => (p.mt ? p.mt : "24px")};
	margin-bottom: ${(p) => p.mb && p.mb};
	color: ${(p) => p.color && p.color};
	${(props) => props.className};

`;
const HeadlineL = ({ mt, children, color, mb, className}) => {
	return (
		<StyledHeadlineL mt={mt} color={color} mb={mb} className="headline_l">
			{children}
		</StyledHeadlineL>
	);
};

export default HeadlineL;
