import styled from 'styled-components';
import { SubheadingS, SubheadingXXS } from '../../Text';

const StyledButtonChannels = styled.div`
background-color: #EBEEEF;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Description = styled.div`
  .sub_heading_xxs {
    margin: 8px 0rem 8px 0rem;
    }
    
  .innerHtml {
    font-family: "Avenir LT Std";
    font-weight: 400;
    
  a:link {
    color: var(--p500);
  }
`;

const Offline = ({data}) => {
    const hasOfflineIdea = data?.channels?.offline?.offline_idea;

    return (
        <StyledButtonChannels>
            <Description>
                <SubheadingS mt={"0px"}>Top direction:</SubheadingS>
                {hasOfflineIdea ? (
                    <div className="innerHtml" dangerouslySetInnerHTML={{ __html: hasOfflineIdea }}></div>
                )
                    : (
                        <SubheadingXXS>No instructions</SubheadingXXS>
                    )}
            </Description>
        </StyledButtonChannels>
    )
}

export default Offline
