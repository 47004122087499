import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import styled from "styled-components";

import { ParagraphS, SubheadingXS } from "../../Components/Text";

// Styled Components
const ModalOverlay = styled.div`
  background-color: ${(props) => (props.overview ? "rgba(0, 0, 0, 0.04)" : "rgba(0, 0, 0, 0.7)")};
   display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transition: background-color 0.2s;
  
  @media (max-width: 768px) {
    background-color: transparent;
    z-index:2;
    top: unset;
  }

`;

const ModalContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: ${(props) => (props.overview ? "0px 0px 10px rgba(0, 0, 0, 0.02)" : "0px 0px 10px rgba(0, 0, 0, 0.5)")};
  width: 100%;
  max-width: 500px;

  transition: transform 0.3s, opacity 0.3s; 
  transform: translateY(${(props) => (props.showModal ? "0" : "100%")});
  opacity: ${(props) => (props.showModal ? "100%" : "0")};


  @media (max-width: 768px) {
    padding-bottom: ${(props) => (props.paddingBottom ? `${props.paddingBottom}px` : '76px')};
    max-width: 100%;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #E0E0E0;
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  flex-grow: 1;
`;

const ModalCloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--b-b100);
  padding: 12px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
`;

const ModalBody = styled.div`
  padding: 0px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  padding: 16px;
  gap: 10px;
`;

const ModalButton = styled.button`
  background-color: var(--p500);
  color: white;
  border: none;
  padding: 14px 20px;
  border-radius: 2px;
  cursor: pointer;
  transition: opacity 0.2s;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }
`;

// Modal-component
function Modal({
    isOpen,
    onClose,
    onSubmit,
    title,
    body,
    actionLabel,
    footer,
    disabled,
    secondaryAction,
    secondaryActionLabel,
    overview,
    paddingBottom
}) {
    const [showModal, setShowModal] = useState(isOpen);

    useEffect(() => {
        setShowModal(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        if (disabled) {
            return;
        }
        setShowModal(false);
        setTimeout(() => {
            onClose();
        }, 300);
   

    };

    const handleSubmit = () => {
        if (disabled) {
            return;
        }

        onSubmit();
    };

    const handleSecondaryAction = () => {
        if (disabled || !secondaryAction) {
            return;
        }

        secondaryAction();
    };

    if (!isOpen) {
        return null;
    }

    return (
        <ModalOverlay overview={overview}>
            <ModalContainer showModal={showModal} overview={overview} paddingBottom={paddingBottom}>
                {title &&
                  <ModalHeader>
                    <ModalTitle><ParagraphS color="#959595">{title}</ParagraphS></ModalTitle>
                    <ModalCloseButton onClick={handleClose}>
                        <IoMdClose size={16} />
                    </ModalCloseButton>
                </ModalHeader>}
                <ModalBody>{body}</ModalBody>
                <ModalButtonContainer>
                    {secondaryAction && secondaryActionLabel && (
                        <ModalButton
                            disabled={disabled}
                            onClick={handleSecondaryAction}
                        >
                            <SubheadingXS>{secondaryActionLabel}</SubheadingXS>
                        </ModalButton>
                    )}
                    {handleSubmit && actionLabel && (
                        <ModalButton disabled={disabled} onClick={handleSubmit}>
                        <SubheadingXS>{actionLabel}</SubheadingXS>
                        </ModalButton>)}
                </ModalButtonContainer>
                {footer}
            </ModalContainer>
        </ModalOverlay>
    );
}

export default Modal;
