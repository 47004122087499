import React from "react";

const PenIcon = () => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.35938 8.59375L9.48438 1.46875C10.0703 0.882812 11.0312 0.882812 11.6172 1.46875L12.5312 2.38281C12.6016 2.45312 12.6719 2.54688 12.7188 2.61719C13.1172 3.20312 13.0469 4 12.5312 4.51562L5.40625 11.6406C5.38281 11.6641 5.33594 11.6875 5.3125 11.7344C5.07812 11.9219 4.82031 12.0625 4.53906 12.1562L1.70312 12.9766C1.51562 13.0469 1.30469 13 1.16406 12.8359C1 12.6953 0.953125 12.4844 1 12.2969L1.84375 9.46094C1.9375 9.13281 2.125 8.82812 2.35938 8.59375ZM2.92188 9.78906L2.38281 11.6172L4.21094 11.0781C4.35156 11.0312 4.49219 10.9609 4.60938 10.8438L9.97656 5.47656L8.5 4.02344L3.15625 9.39062C3.13281 9.39062 3.13281 9.41406 3.10938 9.4375C3.01562 9.53125 2.96875 9.64844 2.92188 9.78906Z"
				fill="white"
			/>
		</svg>
	);
};

export default PenIcon;
