import { createGlobalStyle } from "styled-components";
import AvenirLTStdHeavy from "../assets/fonts/AvenirLTStd-Heavy.otf";
import AvenirLTStdMedium from "../assets/fonts/AvenirLTStd-Medium.otf";
import TiemposHeadlineMedium from "../assets/fonts/TiemposHeadline-Medium.otf";
import TiemposHeadlineBold from "../assets/fonts/TiemposHeadline-Bold.woff";
export const GlobalStyle = createGlobalStyle`

body {
margin: 0;
box-sizing: border-box;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url(${AvenirLTStdHeavy}) format("opentype") ;
    font-weight: 900;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url(${AvenirLTStdMedium}) format("opentype") ;
}

@font-face {
    font-family: 'Tiempos Headline';
    src: url(${TiemposHeadlineMedium}) format("opentype") ;
}
@font-face {
    font-family: 'Tiempos Headline';
    src: url(${TiemposHeadlineBold}) format("woff") ;
    font-weight: 700;
}
h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

.headline_s {
    font-family: 'Tiempos Headline';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
}

.card_title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.card_content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}

.card_content * {
    font-size: 14px !important;
    margin: 0;
}

.headline_xl {
font-family: "Tiempos Headline";
font-size: 38px;
font-weight: 700;
line-height: 50px;
letter-spacing: 0em;
text-align: left;
color: #262626;
}

.headline_l {
    font-family: 'Tiempos Headline';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: 0em;
    text-align: left;
 
}

.heading_m {
font-family: "Tiempos Headline";
font-size: 32px;
font-weight: 700;
line-height: 40px;
letter-spacing: 0em;
}

.headline_m {
font-family: "Tiempos Headline";
font-size: 24px;
font-weight: 700;
letter-spacing: 0em;
}


.heading_xl {
font-family: "Tiempos Headline";
font-size: 36px;
font-weight: 700;
line-height: 50px;
letter-spacing: 0em;
text-align: left;
color: #323232;
}

.sub_heading_xl {
font-size: 24px;
line-height: 150%;
letter-spacing: -1%;
}
.sub_heading_l { 
font-size: 20px;
font-family: 'Avenir LT Std';
line-height: 150%;
letter-spacing: 0%;
}
.sub_heading_m {
    font-family: 'Avenir LT Std';
    font-size: 20px;
    line-height: 150%;
    font-weight: normal;
    font-weight: 700;
}

.sub_heading_s {
    font-family: 'Avenir LT Std';
    font-size: 18px;
    line-height: 1.1;
    font-weight: normal;
    font-weight: 700;
}

.sub_heading_xs  {
font-family: 'Avenir LT Std';
font-size: 16px;
line-height: 150%;
letter-spacing: 0%; 

}
.sub_heading_xxs 
{
font-family: 'Avenir LT Std';
font-size: 12px;
letter-spacing: 0%;
margin-bottom: -1px;
}

.sub_heading_xxxs 
{
font-family: 'Avenir LT Std';
font-size: 14px;
line-height: 150%;
letter-spacing: 0%;
}

.sub_heading_xxxs 
{
font-family: 'Avenir LT Std';
font-size: 12px;
line-height: 150%;
letter-spacing: 0%;
}

.paragraph_m{
font-family: "Avenir LT Std";
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;

}

.paragraph_xs{
    font-family: "Avenir LT Std";
    font-size: 12px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: 0em;
}

.paragraph_s{
    font-family: "Avenir LT Std";
    font-size: 14px;
    letter-spacing: 0em;
}

.ml8px {
    margin-left: 8px;
}
.para_neg_margin_20 {
    margin-top: 51px !important;
}
.dropzone {
font-family: "Avenir LT Std";
font-size: 16px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color: #364e5d;
z-index: 3;
}

:root{
    /* Color styles */
    --g-s50: rgba(241, 241, 241, 1);
    --g-s100: rgba(227, 227, 227, 1);
    --g-s200: rgba(191, 191, 191, 1);
    --g-s300: rgba(149, 149, 149, 1);
    --g-s400: rgba(114, 114, 114, 1);
    --g-s500-secondary: rgba(79, 79, 79, 1);
    --g-s600: rgba(63, 63, 63, 1);
    --g-s700: rgba(47, 47, 47, 1);
    --g-s800: rgba(38, 38, 38, 1);
    --g-s900: rgba(28, 28, 28, 1);
    --b-b50: rgba(235, 238, 239, 1);
    --b-b100: rgba(216, 220, 223, 1);
    --b-b200: rgba(164, 175, 182, 1);
    --b-b300: rgba(105, 123, 134, 1);
    --b-b400: rgba(54, 78, 93, 1);
    --b-b500-secondary: rgba(4, 34, 52, 1);
    --b-b600: rgba(3, 27, 42, 1);
    --p50: rgba(255, 241, 242, 1);
    --p100: rgba(255, 227, 229, 1);
    --p200: rgba(255, 191, 196, 1);
    --p300: rgba(255, 149, 157, 1);
    --p400: rgba(255, 113, 124, 1);
    --p500: rgba(255, 78, 91, 1);
    --p600: rgba(204, 62, 73, 1);
    --p700: rgba(152, 46, 54, 1);
    --p800: rgba(122, 37, 44, 1);
    --p900: rgba(92, 28, 33, 1);
    --gray-2: #4F4F4F;
    --thursd--f-f-f: rgba(255, 255, 255, 1);
    --success: rgba(97, 183, 149, 1);
    --success-light: rgba(240, 247, 209, 1);
    --warning: rgba(255, 172, 9, 1);
    --warning-light: rgba(255, 243, 213, 1);
    
    --max-width: 1240px;
    --max-width-card: 400px;
    --min-width-card: 340px;
    --max-height-card: 780px;
    }
`;
