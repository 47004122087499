import React from "react";

const Close = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fill={props.fill}
        d="M10.75 9.719c.281.312.281.781 0 1.062-.313.313-.781.313-1.063 0L6 7.062l-3.719 3.72c-.312.312-.781.312-1.062 0-.313-.282-.313-.75 0-1.063L4.937 6 1.22 2.281c-.313-.312-.313-.781 0-1.062a.684.684 0 011.031 0L6 4.969 9.719 1.25a.684.684 0 011.031 0c.313.281.313.75 0 1.063L7.031 6l3.719 3.719z"
      ></path>
    </svg>
  );
}

export default Close;
