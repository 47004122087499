import React from "react";
import { useNavigate } from "react-router";

import { LayoutCenter } from "../../Components/Layout";
import { HeadingXL, ParagraphM } from "../../Components/Text";
import { Button } from "../../Components/UI";


const PasswordChanged = () => {
    const navigate = useNavigate();
    return (
        <LayoutCenter>
            <HeadingXL>Password changed!</HeadingXL>
            <ParagraphM>
                Your password has been changed succesfully. You can now login with your new password.
			</ParagraphM>
            <Button 
                fwidth mt24 primary 
                text="Back to login" 
                onClick={() => navigate("/")}
            />
        </LayoutCenter>
    )
}

export default PasswordChanged;