import styled from "styled-components";

import { HeadlineM } from "../../../Components/Text";
import { Button } from "../../../Components/UI";
import { openEmail, openEmailTeam } from '../../../Utils/utility-functions';
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column; 
  gap: 8px;
  align-items: flex-end;
  padding-top: 8px;
  
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;


const BasicContentsHeader = ({ buttonTitle }) => {
  const navigate = useNavigate();

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <HeadlineM mt={'0px'} color={'#ffffff'}>
        Meetup Spot
      </HeadlineM>
      <Wrapper>
        <Button
          primary
          padding={"10px 10px"}
          text="Talk to a team member" 
          textSize={"12px"}
          onClick={openEmailTeam}
          mt={"0px"}
        />
        <Button
          primary
          padding={"10px 32px"}
          text="Create a project"
          textSize={"12px"}
          mt={"0px"}
          onClick={()=> navigate('/project/new')}
        />
      </Wrapper>
    </div>
  );
}

export default BasicContentsHeader;
