import React from 'react'

const Rocket = (props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 21 21"
        className='rocket'
        {...props}
      >
        <path
          fill={props.fill}
          d="M17.5625 6.125C17.5625 7.33594 16.5469 8.3125 15.375 8.3125C14.1641 8.3125 13.1875 7.33594 13.1875 6.125C13.1875 4.95312 14.1641 3.9375 15.375 3.9375C16.5469 3.9375 17.5625 4.95312 17.5625 6.125ZM15.375 7.0625C15.8828 7.0625 16.3125 6.67188 16.3125 6.125C16.3125 5.61719 15.8828 5.1875 15.375 5.1875C14.8281 5.1875 14.4375 5.61719 14.4375 6.125C14.4375 6.67188 14.8281 7.0625 15.375 7.0625ZM5.60938 11.75H1.625C1.39062 11.75 1.19531 11.6719 1.07812 11.4766C0.960938 11.2812 0.960938 11.0469 1.03906 10.8516L3.03125 6.90625C3.46094 6.04688 4.32031 5.5 5.25781 5.5H8.77344C8.89062 5.34375 8.96875 5.22656 9.08594 5.07031C12.2891 0.34375 17.0547 0.1875 19.8672 0.734375C20.3359 0.8125 20.6875 1.16406 20.7656 1.63281C21.3125 4.44531 21.1562 9.21094 16.4297 12.4141C16.2734 12.5312 16.1172 12.6094 16 12.7266V16.2422C16 17.1797 15.4531 18.0391 14.5938 18.4688L10.6484 20.4609C10.4531 20.5391 10.2188 20.5391 10.0234 20.4219C9.82812 20.3047 9.75 20.1094 9.75 19.875V15.8125C9.39844 15.9688 9.08594 16.0859 8.8125 16.2031C8.38281 16.3984 7.83594 16.2812 7.48438 15.9297L5.49219 13.9766C5.14062 13.5859 5.02344 13.0781 5.25781 12.6094C5.33594 12.375 5.45312 12.0625 5.60938 11.75ZM8.34375 15.0312C10.0234 14.3281 13.7734 12.6875 15.7266 11.3984C19.7891 8.625 20.0234 4.52344 19.5547 1.94531C16.9375 1.47656 12.875 1.71094 10.1016 5.77344C8.8125 7.6875 7.13281 11.3984 6.39062 13.0781L8.34375 15.0312ZM14.0469 17.3359C14.4766 17.1406 14.75 16.7109 14.75 16.2422V13.4297C13.5391 14.0938 12.1719 14.7188 11 15.2656V18.8984L14.0469 17.3359ZM4.16406 7.45312L2.60156 10.5H6.19531C6.74219 9.32812 7.40625 7.96094 8.07031 6.75H5.25781C4.78906 6.75 4.35938 7.02344 4.16406 7.45312Z"
        ></path>
      </svg>
    );
  }
  
  export default Rocket;