import React from 'react'
import { useNavigate } from 'react-router-dom'

import { LayoutCenterTop } from '../../../Components/Layout'
import { HeadingXL, ParagraphM } from '../../../Components/Text'
import { useDataSource } from '../../../Utils/hooks/useDataSource'
import InfluencerUserForm from './InfluencerUserForm'
import FormProvider from '../../../Pages/ProfileSetup/FormProvider';
import { RoundButtonLink } from '../../../Components/UI'
import Close from '../../../Icons/Close'

export default function SetupProfileInfluencer() {
const { resource: questions } = useDataSource('/questions?type=profile')
    const { resource: answers } = useDataSource('/me')
    const navigate = useNavigate();

    const profileData = questions?.data;

    return (
        <FormProvider>
            {answers && <LayoutCenterTop>
                <div style={{ position: 'absolute',  right: 40 }}>
                    <RoundButtonLink
                        height={"40px"}
                        width={"40px"}
                        fill={"#FFF"}
                        Icon={<Close fill={"#364E5D"}
                        onClick={()=>navigate("/me")}/>}
                    />
                </div>
                <HeadingXL>Your creative profile</HeadingXL>
                <ParagraphM>On myThursd every creative has a profile to show advertisers who they are, what they do, and what kind of (social) channels they have to do promotions. Make sure to create the most appealing profile possible. More information mostly means more interest and more business.</ParagraphM>
                {profileData && <InfluencerUserForm data={profileData} answers={answers?.data?.profile}/>}
            </LayoutCenterTop>}
        </FormProvider>
    )
}
