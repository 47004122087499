/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";
import { HeadlineL, ParagraphM, SubheadingS } from "../../Text";
import wateringCan from "../../../Images/watering-can.png";
import laptop from "../../../Images/laptop.png";
import { RoundButtonLink } from "../../UI";
import Close from "../../../Icons/Close";
import SubheadingM from "../../Text/SubheadingM";
import apiClient from "../../../services/api";

/* -------------------------- Internal Dependencies ------------------------- */
import Button from '../../UI/Buttons/Button';
import { useNavigate } from "react-router-dom";
import {  LayoutCenterTop } from "../../Layout";

const Content = styled.div`
  margin: 1.5rem;
  position: relative;

  hr {
    border-top: 1px solid var(--g-s50);
    margin-top: 16px;
  }
`;


const Top = styled.div`
  display: flex;
  justify-content: space-between;

  .sub-heading_s {
    margin-bottom: 16px;
  }
`;

const Images = styled.div`
  display: flex;
`;

const Block = styled.div`
  background-color: var(--b-b50);
  
  .timeline {
    margin: 20px auto;
    padding: 20px;
  }
  
  .outer {
    border-left: 2px solid var(--b-b200);
  }
  
  .card {
    position: relative;
    margin: 0 0 20px 20px;
    color: gray;
    border-radius: 8px;
  }
  
  .info {
    display: flex;
    flex-direction: column;
  }

  h3{
    color: var(--b-b300);
    position: relative;
    font-family: 'Avenir LT Std';
    font-size: 18px;
    line-height: 150%;
    font-weight: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  
  .title1::before {
    content: "1";
    position: absolute;
    width: 28px;
    height: 28px;
    background: var(--b-b300);
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    display: flex;
  }

  .title2::before {
    content: "2";
    position: absolute;
    width: 28px;
    height: 28px;
    background: var(--b-b300);
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .title3::before {
    content: "3";
    position: absolute;
    width: 28px;
    height: 28px;
    background: var(--b-b300);
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    display: flex;
  }

  .title4::before {
    content: "4";
    position: absolute;
    width: 28px;
    height: 28px;
    background: var(--b-b300);
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    display: flex;
  }

  .title5::before {
    content: "5";
    position: absolute;
    width: 28px;
    height: 28px;
    background: var(--b-b300);
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    display: flex;
  }
`;


const StyledButtonContainer = styled.div`
    margin-top: 40px;
`
const HoorayScreen = ({  }) => {

const navigate = useNavigate();

return (
      <LayoutCenterTop>
          <Content>
          <Top>
            <Images>
              <img src={wateringCan}></img>
              <img src={laptop}></img>
            </Images>
            <RoundButtonLink
              height={"40px"}
              width={"40px"}
              fill={"#FFF"}
              Icon={<Close fill={"#364E5D"}
              onClick={()=>navigate("/meetupspot")}
              />
              }
            />
          </Top>
          <HeadlineL>Hooray!</HeadlineL>
          <HeadlineL mt={"14px"}>Your Pitch is live!</HeadlineL>
          {/* <SubheadingS>You just pitched on the campaign '{campaigInfo.title || campaigInfoId.title}'</SubheadingS> */}
          <hr />
          <SubheadingM color={"#262626"}>What happens next?</SubheadingM>
          <Block>
            <div class="timeline">
              <div class="outer">
                <div class="card">
                  <div class="info">
                    <h3 class="title1">Pitch review</h3>
                    <ParagraphM>The advertiser/brand or creative will review your pitch. No adjustments? Then your pitch will be accepted, and you are good to go. Adjustments? In the chat, you meet up privately and you can start negotiating.</ParagraphM>
                  </div>
                </div>
                <div class="card">
                  <div class="info">
                    <h3 class="title2">Physical products</h3>
                    <ParagraphM>Are there physical products involved? myThursd will reach out to both you and the advertiser to get the products to your wholesaler or your address.</ParagraphM>
                  </div>
                </div>
                <div class="card">
                  <div class="info">
                    <h3 class="title3">Create content</h3>
                    <ParagraphM>It’s your turn! Start working and creating what is agreed upon. When finished, myThursd will review it. When approved by myThursd, the advertiser/brand or creative gets notice.</ParagraphM>
                  </div>
                </div>
                <div class="card">
                  <div class="info">
                    <h3 class="title4">Post / publish project by advertisers</h3>
                    <ParagraphM>Post or deliver the content that is approved at the date/time to the channels you agreed upon.</ParagraphM>
                  </div>
                </div>
                <div class="card">
                  <div class="info">
                    <h3 class="title5">Get paid</h3>
                    <ParagraphM>You will get paid for the project by myThursd within 30 days of delivery.</ParagraphM>
                  </div>
                </div>
              </div>
            </div>
          </Block>
          <StyledButtonContainer>
            <Button text={"Click here to pitch on more projects"} primary fwidth onClick={() => window.location.href = '/meetupspot'} />
          </StyledButtonContainer>
          </Content>
      </LayoutCenterTop>
 )
};

export default HoorayScreen;
