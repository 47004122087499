import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";


const initialState = {
  marketplace: [],
  marketplaceById: [],
  error: '',
};

export const fetchMarketplace = createAsyncThunk('fetch/marketplace', async () => {
  const response = await apiClient.get('/marketplace');
  return response.data;
});

export const fetchMarketplaceById = createAsyncThunk(
  "fetch/marketplaceById",
  async (marketplace_id) => {
    try {
      const response = await apiClient.get(`/marketplace/${marketplace_id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateIdea = createAsyncThunk(
  'update/marketplace',
  async ({data, id}) => {

    try {
      const response = await apiClient.post(`/marketplace/${id}`, data);
      return response.data.data;
    } catch (err) {
      console.log('error:', err.response);
      if (err.response && err.response.data && err.response.data.error) {
        console.error('API-foutbericht:', err.response.data.error);
      }
    }
  }
);

const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {},
  extraReducers: (builder) => {;
    builder
    .addCase(fetchMarketplace.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchMarketplace.fulfilled, (state, action) => {
      state.marketplace = action.payload
      console.log(action.payload)
    })
    .addCase(fetchMarketplaceById.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchMarketplaceById.fulfilled, (state, action) => {
      state.marketplaceById = action.payload;
      state.isLoading = false;
      state.error = null;
    })
    .addCase(updateIdea.pending, (state) => {
    })
    .addCase(updateIdea.fulfilled, (state, action) => {
      state.marketplace = action.payload;
      state.error = null;
    })
    .addCase(updateIdea.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export default marketplaceSlice.reducer;
