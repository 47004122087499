import React from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = ({ showToast, setShowToast, message = "" }) => {
	const notify = () => toast(message);

	React.useEffect(() => {
		if (showToast) {
			showToast && setTimeout(() => setShowToast(false), 3000);
			notify();
		}
	}, [showToast]);
	return (
		<div>
			<ToastContainer />
		</div>
	);
};

export default Toast;
