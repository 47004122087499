/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledHeadlineM = styled.h1`
	margin-top: ${(p) => (p.mt ? p.mt : "24px")};
	color: ${(p) => p.color && p.color};
`;
const HeadlineM = ({ mt, children, color }) => {
	return (
		<StyledHeadlineM mt={mt} color={color} className="headline_m">
			{children}
		</StyledHeadlineM>
	);
};

export default HeadlineM;
