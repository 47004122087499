/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

/* -------------------------- Internal Dependencies ------------------------- */
import { LayoutCenter, LogoContainer } from "../../Components/Layout";
import { HeadingXL, ParagraphM } from "../../Components/Text";
import Logo from "../../Icons/Logo";
import { useState } from "react";
import { useSelector } from 'react-redux';
import { Button } from "../../Components/UI";
import { useEffect } from "react";

const ButtonWrapper = styled.div`
	display: flex; 
	gap: 16px;

	@media (max-width: 768px) {
  		flex-direction: column;
		gap: 8px;
	}

`

const LogoutContainer = styled.div`
	width: 50%;
	
	@media (max-width: 768px) {
  		width: 100%
	}
`

const ThursdContainer = styled.a`
	width: 50%;
	text-decoration: none;

	@media (max-width: 768px) {
  		width: 100%;
		order: -1; 
	}
`
const WaitForActivation = () => {
	const navigate = useNavigate()

	const user = useSelector((state) => state.profile.profile);
	const usertype = user?.data?.usertype
	const influencertext = `
<br/>
Thank you for joining myThursd. Your account is created and will soon be activated.
<br/><br/>
We notify you when you have access to myThursd, so you can start pitching on the projects you like. And create your own projects.
<br/><br/>
Warm regards,
<br/><br/>
Regine from myThursd.	
	`;

	const companytext = `
<br/>
Thank you for joining myThursd. Your account is created and will soon be activated.
<br/><br/>
We notify you when you have access to myThursd, so you can start creating projects. 
<br/><br/>
Warm regards,
<br/><br/>
Regine from myThursd.
	`;

	useEffect(() => {
		const handleUnload = () => {
			localStorage.removeItem('token');

			setTimeout(() => {
				navigate('/');
				window.location.reload();
			}, 500);

		};
		window.addEventListener('unload', handleUnload);
		return () => {
			window.removeEventListener('unload', handleUnload);
		};
	}, []);



	return (
		<LayoutCenter>
			<LogoContainer>
				<Logo />
			</LogoContainer>
			<HeadingXL>You're almost there!</HeadingXL>
			<ParagraphM>
				<div dangerouslySetInnerHTML={{ __html: usertype === 'company' ? companytext : influencertext }}></div>
			</ParagraphM>
			{/* <ButtonWrapper>
				<LogoutContainer>
					<Button
						width={"100%"}
						primary text={"Logout & go back"}
						textSize={'14px'}
						padding={"10px"}
						mt={"10px"}
						onClick={logout}


					/>
				</LogoutContainer>
				<ThursdContainer href="https://thursd.com/" target="_blank" >
					<Button
						width={"100%"}
						primary text={"Go to Thursd.com"}
						textSize={'14px'}
						padding={"10px"}
						mt={"10px"}
					>

					</Button>
				</ThursdContainer>
			</ButtonWrapper> */}
		</LayoutCenter>
	);
};

export default WaitForActivation;
