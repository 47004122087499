import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ParagraphM, HeadlineXL } from '../../Components/Text'
import { LayoutCenterTop } from '../../Components/Layout'
import { Button } from '../../Components/UI'
import ArrowButton from '../../Icons/ArrowButton'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { profileSetup } from '../../app/features/profileSlice'
import { InputLabel } from '../../Components/Forms'

const ArrowButtonWrapper = styled.div`
  cursor: pointer; 
`;
export default function AddThursdPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector((state) => state.profile.profile);
    const [thursdAccount, setThursdAccount] = useState([]);

    //set default values
    useEffect(() => {
        if (user) {
            setThursdAccount(user.data.profile.channel["Thursd"] || [])
        }
    }, [user])


    const handleBackButton = () => {
        window.history.back();
    };

    const onSubmit = async () => {
       const newChannels = { ...user.data.profile.channel }
        newChannels['Thursd'] = thursdAccount

        dispatch(profileSetup({ data: { channel: newChannels } })).then((resp) => {
            navigate('/me')
        })

    }
    return (
        <LayoutCenterTop>
            <ArrowButtonWrapper onClick={handleBackButton}>
                <ArrowButton />
            </ArrowButtonWrapper>
            <div style={{ padding: '24px', }}>
                <HeadlineXL mt={"16px"}>Add your Thursd account</HeadlineXL>
                <div style={{ marginTop: '16px' }}>
                    <ParagraphM color={'#4F4F4F'}>
                        Welcome! To add your Thursd account, 
                        simply enter your user name in the designated input field. 
                    </ParagraphM>
                </div>
                <div style={{ marginTop: '16px' }}>
                  <InputLabel 
                    value={thursdAccount}
                    placeholder="Your Thursd account"
                    onChange={(e)=>setThursdAccount(e.target.value)}
                  />
                </div>
                <div style={{ marginTop: '32px' }}>
                    <Button
                        fwidth
                        primary
                        onClick={onSubmit}
                        text={"Submit"}
                        mt={"16px"}
                    />
                </div>
            </div>
        </LayoutCenterTop>
    )
}
