/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledButton = styled.button`
	border: ${(p) => (p.primary ? "transparent" : "1px solid #e3e3e3")};
	background: ${(p) => (p.primary ? p.theme.main : p.theme.white)};
	color: ${(p) => p.primary ? p.theme.white : p.theme.main};
	font-size: 14px;
	font-weight: 700;
	gap: 6px;
	padding: 6px 10px;
	padding-bottom: ${(p) => p.pb};
	box-shadow: ${(p) => p.primary ? " 0px 1px 0px #982e36" : "0px 1px 0px #E3E3E3"};
	border-radius: 1px;
	font-family: "Avenir LT Std";
	cursor: pointer;
    display: flex;
	justify-content: flex-start;
    align-items: center;

    &:active {
        background-color: var(--g-s50);
     }

`;

const ButtonContent = styled.div`
     display: flex; 
     align-items: center;
     gap: 8px
`

const HeaderButton = ({
    Icon,
    text,
    primary,
    mt24,
    textcolor,
    fwidth,
    onClick,
    mt,
    iconFront,
    children,
}) => {
    return (
        <StyledButton
            primary={primary}
            mt={mt}
            textcolor={textcolor}
            fwidth={fwidth}
            onClick={onClick}
            mt24={mt24}
        >
            {iconFront ?
                (
                    <ButtonContent>
                        <span>{text}</span>
                        <span style={{ marginTop: '3px' }}>{Icon || null}</span>
                    </ButtonContent>
                ) :
                (
                    <ButtonContent>
                        <span style={{ marginTop: '3px' }}>{Icon || null}</span>
                        <span>{text}</span>
                    </ButtonContent>
                )
            }
        </StyledButton>
    );
};

export default HeaderButton;
