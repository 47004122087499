import React from 'react'

const Globe = (props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        className='globe'
        {...props}
      >
        <path
          fill={props.fill}
          d="M8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM8 14.5C8.21875 14.5 8.8125 14.2812 9.46875 13C9.75 12.4375 9.96875 11.7812 10.1562 11H5.8125C6 11.7812 6.21875 12.4375 6.5 13C7.15625 14.2812 7.75 14.5 8 14.5ZM5.5625 9.5H10.4062C10.4688 9.03125 10.5 8.53125 10.5 8C10.5 7.5 10.4688 7 10.4062 6.5H5.5625C5.5 7 5.5 7.5 5.5 8C5.5 8.53125 5.5 9.03125 5.5625 9.5ZM10.1562 5C9.96875 4.25 9.75 3.59375 9.46875 3.03125C8.8125 1.75 8.21875 1.5 8 1.5C7.75 1.5 7.15625 1.75 6.5 3.03125C6.21875 3.59375 6 4.25 5.8125 5H10.1562ZM11.9062 6.5C11.9688 7 12 7.5 12 8C12 8.53125 11.9688 9.03125 11.9062 9.5H14.3125C14.4375 9.03125 14.5 8.53125 14.5 8C14.5 7.5 14.4375 7 14.3125 6.5H11.9062ZM10.6875 2.09375C11.125 2.90625 11.4688 3.90625 11.6875 5H13.75C13.0938 3.71875 12 2.6875 10.6875 2.09375ZM5.28125 2.09375C3.96875 2.6875 2.875 3.71875 2.21875 5H4.28125C4.5 3.90625 4.84375 2.90625 5.28125 2.09375ZM1.5 8C1.5 8.53125 1.53125 9.03125 1.65625 9.5H4.0625C4 9.03125 4 8.53125 4 8C4 7.5 4 7 4.0625 6.5H1.65625C1.53125 7 1.5 7.5 1.5 8ZM13.75 11H11.6875C11.4688 12.125 11.125 13.125 10.6875 13.9375C12 13.3438 13.0938 12.3125 13.75 11ZM4.28125 11H2.21875C2.875 12.3125 3.96875 13.3438 5.28125 13.9375C4.84375 13.125 4.5 12.125 4.28125 11Z"
        ></path>
      </svg>
    );
  }
  
  export default Globe;
