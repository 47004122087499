import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 0 40px; 
  position: relative;
  height: ${(props) => props.height || 'auto'};

  @media (max-width: 1200px) {
    padding: 0 32px; 
  }

  @media (max-width: 448px) {
    padding: 0 16px; 
  }
`;

const Container = ({ children, height}) => {
  return <StyledContainer height={height}>{children}</StyledContainer>;
};

export default Container;
