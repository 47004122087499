/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledFilterButton = styled.button`
	height: 41px;
	border: ${(p) =>
		p.buttonStyle === "primary" ? "transparent" : "1px solid #e3e3e3"};
	background: ${(p) =>
		p.buttonStyle === "primary" ? p.theme.main : p.theme.white};
	color: ${(p) =>
		p.buttonStyle === "primary" ? p.theme.white : p.theme.headings_black};
	font-size: 14px;
	font-weight: 700;
	line-height: 150%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding: 14px 19px;
	box-shadow: ${(p) =>
		p.primary ? " 0px 1px 0px #982e36" : "0px 1px 0px #E3E3E3"};
	border-radius: 1px;
	margin-top: ${(p) => p.mt};
	width: ${(p) => (p.fwidth ? p.fwidth : "100%")};
	font-family: "Avenir LT Std";
	border-radius: 78px;
	white-space: nowrap;
	cursor: pointer;

	& > * {
		fill: ${(p) =>
			p.buttonStyle === "primary" ? p.theme.white : p.theme.headings_black};
	}
`;

const FilterButton = ({
	Icon,
	onClick,
	text,
	mt,
	fwidth,
	buttonStyle,
	disabled,
}) => {
	return (
		<StyledFilterButton
			tabIndex={-1}
			buttonStyle={buttonStyle}
			mt={mt}
			fwidth={fwidth}
			disabled={disabled}
			onClick={onClick}
		>
			{Icon || null}
			<span>{text}</span>
		</StyledFilterButton>
	);
};

export default FilterButton;
