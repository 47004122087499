import React from "react";
import styled, { css } from "styled-components";

const buttonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  aspect-ratio: 1/1;
  border: 1px solid var(--g-s100);
  border-radius: 2px;
  padding: 12px 14px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.2s;


`;

const PrimaryButtonContainer = styled.button`
    ${buttonStyles}
    background-color: ${(props) =>
      props.canceled === true || props.status?.cancelled
        ? "#C0C0C0"
        : props.theme.main};
    cursor: ${(props) =>
      props.canceled === true || props.status?.cancelled
        ? "not-allowed"
        : "pointer"};
    border: ${(props) => props.primary}

    transition: transform 0.2s ease-in-out;

    &:hover {
      background-color: #982e36;
    }
`;

const SecondaryButtonContainer = styled.button`
  ${buttonStyles}
  background-color: ${(props) =>
    props.canceled === true || props.status?.cancelled ? "#D8DCDF" : "white"};
  cursor: ${(props) =>
    props.canceled === true || props.status?.cancelled
      ? "not-allowed"
      : "pointer"};

      transition: transform 0.2s ease-in-out;

      &:hover {
        background-color: #f0f0f0;
      }

`;

const SquareButton = ({ onClick, Icon, type, canceled, status, primary }) => {
  if (primary) {
    return (
      <PrimaryButtonContainer
        type={type}
        canceled={canceled}
        onClick={onClick}
        disabled={canceled}
      >
        {Icon || null}
      </PrimaryButtonContainer>
    );
  } else {
    return (
      <SecondaryButtonContainer
        type={type}
        canceled={canceled}
        onClick={onClick}
        disabled={canceled}
      >
        {Icon || null}
      </SecondaryButtonContainer>
    );
  }
};

export default SquareButton;
