/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

/* -------------------------- Internal Dependencies ------------------------- */
import {
  ParagraphS,
  SubheadingS,
  SubheadingXS,
  SubheadingXXS,
} from "../../../Components/Text";
import { Button } from "../../../Components/UI";
import ChatWithoutQuestion from "../../../Icons/ChatWithoutQuestion";
import Hamburger from "../../../Icons/Hamburger";
import useEventOptionsModal from "../../../app/hooks/useEventOptionsModal";
import useMarketplaceOverviewFilter from "../../../app/hooks/useMarketplaceOverviewFilter";
import PitchInformation from "../../Common/Card/Campaigns/PitchInformation";
import StatusCampaign from "../../Common/Card/Campaigns/StatusCampaign";
import AvatarStatic from "../../AvatarStatic";

const StyledWrapper = styled.div`
border-radius: 4px;
background: #fff;
width: 100%;
min-width:var(--min-width-card);
max-width: var(--max-width-card);
max-height: var(--max-height-card);
display: flex;
flex-direction: column;
cursor: pointer;
margin-bottom: 1rem;
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02);

@media (min-wdith: 768px){
    margin-bottom: 0rem;
}
}`;

const TopContainer = styled.div`
  margin: 0 1rem;
  padding: 24px 0 16px 0;
`;

const TextContainer = styled.div`
  max-width: 50ch;
  word-wrap: break-word;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #fff1f2;
  padding: 16px 24px;
`;

const BottomContainer = styled.div`
  margin: 0 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Title = styled.p`
  margin-left: -4px;
`;
const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const Card = ({ marketplaceData, onOptionsClick, canceled }) => {
  const navigate = useNavigate();
  const marketplaceOverviewFilter = useMarketplaceOverviewFilter();
  const eventOptionmodal = useEventOptionsModal();
  const user = useSelector((state) => state.profile.profile);
  const isUserOwnedMarketplaceCampaign =
    user?.data?.usertype === "company"
      ? marketplaceData?.user?.id === user?.data?.user?.company_id
      : marketplaceData?.user?.id === user?.data?.id;

  function controleerEnToonHtmlContent(htmlContent, maxKarakters = 50) {
    if (htmlContent.length > maxKarakters) {
      if (htmlContent.indexOf(" ") !== -1) {
        // Als er spaties zijn, probeer dan het laatste woord te vinden dat binnen de limiet past
        const lastSpaceIndex = htmlContent.lastIndexOf(" ", maxKarakters);
        if (lastSpaceIndex !== -1) {
          htmlContent = htmlContent.substring(0, lastSpaceIndex) + "...";
        }
      } else {
        htmlContent = htmlContent.substring(0, maxKarakters) + "...";
      }
    }

    return (
      <TextContainer
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{ color: "#727272" }}
      />
    );
  }

  const navigateToNextComponent = () => {
    marketplaceOverviewFilter.setSelectedFilterValue("Idea");

    navigate(`/projectsbycreatives/${marketplaceData.id}`, {
      state: {
        marketplaceData: marketplaceData,
        canceled,
      },
    });
  };

  const handleEventOptionsClick = () => {
    eventOptionmodal.onOpen();
    onOptionsClick();
  };

  const openChat = () => {
    if (isUserOwnedMarketplaceCampaign) {
      marketplaceOverviewFilter.setSelectedFilterValue("Chats");

      navigate(`/projectsbycreatives/${marketplaceData.id}`, {
        state: {
          marketplaceData: marketplaceData,
          canceled,
        },
      });
    } else {
      navigate(`/chat/marketplace/${marketplaceData.id}`);
    }
  };

  return (
    <StyledWrapper>
      <div style={{ flex: 1 }} onClick={navigateToNextComponent}>
        <StatusCampaign
          image={marketplaceData.image}
          profileImage={marketplaceData.user.image}
          profileName={"Creative"}
        />
        <TopContainer>
          <Title className="card_title headline_l" mt={"0px"}>
            {marketplaceData.title}
          </Title>
        </TopContainer>

        <BottomContainer>
          <NameContainer>
            <SubheadingS fontWeight={"500"} mt={"0px"}>
              By
              <span style={{ fontWeight: 700 }}>
                &nbsp;{marketplaceData.user.name}
              </span>
            </SubheadingS>
            <AvatarStatic size="40px" userImage={marketplaceData.user.image} />
          </NameContainer>

          <SubheadingXS color={"#697B86"} mt={"8px"}>
            Wants
          </SubheadingXS>
          <ParagraphS className="card_content" mt={"4px"} color={"#727272"}>
            {controleerEnToonHtmlContent(marketplaceData.requesting)}
          </ParagraphS>
          <SubheadingXS color={"#697B86"} mt={"8px"}>
            Gives
          </SubheadingXS>
          <ParagraphS className="card_content" mt={"4px"} color={"#727272"}>
            {" "}
            {controleerEnToonHtmlContent(marketplaceData.returning)}
          </ParagraphS>
          <SubheadingXS color={"#697B86"} mt={"8px"}>
            Details
          </SubheadingXS>
          <ParagraphS className="card_content" mt={"4px"} color={"#727272"}>
            {controleerEnToonHtmlContent(marketplaceData.details)}
          </ParagraphS>
          <div onClick={navigateToNextComponent} style={{ marginTop: "8px" }}>
            <SubheadingXXS color={"#FF4E5B"}>View full details</SubheadingXXS>
          </div>
          <div style={{ padding: "16px 0" }}>
            <PitchInformation pitch_deadline={marketplaceData.until} />
          </div>
        </BottomContainer>
      </div>

      {!canceled &&
        (isUserOwnedMarketplaceCampaign ? (
          <Buttons>
            <Button
              iconFirst
              Icon={<ChatWithoutQuestion />}
              width={"50%"}
              primary
              text={"Chats"}
              textSize={"14px"}
              padding={"10px"}
              mt="0px"
              onClick={openChat}
            />
            <Button
              iconFirst
              Icon={<Hamburger color={"#042234"} />}
              onClick={handleEventOptionsClick}
              width={"50%"}
              textcolor={"#042234"}
              textSize={"14px"}
              text={"Options"}
              padding={"10px"}
              mt="0px"
            />
          </Buttons>
        ) : (
          <Button
            primary
            onClick={openChat}
            width={"100%"}
            textcolor={"#042234"}
            textSize={"14px"}
            text={"Chat with Creative"}
          />
        ))}
    </StyledWrapper>
  );
};
export default Card;
