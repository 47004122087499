/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledParagraphXS = styled.p`
	margin-top: ${(p) => (p.mt ? p.mt : "8px")};
	color: ${(p) => (p.color ? p.color : "var(--gray2)")};
	font-weight: ${(p) => p.fontWeight};
	margin-left: ${(p) => p.ml};

`;

const ParagraphXS = ({ children, mt, onClick, color, fontWeight, ml, ...props }) => {
	return (
		<StyledParagraphXS 
			mt={mt} 
			color={color} 
			onClick={onClick} 
			fontWeight={fontWeight} 
			ml={ml}
			className="paragraph_xs">
			{children}
		</StyledParagraphXS>
	);
};

export default ParagraphXS;
