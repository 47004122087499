import React from 'react';
import styled from 'styled-components';

import { ParagraphS } from '../../Text';
import IconRound from '../../IconRound';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const InfoTitle = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const ProfileInformationItem = ({ Icon, title, answers }) => {
  const isAnswersArray = Array.isArray(answers);

  if (answers === undefined || answers === null) return null;
  
  return (
    <Container>
      <InfoTitle>
        <div
          style={{
            display: 'flex',
            gap: '8px',
            marginRight: '8px',
            minWidth: '180px',
          }}
        >
          {Icon && <IconRound width={'24px'} height={'24px'} Icon={Icon} />}
          <ParagraphS mt={'6px'}>{title}</ParagraphS>
        </div>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <Column>
            {isAnswersArray ? (
              answers.map((answer, index) => (
                <div style={{ display: 'flex', marginRight: '10px' }} key={index}>
                  <ParagraphS mt={'2px'} color={'#FF4E5B'}>
                    {answer === '' || answer === null  ? '-' : answer}
                  </ParagraphS>
                </div>
              ))
            ) : (
              Object.values(answers).map((answer, index) => (
                <div style={{ display: 'flex', marginRight: '10px' }} key={index}>
                  <ParagraphS key={index} mt={'2px'} color={'#FF4E5B'}>
                    {answer === '' || answer === null ? '-' : answer}
                  </ParagraphS>
                </div>
              ))
            )}
          </Column>
        </div>
      </InfoTitle>
    </Container>
  );
};

export default ProfileInformationItem;
