import React from "react";

const Flag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 13 10"
    >
      <path
        fill="#031B2A"
        d="M11.656.5c-.164 0-.304.047-.469.117-1.054.492-1.851.656-2.507.656-1.407 0-2.227-.75-3.844-.75-.82 0-1.828.188-3.211.75v-.21A.57.57 0 001.062.5a.555.555 0 00-.562.563v10.874c0 .329.234.563.563.563a.555.555 0 00.562-.563V9.5c1.242-.54 2.25-.727 3.094-.727 1.734 0 2.93.727 4.664.727.75 0 1.594-.14 2.601-.54.329-.116.516-.374.516-.655V1.227C12.5.78 12.102.5 11.656.5zM11.375 8c-.727.258-1.383.375-1.992.375-.727 0-1.36-.164-2.063-.328-.75-.188-1.593-.399-2.601-.399-.985 0-2.016.211-3.094.633V2.492l.422-.187c1.101-.446 2.015-.657 2.789-.657.656 0 1.148.165 1.71.329.587.187 1.266.421 2.134.421.82 0 1.687-.21 2.695-.632V8z"
      ></path>
    </svg>
  );
}

export default Flag;
