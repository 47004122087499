import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../Theme/theme";

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 48px;
  background-color: white;
  border: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 0.25em;
  outline: none;
  &:focus {
    border: 2px solid ${theme.main};
  }

  input {
    border: none;
    outline: none;
    flex-grow: 1;
    font-size: 14px;
    font-family: "Avenir LT Std";
  }
`;

const Value = styled.span`
  flex-grow: 1;
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;

  p {
    font-family: "Avenir LT Std";
    margin-top: 4px;
  }
`;

const OptionBadge = styled.button`
  display: flex;
  align-items: center;
  border-radius: 10em;
  padding: 0.5em 1em;
  gap: 0.5em;
  //cursor: pointer;
  background: ${theme.gs50};
  outline: none;

  p {
    font-family: "Avenir LT Std";
    margin-top: 2px;
    font-size: 14px;
  }
`;

const RemoveButton = styled.p`
  font-size: 14px;
  color: ${theme.bb500};
  cursor: pointer;

  &:hover ,
  &:focus{
    color: hsl(0, 100%, 50%);
  }
`;


export function MultiInput({ value, onChange, placeholder }) {
  const [inputValue, setInputValue] = useState("");
  const containerRef = useRef(null);

  function addOption() {
    if (inputValue.trim() !== "") {
      onChange([...value, inputValue.trim()]);
      setInputValue("");
    }
  }

  function removeOption(option) {
    onChange(value.filter((o) => o !== option));
  }

  useEffect(() => {
    const handler = (e) => {
      if (e.target !== containerRef.current && e.target.tagName !== "INPUT")
        return;
      switch (e.code) {
        case "Enter":
          addOption();
          break;
        case "Escape":
          containerRef.current.blur();
          break;
        default:
          break;
      }
    };
    containerRef.current?.addEventListener("keydown", handler);

    return () => {
      containerRef.current?.removeEventListener("keydown", handler);
    };
  }, [inputValue, value]);

  return (
    <Container ref={containerRef} tabIndex={0}>
      <Value>
        {value.map((v, index) => (
          <OptionBadge
            key={index}
            onClick={((e) => e.stopPropagation())}
          >
            <p>{v}</p>
            <RemoveButton
              className="remove-btn"
              onClick={() => removeOption(v)}
            >
              &times;
            </RemoveButton>
          </OptionBadge>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
        />
      </Value>
    </Container>
  );
}
