import * as z from "zod";
import { isRichTextNotEmpty } from "../../Utils/utility-functions";

const fileSchema = z.union([
  z.string(),
  z
    .instanceof(File)
    .refine(
      (file) =>
        file.type === "application/pdf" || file.type.startsWith("image/"),
      {
        message: "Invalid file type. Only PDF and image files are allowed.",
      }
    )
    .refine((file) => file.size <= 5 * 1024 * 1024, {
      message: "File size should be less than 5MB.",
    }),
]);

export const schema = z.object({
  /** Form General info */
  is_published: z.number({
    required_error: "Publish status is required.",
  }),
  image: fileSchema,
  title: z.string().min(1, { message: "Title cannot be empty" }),
  description: z
    .string()
    .refine((val) => val === undefined || isRichTextNotEmpty(val), {
      message: "Description cannot be empty.",
    }),
  marketing_goals: z
    .object({
      0: z.string().optional(),
      1: z.string().optional(),
      2: z.string().optional(),
      3: z.string().optional(),
      4: z.string().optional(),
      Other: z.union([z.string().optional(), z.null()]),
    })
    .refine((data) => Object.values(data).some((value) => value), {
      message: "Marketing goals cannot be empty",
    }),
  budget_amount: z.string().min(1, { message: "Budget is invalid" }),
  pitch_deadline: z
    .string()
    .min(1, { message: "Pitch deadline cannot be empty" }),
  delivery_deadline: z
    .string()
    .min(1, { message: "Delivery deadline cannot be empty" }),

  /** Channel requirements */
  channels: z
    .object({
      other: z.object({
        other_urls: z.array(z.string()).optional(),
        other_notes: z.string().optional(),
        other_mandatory: z.array(z.string()).optional(),
        other_requirements: z.string().optional(),
      }),
      thursd: z.object({
        thursd_urls: z.array(z.string()).optional(),
        thursd_notes: z.string().optional(),
        thursd_mandatory: z.array(z.string()).optional(),
        thursd_requirements: z.string().optional(),
      }),
      facebook: z.object({
        facebook_do_what: z.array(z.string()).optional(),
        facebook_mandatory: z.array(z.string()).optional(),
        facebook_tag_account: z.array(z.string()).optional(),
        facebook_tag_hashtag: z.array(z.string()).optional(),
        facebook_instructions: z.string().optional(),
      }),
      instagram: z.object({
        instagram_do_what: z.array(z.string()).optional(),
        instagram_mandatory: z.array(z.string()).optional(),
        instagram_tag_account: z.array(z.string()).optional(),
        instagram_tag_hashtag: z.array(z.string()).optional(),
        instagram_instructions: z.string().optional(),
      }),
      pinterest: z.object({
        pinterest_mandatory: z.array(z.string()).optional(),
        pinterest_do_what: z.array(z.string()).optional(),
        pinterest_tag_account: z.array(z.string()).optional(),
        pinterest_tag_hashtag: z.array(z.string()).optional(),
        pinterest_instructions: z.string().optional(),
      }),
    })
    .refine(
    (data) => 
      (data.pinterest.pinterest_mandatory && data.pinterest.pinterest_mandatory.length > 0) ||
      (data.instagram.instagram_mandatory && data.instagram.instagram_mandatory.length > 0) ||
      (data.facebook.facebook_mandatory && data.facebook.facebook_mandatory.length > 0) ||
      (data.thursd.thursd_mandatory && data.thursd.thursd_mandatory.length > 0) ||
      (data.other.other_mandatory && data.other.other_mandatory.length > 0),
    {
      message: "At least one of the mandatory fields (pinterest, instagram, or facebook) must be filled.",
    }
  ),

  /** Creative */
  influencer_product: z.number({
    required_error: "Influencer product status is required.",
  }),
  influencer_occupations: z
    .object({
      0: z.string().optional(),
      1: z.string().optional(),
      2: z.string().optional(),
      3: z.string().optional(),
      4: z.string().optional(),
      5: z.string().optional(),
      6: z.string().optional(),
      Others:  z.union([z.string().optional(), z.null()]),
    })
    .refine((data) => Object.values(data).some((value) => value), {
      message: "Creative occupations cannot be empty",
    }),
  audience_occupations: z
    .object({
      0: z.string().optional(),
      1: z.string().optional(),
      2: z.string().optional(),
      3: z.string().optional(),
      4: z.string().optional(),
      5: z.string().optional(),
      6: z.string().optional(),
      Others:  z.union([z.string().optional(), z.null()]),
    })
    .refine((data) => Object.values(data).some((value) => value), {
      message: "At least one checkbox must be selected",
    }),

  influencer_languages: z.array(z.string().uuid()).min(1, {
    message: "At least one influencer language must be selected",
  }),
  audience_countries: z
    .array(z.string())
    .min(1, { message: "Audience countries cannot be empty" }),
});
