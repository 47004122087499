/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledButton = styled.button`
	border: ${(p) => (p.primary ? "transparent" : "1px solid #e3e3e3")};
	background: ${(p) => (p.primary ? p.theme.main : p.theme.white)};
	color: ${(p) => p.primary ? p.theme.white : p.textcolor ? p.textcolor : p.theme.main};
	font-size: 16px;
	font-weight: 700;
	gap: 6px;
	padding: ${(p) => (p.primary ? "16px 24px" : "6px 10px")};
	padding-bottom: ${(p) => p.pb};
	box-shadow: ${(p) =>p.primary ? " 0px 1px 0px #982e36" : "0px 1px 0px #E3E3E3"};
	border-radius: 1px;
	width: ${(p) => p.fwidth && "100%"};
	font-family: "Avenir LT Std";
	cursor: pointer;
	color: #042234;
    display: flex;
	justify-content: flex-start;
    align-items: center;

    &:active {
        background-color: var(--g-s50);
     }

`;

const ButtonSmall = ({
	Icon,
	text,
	primary,
	mt24,
	textcolor,
	fwidth,
	onClick,
	mt,
	children,
}) => {
	return (
		<StyledButton
			primary={primary}
			mt={mt}
			textcolor={textcolor}
			fwidth={fwidth}
			onClick={onClick}
			mt24={mt24}
		>
			<span style={{marginTop: '3px'}}>{Icon || null}</span>
			<span>{text}</span>
		</StyledButton>
	);
};

export default ButtonSmall;
