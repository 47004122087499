/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerInfluencer, registerCompany } from "../../app/features/authSlice";
import { useNavigate } from "react-router-dom";

/* -------------------------- Internal Dependencies ------------------------- */
import { LayoutCenterTop, GridContainer } from "../../Components/Layout";
import { InputLabel, RadioButton } from "../../Components/Forms";
import { Button } from "../../Components/UI";
import { PasswordBox } from "../../Components/Common";

/* -------------------------- Internal SVG Dependencies ------------------------- */
import Logo from "../../Icons/Logo";
import { HeadingXL, ParagraphM, SubheadingS } from "../../Components/Text";

const RegisterAccount = () => {
	const [selectedOption, setSelectedOption] = useState('Creative');
	const [password, setPassword] = useState("");
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [email, setEmail] = useState("");

	const [firstNameError, setFirstNameError] = useState(false);
	const [lastNameError, setLastNameError] = useState(false);
	const [passwordError, setpasswordError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showPossibleErrors, setShowPossibleErrors] = useState(false)

	const errors = useSelector((state) => state.auth?.errors);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleRadioButtonChange = (event) => {
		setSelectedOption(event.target.value);
	};

	useEffect(() => {
		firstname.length < 2 ? setFirstNameError(true) : setFirstNameError(false)
		lastname.length < 3 ? setLastNameError(true) : setLastNameError(false)

		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		emailRegex.test(email) ? setEmailError(false) : setEmailError(true)
		password.length < 8 ? setpasswordError(true) : setpasswordError(false)

	}, [firstname, lastname, password, email])


	const handleSubmit = (e) => {
		e.preventDefault();
		setShowPossibleErrors(true)

		if (!firstNameError && !lastNameError && !emailError && !passwordError) {

			const registrationAction =
				selectedOption === "Creative"
					? registerInfluencer
					: registerCompany;

			dispatch(
				registrationAction({
					firstname: firstname,
					lastname: lastname,
					email: email,
					password: password,
				})
			).then((res) => {

				if (res?.payload?.success) {
					setErrorMessage("");
					setShowPossibleErrors(false)
					navigate("/welcome");

				} else {
					if (errors?.email[0].includes("email has already been taken") || errors?.email?.includes("email has already been taken")) {
						setErrorMessage("This email has already been taken");
					} else {
						setErrorMessage("Registration unsuccessful. Please double-check your provided information.");
					}
				}
			});
		}
	};

	return (
		<LayoutCenterTop>
			<Logo />
			<HeadingXL>Register account</HeadingXL>
			<ParagraphM>
				Register for an account on myThursd and open up the floral meetup spot where advertisers meet creatives, and vice versa.
				<br/>
				<br/>
				Advertisers can be growers, breeders, traders, or any other company that seeks collaborations with creatives.
				<br/>
				<br/>
				Creatives can be floral designers, influencers, photographers, or any other person who seeks collaborations with advertisers to work with and receive flowers and plants.
			</ParagraphM>
			<form onSubmit={handleSubmit}>
				<SubheadingS>What are you?</SubheadingS>
				<div style={{ display: "flex", alignItems: 'center', gap: 8, marginTop: 8 }}>
					<RadioButton
						label="Advertiser"
						value="Advertiser"
						checked={selectedOption === 'Advertiser'}
						onChange={handleRadioButtonChange}

					/>
					<RadioButton
						label="Creative"
						value="Creative"
						checked={selectedOption === 'Creative'}
						onChange={handleRadioButtonChange}
					/>
				</div>
				<GridContainer>
					<InputLabel
						error={(firstNameError && showPossibleErrors)}
						value={firstname}
						onChange={(e) => setFirstname(e.target.value)}
						label="First name"
						errorMessage={'First name must be at least 2 characters long.'}

					></InputLabel>
					<InputLabel
						value={lastname}
						onChange={(e) => setLastname(e.target.value)}
						label="Last name"
						error={(lastNameError && showPossibleErrors)}
						errorMessage={'Last name must be at least 3 characters long.'}
					></InputLabel>
				</GridContainer>
				<InputLabel
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					InputLabel
					label="Email address"
					email
					error={(emailError && showPossibleErrors)}
					errorMessage={'Invalid email address. Please provide a valid email.'}

				></InputLabel>
				<PasswordBox
					error={(passwordError && showPossibleErrors)}
					label="Create a password"
					password={password}
					placeholder="At least 8 characters"
					onChange={(e) => setPassword(e.target.value)}
					passwordstrengthbar
					type={showPassword ? "text" : "password"}
					setShowPassword={setShowPassword}
					showPassword={showPassword}
				/>
				{(errorMessage && showPossibleErrors && <span
					style={{
						display: "block",
						color: "#982336",
						fontFamily: "Avenir LT Std",
						fontSize: "10px",
						lineHeight: "14px",
						marginLeft: "2px",
					}}
				>
					{errorMessage}
				</span>)}
				<Button fwidth mt24 primary text="Create account" />
			</form>
		</LayoutCenterTop>
	);
};

export default RegisterAccount;
