import React, { useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react18-input-otp";

import { HeadingXL, ParagraphM, SubheadingS } from "../../Components/Text";
import { LayoutCenter } from "../../Components/Layout";
import { theme } from "../../Theme/theme";
import { Button, RoundButtonLink } from "../../Components/UI";
import apiClient from "../../services/api";
import LeftArrow from "../../Icons/LeftArrow";
import Toast from "../../Components/Common/Messages/Toast";

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextBox = styled.div`
  color: ${theme.main};
  cursor: pointer;
  margin-bottom: 16px;

  &:hover {
    color: #982e36;
  }
`;

const ContainerInput = styled.div`
  input {
    caret-color: transparent;
  }
`;

const PhoneVerificationPage = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const locationData = useLocation();
  const [emailOrUsernameError, setEmailOrUsernameError] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  const submitOtp = () => {
    apiClient
      .post("password/check", {
        token: otp,
      })
      .then((resp) => {
        navigate("/createpassword", {
          state: {
            token: otp,
            email: locationData.state.email,
          },
        });
      });
  };
  const requestPasswordCode = () => {
    apiClient
      .post("password/email", {
        email: locationData.state.email,
      })
      .then((resp) => {
        if (resp.data.success || resp.success) {
          setEmailOrUsernameError("Success");
          setShowToast(true);
        }
      });
  };
  return (
    <LayoutCenter>
      <Toast
        showToast={showToast}
        message={emailOrUsernameError}
        setShowToast={setShowToast}
      />
      <RoundButtonLink
        Icon={
          <LeftArrow
            height={"16px"}
            onClick={() => navigate("/forgotpassword")}
          />
        }
      />
      <HeadingXL>Enter verification code</HeadingXL>
      <ParagraphM>
        Enter the code that we have sent to your email{" "}
        {locationData.state.email}
      </ParagraphM>
      <TextContainer>
        <SubheadingS mt="0px">Pincode</SubheadingS>
        <TextBox onClick={requestPasswordCode}>
          <ParagraphM mt="16px">Request new code</ParagraphM>
        </TextBox>
      </TextContainer>
      <ContainerInput>
        <OtpInput
          value={otp}
          onChange={handleChange}
          placeholder="&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;"
          numInputs={6}
          isInputNum={true}
          containerStyle={{ display: "flex ", justifyContent: "space-between" }}
          inputStyle={{
            height: "90px",
            width: "55px",
            border: `1px solid ${
              otp.length === 6 ? "var(--p500)" : "var(--g-s100)"
            }`,
            color: "#727272",
            fontSize: "16px",
            fontFamily: "Avenir",
            fontWeight: "500",
            lineHeight: "24px",
            textAlign: "center",
            borderRadius: "4px",
          }}
        />

        {otp.length === 6 ? (
          <Button fwidth primary text="Submit" onClick={submitOtp} />
        ) : null}
      </ContainerInput>
    </LayoutCenter>
  );
};

export default PhoneVerificationPage;
