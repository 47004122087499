import React from 'react'

const Bag = (props) => {
    return (
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
{/* <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="#EBEEEF"/> */}
<path d="M9.5 8.75V7.8125C9.5 7.10938 10.0859 6.5 10.8125 6.5H14.1875C14.8906 6.5 15.5 7.10938 15.5 7.8125V8.75H17C17.8203 8.75 18.5 9.42969 18.5 10.25V16.25C18.5 17.0938 17.8203 17.75 17 17.75H8C7.15625 17.75 6.5 17.0938 6.5 16.25V10.25C6.5 9.42969 7.15625 8.75 8 8.75H9.5ZM10.625 8.75H14.375V7.8125C14.375 7.71875 14.2812 7.625 14.1875 7.625H10.8125C10.6953 7.625 10.625 7.71875 10.625 7.8125V8.75ZM9.875 9.875V16.625H15.125V9.875H9.875ZM7.625 10.25V16.25C7.625 16.4609 7.78906 16.625 8 16.625H8.75V9.875H8C7.78906 9.875 7.625 10.0625 7.625 10.25ZM17.375 16.25V10.25C17.375 10.0625 17.1875 9.875 17 9.875H16.25V16.625H17C17.1875 16.625 17.375 16.4609 17.375 16.25Z" fill="#031B2A"/>
<rect x="0.5" y="0.5" width="24" height="24" rx="12" stroke="#D8DCDF"/>
</svg>

    );
  }
  
  export default Bag;