import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { AiOutlineCheck } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";

import useEventOptionsModal from "../../app/hooks/useEventOptionsModal";
import Modal from "../../app/modals/Modal";
import PitchOption from "../../Components/Common/Chat/PitchOption";
import apiClient from "../../services/api";
import { formatDate, parseDateString } from "../../Utils/utility-functions";

const Line = styled.div`
  border-bottom: 1px solid #E0E0E0;
`

const EventOptionsModal = ({ overview, resetSelectedMarketplaceData, refreshData, clickedCardData }) => {
    const eventOptionsModal = useEventOptionsModal();
    const navigate = useNavigate();

    const deleteIdea = () => {
        try {
          apiClient.post(`marketplace/${clickedCardData?.id}/delete`).then((response) => {
            eventOptionsModal.onClose();
            navigate('/meetupspot');
            refreshData();
          }).catch((error) => {
            console.error("Error deleting item: ", error);
          });
        } catch (error) {
          console.error("Error deleting item: ", error);
        }
    }
        
    const bodyContent = (
        <div>
            <PitchOption
                title={`Extend deadline ${formatDate(parseDateString(clickedCardData?.until))}`}
                onClick={() => {
                  navigate(`/projectsbycreatives/editDeadline?id=${clickedCardData?.id}`);
                }}
                Icon={<AiOutlineCheck size={16} color={"#61B795"}/>}
            />
            <Line />
        {clickedCardData && 
            <PitchOption 
                title="Edit idea/event" 
                onClick={() => {
                  navigate(`/projectsbycreatives/updateIdea?id=${clickedCardData?.id}`);
                }}
                Icon={<BsPencil size={16} color={"#FFAC09"}/>}
            />
        }
            <Line />
            <PitchOption 
                title="Remove this idea/event"
                onClick={deleteIdea}
                Icon={<IoMdClose size={16} color={"#FF4E5B"}/>}
            />
        </div>
    )

    return (
        <Modal
            title={`Options for ${clickedCardData ? clickedCardData?.title : ''}`}
            isOpen={eventOptionsModal.isOpen}
            onClose={eventOptionsModal.onClose}
            body={bodyContent}
            overview={overview}
            resetSelectedMarketplaceData={resetSelectedMarketplaceData} 

        />);
}


export default EventOptionsModal;