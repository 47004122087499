import styled from "styled-components";
import { FaRegUser } from "react-icons/fa6";
import { IoChatboxOutline } from "react-icons/io5";

import { HeadlineL } from "../../Text";
import SubheadingXXXS from "../../Text/SubheadingXXXS";
import SmallLeftArrow from "../../../Icons/SmallLeftArrow";
import ParagraphXS from "../../Text/ParagraphXS";
import influencerProfile from '../../../Images/influencer-profile.png'
import HeaderButton from "../../UI/Buttons/HeaderButton";

const StyledButton = styled.button`
  margin-bottom: ${(props) => props.marginBottom || "0px"}; 
  z-index: 2;
  background-color: ${(props) => props.secondary ? "#fff" : "var(--p500)"}; 
  border: none;
  display:  flex;
  cursor: pointer;
  box-shadow: 0px 1px 0px #982e36;
  align-self: flex-start;
  justify-content: center;
`;

const ButtonBackground = styled.div`
  background-color: ${(props) => props.secondary ? "#fff" : "var(--p500)"}; 
  border-radius: 4px;
  display: inline-block;
  padding: ${(props) => props.padding || "6px 10px"}; 

  .sub_heading_xxs {
    color: white;
  }
`;

const AvatarBox = styled.div`
	display: flex;
	align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 88px;
  width: 88px;
  overflow: hidden;
`;

const Avatar = styled.img`
    width: 100%;
    height: 100%;
    background-color: var(--b-b50);
`

const InfluencerHeader = ({ title, data, onClickFirstButton, onClickSecondButton, onClickThirdButton }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', }}>
      <StyledButton onClick={onClickFirstButton}>
        <ButtonBackground>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SmallLeftArrow />
            <div style={{ marginLeft: '8px' }}>
              <SubheadingXXXS mt={'0px'}>Back to project by advertisers</SubheadingXXXS>
            </div>
          </div>
        </ButtonBackground>
      </StyledButton>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column', }}>
          <HeadlineL mt={'8px'} mb={'8px'} color={'#F1F1F1'}>{title}</HeadlineL>
          <div style={{ zIndex: 1, marginBottom: '16px' }}>
            <ParagraphXS color={'var(--g-s100)'} mt={"0px"}>
              Learn about their channels & background:
            </ParagraphXS>
          </div>
          <div style={{ display: 'flex', gap: '8px' }}>
            <HeaderButton
              fwidth
              onClick={onClickSecondButton}
              text={"Chat"}
              primary
              Icon={<IoChatboxOutline size={16} color={"#fff"} />}
              iconFront
            />
            <HeaderButton
              fwidth
              onClick={onClickThirdButton}
              text={"Profile"}
              iconFront
              Icon={<FaRegUser size={16} color={"#FF4E5B"} />}
            />
          </div>
        </div>
        <AvatarBox>
          <Avatar src={data?.image || influencerProfile} alt="Creative image" />
        </AvatarBox>
      </div>
    </div>
  );
}

export default InfluencerHeader;
