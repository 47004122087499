import React from "react";

const Money = (props) => {
  return (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="24" height="24" rx="12" fill="#EBEEEF"/>
    <path d="M9.3125 12.125C8.98438 12.125 8.75 11.8906 8.75 11.5625C8.75 11.2578 8.98438 11 9.3125 11H10.0859C10.7188 8.84375 12.7344 7.25 15.125 7.25H15.6875C15.9922 7.25 16.25 7.50781 16.25 7.8125C16.25 8.14062 15.9922 8.375 15.6875 8.375H15.125C13.3672 8.375 11.8672 9.47656 11.2578 11H14.9375C15.2422 11 15.5 11.2578 15.5 11.5625C15.5 11.8906 15.2422 12.125 14.9375 12.125H11C11 12.2656 11 12.3828 11 12.5C11 12.6406 11 12.7578 11 12.875H14.9375C15.2422 12.875 15.5 13.1328 15.5 13.4375C15.5 13.7656 15.2422 14 14.9375 14H11.2578C11.8672 15.5469 13.3672 16.625 15.125 16.625H15.6875C15.9922 16.625 16.25 16.8828 16.25 17.1875C16.25 17.5156 15.9922 17.75 15.6875 17.75H15.125C12.7344 17.75 10.7188 16.1797 10.0859 14H9.3125C8.98438 14 8.75 13.7656 8.75 13.4375C8.75 13.1328 8.98438 12.875 9.3125 12.875H9.875C9.875 12.7578 9.875 12.6406 9.875 12.5C9.875 12.3828 9.875 12.2656 9.875 12.125H9.3125Z" fill="#031B2A"/>
    <rect x="0.5" y="0.5" width="24" height="24" rx="12" stroke="#D8DCDF"/>
  </svg>
  );
}

export default Money;

