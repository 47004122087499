import { useEffect, useState } from 'react';
import Loading from '../../Components/Loading';
import { useNavigate } from 'react-router-dom';

export default function MailRedirectPage() {
  const navigate = useNavigate();
  const localStorageToken = localStorage.getItem('token')
	const [token] = useState(localStorageToken)
  const currentURL = window.location.pathname;

  const pathSegments = currentURL.split('/');
  const campaignId = pathSegments[pathSegments.length - 2];
  
  useEffect(() => {
    if (campaignId && token) {
      //Voer de navigatie uit met een vertraging van 1 seconde
      navigate(`/detailpage/${campaignId}`);
    }
  }, [campaignId, token]);
  

  return (
    <Loading />
  )
}
