import React from "react";
import styled from "styled-components";

import Bag from "../../Icons/Bag";
import Calendar from "../../Icons/Calendar";
import Money from "../../Icons/Money";
import { ParagraphS } from "../../Components/Text";

const Container = styled.div`
`;

const Row = styled.div`
  display: flex;
  gap: ${(props) => props.gap || "0"}; 
  margin-top: 4px;
  margin-bottom: ${(props) => props.marginBottom  ? props.marginBottom : '8px'};
  align-items: center;
  margin-left: ${(props) => props.marginLeft  ? props.marginLeft : '0px'};
`;

function Summary({influencerPitch, data,marginBottom, bid, bidMotivation, bidNote, smallCard}) {

  // This regular expression (/:\d{2}$/) matches the last :XX (seconds) pattern 
  // in the string and replaces it with an empty string, effectively 
  // removing the seconds part.
  //const dateWithoutSeconds = data.pitched_on.replace(/:\d{2}$/, "");

  return (
		<Container>
          <Row gap="5px" marginBottom="16px">
            <Calendar/>
            <ParagraphS color={'#364E5D'}  mt={"0px"}>Pitched </ParagraphS>
            {<ParagraphS color={'#A4AFB6'} mt={"0px"}>{data?.pitched_on || data?.created_at}</ParagraphS>}
          </Row>
          {smallCard ? null :
            <Row gap="5px" marginLeft="31px" marginBottom="16px" >
              <ParagraphS color={'#364E5D'} mt={"0px"}>Motivation</ParagraphS>
              <ParagraphS color={'#A4AFB6'} mt={"0px"}>{data?.motivation  || 'No motivation'}</ParagraphS>
            </Row>
          }
          <Row gap="5px" marginBottom="16px">
          <Bag />
          <ParagraphS color={'#364E5D'} mt={"0px"}>Occupation</ParagraphS>
          {data.occupation ? (
            Object.keys(data.occupation).map((key, index) => (
              <div key={index}>
                <ParagraphS color={'#A4AFB6'} mt={"0px"}>{data?.occupation[key]}</ParagraphS>
              </div>
            ))
          ) : (
            <ParagraphS color={'#A4AFB6'}>-</ParagraphS>

          )}
        </Row>
          {influencerPitch ? (
            <Row gap="5px" marginBottom="16px">
            <Money/>
            <ParagraphS color={'#364E5D'} mt={"0px"}>Pitch status</ParagraphS>
            <ParagraphS color={'#A4AFB6'} mt={"0px"}>{data?.status}</ParagraphS>
          </Row>
          ) : null }
          <Row gap="5px" marginBottom="16px">
            <Money/>
            <ParagraphS color={'#364E5D'} mt={"0px"}>Bid</ParagraphS>
            {/* {data && data?.bid || bid && 
              <ParagraphS color={'#A4AFB6'}>{data?.bid || bid}</ParagraphS>
            } */}
              <ParagraphS color={'#A4AFB6'} mt={"0px"}>{data?.bid || bid}</ParagraphS>
          </Row>
          {smallCard ? null :
            <>
              <Row gap="5px" marginLeft="31px" marginBottom="18px">
                <ParagraphS color={'#364E5D'} mt={"0px"}>Motivation</ParagraphS>
                <ParagraphS color={'#A4AFB6'} mt={"0px"}>{data?.motivation || bidMotivation || 'No motivation'}</ParagraphS>
              </Row>
              <Row gap="5px" marginLeft="31px" marginBottom="25px">
                <ParagraphS color={'#364E5D'} mt={"0px"}>Notes</ParagraphS>
                <ParagraphS color={'#A4AFB6'} mt={"0px"}>{bidNote || 'No notes'}</ParagraphS>
              </Row>
            </>
          }
		</Container>
	);
}

export default Summary;
