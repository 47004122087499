/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components';
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ErrorMessage, HeadlineXL, ParagraphM, SubheadingS } from '../../Text';
import { LayoutCenterTop } from '../../Layout';
import CloseButton from '../../../Icons/CloseButton';
import { InputLabel } from '../../Forms';
import RichTextComponent from './RichTextComponent';
import { Button } from '../../UI';
import apiClient from '../../../services/api';
import Loading from '../../Loading';
import { FileDropZone } from '../../Common';

const ContentContainer = styled.div`
  @media (min-width: 768px) {
    margin: 0 16px;
	}
}`

const HR = styled.div`
  border: 1px solid var(--g-s100);
  width: 100%;
  margin-top: 20px;
`

const TopContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: ${(props) => props.mt || "20px"};
  padding: 16px;
`

const UpdateIdeaOrEvent = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [marketplaceData, setMarketplaceData] = useState(null);

  const [details, setDetails] = useState(null);
  const [requesting, setRequesting] = useState(null);
  const [returning, setReturning] = useState(null);
  const [title, setTitle] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [image, setImage] = useState(null)
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false)

 


  useEffect(() => {
    const selectedMarketplaceDataId = searchParams.get('id')
    apiClient.get(`/marketplace/${selectedMarketplaceDataId}`).then((response) => {
      setMarketplaceData(response.data.data);

      setTitle(response.data.data.title);
      setSubtitle(response.data.data.subtitle);
      setDetails(response.data.data.details);
      setRequesting(response.data.data.requesting);
      setReturning(response.data.data.returning)
      setImage(response.data.data.image)
    })


  }, [searchParams]);


  if (!marketplaceData) {
    return null;
  }


  const handleUpdateIdea = () => {
    if (!title || !requesting || !returning || !details) {
      setErrorMessage("Fill in all fields before updating.");
      return;
    }

    let formData = new FormData();

    if (image) {
      formData.set("image", image);
    }

    formData.append("title", title)
    formData.append("subtitle", subtitle)
    formData.append("requesting", requesting)
    formData.append("details", details)
    formData.append("returning", returning)

    const marketplaceDataId = searchParams.get('id')

   apiClient.post(`marketplace/${marketplaceDataId}`, formData,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }

    ).then(() => {
      navigate("/meetupspot")
      setIsLoading(false)

    })
  };

  return (
    <LayoutCenterTop>
      {isLoading ? (
        <Loading height={'100%'} />
      ) : (
        <>
          <TopContainer mt={"-10px"}>
            <div onClick={() => navigate("/meetupspot")} style={{ cursor: 'pointer' }} >
              <CloseButton />
            </div>
          </TopContainer>
          <HeadlineXL mt={"8px"}>Update an idea or event to the Projects by creatives</HeadlineXL>
          <ParagraphM mr={"24px"} color={"#4F4F4F"}>You as a creative can pitch your events and ideas freely for advertisers/brands to see. What cool idea will you collaborate on?</ParagraphM>
          <HR />
          <ContentContainer>
            <SubheadingS mt={'16px'}>Upload a file to this project</SubheadingS>
            <FileDropZone setImage={setImage} image={image} />
            <SubheadingS>Title</SubheadingS>
            <ParagraphM mt={"4px"} color={"#727272"}>A short but attractive title that summarizes your project, event, or idea</ParagraphM>
            <InputLabel
              value={title}
              onChange={(e) => setTitle(e.target.value)} 
              name="title"
            />
            <SubheadingS>Sub-title</SubheadingS>
            <ParagraphM mt={"4px"} color={"#727272"}>Optional sub-title for additional information</ParagraphM>
            <InputLabel
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              name="sub-title"
            />
            <RichTextComponent
              title={"What do you want from the brands/advertisers?"}
              editorContent={requesting}
              setEditorContent={setRequesting}
              details={"“E.g., I would like to receive yellow and red flowers to use at this amazing event I’m working for.”"} />
            <RichTextComponent
              title={"What will you give in exchange to the brands/advertisers?"}
              editorContent={returning}
              setEditorContent={setReturning}
              details={"“E.g., I provide professional photographs and exposure on my Instagram channel with 24k followers.”"} />

            <RichTextComponent
              title={"Details"}
              editorContent={details}
              setEditorContent={setDetails}
              details={"Write a detailed description of the project, event, or idea you have. Advertisers/brands or other creatives need to be triggered to start a conversation with you!"}
            />


            {errorMessage &&
              <ErrorMessage message={errorMessage} />
            }

            <Button text={"Save idea/event"}
              fwidth
              primary
              onClick={handleUpdateIdea}
            />
          </ContentContainer>
        </>
      )}
    </LayoutCenterTop>

  )
};
export default UpdateIdeaOrEvent;
