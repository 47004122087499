/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

/* -------------------------- Internal Dependencies ------------------------- */
import { SubheadingXXS } from "../Text";

/* -------------------------- Internal Data Dependencies ------------------------- */
import Close from "../../Icons/Close";

const StyledListItem = styled.li`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 14px;
	gap: 8px;
	background: #f1f1f1;
	border: 1px solid #e3e3e3;
	box-shadow: 0px 1px 0px #e3e3e3;
	border-radius: 78px;
`;

const StyledButton = styled.button`
	border: none;
	cursor: pointer;
`;
const InputTrackerItem = ({ message, deleteItem, question }) => {
	const handleSubmit = (e) => {
		e.preventDefault();
		deleteItem(question, message);
	};

	return (
		<StyledListItem>
			<SubheadingXXS>{message}</SubheadingXXS>
			<StyledButton onClick={handleSubmit} data-testid="c-multi-select-dropdown__close">
				<Close width={8} height={8} />
			</StyledButton>
		</StyledListItem>
	);
};

export default InputTrackerItem;
