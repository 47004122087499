import React, { useState, useEffect, useContext } from 'react'

import { CustomCheckbox } from '../../Forms';
import CollapsibleCheckbox from '../../Forms/CheckboxList/CollapsibleCheckbox';
import { FormContext } from '../../../Pages/ProfileSetup/FormProvider';

export default function CheckboxListSubOptions({ title,question, value, selectedOptions, onChange }) {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const formCtx = useContext(FormContext)

    useEffect(() => {
        let newSelectedOptionsDict = { ...formCtx.formData };

        if(!isCollapsed){
            if( newSelectedOptionsDict[question?.name]){
                delete newSelectedOptionsDict[question.name][title]
                formCtx.createFormData(newSelectedOptionsDict)
            }
          
        }

    }, [isCollapsed]);

    return (
        <div style={{ marginTop: 16 }}>
            {/*renderCheckbox(key, key)*/
                <CollapsibleCheckbox
                    label={title}
                    isChecked={isCollapsed}
                    handleCheckboxChange={() => setIsCollapsed(!isCollapsed)} />
            }
            {isCollapsed &&
                <div style={{ marginLeft: '20px' }}>
                    {value.map((subValue, index) =>
                        <CustomCheckbox
                            id={subValue}
                            key={index}
                            tabIndex={-1}
                            text={subValue}
                            checked={Array.isArray(selectedOptions[title]) ? selectedOptions?.[title]?.includes(subValue) : selectedOptions?.[title] === subValue}
                            onChange={() => onChange(question, title, subValue)}
                            onClick={() => onChange(question, title, subValue)}
                        />                    
                        )}
                </div>
            }
        </div>
    )
}
