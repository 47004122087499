import React from 'react'
import styled from 'styled-components';

const StyledHiddenCheckboxInput = styled.input`
    height: 20px;
    width: 20px;
    margin: 0;
    position: absolute;
    border: none;
    cursor: pointer;
    opacity: 0;
`;

const HiddenCheckboxInput = ({ value, handleChange}) => {
    return (
        <StyledHiddenCheckboxInput
            type="checkbox"
            value={value}
            onChange={handleChange} />
    )
}

export default HiddenCheckboxInput
