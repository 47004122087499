import React, { useEffect } from "react";
import styled from "styled-components";

import { ParagraphM, SubheadingS } from "../../Components/Text";

const StyledList = styled.select`
  text-align: center;
  width: 100%;
  padding: 12px 16px;
  display: inline-block;
  text-align: left;
  position: relative;
  border: 1px solid #697b86;
  border-radius: 4px;
  padding: 12px 16px;
  box-sizing: border-box;
  height: 48px;
  appearance: none;
  margin-top: ${(p) => (p.mt ? p.mt : "8px")};
  background-image: url("Dropdown.png");
  background-position: right 15.5px center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

const StyledOption = styled.option`
  padding: 12px 16px;
  border-left: 1px solid #697b86;
  border-right: 1px solid #697b86;
  border-bottom: 1px solid #697b86;
  width: 50px;
  height: 50px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 4px;
`;

const NationalitySelect = ({
    data,
    defaultValue,
    onChange,
    label,
    subheading
}) => {
    useEffect(() => {
        if (defaultValue && data) {
            const selectedObject = data.find(item => item.nationality === defaultValue);

            if (selectedObject) {
                onChange(selectedObject.id)
            }

        }
    }, [defaultValue, data, onChange])

    return (
        <div>
            {label && <SubheadingS>{label}</SubheadingS>}
			{subheading && <ParagraphM>{subheading}</ParagraphM>}
            <StyledList onChange={(e)=>onChange(e.target.value)} defaultValue={defaultValue}>
                <StyledOption key="default" value="" selected>
                    {defaultValue || 'Select your nationality'}
                </StyledOption>

                {data.map((item) => (
                    <StyledOption key={item.id} value={item.id}>
                        {item.nationality}
                    </StyledOption>
                ))}
            </StyledList>
        </div>
    );
};

export default NationalitySelect;
