import React from "react";
import { LayoutCenterTop } from "../../../Components/Layout";
import { HeadingXL, ParagraphM } from "../../../Components/Text";
import { useDataSource } from "../../../Utils/hooks/useDataSource";
import CompanyUserForm from "./CompanyUserForm";
import FormProvider from "../../../Pages/ProfileSetup/FormProvider";
import { useSelector } from "react-redux";

export default function SetupProfileCompany() {
  const { resource: questions } = useDataSource("/questions?type=profile");
  const answers = useSelector((state) => state.profile.profile);

  const profileData = questions?.data;

  return (
    <FormProvider>
      <LayoutCenterTop>
        <HeadingXL>Tell us about your company</HeadingXL>
        <ParagraphM>
          Please fill in the information below so we can finalize your
          registration.
        </ParagraphM>

        <CompanyUserForm
          data={profileData}
          answers={answers?.data?.profile}
          initialLogo={answers?.data?.logo}
          customLabel="Select your country "
        />
      </LayoutCenterTop>
    </FormProvider>
  );
}
