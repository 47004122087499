import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import ReactGA from 'react-ga4';

import RegisterAccount from "./Pages/RegisterPage/RegisterAccount";
import LoginPage from "./Pages/Login/LoginPage";
import WaitForActivation from "./Pages/Activitation/WaitForActivation";
import UserInfo from "./Pages/ProfileSetup/UserInfo";
import { Helmet } from "react-helmet";
import Onboarding from "./Pages/Onboarding/Onboarding";
import SetupPage from "./Pages/ProfileSetup/SetupPage";
import SetupProfileInfluencer from "./Components/Creatives/ProfileSetup/SetupProfileInfluencer";
import SetupProfileCompany from "./Components/Advertisers/ProfileSetup/SetupProfileCompany";
import PasswordChanged from "./Pages/ForgotPassword/PasswordChanged";
import CreatePassword from "./Pages/ForgotPassword/CreatePassword";
import Card from "./Components/Common/Card/Campaigns/Card";
import DetailCampaignPage from "./Pages/Campaigns/DetailCampaignPage";
import HoorayPopUp from "./Components/Common/HoorayPopUp";
import ErrorPage from "./Pages/Error/ErrorPage";
import ErrorBoundary from "./Components/ErrorBoundary";
import OnboardingThankYouPage from "./Pages/Onboarding/OnboardingThankYouPage";
import WelcomePage from "./Pages/RegisterPage/WelcomePage";
import HoorayScreen from "./Components/Common/PitchPage/HoorayScreen";
import ProposeChanges from "./Components/Advertisers/Chat/ProposeChanges";
import ChatPitch from "./Pages/Chat/ChatPitch";
import CampaignPage from "./Pages/Campaigns/CampaignPage";
import { useSelector } from "react-redux";
import CardMarketplace from "./Components/Advertisers/Marketplace/Card";
import IdeaOrEventScreen from "./Components/Advertisers/Marketplace/IdeaOrEventScreen";
import { PusherProvider } from "./app/features/PusherContext";
import { fetchProfile } from "./app/features/profileSlice";
import MarketplaceOverview from "./Components/Advertisers/Marketplace/MarketplaceOverview";
import EditDeadline from "./Components/Advertisers/Marketplace/EditDeadline";
import { fetchLanguages } from "./app/features/languagesSlice";
import { fetchCountries } from "./app/features/countriesSlice";
import InviteInfluencer from "./Pages/Creatives/InviteInfluencer";
import ProfilePageOtherInfluencer from "./Pages/ProfilePage/ProfilePageOtherInfluencer";
import MailRedirectPage from "./Pages/MailRedirect/MailRedirectPage";
import AddWebsitePage from "./Pages/AddChannels/AddWebsitePage";
import FacebookConnect from "./Pages/Connect/FacebookConnect";
import AddThursdPage from "./Pages/AddChannels/AddThursdPage";
import AddOfflinePage from "./Pages/AddChannels/AddOfflinePage";
import Pitch from "./Components/Common/PitchPage/Pitch";
import AddInstagramPage from "./Pages/AddChannels/AddInstagramPage";
import PrivateRoutes from "./Components/Routes/ProtectedRoute";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import RouteChangeTracker from "./Components/GoogleAnalytics/RouteChangeTracker";
import CampaignDetail from "./Pages/Campaigns/CampaignDetail";
import InfluencerPitch from "./Components/Creatives/Pitch/InfluencerPitch";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import PhoneVerificationPage from "./Pages/ForgotPassword/PhoneVerificationPage";
import UpdateIdeaOrEvent from "./Components/Advertisers/Marketplace/UpdateIdeaOrEvent";
import Project from "./Pages/Project/Project";
import ProjectDetails from "./Pages/Project/ProjectDetails";

function App() {
  const user = useSelector((state) => state.profile.profile);
  const localStorageToken = localStorage.getItem("token");
  const [token, setToken] = useState(null);
  const dispatch = useDispatch();

  //add Google Analytics
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    const routePattern = /invite\/[^/]+\/[^/]+/;
    const currentURL = decodeURIComponent(window.location.pathname);

    /**
     * Als de url invite bevat en daarna een campaign id en een code heeft
     * dan moet hij het token er uit halen en in het locatStorage zetten.
     */
    if (routePattern.test(currentURL)) {
      const pathSegments = currentURL.split("/");
      const tokenUrl = pathSegments[pathSegments.length - 1];

      // Roep setLocalStorageToken binnen de useEffect aan
      const setLocalStorageToken = async () => {
        if (tokenUrl) {
          await localStorage.setItem("token", tokenUrl);
          setToken(tokenUrl);
        }
      };

      setLocalStorageToken();
    } else {
      setToken(localStorageToken || null);
    }
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(fetchProfile());
      dispatch(fetchLanguages());
      dispatch(fetchCountries());
    }
  }, [token]);

  const getMainElement = function (user) {
    if (!user?.data?.onboarded) {
      return <Onboarding />;
    } else if (!user.data?.user?.is_active && !user.data?.is_active) {
      return <WaitForActivation />;
    } else if (user?.data.profile_filled) {
      return <CampaignPage />;
    } else {
      return <UserInfo />;
    }
  };

  const mainContent = (
    <Routes>
      {/* Private routes */}
      <Route element={<PrivateRoutes />}>
        {user?.data?.onboarded &&
        user?.data?.profile_filled &&
        !(user.data?.user?.is_active || user.data?.is_active) ? (
          <Route path="*" element={<WaitForActivation />} />
        ) : (
          <>
            {user !== null && <Route path="/" element={getMainElement(user)} />}
            <Route path="/userinfo" element={<UserInfo />} />
            <Route
              path="/registrationcomplete"
              element={<OnboardingThankYouPage />}
            />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/setup" element={<SetupPage />} />
            <Route
              path="/setupprofilecreative"
              element={<SetupProfileInfluencer />}
            />
            <Route
              path="/setupprofilecompany"
              element={<SetupProfileCompany />}
            />
            {/* <Route path="/editpage" element={<EditOnboardedUserData />} /> */}
            <Route path="/me" element={<ProfilePage />} />
            <Route path="/card" element={<Card />} />
            <Route path="/meetupspot" element={<CampaignPage />} />
            
            <Route path="/detailpage/:id" element={<DetailCampaignPage />} />
            <Route path="/projectdetail/:id" element={<CampaignDetail />} />
            <Route path="/project/:id" element={<ProjectDetails />} />

            <Route path="/hooray" element={<HoorayPopUp />} />
            
            <Route path="/propose/:campaignId/:pitchId" element={<ProposeChanges />} />
            <Route path="/pitch/:campaignId/:pitchId" element={<InfluencerPitch />} />
            <Route path="/chat/marketplace/:marketplaceId" element={<ChatPitch />} />
            <Route path="/chat/campaign/:campaignId/:pitchId" element={<ChatPitch />} />

            <Route
              path="/projectsbycreatives/:id"
              element={<MarketplaceOverview />}
            />
            <Route
              path="/projectsbycreatives/card"
              element={<CardMarketplace />}
            />
            <Route
              path="/projectsbycreatives/idea"
              element={<IdeaOrEventScreen />}
            />
            <Route
              path="/projectsbycreatives/updateIdea"
              element={<UpdateIdeaOrEvent />}
            />
            <Route
              path="/projectsbycreatives/editDeadline"
              element={<EditDeadline />}
            />
            <Route path="/hoorayscreen" element={<HoorayScreen />} />
            <Route path="/setupprofile" element={<SetupPage />} />
            <Route path="/:id/invitecreative" element={<InviteInfluencer />} />
            <Route
              path="/creative/:id"
              element={<ProfilePageOtherInfluencer />}
            />
            <Route
              path="/invite/:campaign_id/:token"
              element={<MailRedirectPage />}
            />
            <Route path="/addoffline" element={<AddOfflinePage />} />
            <Route path="/addthursd" element={<AddThursdPage />} />
            <Route path="/addwebsite" element={<AddWebsitePage />} />
            <Route path="/addinstagram" element={<AddInstagramPage />} />
            <Route path="/:campaignId/new-pitch" element={<Pitch />} />
            <Route
              path="/social/facebook/connect"
              element={<FacebookConnect />}
            />
            <Route component={<ErrorPage />} />
          </>
        )}
      </Route>

      {/* Specific routes */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterAccount />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/passwordchanged" element={<PasswordChanged />} />
      <Route path="/createpassword" element={<CreatePassword />} />
      <Route path="/phoneverification" element={<PhoneVerificationPage />} />
      <Route component={<ErrorPage />} />
      <Route path="/setupprofile" element={<SetupPage />} />
      <Route path="*" element={<LoginPage />} />
    </Routes>
  );

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Helmet>
          <title>myThursd</title>
        </Helmet>
        <PusherProvider>
          <RouteChangeTracker />
          {mainContent}
        </PusherProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
