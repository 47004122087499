/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

/* -------------------------- Internal Dependencies ------------------------- */
import { SubheadingXS } from "../Text";
import { FlexContainer } from "../Layout";
import { theme } from "../../Theme/theme";

const CheckboxContainer = styled.div`
	position: relative;
	margin-top: ${(p) => (p.mt ? p.mt : "16px")};
	pointer-events: ${(p) => (p.disabled ? 'none' : "initial")};
`;

const StyledIcon = styled.svg`
	fill: none;
	stroke: white;
	stroke-width: 1.5px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
	border: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const StyledCheckbox = styled.div`
	width: 20px;
	height: 20px;
  border: ${(p) =>
    p.grey && p.checked
      ? "1px solid #A4AFB6"
      : `1px solid ${theme.main}`}; 	
	background: ${(p) =>
    p.checked
      ? p.grey
        ? '#A4AFB6'
        : theme.main 
      : theme.white}; 
	transition: all 150ms;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	${StyledIcon} {
		visibility: ${(p) => (p.checked ? "visible" : "hidden")};
	}
`;

const CustomCheckbox = ({
	Icon,
	text,
	className,
	checked,
	onChange,
	grey,
	mt,
	id,
	onClick,
	...props
}) => {

return(
	<CheckboxContainer className={className} checked={checked} mt={mt} disabled={grey}>
		<FlexContainer gap8>
			<div>
				<HiddenCheckbox
					tabIndex={-1}
					onChange={onChange}
					checked={checked}
					{...props}
					id={id}
					name={id}
				/>
				<StyledCheckbox
					tabIndex={-1}
					onClick={onClick}
					checked={checked}
					onChange={onChange}
					grey={grey}
				>
					{Icon || (
						<StyledIcon viewBox="0 0 23 23">
							<polyline points="18 9 9 17 4 12" />
						</StyledIcon>
					)}
				</StyledCheckbox>
			</div>
			<label htmlFor={id}><SubheadingXS>{text}</SubheadingXS></label>
		
		</FlexContainer>
	</CheckboxContainer>
)}

export default CustomCheckbox;
