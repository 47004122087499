import React from 'react'

const ErrorMessage = ({ message }) => {
	return (
        <span
            className='error-message'
            style={{
                display: "block",
                color: "#982336",
                fontFamily: "Avenir LT Std",
                fontSize: "10px",
                lineHeight: "14px",
                marginLeft: "2px",
                marginTop: 8
            }}
        >
            {message}
        </span>
	);
};

export default ErrorMessage;
