import PageHeader from "../../Components/Common/PageHeader";
import { Container } from "../../Components/Layout";
import BasicContentsHeader from '../../Components/Advertisers/Headers/BasicContentsHeader'

const HomePageHeader = () => {
    return (
        <PageHeader>
            <Container>
                <BasicContentsHeader buttonTitle={'Create a project'} />
            </Container>
        </PageHeader>);
}

export default HomePageHeader;