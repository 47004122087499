/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState, useEffect, useCallback } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import Modal from 'react-modal';
import Cropper from 'react-easy-crop';
import { Document, Page, pdfjs } from 'react-pdf';
import { IoClose } from 'react-icons/io5';
import getCroppedImg from '../../Utils/getCroppedImg';

/* -------------------------- Internal Dependencies ------------------------- */
import { theme } from '../../Theme/theme';
import { ErrorMessage, ParagraphS } from '../../Components/Text';
import RoundButtonLink from '../UI/Buttons/RoundButtonLink';
import { Button } from '../UI';
import DropzoneIcon from '../../Icons/DropzoneIcon';
import CloseIcon from '../../Icons/Close';

/* ----------------------------- Styled Components -------------------------- */

const StyledDropZoneWrapper = styled.div`
  margin-top: 10px;
  cursor: pointer;
`;

const StyledContainer = styled.div`
  height: 152px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px dashed var(--g-s200);
  background-color: var(--b-b50);
  color: var(--b-b400);
  font-family: 'Avenir LT Std';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  cursor: pointer;

  span {
    color: ${({ color }) => color};
    text-decoration: underline;
  }
`;

const DroppedImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  aspect-ratio: ${({ isAvatar }) => (isAvatar ? '1/1' : '16/9')};
  border-radius: ${({ isAvatar }) => (isAvatar ? '50%' : '0')};
  height: ${({ isAvatar }) => (isAvatar ? '120px' : '268px')};
`;

const DropZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-items: center;
`;

const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledListBox = styled.ul`
  list-style-type: disc;
  color: #a4afb6;
  font-family: 'Avenir LT Std';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  margin-left: -12px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ isAvatar }) => (isAvatar ? '50%' : '0')};
`;

const CropperWrapper = styled.div`
  position: relative;
  flex: 1;
  background: #333;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: flex-end;
`;

const HeaderButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

const DesktopSaveButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 12px;
`;

/* -------------------------------------------------------------------------- */
/*                             FileDropZone Component                         */
/* -------------------------------------------------------------------------- */

const FileDropZone = ({
  image, // Initial image prop
  setImage,
  fileUpload = false,
  isAvatar = false,
  maxFileSize = 5 * 1024 * 1024, // Default to 5MB
}) => {
  const [file, setFile] = useState(null);
  //const [numPages, setNumPages] = useState(null);
  const [pageNumber] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  // Handle file drop
  const handleDrop = (acceptedFiles, fileRejections) => {
    setErrorMessage(null);

    if (fileRejections.length > 0) {
      // Handle errors
      fileRejections.forEach((fileRejection) => {
        fileRejection.errors.forEach((error) => {
          if (error.code === 'file-too-large') {
            setErrorMessage('File size exceeds the limit of 5MB.');
          } else if (error.code === 'file-invalid-type') {
            setErrorMessage('Invalid file type.');
          }
        });
      });
      return;
    }

    const acceptedFile = acceptedFiles[0];

    if (fileUpload && acceptedFile.type === 'application/pdf') {
      setFile(acceptedFile);
    } else if (acceptedFile.type.startsWith('image/')) {
      setSelectedImage({ file: acceptedFile, url: URL.createObjectURL(acceptedFile) });
      setShowCropModal(true);
    } else {
      setErrorMessage('Unsupported file type.');
    }
  };

  // Handle file delete
  const handleDelete = () => {
    setFile(null);
    setImage(null);
  };

  // PDF Document load success
  const onDocumentSuccess = ({ numPages }) => {
    //setNumPages(numPages);
  };

  // Crop complete handler
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Handle crop save
  const handleCropSave = async () => {
    try {
      const croppedImageUrl = await getCroppedImg(selectedImage.url, croppedAreaPixels);
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      const croppedFile = new File([blob], selectedImage.file.name, {
        type: 'image/png',
      });
      setFile(croppedFile);
      setImage(croppedFile);
      setShowCropModal(false);
      setSelectedImage(null);
    } catch (e) {
      console.error(e);
      setErrorMessage('Error cropping image.');
    }
  };

  useEffect(() => {
    if (file && !file.type.startsWith('image/')) {
      setImage(file);
    }
  }, [file]);

  // Modal styles
  const customStyles = {
    content: {
      width: '500px',
      height: '500px',
      margin: 'auto',
      padding: '0',
      overflow: 'hidden',
      border: 'none',
      borderRadius: '0',
      zIndex: '9999',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: '9999',
    },
  };

  // Determine what content to display
  let content;
  if (file || image) {
    if (file && file.type.startsWith('image/')) {
      // Display the uploaded image file
      content = (
        <DroppedImageContainer isAvatar={isAvatar}>
          <StyledImage
            src={URL.createObjectURL(file)}
            alt={file.name}
            isAvatar={isAvatar}
          />
        </DroppedImageContainer>
      );
    } else if (image && !file) {
      // Display the initial image
      content = (
        <DroppedImageContainer isAvatar={isAvatar}>
          <StyledImage src={image} alt="Initial" isAvatar={isAvatar} />
        </DroppedImageContainer>
      );
    } else if (file) {
      // Display the PDF document
      content = (
        <DroppedImageContainer isAvatar={isAvatar}>
          <Document file={file} onLoadSuccess={onDocumentSuccess}>
            <div
              style={{
                width: '200px',
                height: '100px',
                position: 'relative',
              }}
            >
              <Page pageNumber={pageNumber} width={200} height={100} />
            </div>
          </Document>
        </DroppedImageContainer>
      );
    }
  } else {
    // Display the drop zone instructions
    content = (
      <DropZoneContainer>
        <DropzoneIcon />
        <p>
          Drag & drop file here or <span>browse</span>
        </p>
      </DropZoneContainer>
    );
  }

  return (
    <StyledDropZoneWrapper>
      <Dropzone
        onDrop={handleDrop}
        accept={fileUpload ? 'application/pdf,image/*' : 'image/*'}
        maxFiles={1}
        maxSize={maxFileSize}
      >
        {({ getRootProps, getInputProps }) => (
          <StyledContainer
            {...getRootProps()}
            file={file}
            initialImage={image}
            color={theme.main}
            isAvatar={isAvatar}
          >
            <input {...getInputProps()} />
            {content}
          </StyledContainer>
        )}
      </Dropzone>
      {(file || image) && (
        <FileContainer>
          <ParagraphS mt={'0px'}>
            {file ? file.name : 'Image Selected'}
          </ParagraphS>
          <div onClick={handleDelete}>
            <RoundButtonLink
              height={'20px'}
              width={'20px'}
              Icon={<CloseIcon height={'8px'} />}
            />
          </div>
        </FileContainer>
      )}
      <StyledListBox>
        <li>
          png, gif, jpeg, jpg, webp, svg{fileUpload ? ', pdf' : ''}
        </li>
        <li>Size limit: 5MB.</li>
        <li>We recommend a ratio of {isAvatar ? '1:1' : '16:9'}</li>
      </StyledListBox>
      {errorMessage && <ErrorMessage message={errorMessage} />}

      {/* Crop Modal */}
      {showCropModal && (
        <Modal
          isOpen={showCropModal}
          onRequestClose={() => setShowCropModal(false)}
          contentLabel="Crop Image"
          ariaHideApp={false}
          style={customStyles}
        >
          <ModalContent>
            <ModalHeader>
              <HeaderButton onClick={() => setShowCropModal(false)}>
                <IoClose size={18} />
              </HeaderButton>
            </ModalHeader>
            <CropperWrapper>
              <Cropper
                image={selectedImage.url}
                crop={crop}
                zoom={zoom}
                aspect={isAvatar ? 1 : 16 / 9}
                cropShape={isAvatar ? 'round' : 'rect'}
                showGrid={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </CropperWrapper>
            <DesktopSaveButtonWrapper>
              <Button
                primary
                padding={'10px 32px'}
                onClick={handleCropSave}
                text={'Save'}
                mt={'0px'}
              />
            </DesktopSaveButtonWrapper>
          </ModalContent>
        </Modal>
      )}
    </StyledDropZoneWrapper>
  );
};

export default FileDropZone;
