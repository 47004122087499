import styled from "styled-components";

const StyledInput = styled.input`
    padding: 12px 16px;
    border: 1px solid var(--g-s100);
    border-radius: 4px;
    width: 100%;
    //margin-left: 16px;
    margin-right: 8px;
    font-family: 'Avenir LT Std';
    outline: none;
    font-size: 16px; 
    max-height: 40px;
    max-width: 100%; 
    user-scalable: 0; 
    -webkit-user-scalable: 0;
`;

const ChatInput = ({
    id,
    disabled,
    register,
    placeholder
}) => {
    return (
        <StyledInput
            type="text"
            id={id}
            disabled={disabled}
            {...register(id)}
            placeholder={placeholder}
/>
    );
}

export default ChatInput;