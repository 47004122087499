/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components';

/* -------------------------- Internal Dependencies ------------------------- */
import { ParagraphS, SubheadingS, SubheadingXXS }  from '../../../Text';
import { FlexContainer } from '../../../Layout';
import Clock from '../../../../Icons/Clock';

const StyledPitchInformationContainer = styled.div`
    display: flex;
    justify-content: space-between;
`
const StyledPitchInformation = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 12px;
    background: #EBEEEF;
    border-radius: 1px;
    gap: 4px;
`
const StyledFlexContainer = styled(FlexContainer)`      
    justify-content: space-between;   
    align-items: center;
`
const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`

const formatDate = (date) => {
    const day = date.getDate();
    const suffix = getDaySuffix(day);
    const month = date.toLocaleString('default', { month: 'long' }).charAt(0).toUpperCase() + date.toLocaleString('default', { month: 'long' }).slice(1);
    const year = date.getFullYear();
    return `${month} ${day}${suffix} ${year}`;
}

const getDaySuffix = (day) => {
    if (day === 11 || day === 12 || day === 13) {
        return "th";
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
}

const PitchInformation = (props) => {
    const { budget_amount } = props;
    const pitchDeadline = new Date(props.pitch_deadline);
    const formattedPitchDeadline = formatDate(pitchDeadline);
  
  return (
    <div>
        <StyledPitchInformationContainer>
            <StyledPitchInformation>
                <StyledFlexContainer>
                    <ParagraphS color="#364E5D" mt="0px">Pitch deadline</ParagraphS>
                    <Wrapper>
                        <Clock/>
                        <SubheadingXXS mt="0px">{props.pitch_deadline}</SubheadingXXS>
                    </Wrapper>
                </StyledFlexContainer>
               {budget_amount&&
                 <StyledFlexContainer>
                    <ParagraphS color="#364E5D" mt="0px">Budget</ParagraphS>
                    <SubheadingS color={"var(--bb600)"} mt="0px">€{budget_amount}</SubheadingS>
                </StyledFlexContainer> }
            </StyledPitchInformation>
        </StyledPitchInformationContainer>
    </div>
  )
}

export default PitchInformation
