import React from 'react'

const Hamburger = (props) => {
  const fillColor = props.color || "#F1F1F1";

    return (
      <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.375 1.15625C0.375 0.800781 0.648438 0.5 1.03125 0.5H11.9688C12.3242 0.5 12.625 0.800781 12.625 1.15625C12.625 1.53906 12.3242 1.8125 11.9688 1.8125H1.03125C0.648438 1.8125 0.375 1.53906 0.375 1.15625ZM0.375 5.53125C0.375 5.17578 0.648438 4.875 1.03125 4.875H11.9688C12.3242 4.875 12.625 5.17578 12.625 5.53125C12.625 5.91406 12.3242 6.1875 11.9688 6.1875H1.03125C0.648438 6.1875 0.375 5.91406 0.375 5.53125ZM11.9688 10.5625H1.03125C0.648438 10.5625 0.375 10.2891 0.375 9.90625C0.375 9.55078 0.648438 9.25 1.03125 9.25H11.9688C12.3242 9.25 12.625 9.55078 12.625 9.90625C12.625 10.2891 12.3242 10.5625 11.9688 10.5625Z" fill={fillColor}/>
      </svg>
    );
  }
  
  export default Hamburger;