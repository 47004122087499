
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { TbTargetArrow } from 'react-icons/tb'
import { useParams } from "react-router-dom";

import Biography from "../../Components/Common/ProfilePage/Biography";
import ProfileInformationItem from "../../Components/Common/ProfilePage/ProfileInformationItem";
import { HeadlineL, HeadlineM, ParagraphM, SubheadingXS } from "../../Components/Text";
import NavigationBar from "../../Components/Common/Navbar/NavigationBar";
import ProfileInformationItemSmall from "../../Components/Common/ProfilePage/ProfileInformationItemSmall";
import Flag from "../../Icons/Flag";
import Suitcase from "../../Icons/Suitcase";
import Audience from "../../Icons/Audience";
import { EndIconButton } from "../../Components/UI";
import { findNamesByKeys } from "./utils";
import apiClient from "../../services/api";
import Channel from "../../Components/Common/ProfilePage/Channel";
import ChannelSocials from "../../Components/Common/ProfilePage/ChannelSocials";
import AvatarStatic from "../../Components/AvatarStatic";


const Container = styled.section`
    // padding-top: 108px;
    min-height: 100vh;
    // min-width: 100vw;
    background-color: rgba(54, 78, 93, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 780px) {
        align-items: flex-start;
    }
    `;

const ProfileContainer = styled.div`
    heigth: 100%;
    width: 980px;
    border-radius: 8px;
    
    @media (max-width: 780px) {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background-color: rgba(54, 78, 93, 1);
        border-radius: 0px;
 
    }
`
const ProfileHeaderInformation = styled.div`
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: rgba(54, 78, 93, 1);
    padding-bottom: 40px;

    @media (max-width: 780px) {
        padding-bottom: 24px;
    }
`

const AboutSection = styled.div`
    width: 100%;
    min-height: 100%;
    background-color: var(--g-s50);

    @media (max-width: 780px) {
        border-radius: 0px;
    }

`

const TextContainer = styled.div`
    padding: 32px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 780px) {
        flex-direction: column;
    }
`

const SubContainer = styled.div`
    width: 50%;

    @media (max-width: 780px) {
        width: 100%;
    }
   
`
const SecondSubContainer = styled(SubContainer)`

@media (max-width: 780px) {
   margin-top: 24px;
}
`
const SubTextBlock = styled.div`
    max-width: 50ch;

    @media (max-width: 780px) {
        max-width: 100%;
    }
`

const SmallItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top:16px;
`


const ButtonContainer = styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 22px;
    padding-top: 32px;
    
     @media (max-width: 780px) {
        width: 200px;
        margin-left:24px;

    }
`

const ChannelsContainer = styled.div`
    background: var(--b-b500-secondary);
    padding: 24px 24px 60px 24px;
  
    @media (max-width: 780px) {
        padding: 24px 24px 180px 24px;

    }
`
const ProfilePageOtherInfluencer = () => {
    const routeParams = useParams();
    const [user, setUser] = useState(null);
    const [userLanguages, setUserLanguages] = useState()
    const [audienceCountries, setAudienceCountries] = useState()
    const languagesData = useSelector((state) => state.languages?.languages);
    const countriesData = useSelector((state) => state.countries?.countries);


    useEffect(()=>{
        if(routeParams?.id){
            const { id } = routeParams;

            apiClient.get(`profile/${id}`).then((resp)=>{
                setUser(resp.data.data)
            })
        }
    },[routeParams])

    useMemo(() => {
        if (user) {
            setUserLanguages(findNamesByKeys(user?.profile?.my_languages, languagesData))
            setAudienceCountries(findNamesByKeys(user?.profile?.audience_country, countriesData))
        }

    }, [languagesData, user])

    if(!user) return null;
    return (
        <Container>
            <NavigationBar />
            <ProfileContainer>
                <ButtonContainer>
                    <EndIconButton
                        primary={true}
                        textcolor={true}
                        color={"var(--bb500)"}
                        text={"Back to influencers"}
                        mt={"0px"}
                        onClick={() => window.history.back()}
                    >
                    </EndIconButton>
                </ButtonContainer>
                <ProfileHeaderInformation>
                    <div style={{ marginBottom: '16px', display: 'flex', gap: 16 }}>
                        <AvatarStatic userImage={user?.avatar} />
                        <div style={{ flexWrap: 'nowrap', maxWidth: '50ch' }}>
                            <HeadlineL color={"white"}>{user?.firstname} {user?.lastname}</HeadlineL>

                        </div>
                    </div>
                    <ProfileInformationItem
                        Icon={<TbTargetArrow size={18} />}
                        title={"Account created"}
                        answer={user?.registered}
                    />
                </ProfileHeaderInformation>
                <AboutSection>
                    <TextContainer>
                        <SubContainer>
                            <HeadlineM mt={'0px'}>About</HeadlineM>
                            <SubTextBlock>
                                <SubheadingXS mt={'16px'}>Starting Reason</SubheadingXS>
                                <ParagraphM>{user?.profile?.reason_start}</ParagraphM>
                            </SubTextBlock>

                            {user && <Biography text={user?.profile?.biography} />}

                        </SubContainer>

                        <SecondSubContainer>
                            <HeadlineM mt={'0px'}>Occupation & Audience</HeadlineM>
                            <SmallItemsContainer>

                                <ProfileInformationItemSmall
                                    Icon={<Suitcase />}
                                    title={"Occupation"}
                                    answers={user?.profile?.occupation}
                                />
                                <ProfileInformationItemSmall
                                    Icon={<Audience />}
                                    title={"Audience occupations"}
                                    answers={user?.profile?.audience}
                                />
                                <ProfileInformationItemSmall
                                    Icon={<Flag />}
                                    title={"Audience(s) in"}
                                    answers={audienceCountries} />
                            </SmallItemsContainer>
                        </SecondSubContainer>

                    </TextContainer>
                </AboutSection>
                <ChannelsContainer>
                    <HeadlineM color="#fff">Channels</HeadlineM>

                    {user && user.connected_socials && Object.keys(user.connected_socials).map((platform) => (
                        <div key={platform}>
                            {Array.isArray(user.connected_socials[platform]) && user.connected_socials[platform].length > 0 ? (
                                user.connected_socials[platform].map((user, index) => (
                                    <div style={{ marginTop: '16px' }}>
                                        <ChannelSocials
                                            key={index}
                                            user={user.data}
                                            platform={platform}
                                            staticChannel={true}
                                        />
                                    </div>
                                ))) : null
                            }
                        </div>
                    ))}
                    <div>
                        {user && user.profile && user.profile.channel && Object.keys(user.profile.channel).map((platform) => (
                            <div key={platform}>
                                {Array.isArray(user.profile.channel[platform]) && user.profile.channel[platform].length > 0 ? (
                                    user.profile.channel[platform].map((channel, index) => (
                                        <div style={{ marginTop: '16px' }}>
                                            <Channel
                                                key={index}
                                                channel={channel}
                                                channels={user.profile.channel}
                                                platform={platform}
                                                staticChannel={true}
                                            />
                                        </div>
                                    ))) : (
                                    <div style={{ marginTop: '16px' }}>
                                        <Channel
                                            channel={user.profile.channel[platform]}
                                            channels={user.profile.channel}
                                            platform={platform}
                                        />
                                    </div>
                                )
                                }
                            </div>
                        ))}
                    </div>
                </ChannelsContainer>
            </ProfileContainer>
        </Container>

    );
}

export default ProfilePageOtherInfluencer;