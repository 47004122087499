/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';

/* -------------------------- Internal Dependencies ------------------------- */
import { ErrorMessage, HeadlineXL, ParagraphM, SubheadingS, SubheadingXS } from '../../../Components/Text';
import SubheadingL from '../../../Components/Text/SubheadingL';
import Instagram from '../../../Icons/Instagram';
import Facebook from '../../../Icons/Facebook';
import Pinterest from '../../../Icons/Pinterest';
import { Button, RoundButtonLink } from '../../../Components/UI';
import apiClient from '../../../services/api';
import usePitchOptionsModal from '../../../app/hooks/usePitchOptionsModal';
import MessageSendModal from '../../../app/modals/MessageSendModal'
import useMessageSendModal from '../../../app/hooks/useMessageSendModal';
import { isChannelMandatory } from '../../../Utils/utility-functions';
import Close from '../../../Icons/Close';
import ProposeChangesCheckbox from './ProposeChangesCheckbox';
import ProposeChangesCheckboxInput from './ProposeChangesCheckboxInput';
import { LayoutCenterTop } from '../../Layout';

const InputWrapper = styled.div`
  position: relative;
`;

const EuroSign = styled.div`
  position: absolute;
  left: 12px;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
`;

const StyledInput = styled.input`
  height:  ${(props) => props.height ? props.height : '50px'};
  background-color: white;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  padding: ${(props) => props.padding || "12px 16px"};
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #727272;
  width: 100%;

`;

const StyledTextArea = styled.textarea`
width: 100%;
min-height: 122px;
border: 1px solid #e3e3e3;
border-radius: 4px;
padding: 12px 16px;
font-family: "Avenir LT Std";
font-size: 16px;
font-weight: 500;
line-height: 24px;
box-sizing: border-box;
color: #2f2f2f;

&::placeholder {
  color: #999; 
}
`;

const EuroSignWrapper = styled.div`
  background-color: #FF4E5B;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%; 
  width: 36px; 
`;

const HR = styled.div`
  border: 1px solid var(--g-s100);
  width: 100%;
  margin-top: 20px;
}`

const Content = styled.div`
margin: 0 24px;

@media (max-width: 768px) {
  margin: 0;
}
`

const ProposeChanges = () => {
  const params = useParams();

  const [campaignData, setCampaignData] = useState(null);
  const [pitchData, setPitchData] = useState(null);
  const [inputBid, setInputBid] = useState('');
  const user = useSelector((state) => state.profile.profile);
  const userType = user.data.usertype;
  const [selectedChannels, setSelectedChannels] = useState({});
  const [errorMessage, setErrorMessage] = useState({
    inputBidError: '',
    channelsError: ''
  })
  const pitchOptionsModal = usePitchOptionsModal();
  const messageSendModal = useMessageSendModal();
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherInput, setOtherInput] = useState('')
  const [inputMotivationBid, setInputMotivationBid] = useState("")

  const { campaignId, pitchId } = params;

  useEffect(()=>{
    apiClient.get(`campaign/${campaignId}/pitch/${pitchId}`).then((resp) => {
        const pitchData = resp.data.data;
        setPitchData(pitchData);
      });
      // Fetch campaign
      apiClient.get(`campaign/${campaignId}`).then((resp) => {
        const campaignData = resp.data.data;
        setCampaignData(campaignData);
      });
  },[campaignId, pitchId])

  useEffect(() => {
    const updatedChannels = { ...selectedChannels };
    if (showOtherInput) {
      updatedChannels['other'] = otherInput;
    } else {
      delete updatedChannels['other'];
    }
    setSelectedChannels(updatedChannels);
  }, [showOtherInput, otherInput]);

  const handleCheckboxChange = (channel) => {
    const updatedChannels = { ...selectedChannels };
  
    if (channel === 'other') {
      setShowOtherInput(!showOtherInput);
      
      if (showOtherInput) {
        // If "other" was checked and it's now unchecked, remove it from selectedChannels
        delete updatedChannels['other'];
      } else {
        // If "other" is checked, add it to selectedChannels with the value of otherInput
        updatedChannels['other'] = otherInput;
      }
    } else {
      if (updatedChannels['other']) {
        // If any other checkbox is checked, remove "other" from selectedChannels
        delete updatedChannels['other'];
      }
      
      if (updatedChannels[channel] === channel) {
        delete updatedChannels[channel];
      } else {
        updatedChannels[channel] = channel;
      }
    }
  
    setSelectedChannels(updatedChannels);
  };
  

  function isNumeric(inputString) {
    const numericPattern = /^[0-9]+$/;
    return numericPattern.test(inputString);
  }

  const onSubmit = () => {
    const updatedChannels = { ...selectedChannels };

    if (otherInput.length > 0) {
      updatedChannels['other'] = otherInput;
      setSelectedChannels(updatedChannels)
    }

    const mandatoryChannels = Object.values(campaignData.channels).filter(channel => isChannelMandatory(channel));

    let inputBidError = '';
    let channelsError = '';

    if (!inputBid) {
      inputBidError = 'Please fill in a counteroffer';
    } else if (!isNumeric(inputBid)) {
      inputBidError = 'The counteroffer must consist only of numbers';
    }

    if (selectedChannels.length === 0 && mandatoryChannels.length === 0) {
      channelsError = 'Fill in a channel';
    }

    setErrorMessage({
      inputBidError,
      channelsError,
    });

    if (!inputBidError && !channelsError) {
      
      apiClient.post(`/campaign/${campaignData.id}/pitch/${pitchData.id}/bid`, {
        bid: inputBid,
        changes: selectedChannels,
        bid_justify: inputMotivationBid
      }

      ).then((response) => {
        messageSendModal.onOpen()
      })
    }
  };

  const onSubmitMessageSendModal = () => {
    messageSendModal.onClose();
    pitchOptionsModal.onClose();
    window.history.back();

  }

  if(!campaignData || !pitchData) return null;

  return (
    <LayoutCenterTop>
      <RoundButtonLink
        height={"40px"}
        width={"40px"}
        fill={"#FFF"}
        onClick={onSubmitMessageSendModal}
        Icon={<Close fill={"#364E5D"} />}
      />
      <div style={{ marginLeft: "24px" }}>
        <HeadlineXL mt={"16px"}>Propose changes</HeadlineXL>
      </div>
      <HR />

      <Content>
        <SubheadingS mt={"24px"} mb={"16px"} color="black">My counteroffer</SubheadingS>
        <InputWrapper>
          <EuroSignWrapper>
            <EuroSign>
              <SubheadingL mt={"0px"} color="white">€</SubheadingL>
            </EuroSign>
          </EuroSignWrapper>
          <StyledInput
            padding="12px 16px 12px 50px"
            type="number"
            placeholder='Please state the full price e.g. 250'
            onChange={(e) => setInputBid(e.target.value)}
            value={inputBid}
          />

        </InputWrapper>
        {pitchData?.bid ? (
          <SubheadingXS mt={"8px"} mb={"0px"} color="var(--g-s400)" fontW={"400"}>
            The creative’s original bid was: €{pitchData.bid}
          </SubheadingXS>
        ) : (
          <SubheadingXS mt={"8px"} mb={"0px"} color="var(--g-s400)" fontW={"400"}>
            The advertiser's budget is: €{campaignData.budget_amount}
          </SubheadingXS>
        )}


        {errorMessage &&
          errorMessage.inputBidError &&
          <ErrorMessage message={errorMessage.inputBidError} />
        }

        <div style={{ marginTop: '8px', gap: '8px', display: 'flex', flexDirection: 'column' }}>
          <SubheadingS mt={"8px"} mb={'-6px'}>Justify your countoffer!</SubheadingS>
          <ParagraphM mb="8px" colorSecondary>Explain in detail why you want to change the amount you are willing to pay.
          </ParagraphM>
          <StyledTextArea
            type="text"
            placeholder='“E.g., The fee we offer is for the combination of all things we like you to do….”'
            onChange={(e) => setInputMotivationBid(e.target.value)}
            value={inputMotivationBid}
          />
        </div>
        <SubheadingS mt={"16px"} mb={'8px'}>Channels</SubheadingS>
        <ParagraphM mb="8px" colorSecondary>
          { userType == "influencer" ? `Please select the social media channels you'd like to include. If there are grey checkboxes,
          these channels are mandatory.` : `Please select the social media channels you'd like the creative to include` }
        </ParagraphM>

        <ProposeChangesCheckbox
          label="Instagram"
          Icon={<Instagram />}
          checked={campaignData.channels?.instagram?.instagram_mandatory ? true : selectedChannels?.["instagram"] === 'instagram'}
          mandatory={campaignData.channels?.instagram?.instagram_mandatory || false}
          handleCheckboxChange={() => handleCheckboxChange('instagram')}

        />
        <ProposeChangesCheckbox
          label="Facebook"
          Icon={<Facebook />}
          checked={campaignData.channels?.facebook?.facebook_mandatory ? true : selectedChannels?.["facebook"] === 'facebook'}
          mandatory={campaignData.channels?.facebook?.facebook_mandatory || false}
          handleCheckboxChange={() => handleCheckboxChange('facebook')}

        />

        <ProposeChangesCheckbox
          label="Pinterest"
          Icon={<Pinterest />}
          checked={campaignData.channels?.pinterest?.pinterest_mandatory ? true : selectedChannels?.["pinterest"] === 'pinterest'}
          mandatory={campaignData.channels?.pinterest?.pinterest_mandatory || false}
          handleCheckboxChange={() => handleCheckboxChange('pinterest')}

        />
        <ProposeChangesCheckbox
          label="Thursd.com blogpost"
          subheading="
          For the publishing on thursd.com, an extra fee will be charged by Thursd of €500. The creative can decide to raise his or her bid when choosing this option."
          checked={campaignData.channels?.thursd?.thursd_mandatory ? true : selectedChannels["thursd"] === 'thursd'}
          mandatory={campaignData.channels?.thursd?.thursd_mandatory || false}
          handleCheckboxChange={() => handleCheckboxChange('thursd')}

        />
        <ProposeChangesCheckboxInput
          label="Other"
          checked={showOtherInput}
          input={otherInput}
          setInput={setOtherInput}
          subheading="Off-line events, Magazines, TV, YouTube, or Podcast appearances, in-shop advertisements, and more."
          handleCheckboxChange={() => handleCheckboxChange('other')}
        />
        {errorMessage &&
          errorMessage.channelsError &&
          <ErrorMessage message={errorMessage.channelsError} />
        }
        <Button
          fwidth
          primary
          onClick={onSubmit} // Pass campaignId in the state
          text={"Propose"}
          mt={"16px"}
        />
      </Content>
      <MessageSendModal onSubmit={onSubmitMessageSendModal} />
    </LayoutCenterTop>
  )
}

export default ProposeChanges
