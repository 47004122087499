/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledButtonLink = styled(Link)`
	border: ${(p) => (p.primary ? "transparent" : "1px solid #e3e3e3")};
	background: ${(p) => (p.primary ? p.theme.main : p.theme.white)};
	color: ${(p) =>
		p.primary ? p.theme.white : p.textcolor ? p.textcolor : p.theme.main};
	font-size: ${(p) => (p.fs ? p.fs : "16px")};
	font-weight: 700;
	line-height: 150%;
	display: flex;
	justify-content: ${(p) => (p.space ? "space-between" : "center")};
	align-items: center;
	gap: 6px;
	padding: ${(p) => (p.primary || p.space ? "16px 24px" : "14px 10px")};
	padding-bottom: ${(p) => (p.primary || p.space ? "16px" : "10px")};
	box-shadow: ${(p) =>
		p.primary ? " 0px 1px 0px #982e36" : "0px 1px 0px #E3E3E3"};
	border-radius: 1px;
	margin-top: ${(p) => (p.mt ? p.mt : "24px")};
	width: ${(p) => p.fwidth && "100%"};
	text-decoration: none;
	cursor: pointer;

	span {
		font-family: "Avenir LT Std";
	}
`;

const ButtonLink = ({
	Icon,
	text,
	primary,
	to,
	mt,
	fs,
	textcolor,
	space,
	onClick,
}) => {
	return (
		<StyledButtonLink
			primary={primary}
			textcolor={textcolor}
			fs={fs}
			mt={mt}
			to={to}
			space={space}
			onClick={onClick}
		>
			<span>{text}</span>
			{Icon || null}
		</StyledButtonLink>
	);
};

export default ButtonLink;
