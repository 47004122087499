import React, { useState } from 'react'
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import { OnboardingFlow } from './OnboardingFlow'
import SurveyPage1 from './SurveyPage1';
import { useDataSource } from '../../Utils/hooks/useDataSource';
import { onboardingUser } from '../../app/features/onboardingSlice';
import { objectHasEmptyValue } from '../../Utils/utility-functions';

const LoadingContainer = styled.div`
	widht: 100vw; 
    height: 100vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default function Onboarding() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [onboardingData, setOnboardingData] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showMandatoryError, setShowMandatoryError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { resource: questions, loading } = useDataSource('/questions')
  const onboardingQuestions = questions?.data?.[0].questions


  const validateInput = (question, input) => {
    let errorMessage = "This question is mandatory"
    let pass = true
    if (Array.isArray(input)) {
      pass = !question.mandatory || input.length > 0;
    } else if (typeof input === 'object' && input !== null) {
      if (objectHasEmptyValue(input)) {
        errorMessage = "Please fill the input for your selected answer"
        pass = false
      } else {
        pass = !question.mandatory || Object.keys(input).length > 0;
      }
    } else if (typeof input === 'string') {
      pass = !question.mandatory || input.length > 0;
    } else {
      // Onbekend type, behandelen als ongeldig
      pass = false;
    }
    setErrorMessage(errorMessage)
    return pass
  };

  const handleStep = (stepData, isFinish) => {
    const question = onboardingQuestions[currentIndex];
    const input = stepData[question.name];

    if (!validateInput(question, input)) {
      setShowMandatoryError(true)
      return;
    }

    setShowMandatoryError(false)

    const newOnboardingData = {
      ...onboardingData,
      ...stepData
    }

    setOnboardingData(newOnboardingData);

    if (isFinish) {
      dispatch(onboardingUser(newOnboardingData))
        .then((action) => {
          if (onboardingUser.fulfilled.match(action)) {
            navigate('/registrationcomplete');
          }
        });
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const onNext = stepData => {
    handleStep(stepData, false);
  };

  const onPrevious = () => {
    setCurrentIndex(currentIndex - 1)
  }

  const onFinish = stepData => {
    handleStep(stepData, true);
  };

  if (loading) {
    return (
      <LoadingContainer>
        <ClipLoader
          color={'#FF4E5B'}
          loading={true}
          size={60}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </LoadingContainer>);
  }

  return (
    <OnboardingFlow
      currentIndex={currentIndex}
      onNext={onNext}
      onPrevious={onPrevious}
      onFinish={onFinish}
    >
      {onboardingQuestions.map((question, index) => (
        <SurveyPage1
          key={index}
          question={question}
          data={onboardingData}
          totalQuestions={onboardingQuestions.length}
          index={index + 1}
          showMandatoryError={showMandatoryError}
          errorMessage={errorMessage}
        />
      ))}
    </OnboardingFlow>
  );
}
