import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import ChatCard from "./ChatCard";
import IdeaScreen from "./IdeaScreen";
import { Container } from "../../../Components/Layout";
import useMarketplaceOverviewFilter from "../../../app/hooks/useMarketplaceOverviewFilter";
import apiClient from "../../../services/api";
import EventOptionsModal from "../../../app/modals/EventOptionsModal";
import useEventOptionsModal from "../../../app/hooks/useEventOptionsModal";
import NoData from "../../Common/NoData";

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const CardContainer = styled.div`
  border-bottom: ${(props) =>
    props.showBottom ? " 1px solid var(--b-b50)" : "none"};
`;
const ButtonContainer = styled.div`
  background: white;
  padding: 16px 0px;
`;

const StyledButton = styled.button`
  padding: 10px 14px;
  background-color: ${(props) => (props.active ? "var(--p500)" : "#ffffff")};
  color: ${(props) => (props.active ? "white" : "#042234")};
  font-weight: 700;
  border-radius: 50px;
  border: ${(props) =>
    props.active ? "2px solid var(--b-b500)" : "2px solid var(--g-s100)"};
  cursor: pointer;
  font-family: "Avenir LT Std";
  font-size: 14px;
  margin-right: 8px;
`;

const NoChatsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChatFilter = ({ marketplaceData }) => {
  const [chatList, setChatList] = useState(null);
  const marketplaceOverviewFilter = useMarketplaceOverviewFilter();
  const user = useSelector((state) => state.profile.profile);
  const [profileImage, setProfileImage] = useState("");
  const isUserOwnedMarketplaceCampaign =
    user?.data?.usertype === "company"
      ? marketplaceData.user.id === user?.data?.user.company_id
      : marketplaceData.user.id === user?.data?.id;
  const [isEventOptionsModalOpen, setIsEventOptionsModalOpen] = useState(false);
  const [selectedMarketplaceData, setSelectedMarketplaceData] = useState(null);
  const [clickedCardData, setClickedCardData] = useState(null);
  const eventOptionmodal = useEventOptionsModal();

  const switchToComponent = (component) => {
    marketplaceOverviewFilter.setSelectedFilterValue(component);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setClickedCardData(marketplaceData);

    if (marketplaceData) {
      apiClient
        .get(`/chat/marketplace/${marketplaceData.id}`)
        .then((response) => {
          setChatList(response.data.data);
        });
    }
  }, []);

  const handleOptionsClick = (marketplaceData) => {
    setSelectedMarketplaceData(marketplaceData);
    setIsEventOptionsModalOpen(true);
    eventOptionmodal.onOpen();
  };

  return (
    <StyledContainer>
      <ButtonContainer>
        {isUserOwnedMarketplaceCampaign === false ? (
          <Container height="40px"></Container>
        ) : (
          <Container>
            {chatList && chatList.length > 1 && (
              <StyledButton
                active={
                  marketplaceOverviewFilter.selectedFilterValue === "Idea"
                }
                onClick={() => switchToComponent("Idea")}
              >
                Idea
              </StyledButton>
            )}
            {isUserOwnedMarketplaceCampaign && (
              <StyledButton
                active={
                  marketplaceOverviewFilter.selectedFilterValue === "Chats"
                }
                onClick={() => switchToComponent("Chats")}
              >
                {chatList && `Chats (${chatList.length})`}
              </StyledButton>
            )}
          </Container>
        )}
      </ButtonContainer>
      <>
        {marketplaceOverviewFilter.selectedFilterValue === "Idea" && (
          <IdeaScreen
            marketplaceData={marketplaceData}
            openEventOptionsModal={() => {}}
            onOptionsClick={handleOptionsClick}
          />
        )}

        {marketplaceOverviewFilter.selectedFilterValue === "Chats" && (
          <Container>
            <CardContainer showBottom={chatList?.length > 0}>
              {chatList && chatList?.length > 0 ? (
                chatList?.map((chatMessage) => (
                  <ChatCard key={chatMessage.id} chatMessage={chatMessage} />
                ))
              ) : (
                <NoChatsWrapper>
                  <NoData text="No chats found" />
                </NoChatsWrapper>
              )}
            </CardContainer>
          </Container>
        )}
      </>
      {isEventOptionsModalOpen && (
        <EventOptionsModal
          onClick={eventOptionmodal.onOpen}
          // refreshData={refreshData}
          clickedCardData={clickedCardData}
        />
      )}
    </StyledContainer>
  );
};

export default ChatFilter;
