import styled from "styled-components";
import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
} from "react-router-dom";

import PageHeader from "../../Common/PageHeader";
import InfluencerHeader from "../Header/InfluencerHeader";
import { Container } from "../../Layout";
import Navbar from "../../Common/Navbar/NavigationBar";
import apiClient from "../../../services/api";
import CampaignBadge from "../../Common/Card/Campaigns/CampaignBadge";
import Summary from "../../Advertisers/Summary";
import { HeadlineM } from "../../Text";

const Wrapper = styled.div`
  background: #ebeeef;
  padding: 8px;
  flex: 1;
`;

const BadgesContainer = styled.div`
  margin-top: 16px;
`;

const InfluencerPitch = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [user, setUser] = useState(null);
  const [channels, setChannels] = useState(null);
  const [bid, setBid] = useState(null);
  const [bidMotivation, setBidMotivation] = useState(null);
  const [bidNote, setBidNote] = useState(null);

  const [pitchData, setPitchData] = useState(null);

  const { campaignId, pitchId } = params;

  //fetch de pitchData
  useEffect(() => {
    fetchPitchData();
  }, [campaignId, pitchId]);

  //Als er pitchdata is, zet de BidRelatedStates en de user
  useEffect(() => {
    if (pitchData) {
      setBidRelatedStates(pitchData);
      setUser(pitchData.user);
      fetchUserProfileData(pitchData);
    }
  }, [pitchData]);

  function fetchPitchData() {
    apiClient.get(`campaign/${campaignId}/pitch/${pitchId}`).then((resp) => {
      const newPitchData = resp.data.data;
      setPitchData(newPitchData);
    });
  }

  function setBidRelatedStates(newPitchData) {
    const latestBid = newPitchData?.bids?.[newPitchData.bids.length - 1];
    if (latestBid) {
      setBid(latestBid.bid);
      setBidMotivation(latestBid.motivation);
      setBidNote(latestBid.note);
    }
  }

  function fetchUserProfileData(pitchData) {
    const { user } = pitchData;
    apiClient.get(`profile/${user.id}`).then((resp) => {
      const profile = resp.data.data;
      const result = processPitchedChannels(pitchData, profile);
      setChannels(result);
    });
  }

  function processPitchedChannels(newPitchData, profile) {
    const result = {};
    for (const pitchedChannel of Object.keys(newPitchData.pitch_channels)) {
      if (pitchedChannel === "other") {
        result["Other"] = [newPitchData.pitch_channels[pitchedChannel]];
      }
      const uppercaseString =
        pitchedChannel.charAt(0).toUpperCase() + pitchedChannel.slice(1);
      if (profile?.profile?.channel[uppercaseString]) {
        result[uppercaseString] = profile.profile.channel[uppercaseString];
      } else {
        result[uppercaseString] = [uppercaseString];
      }
      if (
        pitchedChannel === "Facebook" &&
        profile?.connected_socials?.facebook.length > 0
      ) {
        result["Facebook"] = profile.connected_socials.facebook.map(
          (channel) => channel.data.name
        );
      }
      if (
        pitchedChannel === "Pinterest" &&
        profile?.connected_socials?.pinterest.length > 0
      ) {
        result["Pinterest"] = profile.connected_socials.pinterest.map(
          (channel) =>
            channel.account_type === "BUSINESS"
              ? channel.business_name
              : channel.name
        );
      }
    }
    return result;
  }

  const handleBackButtonClick = () => {
    window.history.back();
  };

  const navigateToChat = () => {
    navigate(`/chat/campaign/${campaignId}/${pitchId}`);
  };

  const navigateToProfile = () => {
    navigate(`/creative/${user.id}`);
  };

  if (!pitchData || !user || !pitchData) return null;

  return (
    <div style={{ height: "100vh", background: "#EBEEEF" }}>
      <Navbar />
      <PageHeader>
        <Container>
          <InfluencerHeader
            data={pitchData}
            title={`${pitchData?.user?.name}'s pitch`}
            onClickFirstButton={handleBackButtonClick}
            onClickSecondButton={navigateToChat}
            onClickThirdButton={navigateToProfile}
          />
        </Container>
      </PageHeader>

      <div style={{ background: "white", padding: "16px" }}>
        <Container>
          <HeadlineM mt={"0px"}>Summary</HeadlineM>
          <div style={{ marginTop: "16px" }}>
            {pitchData && (
              <Summary
                influencerPitch
                data={pitchData}
                bid={bid}
                bidMotivation={bidMotivation}
                bidNote={bidNote}
              />
            )}
          </div>
        </Container>
      </div>
      <Wrapper>
        <Container>
          <HeadlineM mt={"16px"}>Proposed services</HeadlineM>
          <BadgesContainer>
            {channels && <CampaignBadge channels={channels} />}
          </BadgesContainer>
        </Container>
      </Wrapper>
    </div>
  );
};
export default InfluencerPitch;
