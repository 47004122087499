import React, { useState } from 'react';
import styled from 'styled-components';

import { SubheadingXXS } from '../../Text';

const StyledHashtag = styled.span`
  cursor: pointer;
`;

function Hashtag({ name }) {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(name);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <StyledHashtag onClick={handleClick}>
      <SubheadingXXS mb="0px">{copied ? 'copied' : name}</SubheadingXXS>
    </StyledHashtag>
  );
}

export default Hashtag;
