import React from 'react'
import styled from 'styled-components'
import { ParagraphM, SubheadingXS } from '../../Text'
import Handshake from '../../../Icons/Handshake'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 20px;
`

export default function WelcomeContent() {
    return (
        <>
            <Wrapper>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
                    <Handshake />
                    <SubheadingXS>Pitching via myThursd creates connections & partnerships</SubheadingXS>
                </div>
                <div style={{ marginLeft: 30 }}>
                    <ParagraphM colorSecondary>Pitching is a great opportunity to:</ParagraphM>
                </div>
                <div style={{ marginLeft: '40px' }}>
                    <ParagraphM colorSecondary>• Introduce yourself to a potential client</ParagraphM>
                    <ParagraphM colorSecondary>• Kickoff a long-lasting partnership</ParagraphM>
                    <ParagraphM colorSecondary>• Start collaborations with other creatives</ParagraphM>
                </div>
            </Wrapper>
            <Wrapper>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
                    <Handshake />
                    <SubheadingXS>Questions? We are here to help</SubheadingXS>
                </div>
                <div style={{ marginLeft: '32px' }}>
                    <ParagraphM colorSecondary>Please find us at hello@my.thursd.com</ParagraphM>
                </div>
            </Wrapper>
        </>
    )
}
