import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";

export const profileSetup = createAsyncThunk(
	"user/profileSetup",
  async ({ data }) => {
    const response = await apiClient.post(`/profile`, data, {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'multipart/form-data',
		},
	})
    return response.data;
  }
);


export const fetchProfile = createAsyncThunk(
	"user/profile",
  async () => {
    const response = await apiClient.get(`/me`)
    return response.data;
  }
);

const initialState = {
	profile: null
};

export const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		setProfileData: (state, action) => {
			state.profile = action.payload;
		  },
	},
	extraReducers: (builder) => {
		builder
			.addCase(profileSetup.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(profileSetup.fulfilled, (state, action) => {
				state.profile = action.payload
			})
			.addCase(profileSetup.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
			})
			.addCase(fetchProfile.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchProfile.fulfilled, (state, action) => {
				state.profile = action.payload
			})
			.addCase(fetchProfile.rejected, (state, action) => {
				state.isLoading = false;
				state.isError = true;
			});
	},
});

export const { setProfileData } = profileSlice.actions;

export default profileSlice.reducer;