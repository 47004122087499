import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { profileSetup } from "../app/features/profileSlice";
import { FaPen } from "react-icons/fa";
import Cropper from "react-easy-crop";

import Modal from "react-modal";
import { IoClose } from "react-icons/io5"; // Icon for the close button
import getCroppedImg from "../Utils/getCroppedImg";
import { Button } from "./UI";
import { SubheadingS } from "./Text";

// Styled components

const AvatarBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50%;
  border: ${(p) =>
    p.size ? "1px solid var(--b-b100)" : "2px solid var(--b-b100)"};
  height: ${(p) => (p.size ? p.size : "80px")};
  width: ${(p) => (p.size ? p.size : "80px")};
  background-color: var(--b-b50);
`;

const DropZoneContainer = styled.div`
  height: ${(p) => (p.size ? p.size : "80px")};
  width: ${(p) => (p.size ? p.size : "80px")};
`;

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%; /* Ensure the image is displayed as a circle */
`;

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: transform 0.5s ease-in-out;

  &:hover {
    opacity: 100%;
  }
`;

const PenContainer = styled.div`
  position: absolute;
  bottom: ${(p) => (p.logo ? "4px" : "8px")};
  right: ${(p) => (p.logo ? "0px" : "10px")};
  opacity: 0;
  aspect-ratio: 1 / 1;
  width: ${(p) => (p.logo ? "16px" : "20px")};

  ${AvatarBox}:hover & {
    opacity: 1;
  }
`;

/* New Styled Components for Modal */

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  /* Mobile styles */
  @media (max-width: 768px) {
    justify-content: space-between;
    background-color: #f0f0f0;
  }
  /* Desktop styles */
  @media (min-width: 769px) {
    justify-content: flex-end;
  }
`;

const HeaderButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

const Title = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  /* Hide on desktop */
  @media (min-width: 769px) {
    display: none;
  }
`;

const DesktopSaveButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 12px;
`;

const CropperWrapper = styled.div`
  position: relative;
  flex: 1;
  background: #333;
`;

const Avatar = ({ userImage, size, logo }) => {
  const [image, setImage] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [showCropModal, setShowCropModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userImage) {
      setImage(userImage);
    }
  }, [userImage]);

  const onDrop = (acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      // Handle errors
      fileRejections.forEach((fileRejection) => {
        fileRejection.errors.forEach((error) => {
          if (error.code === "file-too-large") {
            alert("The file is too large. The maximum size is 5MB.");
        } else if (error.code === "file-invalid-type") {
          alert("Invalid file type. Only images are allowed.");
        }
        });
      });
      return;
    }
    const file = acceptedFiles[0];
    const imageUrl = URL.createObjectURL(file);
    setSelectedImage({ file, url: imageUrl });
    setShowCropModal(true);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = async () => {
    try {
      const croppedImageUrl = await getCroppedImg(
        selectedImage.url,
        croppedAreaPixels
      );
      setImage(croppedImageUrl);

      // Convert the base64 image to a file object
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      const croppedFile = new File([blob], selectedImage.file.name, {
        type: "image/png", // Ensure the file type matches the output
      });

      let formData = new FormData();
      if (logo) {
        formData.set("logo", croppedFile);
      } else {
        formData.set("image", croppedFile);
      }

      dispatch(profileSetup({ data: formData })).then((action) => {
        if (profileSetup.fulfilled.match(action)) {
          // Handle success
        }
      });

      setShowCropModal(false);
      setSelectedImage(null);
    } catch (e) {
      console.error(e);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    maxSize: 5 * 1024 * 1024,
  });

  /* Modal Styles */

  const customStyles = {
    content: {
      /* Mobile styles */
      inset: "0px",
      padding: "0px",
      border: "none",
      borderRadius: "0px",
      /* Desktop styles */
      width: "500px",
      height: "500px",
      "z-index": "99999999999999999999999999999",
      margin: "auto",
      overflow: "hidden",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      "z-index": "99999999999999999999999999999",
    },
  };

  /* Adjust styles based on screen size */
  const isMobile = window.innerWidth <= 768;

  if (isMobile) {
    customStyles.content = {
      ...customStyles.content,
      width: "100%",
      height: "100%",
      inset: "0px",
    };
  } else {
    customStyles.content = {
      ...customStyles.content,
    };
  }

  return (
    <div style={{ width: size }}>
      <AvatarBox size={size}>
        <DropZoneContainer {...getRootProps()} size={size}>
          <input {...getInputProps()} />
          {image && <StyledImage src={image} alt="Avatar" />}
          <Overlay />
          <PenContainer logo={logo}>
            <FaPen color={"#fff"} size={logo ? 10 : 14} />
          </PenContainer>
        </DropZoneContainer>

      </AvatarBox>
      {/* Crop Modal */}
      {showCropModal && (
        <Modal
          isOpen={showCropModal}
          onRequestClose={() => setShowCropModal(false)}
          contentLabel="Crop Image"
          ariaHideApp={false}
          style={customStyles}
        >
          <ModalContent>
            {/* Modal Header */}
            <ModalHeader>
              {/* Mobile Close Button */}
              {isMobile && (
                <HeaderButton onClick={() => setShowCropModal(false)}>
                  <IoClose size={18} />
                </HeaderButton>
              )}
              {/* Title */}
              {isMobile && (
                <Title>
                  {" "}
                  <SubheadingS color={"#2F2F2F"} mt={"3px"} mb={"0px"}>
                    Crop photo
                  </SubheadingS>
                </Title>
              )}
              {/* Mobile Save Button */}
              {isMobile && (
                <Button
                  primary
                  padding={"10px 32px"}
                  onClick={handleCropSave}
                  text={"Save"}
                  mt={"0px"}
                />
              )}
              {/* Desktop Close Button */}
              {!isMobile && (
                <HeaderButton onClick={() => setShowCropModal(false)}>
                  <IoClose size={18} />
                </HeaderButton>
              )}
            </ModalHeader>

            {/* Cropper */}
            <CropperWrapper>
              <Cropper
                image={selectedImage.url}
                crop={crop}
                zoom={zoom}
                aspect={1}
                cropShape="round"
                showGrid={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </CropperWrapper>

            {/* Desktop Save Button */}
            {!isMobile && (
              <DesktopSaveButtonWrapper>
                <Button
                  primary
                  padding={"10px 32px"}
                  onClick={handleCropSave}
                  text={"Save"}
                  mt={"0px"}
                />
              </DesktopSaveButtonWrapper>
            )}
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default Avatar;
