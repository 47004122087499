import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from '../../services/api';
import { uniqBy } from "lodash";

const initialState = {
  influencers: {},

  meta: []
};


export const fetchInfluencers = createAsyncThunk('influencer', async (filterBody) => {
  const response = await apiClient.post('/influencer/search/',
    filterBody

  );
  return response.data.data;
});

const influencersSlice = createSlice({
  name: 'influencers',
  initialState,
  reducers: {
    updateInfluencers: (state, action) => {
      state.influencers = action.payload;
    },
    updateInfluencer: (state, action) => {
      // Haal de ID van de influencer op uit de payload
      const influencerIdToUpdate = action.payload.id;

      // Zoek de index van de influencer met de overeenkomende ID in de state.influencers array
      const influencerIndex = state.influencers.findIndex(
        (influencer) => influencer.id === influencerIdToUpdate
      );

      if (influencerIndex !== -1) {
        // Als de influencer is gevonden (index is niet -1), update de influencer
        state.influencers[influencerIndex] = action.payload;
      } else {
        // Als de influencer niet is gevonden, voeg deze toe aan de array
        state.influencers.push(action.payload);
      }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchInfluencers.fulfilled, (state, action) => {
      const data = action.payload;
      state.meta = data.meta

      if (data.meta.current_page === 1) {
        state.influencers = data.data;
      } else {
        const uniqueData = uniqBy([...state.influencers, ...data.data], "id");
        state.influencers = uniqueData;
      }
    });
  },
});

export const { updateInfluencers , updateInfluencer} = influencersSlice.actions;

export default influencersSlice.reducer;
