import React from "react";

const Suitcase = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      fill="none"
      viewBox="0 0 14 12"
    >
      <path
        fill="#031B2A"
        d="M3.5 2.75v-.938C3.5 1.11 4.086.5 4.813.5h3.375C8.89.5 9.5 1.11 9.5 1.813v.937H11c.82 0 1.5.68 1.5 1.5v6c0 .844-.68 1.5-1.5 1.5H2a1.48 1.48 0 01-1.5-1.5v-6c0-.82.656-1.5 1.5-1.5h1.5zm1.125 0h3.75v-.938a.202.202 0 00-.188-.187H4.813a.185.185 0 00-.188.188v.937zm-.75 1.125v6.75h5.25v-6.75h-5.25zm-2.25.375v6c0 .21.164.375.375.375h.75v-6.75H2a.385.385 0 00-.375.375zm9.75 6v-6A.403.403 0 0011 3.875h-.75v6.75H11a.385.385 0 00.375-.375z"
      ></path>
    </svg>
  );
}

export default Suitcase;
