import React, { useState, useEffect } from 'react'
import styled from 'styled-components';

import { ErrorMessage, HeadlineXL, ParagraphM, SubheadingS, SubheadingXS } from '../../Components/Text';
import ArrowButton from '../../Icons/ArrowButton';
import { Button } from '../../Components/UI';
import ArrowSmall from '../../Icons/ArrowSmall';
import { LayoutCenterTop } from '../../Components/Layout';
import CloseButton from '../../Icons/CloseButton';
import WelcomeContent from '../../Components/Common/PitchPage/WelcomeContent';
import ProposeChangesCheckboxInput from '../../Components/Advertisers/Chat/ProposeChangesCheckboxInput';
import ProposeChangesCheckbox from '../../Components/Advertisers/Chat/ProposeChangesCheckbox';
import Instagram from '../../Icons/Instagram';
import Facebook from '../../Icons/Facebook';
import Pinterest from '../../Icons/Pinterest';
import EuroInput from '../../Components/Common/PitchPage/EuroInput';
import { isChannelMandatory } from '../../Utils/utility-functions';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
const StepContainer = styled.div`
  display: flex;
  align-items: center; 
`;

const StepNumber = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--g-s100);
  margin-right: 10px;
  background-color: ${(props) => (props.isActive ? 'var(--b-b400)' : 'transparent')};
  color: ${(props) => (props.isActive ? 'white' : 'black')};

`;

const ArrowButtonWrapper = styled.div`
  cursor: pointer; /* Voeg een cursor toe om aan te geven dat het klikbaar is */
`;

const HR = styled.div`
  border: 1px solid var(--g-s100);
  width: 100%;
  margin-top: 20px;
`
const StyledTextArea = styled.textarea`
width: 100%;
min-height: 122px;
border: 1px solid #e3e3e3;
border-radius: 4px;
padding: 12px 16px;
font-family: "Avenir LT Std";
font-size: 16px;
font-weight: 500;
line-height: 24px;
box-sizing: border-box;
color: #2f2f2f;

&::placeholder {
  color: #999; 
}
`;

const ContentContainer = styled.div`
  margin: 0 24px;

  @media (max-width: 768px) {
    margin: 0;
}
`
export default function PitchPage({
  goToNext,
  goToPrevious,
  page,
  totalPages,
  currentIndex,
  showMandatoryError,
  pitchData,
  campaignData
}) {
  const [approach, setApproach] = useState(pitchData[1] || '');
  const [selectedChannels, setSelectedChannels] = useState(pitchData[2] || {});
  const [otherInput, setOtherInput] = useState(pitchData[2]?.other || '')
  const [showOtherInput, setShowOtherInput] = useState(otherInput?.length > 0 || false);
  const [note, setNote] = useState(pitchData[3]?.note || '')
  const [acceptDeadline, setAcceptDeadline] = useState(pitchData[3]?.accept_deadline || false)
  const [bidJustify, setBidJustify] = useState(pitchData[4]?.bid_justify || '')
  const [bid, setBid] = useState(pitchData[4]?.bid || null)
  const [errorMessage, setErrorMessage] = useState({
    approach: false,
    channels: false,
    delivery_deadline: false,
    my_bid: false,
    bid_justify: false
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  //Channel logic
  useEffect(() => {
    const updatedChannels = { ...selectedChannels };
    updatedChannels['other'] = otherInput
    setSelectedChannels(updatedChannels);

  }, [otherInput])

  useEffect(() => {
    if (!showOtherInput) {
      const updatedChannels = { ...selectedChannels };

      setOtherInput('')
      delete updatedChannels['other'];
      setSelectedChannels(updatedChannels);

    }
  }, [showOtherInput])


  const handleChannelChange = (channel) => {
    const updatedChannels = { ...selectedChannels };

    if (channel === 'other') {
      setShowOtherInput(!showOtherInput);
    } else {

      if (updatedChannels[channel] === channel) {
        delete updatedChannels[channel];
      } else {
        updatedChannels[channel] = channel
      }
    }

    setSelectedChannels(updatedChannels);

  };

  //Error handeling and setting the data
  const onNext = () => {
    let answer;

    if (currentIndex === 1) {
      if (approach === '') {
        setErrorMessage((prevState) => ({ ...prevState, approach: true }));
        return
      }
      setErrorMessage((prevState) => ({ ...prevState, approach: false }));
      answer = approach
    }
    if (currentIndex === 2) {
      const mandatoryChannels = Object.values(campaignData.channels).filter(channel => isChannelMandatory(channel));

      if (Object.keys(selectedChannels).length === 0 && mandatoryChannels.length === 0) {
        setErrorMessage((prevState) => ({ ...prevState, channels: true }));
        return
      }
      setErrorMessage((prevState) => ({ ...prevState, channels: false }));
      answer = selectedChannels
    }
    if (currentIndex === 3) {
      if (!acceptDeadline) {
        setErrorMessage((prevState) => ({ ...prevState, delivery_deadline: true }));
        return
      }

      setErrorMessage((prevState) => ({ ...prevState, delivery_deadline: false }));

      answer = {
        note: note,
        accept_deadline: acceptDeadline
      }
    }
    if (currentIndex === 4) {
      if (bid === null || bid === '') {
        setErrorMessage((prevState) => ({ ...prevState, bid: true }));
        return
      }

      setErrorMessage((prevState) => ({ ...prevState, bid: false }));

      if (bidJustify === '') {
        setErrorMessage((prevState) => ({ ...prevState, bid_justify: true }));
        return
      }

      setErrorMessage((prevState) => ({ ...prevState, bid_justify: false }));

      answer = {
        bid: bid,
        bid_justify: bidJustify
      }
    }
    
    goToNext({ [currentIndex]: answer })
  }

  return (
    <LayoutCenterTop>
      {/**----------------------- Header -------------------- */}
      <Header>
        <ArrowButtonWrapper onClick={goToPrevious}>
          <ArrowButton />
        </ArrowButtonWrapper>

        <StepContainer>
          {Array.from({ length: totalPages }, (_, index) => (
            <StepNumber key={index} isActive={index === currentIndex}>
              <ParagraphM mt={"4px"}>{index + 1}</ParagraphM>
            </StepNumber>
          ))}
        </StepContainer>

        <div onClick={() => window.location.href = '/'}>
          <CloseButton />
        </div>
      </Header>

      {/**----------------------- Title -------------------- */}
      <div style={{ marginLeft: '20px', marginTop: '32px' }}>
        <HeadlineXL mt={"8px"}>{currentIndex + 1}. {page.title}</HeadlineXL>
        <ParagraphM color={'#4F4F4F'}>{page.subtitle}</ParagraphM>
      </div>
      <HR />

      {/**----------------------- Content -------------------- */}


      {currentIndex === 0 &&
        <WelcomeContent />
      }

      {/**----------------------- Page1 -------------------- */}

      {currentIndex === 1 &&
        <ContentContainer>
          <ParagraphM colorSecondary>
            Some advertisers/brands or creatives have already mapped out their project
            from start to finish, but most are open to new ideas! Don’t limit yourself
            and propose new things in your pitch if possible.
          </ParagraphM>
          <ParagraphM colorSecondary>
            This field below is meant as a summary. It’s the first thing the advertiser sees.
            Make sure you create an attractive and compelling summary. You can add your detailed
            approach in a later step.
          </ParagraphM>
          <SubheadingS mt={"16px"} mb={'8px'}>Your approach</SubheadingS>
          <StyledTextArea
            type="text"
            placeholder='“E.g., I will create an awesome design of your flowers, 
            make stunning photography, and show them to all my followers. Including
             a link to your website.”'
            onChange={(e) => setApproach(e.target.value)}
            value={approach}
          />
          {errorMessage?.approach &&
            <ErrorMessage message="Fill in your approach" />
          }

        </ContentContainer>
      }

      {/**----------------------- Page2 -------------------- */}

      {campaignData &&
        currentIndex === 2 &&
        <ContentContainer>
          <ProposeChangesCheckbox
            label="Instagram"
            Icon={<Instagram />}
            checked={campaignData.channels?.instagram?.instagram_mandatory ? true : selectedChannels?.["instagram"] === 'instagram'}
            mandatory={campaignData.channels?.instagram?.instagram_mandatory || false}
            handleCheckboxChange={() => handleChannelChange('instagram')}
            secondary
          />
          <ProposeChangesCheckbox
            label="Facebook"
            Icon={<Facebook />}
            checked={campaignData.channels?.facebook?.facebook_mandatory ? true : selectedChannels?.["facebook"] === 'facebook'}
            mandatory={campaignData.channels?.facebook?.facebook_mandatory || false}
            handleCheckboxChange={() => handleChannelChange('facebook')}
            secondary
          />

          <ProposeChangesCheckbox
            label="Pinterest"
            Icon={<Pinterest />}
            checked={campaignData.channels?.pinterest?.pinterest_mandatory ? true : selectedChannels?.["pinterest"] === 'pinterest'}
            mandatory={campaignData.channels?.pinterest?.pinterest_mandatory || false}
            handleCheckboxChange={() => handleChannelChange('pinterest')}
            secondary
          />
          <ProposeChangesCheckbox
            label="Thursd.com blogpost"
            subheading="
            You can propose to create a blog post on Thursd.com. For the publishing on Thursd.com, the
            advertiser will be charged an extra €500. We suggest you raise your fee if you propose this option,
            as must send texts and pictures to the Thursd editorial team." 
            checked={campaignData.channels?.thursd?.thursd_mandatory ? true : selectedChannels["thursd"] === 'thursd'}
            mandatory={campaignData.channels?.thursd?.thursd_mandatory || false}
            handleCheckboxChange={() => handleChannelChange('thursd')}
            secondary
          />
          <ProposeChangesCheckboxInput
            label="Other"
            checked={showOtherInput}
            input={otherInput}
            setInput={setOtherInput}
            subheading="Off-line events, Magazines, TV, YouTube, or Podcast appearances, in-shop advertisements, and more."
            handleCheckboxChange={() => handleChannelChange('other')}
          />
          {errorMessage?.channels &&
            <ErrorMessage message="Fill in your channels" />
          }
        </ContentContainer>
      }

      {/**----------------------- Page3 -------------------- */}

      {
        currentIndex === 3 &&
        <ContentContainer>
          <ProposeChangesCheckbox
            label={`I have seen and agreed on the deadline of ${campaignData.delivery_deadline} `}
            subheading="
            By agreeing to the deadline, you ensure that you deliver on time."
            checked={acceptDeadline}
            mandatory={false}
            handleCheckboxChange={() => setAcceptDeadline(!acceptDeadline)}
          />
          <SubheadingS mt={"16px"} mb={'8px'}>Notes about posting (optional)</SubheadingS>
          <StyledTextArea
            type="text"
            placeholder='E.g., after approval, I could deliver within 3 days.'
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
          {errorMessage?.delivery_deadline &&
            <ErrorMessage message="You have to agree to the deadline." />
          }
        </ContentContainer>
      }

      {/**----------------------- Page4 -------------------- */}

      {
        currentIndex === 4 &&
        <ContentContainer>
          <EuroInput
            label="My bid"
            sublabel={`Please state your full price (in euros). 
            This is the combined price for all you promise to do in your pitch. 
            (Social channels, blog posts, and/or other). `}
            setInput={setBid}
            input={bid}
          />
          <SubheadingXS mt={"8px"} mb={"0px"} color="var(--g-s400)" fontW={"400"}>
          The advertiser's budget is: €{campaignData?.budget_amount}
        </SubheadingXS>
          {errorMessage?.bid &&
            <ErrorMessage message="Fill in your bid" />
          }
          <SubheadingS mt={"16px"} mb={'8px'}>Justify your bid!</SubheadingS>
          <ParagraphM colorSecondary mb="16px">
            Explain in detail why you are asking this price and provide
            as much additional information as possible to convince the advertiser. This is your chance to shine!
          </ParagraphM>
          <StyledTextArea
            type="text"
            placeholder='“E.g., The fee I ask is for the combination of all channels. I have a very large b2b following on Instagram, which perfectly suits your goals. I suggest you also choose an extra blog post on Thursd.com because….”'
            onChange={(e) => setBidJustify(e.target.value)}
            value={bidJustify}
          />
        

          {errorMessage?.bid_justify &&
            <ErrorMessage message="Justify your bid" />
          }
        </ContentContainer>
      }

      <Button
        mt="40px"
        fwidth
        primary
        onClick={onNext}
        Icon={<ArrowSmall height={"8px"} />}
        text={
          currentIndex === 0 ? "I'm ready to pitch" :
            currentIndex === (totalPages - 1) ? "Finish & send pitch" : "Next step"
        }
      />
    </LayoutCenterTop>
  )
}
