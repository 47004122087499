import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Hamburger from "../../../Icons/Hamburger";
import { HeadlineL, ParagraphM, ParagraphS, SubheadingS, SubheadingXS, } from "../../../Components/Text";
import { Button } from "../../../Components/UI";
import ChatWithoutQuestion from "../../../Icons/ChatWithoutQuestion";
import useEventOptionsModal from "../../../app/hooks/useEventOptionsModal";
import { Container } from "../../../Components/Layout";
import apiClient from "../../../services/api"; 
import useMarketplaceOverviewFilter from "../../../app/hooks/useMarketplaceOverviewFilter";
import noImage from '../../../Images/influencer-profile.png'
import PitchInformation from "../../Common/Card/Campaigns/PitchInformation";
import ProfileTag from "../../Common/ProfileTag";
import Loading from "../../Loading";

const StyledContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  background: var(--g-s50);
  padding: 16px 0 60px 0;
  box-sizing: border-box;
`;

const Bottom = styled.div`
width: 100%;
  background-color: #364E5D;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  padding: 16px;
  box-sizing: border-box;
 `;
const ButtonWrapper = styled.div`
  width: 100vw;
  max-width: var(--max-width);
`
const MultipleButtonWrapper = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
const TextBlock = styled.div`
  height: 100%;
  // width: 80ch;
  overflow-wrap: break-word;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 50ch;
    max-width: 90vw;
  }
`
const StyledImage = styled.img`
  border: 2px solid #FF4E5B;
  height: 213px;
  width: 213px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: auto;
  border-radius: 50%;
  object-fit: cover;
`;

const Hr = styled.div`
  margin-top: 8px;
  border: 1px solid #d3d3d3;
`

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 40px;

 @media (max-width: 768px) {
  flex-direction: column;
  align-items: stretch; /* Optioneel, afhankelijk van de gewenste uitlijning */
  padding-bottom: 68px;
}
`
const CardOne = styled.div`
  position: relative;
  background: white;
  width: 600px;
  height: auto;

  @media (max-width: 768px) {
    width: auto
  }
`

const CardTwo = styled.div`
  background: white;
  padding: 24px;
  width: 600px;
  height: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: auto
  }
`

const WrapperPitch = styled.div`
  margin-top: auto;
  justify-content: flex-end; /* Voeg hier een puntkomma toe */

  @media (max-width: 770px) {
    margin-top: 16px;
    background: red;
  }
`;


const IdeaScreen = ({ marketplaceData, onOptionsClick }) => {
  const marketplaceOverviewFilter = useMarketplaceOverviewFilter();
  const navigate = useNavigate();
  const user = useSelector((state) => state.profile.profile);
  const isUserOwnedMarketplaceCampaign = user?.data?.usertype === 'company' ?
    marketplaceData.user.id === user?.data?.user.company_id :
    marketplaceData.user.id === user?.data?.id;

  const eventOptionmodal = useEventOptionsModal();

  function htmlForamatter(htmlContent) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{ color: '#727272' }}
      />
    );
  }

  const openChat = () => {

    if (isUserOwnedMarketplaceCampaign) {
      marketplaceOverviewFilter.setSelectedFilterValue('Chats');
    } else {
      apiClient.get(`/chat/marketplace/${marketplaceData.id}`).then((resp) => {
        navigate(`/chat/marketplace/${marketplaceData.id}`);
      })
    }
  }

  const handleEventOptionsClick = () => {
    eventOptionmodal.onOpen();
    onOptionsClick();
  };

  return (
    <StyledContainer>
      {marketplaceData ? (
        <Container>
       <Wrapper>
          <CardOne>
            <div style={{ position: 'relative', zIndex: '1', background: 'white', height: 'auto', padding: '24px'}}>
                <div style={{display: 'flex' , flexDirection: 'column', alignItems: 'center', justifyContent: 'center',}}>
                  <StyledImage style={{marginTop: '-64px'}} src={marketplaceData.user.image || noImage} alt="Logo Thursd Influence" />
                  <div style={{ display: 'flex', marginTop: '16px',  background: 'white', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '8px'}}>
                  <ProfileTag className={'border-radius: 2px'}>
                      <ParagraphM color="#fff" mt="0px">Creative</ParagraphM>
                  </ProfileTag>
                    <SubheadingXS color={"#2F2F2F"}>By {marketplaceData.user.name || noImage}</SubheadingXS>
                  </div>
                </div>
                <div >
                <div>
                <Hr/>
                    <SubheadingS color={"#2F2F2F"} mt={'16px'} mb={'0px'} >Deadline</SubheadingS>
                    <TextBlock>
                      <ParagraphS mt={"4px"} mb={'0px'}  color={"#4F4F4F"}>{marketplaceData.until}</ParagraphS>
                    </TextBlock> 
                    <SubheadingS mt={"16px"} mb={'0px'} color={"#2F2F2F"}>Wants</SubheadingS>
                    <TextBlock>
                      <ParagraphS mt={"4px"} mb={'0px'}  color={"#727272"}>{htmlForamatter(marketplaceData.requesting)}</ParagraphS>
                    </TextBlock>
                    <SubheadingS mt={"16px"}  mb={'0px'}  color={"#2F2F2F"}>Gives</SubheadingS>
                    <TextBlock>
                      <ParagraphS mt={"4px"}mb={'0px'}  color={"#727272"}>{htmlForamatter(marketplaceData.returning)}</ParagraphS>
                    </TextBlock>
                  </div>
                </div>
            </div>
          </CardOne>
          <CardTwo>
            <HeadlineL mt={'0px'} mb={'8px'}>{marketplaceData.title}</HeadlineL>
            <SubheadingS mt={'0px'} mb={'0px'} color={"#2F2F2F"}>Details</SubheadingS>
            <ParagraphS mt={"4px"} color={"#727272"}>{htmlForamatter(marketplaceData.details)}</ParagraphS>
            <WrapperPitch>
              <PitchInformation
                pitch_deadline={marketplaceData.until}
              />
            </WrapperPitch>
          </CardTwo>
          </Wrapper>        
        </Container>
      ) : (
        <Loading />
      )}


      {isUserOwnedMarketplaceCampaign ? (
        <Bottom>
          <MultipleButtonWrapper>
            <Button
              iconFirst
              Icon={<ChatWithoutQuestion />}
              width={"50%"}
              primary text={"Chats"}
              textSize={'14px'}
              padding={"10px"}
              mt={"0px"}
              onClick={openChat}
            />
            <Button
              iconFirst
              Icon={<Hamburger color={"#042234"} />}
              width={"50%"}
              textcolor={"#042234"}
              text={"Options"} textSize={'14px'}
              padding={"10px"} mt={"0px"}
              onClick={handleEventOptionsClick}
            />
          </MultipleButtonWrapper>
        </Bottom>
      ) :
        (
          <Bottom>
            <ButtonWrapper>
              <Button
                iconFirst
                Icon={<ChatWithoutQuestion />}
                primary text={"Discuss this idea/event"}
                textSize={'14px'}
                width={"100%"}
                padding={"10px"}
                mt={"0px"}
                onClick={openChat}
              />
            </ButtonWrapper>
          </Bottom>
        )


      }


    </StyledContainer>
  );
};

export default IdeaScreen;
