import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importeer de standaardstijlen

// Voeg de CSS-stijlen voor .ql-editor toe als een JavaScript-object

function RichTextEditor({
  editorContent,
  setEditorContent,
  placeholder,
  ...rest
}) {
  const [editorHtml, setEditorHtml] = useState(""); // Houd de inhoud van de editor bij in de staat

  // Handel wijzigingen in de editor-inhoud af
  const handleEditorChange = (html) => {
    setEditorHtml(html);
    setEditorContent(html);
  };

  const formatPlaceholder = () => {
    return { __html: placeholder }; // Gevaarlijke HTML hier
  };

  return (
    <div>
      <div dangerouslySetInnerHTML={formatPlaceholder()} />
      <ReactQuill
        value={editorContent}
        onChange={handleEditorChange}
        modules={RichTextEditor.modules}
        formats={RichTextEditor.formats}
        style={{ height: "140px" }}
     
      />
    </div>
  );
}

// Configureer modules en formats voor de editor
RichTextEditor.modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    ["link"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

RichTextEditor.formats = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "link",
  "list",
  "bullet",
  "image",
];

export default RichTextEditor;
