import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationPage from "../../Components/MegaphonePage";

export default function WelcomePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedOption = location?.state?.selectedOption;

  return (
    <NotificationPage
      onButtonClick={() => navigate("/login")}
      buttonText={"To my account"}
      text={
        "We’ve created your account.<br/>To finish your registration, we ask you some questions in an onboarding survey, and more details.<br/>Please log in to continue the registration."
      }
    />
  );
}
