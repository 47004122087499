import styled from "styled-components";

import Modal from "../modals/Modal";
import useMessageSendModal from "../hooks/useMessageSendModal";
import { ParagraphM, SubheadingS, SubheadingXS } from "../../Components/Text";

const Content = styled.div`
  margin: 1.5rem;
  position: relative;

  hr {
    border-top: 1px solid var(--g-s50);
    margin-top: 16px;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;

  .sub-heading_s {
    margin-bottom: 16px;
  }
`;

const Block = styled.div`
  background-color: var(--b-b50);
  
  .timeline {
    margin: 20px auto;
    padding: 20px;
  }
  
  .outer {
    border-left: 2px solid var(--b-b200);
  }
  
  .card {
    position: relative;
    margin: 0 0 20px 20px;
    color: gray;
    border-radius: 8px;
  }
  
  .info {
    display: flex;
    flex-direction: column;
  }

  h3{
    color: var(--b-b300);
    position: relative;
    font-family: 'Avenir LT Std';
    font-size: 14px;
    line-height: 150%;
    font-weight: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  
  .title1::before {
    content: "1";
    position: absolute;
    width: 28px;
    height: 28px;
    background: #61B795;
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .title2::before {
    content: "2";
    position: absolute;
    width: 28px;
    height: 28px;
    background: #61B795;
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .title3::before {
    content: "3";
    position: absolute;
    width: 28px;
    height: 28px;
    background: #61B795;    
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .title4::before {
    content: "4";
    position: absolute;
    width: 28px;
    height: 28px;
    background: var(--b-b300);
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    display: flex;
  }

  .title5::before {
    content: "5";
    position: absolute;
    width: 28px;
    height: 28px;
    background: var(--b-b300);
    border-radius: 999px;
    color: white;
    left: -34px;
    justify-content: center;
    display: flex;
  }
`;

const MessageSendModal = ({onSubmit}) => {
    const messageSendModal = useMessageSendModal()

    const bodyContent = (
        <div>
            <Content>
              <Top>
                <SubheadingS>Your proposal for changes has been sent successfully</SubheadingS>
              </Top>
              <SubheadingXS color={"#4F4F4F"}>What happens next?</SubheadingXS>
              <Block>
                <div class="timeline">
                  <div class="outer">
                    <div class="card">
                      <div class="info">
                        <h3 class="title1">The project owner gets a notification that there is a request for changes.</h3>
                        {/* <ParagraphM>The company/brand or creative will review your pitch. No adjustments? Then your pitch will be accepted, and you are good to go. Adjustments? In the chat, you meet up privately and you can start negotiating.</ParagraphM> */}
                      </div>
                    </div>
                    <div class="card">
                      <div class="info">
                        <h3 class="title2">Project owner's response</h3>
                        <ParagraphM>He or she will accept your counteroffer and/or propose changes in the chat.</ParagraphM>
                      </div>
                    </div>
                    <div class="card">
                      <div class="info">
                        <h3 class="title3">Continue in the chat</h3>
                        <ParagraphM>You and the creative can both use the chat to go into depth and come to any agreement.</ParagraphM>
                      </div>
                    </div>
                    <div class="card">
                      <div class="info">
                        <h3 class="title3">Physical products</h3>
                        <ParagraphM>Are there physical products involved? Upon agreement, myThursd will reach out to both you and the creative to get the products delivered.</ParagraphM>
                      </div>
                    </div>
                  </div>
                </div>
              </Block>
            </Content>
        </div>
    )

    return (
        <Modal
            isOpen={messageSendModal.isOpen}
            onClose={messageSendModal.onClose}
            body={bodyContent}
            actionLabel="I understand"
            onSubmit={onSubmit}
        />);
}

export default MessageSendModal;