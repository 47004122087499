import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LayoutCenterTop } from "../../Components/Layout";
import LeftArrow from "../../Icons/LeftArrow";
import { HeadingXL, ParagraphM } from "../../Components/Text";
import { InputLabel } from "../../Components/Forms";
import { Button, RoundButtonLink } from "../../Components/UI";
import apiClient from "../../services/api";
import Toast from "../../Components/Common/Messages/Toast";


const ForgotPassword = () => {
  const navigate = useNavigate();

  const inputRef = useRef();
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [emailOrUsernameError, setEmailOrUsernameError] = useState(false);
  const [showToast, setShowToast] = useState(false);
  
  const requestPasswordCode = () => {
  apiClient
    .post("password/email", {
      email: emailOrUsername,
    })
    .then((resp) => {
        console.log(resp)
      if (resp.data.success || resp.success) {
        // Als succes true is, navigeer naar /phoneverification
        setEmailOrUsernameError('Success')
        setShowToast(true);

        setTimeout(() => {
          navigate("/phoneverification", {
            state: { email: emailOrUsername },
          });
        }, 1000);


      } else {
        // Als succes false is, toon een toastbericht
        setShowToast(true);
        setEmailOrUsernameError(resp.message)
      }
    })
    .catch((error) => {
      // Behandel eventuele fouten van de API-aanroep hier
      console.error("Fout bij het verwerken van de aanvraag:", error);
      setShowToast(true); // Toon ook een toastbericht in geval van fouten
      setEmailOrUsernameError(error.response.data.message)

    });
};

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <LayoutCenterTop>
      <Toast
        showToast={showToast}
        message={emailOrUsernameError}
        setShowToast={setShowToast}
      />
      <RoundButtonLink
        Icon={<LeftArrow height={"16px"} onClick={() => navigate("/login")} />}
      />
      <HeadingXL mt="40px">Forgot your password?</HeadingXL>
      <ParagraphM>
        No worries, just type your email and we will send a password
        reset link.
      </ParagraphM>
      <form onSubmit={handleSubmit}>
        <InputLabel
          error={emailOrUsernameError}
          value={emailOrUsername}
          onChange={(e) => setEmailOrUsername(e.target.value)}
          label="Email"
          ref={inputRef}
        ></InputLabel>
        <Button
          fwidth
          mt24
          primary
          text="Send reset password link"
          onClick={requestPasswordCode}
          className={'submit-btn'}
        />
      </form>
    </LayoutCenterTop>
  );
};

export default ForgotPassword;
