import axios from 'axios'

let token = ""
if (localStorage.getItem('token')) {
    try {
        token = JSON.parse(localStorage.getItem('token'))
    } catch(error) {
        token = localStorage.getItem('token')
    }
}
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_URL,
    headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': 'Bearer '+ token
    }
})

export default apiClient
