/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components';
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useDispatch } from 'react-redux';

/* -------------------------- Internal Dependencies ------------------------- */
import { HeadlineXL, ParagraphM, SubheadingS, } from '../../../Components/Text';
import { LayoutCenterTop } from '../../../Components/Layout';
import CloseButton from '../../../Icons/CloseButton';
import Visbility from './Visibility';
import CalendarIconFilled from '../../../Icons/CalendarIconFilled';
import CustomCalendar from './Calendar';
import { Button } from '../../../Components/UI';
import apiClient from '../../../services/api';
import { updateIdea } from '../../../app/features/marketplaceSlice';
import { formatDate, parseDateString } from '../../../Utils/utility-functions';

const HR = styled.div`
  border: 1px solid var(--g-s100);
  width: 100%;
  margin-top: 20px;
`

const TopContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: ${(props) => props.mt || "20px"};
  padding: 16px;
`


const InputContainer = styled.div`
  padding-top: 8px;
  position: relative;
  cursor: ${(props) => (props.disabledWithColor ? 'not-allowed' : 'pointer')}; 

  @media (max-width: 768px) {
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px
   }
`;

const InputWrapper = styled.div`
  position: relative;
  cursor: ${(props) => (props.disabledWithColor ? 'not-allowed' : 'pointer')}; 

`;

const Icon = styled.div`
  position: absolute;
  left: 12px;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
`;

const StyledInput = styled.div`
  height: 50px;
  background-color: white;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  padding: ${(props) => props.padding || "12px 16px"};
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #727272;
  width: 100%;
`;

const IconWrapper = styled.div`
  background-color: ${(props) => props.selectedVisibilityOption === "Different date" ? "#FF4E5B" : "#D8DCDF"};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%; 
  width: 36px; 
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(105, 123, 134, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const CalendarModal = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  width: 100%;

  @media (max-width: 768px) {
     margin: 20px;
  }
`;

const CalendarModalContent = styled.div`
  text-align: center;
`;

const ContentContainer = styled.div`
  margin: 0 24px;

  @media (max-width: 768px) {
    margin: 0;
}
`

const EditDeadline = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [data, setData] = useState(null)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedVisibilityOption, setSelectedVisibilityOption] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Default; Two months from now");
  const [defaultUntil, setDefaultUntil] = useState(null);
  const [today] = useState(new Date());
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [formattedDate, setFormattedDate] = useState(null)

  useEffect(() => {
    const selectedMarketplaceDataId = searchParams.get('id')
    apiClient.get(`/marketplace/${selectedMarketplaceDataId}`).then((response) => {
      setData(response.data.data);
      const formatedDate = formatDate(parseDateString(response.data.data.until))
      setFormattedDate(formatedDate)
    })

  }, [searchParams]);

  useEffect(() => {
    if (selectedOption === "Default; Two months from now") {
      const twoMonthsLater = new Date(today);
      twoMonthsLater.setDate(today.getDate() + 60);
      setSelectedDate(twoMonthsLater);
    }
  }, [selectedOption, today]);

  if (!data) {
    return null;
  }

  const toggleCalendarModal = () => {
    if (selectedOption === "Default; Two months from now") {
      setIsCalendarOpen(false);
    } else if (selectedOption === null) {
      setIsCalendarOpen(false);
    } else {
      setIsCalendarOpen(!isCalendarOpen);
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setIsDateSelected(true);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSelectedVisibilityOption(option);
    if (option === "Default; Two months from now") {
      setSelectedDate(defaultUntil);
      setIsDateSelected(true);
    }
  };


  const handleUpdateIdea = () => {
    if (selectedOption) {

      const userTimeZone = 'Europe/Amsterdam'
      const adjustedDate = utcToZonedTime(selectedDate, userTimeZone);

      const formattedDate = format(adjustedDate, 'yyyy-MM-dd');

      const ideaData = {
        title: data.title,
        requesting: data.requesting,
        returning: data.returning,
        details: data.details,
        until: formattedDate,
      };
      const marketPlaceId = searchParams.get('id')

      dispatch(updateIdea({
        data: ideaData, id: marketPlaceId
      }))
        .then((response) => {
          if (response.error) {
            console.error('Error:', response.error);
          } else {
            navigate('/meetupspot');
          }
        })
        .catch((error) => {
          console.error('Network error:', error);
        });
    }
  }

  return (
    <LayoutCenterTop>
      <TopContainer mt={"16px"}>
        <div style={{ cursor: 'pointer' }} onClick={() => navigate("/meetupspot")}>
          <CloseButton />
        </div>
      </TopContainer>
      <HeadlineXL mt={"8px"}>Edit deadline of {data.title}</HeadlineXL>
      <ParagraphM mr={"24px"} color={"#4F4F4F"}>
        Current deadline: {formattedDate || selectedDate?.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })}
      </ParagraphM>
      <HR />
      <ContentContainer>
        <Visbility
          defaultOption={"Default; Two months from now"}
          onOptionClick={handleOptionClick}
          selectedOption={selectedOption}
          title={"Visible until?"} details={"By default, your project will be visible for two months in the meetup. You can change that period below."} />
        <SubheadingS mt={"8px"}>Your project is visible until...</SubheadingS>
        <div onClick={toggleCalendarModal}>
          <InputContainer>
            <InputWrapper>
              <IconWrapper selectedVisibilityOption={selectedVisibilityOption}  >
                <Icon>
                  <CalendarIconFilled />
                </Icon>
              </IconWrapper>
              <StyledInput>
                <ParagraphM mt={'0px'} ml={'32px'}>
                  {selectedDate?.toLocaleDateString('en-GB', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  }).split('/').join('-') || isDateSelected ? selectedDate?.toLocaleDateString('en-GB', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  }).split('/').join('-') : formattedDate}
                </ParagraphM>
              </StyledInput>
            </InputWrapper>
          </InputContainer>
        </div>

        {isCalendarOpen && (
          <Background>
            <CalendarModal >
              <CalendarModalContent>
                <CustomCalendar onSelectDate={handleDateSelect} />
                <Button textcolor={"042234"} onClick={toggleCalendarModal} text={"Confirm date"} />
              </CalendarModalContent>
            </CalendarModal>
          </Background>
        )}
        <Button text={"Save idea/event"}
          fwidth
          primary
          onClick={handleUpdateIdea}
          cursor={isDateSelected && selectedOption ? 'pointer' : 'not-allowed'}
        />
      </ContentContainer>
    </LayoutCenterTop>
  )
};
export default EditDeadline;
