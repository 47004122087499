import React from "react";

const CheckBox = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      {...props}
    >
      <path
        fill="A4AFB6"
        d="M8.17969 14.3438C7.94531 14.5781 7.51562 14.5781 7.28125 14.3438L5.09375 12.1562C4.85938 11.9219 4.85938 11.4922 5.09375 11.2578C5.32812 11.0234 5.75781 11.0234 5.99219 11.2578L7.75 13.0156L11.6562 9.07031C11.8906 8.83594 12.3203 8.83594 12.5547 9.07031C12.7891 9.30469 12.7891 9.73438 12.5547 9.96875L8.17969 14.3438ZM17.5156 5.90625C17.6719 6.21875 17.75 6.57031 17.75 6.92188V15.75C17.75 17.1562 16.6172 18.25 15.25 18.25H2.75C1.34375 18.25 0.25 17.1562 0.25 15.75V6.92188C0.25 6.57031 0.289062 6.21875 0.445312 5.90625L2.08594 2.27344C2.47656 1.33594 3.375 0.75 4.35156 0.75H13.6094C14.5859 0.75 15.4844 1.33594 15.875 2.27344L17.5156 5.90625ZM9.625 5.75H16.0703L14.7422 2.74219C14.5469 2.3125 14.1172 2 13.6094 2H9.625V5.75ZM1.5 7V15.75C1.5 16.4531 2.04688 17 2.75 17H15.25C15.9141 17 16.5 16.4531 16.5 15.75V7H1.5ZM8.375 2H4.35156C3.84375 2 3.41406 2.3125 3.21875 2.74219L1.89062 5.75H8.375V2Z"
      ></path>
    </svg>
  );
}

export default CheckBox;

