/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from 'styled-components';

const StyledSection = styled.section`
min-height: 100vh;

	width: 100%;	
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;


	@media (min-width: 768px) {
		background: #687A85;
		align-items: center;	
	}
`
const StyledWrapper = styled.div`
width: 100%;
	height: 100%;
	padding: 24px;
	box-sizing: border-box;
	position: relative;

	@media (min-width: 768px) {
		max-width: 768px;
		max-height: 744px;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 40px;
		border-radius: 8px;
		background: #fff;
	}`

const LayoutCenter = ({children}) => {
  return (
    <StyledSection>
        <StyledWrapper>
            {children}
        </StyledWrapper>
    </StyledSection>
  )
}

export default LayoutCenter
