export const handleOtherCheckboxChange = (
    fieldName,
    fieldValue,
    setValue,
    checkboxLabel,
    checked
  ) => {
    if (checked) {
      setValue(fieldName, { ...fieldValue, [checkboxLabel]: "" });
    } else {
      const { [checkboxLabel]: _, ...rest } = fieldValue;
      setValue(fieldName, rest);
    }
  };


  export const handleCheckboxValueChange = (name, checked, key, value, setValue, values) => {
    const currentValues = values || {};
    const updatedValues = { ...currentValues };

    if (checked) {
      updatedValues[key] = value;
    } else {
      delete updatedValues[value];
    }
    setValue(name, updatedValues);
  };
