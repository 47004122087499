import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../Theme/theme";

const Container = styled.div`
  position: relative;
  width: 20em;
  min-height: 48px;
  background-color: white;
  border: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.5em;
  border-radius: 0.25em;
  outline: none;
  &:focus {
    border: 2px solid ${theme.main};
  }
`;

const Value = styled.span`
  flex-grow: 1;
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;

  p {
    font-family: "Avenir LT Std";
    margin-top: 4px;
  }
`;

const Caret = styled.div`
  translate: 0 25%;
  border: 0.25em solid transparent;
  border-top-color: #777;
`;

const Options = styled.div`
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  max-height: 15em;
  overflow-y: auto;
  border: 1px solid #e3e3e3;
  border-radius: 0.25em;
  width: 100%;
  left: 0;
  ${({ position }) =>
    position === "top"
      ? "bottom: calc(100% + 0.25em);"
      : "top: calc(100% + 0.25em);"}
  background-color: white;
  z-index: 100;

  &.show {
    display: block;
  }
`;

const Option = styled.div`
  padding: 0.5em 0.5em;
  cursor: pointer;

  &.selected {
    background-color: ${theme.gs50};
  }

  &.highlighted {
    background-color: hsl(0, 100%, 90%);
    border-color: hsl(0, 100%, 50%);
  }

  p {
    font-family: "Avenir LT Std";
    margin-top: 4px;
  }
`;

const OptionBadge = styled.button`
  display: flex;
  align-items: center;
  border-radius: 10em;
  padding: 0.5em 1em;
  gap: 0.5em;
  cursor: pointer;
  background: ${theme.gs50};
  outline: none;

  p {
    font-family: "Avenir LT Std";
    margin-top: 2px;
    font-size: 14px;
  }

  &:hover,
  &:focus {
    background-color: hsl(0, 100%, 90%);
    border-color: hsl(0, 100%, 50%);
  }

  &:hover > .remove-btn,
  &:focus > .remove-btn {
    color: hsl(0, 100%, 50%);
  }
`;

const RemoveButton = styled.p`
  font-size: 14px;
  color: ${theme.bb500};
`;

const Placeholder = styled.p`
  color: #999;
  margin: 0;
  flex: 1;
  font-family: "Avenir LT Std";
  margin-top: 3px;
`;

export function Select({ multiple, value, onChange, options, placeholder }) {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const containerRef = useRef(null);
  const optionsRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState("bottom");

  function selectOption(option) {
    if (multiple) {
      if (value.includes(option.value)) {
        onChange(value.filter((o) => o !== option.value));
      } else {
        onChange([...value, option.value]);
      }
    } else {
      if (option.value !== value) onChange(option.value);
    }
  }

  function isOptionSelected(option) {
    return multiple ? value.includes(option.value) : option.value === value;
  }

  useEffect(() => {
    if (isOpen) setHighlightedIndex(0);
  }, [isOpen]);

  useEffect(() => {
    const handler = (e) => {
      if (e.target !== containerRef.current) return;
      switch (e.code) {
        case "Enter":
        case "Space":
          setIsOpen((prev) => !prev);
          if (isOpen) selectOption(options[highlightedIndex]);
          break;
        case "ArrowUp":
        case "ArrowDown": {
          if (!isOpen) {
            setIsOpen(true);
            break;
          }

          const newValue = highlightedIndex + (e.code === "ArrowDown" ? 1 : -1);
          if (newValue >= 0 && newValue < options.length) {
            setHighlightedIndex(newValue);
          }
          break;
        }
        case "Escape":
          setIsOpen(false);
          break;
        default:
          break;
      }
    };
    containerRef.current?.addEventListener("keydown", handler);

    return () => {
      containerRef.current?.removeEventListener("keydown", handler);
    };
  }, [isOpen, highlightedIndex, options]);

  const getPosition = () => {
    if (!isOpen) return;
    const rect = containerRef.current.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const position = rect.top < windowHeight / 2 ? "bottom" : "top";
    setDropdownPosition(position);
  };

  useEffect(() => {
    getPosition();
  }, [isOpen]);

 const selectedOptions = options.filter((option) =>
    multiple ? value.includes(option.value) : option.value === value
  );

  return (
    <Container
      ref={containerRef}
      onBlur={() => setIsOpen(false)}
      onClick={() => setIsOpen((prev) => !prev)}
      tabIndex={0}
    >
      {selectedOptions.length > 0 ? (
        <Value>
          {multiple
            ? selectedOptions.map((v) => (
                <OptionBadge
                  key={v.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    selectOption(v);
                  }}
                >
                  <p>{v.label}</p>
                  <RemoveButton className="remove-btn">&times;</RemoveButton>
                </OptionBadge>
              ))
            : <p>{selectedOptions[0]?.label}</p>}
        </Value>
      ) : (
        <Placeholder>{placeholder}</Placeholder>
      )}
      <Caret />
      <Options
        className={isOpen ? "show" : ""}
        position={dropdownPosition}
        ref={optionsRef}
      >
        {options.map((option, index) => (
          <Option
            onClick={(e) => {
              e.stopPropagation();
              selectOption(option);
              setIsOpen(false);
            }}
            onMouseEnter={() => setHighlightedIndex(index)}
            key={option.value}
            className={`${isOptionSelected(option) ? "selected" : ""} ${
              index === highlightedIndex ? "highlighted" : ""
            }`}
          >
            <p>{option.label}</p>
          </Option>
        ))}
      </Options>
    </Container>
  );
}
