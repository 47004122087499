import { useNavigate } from 'react-router-dom';
import styled from "styled-components";

import PageHeader from "../../Common/PageHeader";
import { Container } from "../../Layout";
import { HeadlineM } from "../../Text";
import { Button } from "../../UI";
import { openEmailTeam } from '../../../Utils/utility-functions';

const Wrapper = styled.div`
    width: 100%;
`
const ButtonWrapper = styled.div`
    display: flex;
    gap: 1rem;
    padding-top: 8px;

    @media (max-width: 1224px) {
        margin-right: 16px;
      }
    @media (max-width: 768px){
        flex-direction: column;
        gap: 0.5rem;

    }
    `
const CampaignHeaderInfluencer = () => {
    const navigate = useNavigate();

    return (
        <Wrapper>
            <PageHeader>
                <Container>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignItems: 'center' }}>
                        <HeadlineM mt={'0px'} color={'#ffffff'}>
                            Meetup Spot
                        </HeadlineM>
                        <ButtonWrapper>
                            <Button
                                primary
                                padding={"10px 16px"}
                                text="Talk to a team member"
                                textSize={"12px"}
                                mt={"0px"}
                                onClick={openEmailTeam}
                            />
                            <Button
                                primary
                                padding={"10px 32px"}
                                text="Create a project"
                                textSize={"12px"}
                                mt={"0px"}
                                onClick={() => { navigate('/projectsbycreatives/idea') }}
                            />
                        </ButtonWrapper>
                    </div>
                </Container>
            </PageHeader>
        </Wrapper>
    );
}

export default CampaignHeaderInfluencer;