import styled from 'styled-components'
import { theme } from "../../../Theme/theme";

const StyledIcon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
    height: 20px;
    width: 20px;
`;

const StyledCheckbox = styled.div`
	width: 20px;
	height: 20px;
	border: 1px solid ${theme.main};
	background: ${(p) => (p.checked ? theme.main : theme.white)};
	border-radius: 2px;
	transition: all 150ms;
    display: flex;
    align-items: center;
    justify-content: center;


	${StyledIcon} {
		visibility: ${(p) => (p.checked ? "visible" : "hidden")};
	}
`;
const CheckBoxIcon = ({ checked, Icon }) => {
    return (
        <StyledCheckbox
            checked={checked}
        >
            {Icon || (
                <StyledIcon viewBox="0 0 24 24">
                    <polyline points="5,12 9,16 19,6" />
                </StyledIcon>
            )}
        </StyledCheckbox>
    )
}

export default CheckBoxIcon
