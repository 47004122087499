/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import styled from "styled-components";

const StyledHeadlineS = styled.h1`
	margin-top: ${(p) => (p.mt ? p.mt : "24px")};
	color: ${(p) => p.color && p.color};
`;
const HeadlineS = ({ mt, children, color }) => {
	return (
		<StyledHeadlineS mt={mt} color={color} className="headline_s">
			{children}
		</StyledHeadlineS>
	);
};

export default HeadlineS;
