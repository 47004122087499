import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import NavigationBar from "../../../Components/Common/Navbar/NavigationBar";
import Header from "../../../Components/Common/PageHeader";
import SubheadingXXXS from "../../../Components/Text/SubheadingXXXS";
import LeftArrow from "../../../Icons/LeftArrow";
import { HeadlineL } from "../../../Components/Text";
import { Container } from "../../../Components/Layout";
import { truncateText } from "../../../Utils/utility-functions";

const Button = styled.button`
  position: relative;
  background-color: var(--p500);
  color: var(--g-s50); 
  padding: 6px 10px 6px 10px;
  border: none;
  box-shadow: 0px 1px 0px #982e36;
  display: inline-block;
  z-index: 2; 
  cursor: pointer;

  .sub_heading_xxxs {
    align-items: center;
    font-weight: 400;
  }

  &:hover {
		background: #982e36;
  }

  &:hover {
    background-color: #982e36;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  
  .left-arrow {
    padding-right: 8px;
    align-items: center;
  }

`;

const Chatheader = ({ actionLabel, headerTitle, href}) => {
  const navigate = useNavigate();
  
  const navigateBack = ()=>{

    if(href?.length > 0){
      navigate(href)
    }else{
      window.history.back()
    }
  }
  return (
    <div>
      <NavigationBar />
      <Header>
        <Container>
          <Button onClick={navigateBack}>
            <Wrapper>
              <LeftArrow fill={"#ffffff"} />
              <SubheadingXXXS mt={"0px"}>{actionLabel}</SubheadingXXXS>
            </Wrapper>
          </Button>
          <HeadlineL color={"white"} mt={"8px"}>{truncateText(headerTitle, 40)}</HeadlineL>
        </Container>
      </Header>
    </div>
  );
}

export default Chatheader