import React from 'react'

const Microphone = (props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        className='microphone'
        fill={props.fill} // Hier voeg je de fill-kleur toe

        {...props}
      >
        <path
          fill={props.fill}
          d="M15.875 2.125C14.375 0.65625 11.9688 0.65625 10.5 2.125L9.59375 3.03125C8.90625 3.71875 8.5 4.6875 8.5 5.6875L1.375 13.7188C0.84375 14.2812 0.875 15.2188 1.40625 15.7812L2.1875 16.5625C2.75 17.125 3.65625 17.1562 4.25 16.625L8.21875 13.125V16.25C8.21875 16.6875 8.53125 17 8.9375 17C9.34375 17 9.6875 16.6875 9.6875 16.25V11.7812L12.25 9.53125C13.25 9.53125 14.2188 9.125 14.9375 8.4375L15.8125 7.53125C17.3438 6.03125 17.3438 3.625 15.875 2.125ZM3.28125 15.5L2.5 14.6875L9.15625 7.1875L10.7812 8.8125L3.28125 15.5ZM12.2812 8C12.2188 8 12.125 8 12.0625 8L10 5.9375C9.9375 5.3125 10.1562 4.65625 10.5938 4.1875L13.8125 7.40625C13.4062 7.8125 12.8438 8 12.2812 8ZM14.875 6.34375L11.6562 3.125C12.5312 2.28125 13.9375 2.3125 14.8125 3.1875C15.6875 4.0625 15.6875 5.4375 14.875 6.34375Z"
        ></path>
      </svg>
    );
  }
  
  export default Microphone;