import React from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { SubheadingXS } from "../../Text";
import { FlexContainer } from "../../Layout";
import { theme } from "../../../Theme/theme";

const CheckboxContainer = styled.div`
  position: relative;
  margin-top: ${(p) => (p.mt ? p.mt : "16px")};
  pointer-events: ${(p) => (p.disabled ? "none" : "initial")};
`;

const StyledIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 1.5px;
`;

const StyledCheckbox = styled.div`
  border-radius: 3px;
  width: 20px;
  height: 20px;
  border: ${(p) =>
    p.grey && p.checked
      ? "1px solid #A4AFB6"
      : `1px solid ${theme.main}`};
  background: ${(p) =>
    p.checked ? (p.grey ? "#A4AFB6" : theme.main) : theme.white};
  transition: all 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${StyledIcon} {
    visibility: ${(p) => (p.checked ? "visible" : "hidden")};
    margin-bottom: 1px;
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  
`;
const Label = styled.label`
  cursor: pointer;
`

const CustomCheckbox = ({
  text,
  id,
  checked,
  onChange,
  disabled,
  ...props
}) => {
  const handleCheckboxClick = () => {
    onChange(!checked);
  };

  return (
    <CheckboxContainer mt={props.mt} disabled={disabled}>
      <FlexContainer gap8>
        <div>
          <HiddenCheckbox
            id={id}
            checked={checked}
            onChange={handleCheckboxClick}
            {...props}
          />
          <StyledCheckbox
            tabIndex={-1}
            checked={checked}
            onClick={handleCheckboxClick}
          >
            <StyledIcon viewBox="0 0 23 23">
              <polyline points="18 9 9 17 4 12" />
            </StyledIcon>
          </StyledCheckbox>
        </div>
        <Label htmlFor={id}>
          <SubheadingXS>{text}</SubheadingXS>
        </Label>
      </FlexContainer>
    </CheckboxContainer>
  );
};

export default CustomCheckbox;
