import React from 'react';
import styled from 'styled-components';
import { IoMdClose } from "react-icons/io";
import { BiWifiOff } from "react-icons/bi";
import { PiGlobe } from "react-icons/pi";
import { FiExternalLink } from "react-icons/fi";
import { AiOutlineInstagram } from "react-icons/ai";

import { ParagraphS, SubheadingXXS } from '../../../Components/Text';
import IconRound from '../../IconRound';
import T from '../../../Icons/T';

const ChannelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 1px;
  border: 1px solid var(--GS100, #E3E3E3);
  box-shadow: 0px 1px 0px 0px #E3E3E3;
  background: #FFF;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 88px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 39px
`;

const RemoveButton = styled.button`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px 14px;
  background: var(--bb-500-secondary, #042234);
  color: #fff;
  text-align: left;
  cursor: pointer;

  &:active {
    opacity: 0.5;
 }
`

const ExternalButton = styled.button`
 margin-bottom: 34px;
 background-color: transparent;
 border: none;
`


export default function Channel({ channel, platform, removeChannel }) {

  if (/*platform === 'Instagram' ||*/ platform === 'Facebook' || platform === 'Pinterest') {
    // Als het platform 'instagram', 'facebook' of 'pinterest' is, retourneer null
    return null;
  }

  const platformIcons = {
    offline: <BiWifiOff size={24} color="#031B2A" />,
    thursd: <T height={"100px"} />,
    websites: <PiGlobe size={24} color={"#031B2A"} />,
    instagram: <AiOutlineInstagram size={24} color="#031B2A" />,
  };

  function formatInstagramName(inputString) {
    // Verwijder spaties uit de tekenreeks
    const stringWithoutSpaces = inputString.replace(/\s/g, '');

    // Controleer of het "@"-symbool aan het begin staat en verwijder het indien aanwezig
    if (stringWithoutSpaces.charAt(0) === '@') {
      return stringWithoutSpaces.slice(1);
    }

    return stringWithoutSpaces;
  }

  return (
    <ChannelContainer>
      <Content>
        <LeftContainer>
          <IconRound Icon={platformIcons[platform.toLowerCase()]} />
          <div>
            <ParagraphS mt="0px" color="#262626">{channel}</ParagraphS>
            {platform === 'Thursd' && (
              <a href={`https://www.thursd.com/author/${channel}`} target="_blank" rel="noopener noreferrer">
                <ParagraphS mt="0px" color="#959595">Blogger on Thursd</ParagraphS>
              </a>
            )}
          </div>
        </LeftContainer>
        {platform === 'Thursd' && (
          <ExternalButton>
            <a href={`https://www.thursd.com/author/${channel}`} target="_blank" rel="noopener noreferrer">
              <FiExternalLink size={24} color={"#031B2A"} />
            </a>
          </ExternalButton>
        )}
        {platform === 'Websites' && (
          <ExternalButton>
            <a href={channel.startsWith('http://') || channel.startsWith('https://') ? channel : `https://${channel}`} target="_blank">
              <FiExternalLink size={24} color={"#031B2A"} />
            </a>
          </ExternalButton>
        )}
        {platform === 'Instagram' && (
          <ExternalButton>
            <a  href={channel.startsWith('http://') || channel.startsWith('https://') ? channel : `https://instagram.com/${formatInstagramName(channel)}`} target="_blank">
              <FiExternalLink size={24} color={"#031B2A"} />
            </a>
          </ExternalButton>
        )}
      </Content>
      {removeChannel &&
        <RemoveButton onClick={removeChannel}>
          <SubheadingXXS>Remove this channel</SubheadingXXS>
          <IoMdClose size={14} />
        </RemoveButton>
      }
    </ChannelContainer>
  );
}
