import styled from "styled-components";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";

import usePitchOptionsModal from "../../app/hooks/usePitchOptionsModal";
import Modal from "../modals/Modal";
import PitchOption from "../../Components/Common/Chat/PitchOption";
import useDeletePitchModal from "../hooks/useDeletePitch";

const Line = styled.div`
  border-bottom: 1px solid #e0e0e0;
`;
const PitchOptionsModal = () => {
  const user = useSelector((state) => state.profile.profile);
  const pitchOptionsModal = usePitchOptionsModal();
  const deletePitchModal = useDeletePitchModal();

  const openDeletePitchModal = () => {
    pitchOptionsModal.onClose();
    deletePitchModal.onOpen();
  };

  const bodyContent = (
    <div>
      <PitchOption
        textAlign="left"
        title="...Close this pitch"
        Icon={<IoMdClose size={16} color={"#FF4E5B"} />}
        onClick={openDeletePitchModal}
      />
      <Line />
    </div>
  );
  return (
    <Modal
      title={`Hello ${user?.data?.firstname || user?.data?.user?.firstname}`}
      isOpen={pitchOptionsModal.isOpen}
      onClose={pitchOptionsModal.onClose}
      body={bodyContent}
    />
  );
};

export default PitchOptionsModal;
