
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from 'react-router-dom';

import Navbar from "../../../Components/Common/Navbar/NavigationBar";
import ChatFilter from "../../Common/Chat/ChatFilter";
import PageHeader from "../../Common/PageHeader";
import { Container } from "../../../Components/Layout";
import apiClient from "../../../services/api";
import MarketplaceHeader from "./MarketplaceHeader";

const StyledContainer = styled.div`
  width: 100%;
  position: relative;
`
const MarketplaceOverview = () => {
  const [marketplaceData, setMarketplaceData] = useState(null)
  const params = useParams();

  useEffect(() => {
    if (params?.id) {
      apiClient.get(`/marketplace/${params.id}`).then((resp) => {
        const { data } = resp.data;
        setMarketplaceData(data)
      })
    }

  }, [params])

  return (
    <StyledContainer>
      <Navbar />
      <PageHeader
        backgroundImage={marketplaceData?.image}
      >
        <Container>
          {marketplaceData &&
            <MarketplaceHeader
              buttonTitle={'Back'}
              headerTitle={marketplaceData?.title}
              headerImage={marketplaceData?.image}
            />}
        </Container>
      </PageHeader>
      {marketplaceData && <ChatFilter marketplaceData={marketplaceData}/>}
    </StyledContainer>
  );
}

export default MarketplaceOverview;