import React from "react";

const LeftArrow = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      className="left-arrow"
      {...props}
    >
      <path
        fill={props.fill}
        d="M13.969 7c0 .438-.344.781-.75.781H2.594L6.75 11.72a.746.746 0 01.031 1.062c-.281.313-.75.313-1.062.031l-5.5-5.25A.753.753 0 010 7c0-.188.063-.375.219-.531l5.5-5.25C6.03.937 6.5.937 6.78 1.25a.746.746 0 01-.031 1.063L2.594 6.25h10.625a.74.74 0 01.75.75z"
      ></path>
    </svg>
  );
}

export default LeftArrow;