import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";
import { SubheadingS, ParagraphM, ParagraphS } from '../../Components/Text';

const Section = styled.div`
    margin-top: 16px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02);
    border: 1px solid #E3E3E3;
    border-radius: 8px;
    padding: 20px 16px;

  @media (max-width: 770px) {
    width: 90vw;
  }
`

const AccordionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    cursor: pointer;

    &:hover {
        .accordion-btn{
            background-color: var(--g-s100);
        }
    }
    
`
const TitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`

const ButtonContainer = styled.div`
    margin-left: 16px;
`

const Button = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px #D8DCDF;
    background: #D8DCDF;
    border-radius: 50%;
    cursor: pointer;
`
const OpenButton = styled(Button)`
    transform: ${(p) => (p.arrowButtonClick ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform .15s ease-in, background-color .2s;
`
const AccordionContent = styled.div`
    width: 100%;
    overflow: hidden;
    transition: 0.5s ease-in-out;
    max-height: ${props => props.arrowButtonClick ? "auto" : "0"};
`

const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: #E3E3E3;
    transition: all 0.5s ease-in-out;
    opacity: ${props => props.arrowButtonClick ? "1" : "0"};
    margin: ${props => props.arrowButtonClick ? "16px 0" : "0"};
`

const Accordion = ({ Icon, title, text, children, open, toggleAccordion }) => {
    const [arrowButtonClick, setArrowButtonClick] = useState(open);

    useEffect(() => {
        setArrowButtonClick(open);
    }, [open]);

    const handleToggle = () => {
        toggleAccordion();
        setArrowButtonClick(!arrowButtonClick);
    };

    return (
        <Section>
            <AccordionHeader onClick={handleToggle}>
                <div>
                    <TitleContainer>
                        {Icon || null}
                        <SubheadingS mt="0px">{title}</SubheadingS>
                        {text && <ParagraphS mt="0px">{text}</ParagraphS>}

                    </TitleContainer>
                </div>
                <ButtonContainer>
                    <OpenButton 
                        className='accordion-btn'
                        arrowButtonClick={arrowButtonClick}>
                        {arrowButtonClick? <FiMinus /> : <FiPlus />}
                    </OpenButton>
                </ButtonContainer>
            </AccordionHeader>
            <Separator arrowButtonClick={arrowButtonClick} />
            <AccordionContent arrowButtonClick={arrowButtonClick}>
                {children}
            </AccordionContent>
        </Section>
    )
}

export default Accordion;
