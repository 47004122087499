import styled from 'styled-components';

import { RoundButtonLink } from '../../../UI';
import Pinterest from '../../../../Icons/Pinterest';
import Facebook from '../../../../Icons/Facebook';
import T from '../../../../Icons/T';
import Globe from '../../../../Icons/Globe';
import WifiSlash from '../../../../Icons/WifiSlash';
import Instagram from '../../../../Icons/Instagram';

const Container = styled.div`
  display: flex;

  .round-button {
    background-color: var(--b-b50);
    display: flex;
    margin-left: -9px;
    border: 1px solid var(--b-b100);
    cursor: default;
  }
`
 
const CampaignBadges = ({ channels, data }) => {
  const icons = {
    instagram: <Instagram height={"100px"} />,
    facebook: <Facebook height={"100px"} />,
    pinterest: <Pinterest height={"100px"} />,
    thursd: <T height={"100px"} />,
    other: <Globe height={"100px"} />,
    offline: <WifiSlash height={"100px"} />,
  };

  // Check if either channels or data is defined and not null
  if (channels || data) {
    const badges = Object.entries(channels || data).map(([channel, isActive]) => {
      if (isActive && icons[channel]) {
        return (
          <RoundButtonLink
            key={channel}
            height={"32px"}
            width={"32px"}
            fill={"#FFF"}
            Icon={icons[channel]}
          />
        );
      }
      return null;
    });

    return <Container>{badges}</Container>;
  }

  // Return null if neither channels nor data is defined
  return null;
};

export default CampaignBadges;
